import { useEffect, useState } from "react";
import { Chip, Tooltip } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_NEW,
  show_proper_words,
} from "src/utils/constant";
import { convertCurrencyToSign } from "src/utils/constant";
import moment from "moment";
import { s3baseUrl } from "src/config/config";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import FilteredChip from "src/components/FilteredChip";
import { download_csv_file } from "src/utils/convertHtml";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import MemberTransactionsFilter from "../MembersList/MemberTransactionsFilter";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import GeneralRequestForCsv from "../MembersList/GeneralRequestForCsv";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
let member_options = [
  {
    label: "First Name",
    value: "first_name",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Last Name",
    value: "last_name",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Email", value: "email", is_checked: true, is_disabled: true },
  {
    label: "Contact Number",
    value: "contact_number",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Amount", value: "amount", is_checked: false },
  {
    label: "Dynamite_commission",
    value: "dynamite_commission",
    is_checked: false,
  },
  {
    label: "Referral_commission",
    value: "referral_commission",
    is_checked: false,
  },
  {
    label: "Transaction_date",
    value: "transaction_date",
    is_checked: false,
  },
  { label: "Referral_user", value: "referral_user", is_checked: false },
  {
    label: "Transaction_referral_commission",
    value: "transaction_referral_commission",
    is_checked: false,
  },
  {
    label: "Transaction_referral",
    value: "transaction_referral",
    is_checked: false,
  },
  { label: "Ticket_count", value: "ticket_count", is_checked: false },
  {
    label: "Transaction_type",
    value: "transaction_type",
    is_checked: false,
  },
  { label: "Other_info", value: "other_info", is_checked: false },
  {
    label: "Order_products",
    value: "order_products",
    is_checked: false,
  },
];

export default function MemberTransactionsHoistory({
  tabData,
  searchFunction,
  searchText,
  setSearchText,
  pageCount,
  totalPages,
  handleChangePages,
  custom_pagination,
  handleOpenFilterDrawer,
  filterStateUpdated,
  filterData,
  EMPTY_FILTER,
  ALTER_FILTER,
  handleDeleteChip,
  handleClearFilter,
  filterQuery,
}) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [transactionsData, setTransactionsData] = useState([]);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [showExportcsvFile, setShowExportcsvFile] = useState(false);
  const showPopUPcsv = () => {
    setShowExportcsvFile(true);
  };
  const replace_space = (string) => {
    return string.replace(/,/g, " ");
  };
  const getTransactionsListing = () => {
    setIsLoading(true);
    const transactions = tabData?.map((transaction) => {
      let currency_sign = convertCurrencyToSign(transaction.currency);
      let referral_name = "No Referred User";

      let transactionType = "N/A";
      let user_name_for_export = "";
      let user_email_for_export = "";
      let payment_request_title = "N/A";
      let member_obj = transaction?.member_info;
      if (transaction.payment_request_info) {
        payment_request_title = transaction.payment_request_info?.request_title;
      }
      if (
        transaction.transaction_type == "payment_plan" &&
        transaction.sale_page_info?.sale_page_title
      ) {
        transactionType =
          "Sale Page (" + transaction.sale_page_info.sale_page_title + ")";
      } else if (
        transaction.transaction_type == "payment_request" &&
        transaction.payment_request_info?.request_title
      ) {
        transactionType =
          "Payment Request (" +
          transaction.payment_request_info.request_title +
          ")";
      } else if (transaction.transaction_type == "dynamite_product_purchase") {
        transactionType = "Dynamite Shop";
      }

      let affliliate = transaction.affliliate;
      let affiliate_info = affliliate?.affiliate_user_info;
      if (affiliate_info) {
        referral_name =
          affiliate_info.first_name + " " + affiliate_info.last_name;
      }
      if (affliliate?.affiliate_url_name) {
        referral_name =
          referral_name + "(" + affliliate.affiliate_url_name + ")";
      }

      let dynamite_commissions = currency_sign + 0;
      if (transaction.dynamite_commission) {
        dynamite_commissions = currency_sign + transaction.dynamite_commission;
      }

      let referral_commissions = currency_sign + 0;
      if (transaction.referral_commission) {
        referral_commissions = currency_sign + transaction?.referral_commission;
      }

      let transaction_referral_commissions = currency_sign + 0;
      if (transaction.transaction_referral_commission)
        transaction_referral_commissions =
          currency_sign + transaction.transaction_referral_commission;

      let transactionDate = "N/A";
      if (transaction.transaction_date) {
        transactionDate = moment
          .utc(transaction.transaction_date)
          .format("DD-MM-YYYY hh:mm A");
      }
      let transaction_amount = currency_sign + transaction.amount.toFixed(2);
      if (member_obj) {
        user_name_for_export =
          member_obj.first_name + " " + member_obj.last_name;
      }
      if (member_obj) {
        user_email_for_export = member_obj.email;
      }

      let tickets_count = transaction?.tickets?.length;
      let t_created_by = transaction.created_by;

      let other_info_menu = "";
      let other_if = transaction?.other_info;
      let concatenatedString;
      if (transaction.other_info) {
        let other_info = transaction.other_info;
        concatenatedString =
          '"' +
          `Main Menu: ${replace_space(
            other_info.main_menu
          )}\nStarter Menu: ${replace_space(
            other_info.starter_menu
          )}\nTea And Coffee Menu: ${replace_space(
            other_info.tea_and_coffee_menu
          )}\nDietary Requirements: ${replace_space(
            other_info.dietary_requirements
          )}\nDessert Menu: ${replace_space(other_info.dessert_menu)}` +
          '"';
      }

      return {
        ...transaction,
        totat_amount: currency_sign + transaction?.amount,
        transactionType,
        user_name_for_export: user_name_for_export,
        user_email_for_export: user_email_for_export,
        transaction_amount: transaction_amount,
        payment_request_title: payment_request_title,
        get_transaction_type: get_transaction_type(transaction),
        dynamite_commissions,
        referral_commissions,
        transactionDate,
        other_info_menu_export: concatenatedString ? concatenatedString : "N/A",
        transaction_referral_commissions,
        tickets_count: tickets_count ? tickets_count : "0",
        t_created_by: t_created_by == "admin_user" ? "Admin" : t_created_by,
        other_info_menu: other_if ? other_info_menu : "N/A",
        referral_name: referral_name,
      };
    });
    setTransactionsData(transactions);

    setIsLoading(false);
  };

  const get_transaction_type = (row) => {
    let t_type = "Sale Page";
    let transaction_type = row.transaction_type;
    let sale_page = row?.sale_page_info?.sale_page_title;
    let plan_title = row?.plan_info?.plan_title;
    let request_title = row?.payment_request_info?.request_title;
    if (transaction_type == "payment_plan" && sale_page) {
      t_type =
        "Sale Page (" +
        sale_page +
        " | " +
        plan_title +
        " | " +
        row.transaction_request_type +
        ")";
    } else if (transaction_type == "dynamite_product_purchase") {
      t_type = "Dynamite Shop";
    } else if (transaction_type == "payment_request" && request_title) {
      t_type = "Payment Request (" + request_title + ")";
    }
    return t_type;
  };
  const referralName = (refferal_name, affiliate_url_name, userType) => {
    return (
      <div>
        {`${refferal_name} `}
        {affiliate_url_name && (
          <span className="affiliate_url_name">({affiliate_url_name})</span>
        )}
        {userType}
      </div>
    );
  };

  const get_affliliate_info = (row) => {
    let affiliate_user_name = "Master Link";
    let refUser = row.affiliate_info;
    let affiliate_user = refUser?.affiliate_user_info;
    let userType = "";
    if (affiliate_user) {
      userType =
        affiliate_user?.affiliate_user_created_for == "memberuser"
          ? " | Public User "
          : " | Delegate User";
    }

    if (affiliate_user) {
      affiliate_user_name =
        affiliate_user.first_name + " " + affiliate_user.last_name;
    }

    return referralName(
      affiliate_user_name,
      refUser?.affiliate_url_name,
      userType
    );
  };

  const get_transaction_referral = (row) => {
    let affiliate_info = row.transaction_referral_info;
    let affiliate_user = affiliate_info?.affiliate_user_info;
    let refferal_name = "N/A";
    let userType = "";
    if (affiliate_info?.affiliate_url_name && affiliate_user) {
      userType =
        affiliate_user?.affiliate_user_created_for == "memberuser"
          ? " | Public User "
          : " | Delegate User";
    }

    if (affiliate_info?.affiliate_url_name && affiliate_user) {
      refferal_name =
        affiliate_user?.first_name + " " + affiliate_user?.last_name;
    }
    return referralName(
      refferal_name,
      affiliate_info?.affiliate_url_name,
      userType
    );
  };

  const handleChangeRoute = (route, data) => {
    delete data?.get_transaction_type;
    navigate(route, { state: data });
  };

  const handleViewTickets = (value) => {
    if (value.tickets.length > 0) {
      let path = `/payment-transactions/view-tickets/${value._id}`;
      handleChangeRoute(path, value);
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "user_name",
      label: "Member",
      renderData: (row) => {
        let find_status = TRANSACTION_STATUS.find(
          (status) => status.value == row.transaction_status
        );
        const iconStyle = {
          color: "blue !important",
        };

        let verify_name = row?.verified_by?.name;

        return (
          <div
            className="d-flex "
            onClick={() => {
              setInfoDetail(row);
              if (row?.get_transaction_type === "Dynamite Shop") {
              } else {
                handleOpenPopup(row);
              }
            }}
            style={{ cursor: "" }}
          >
            <Tooltip title={find_status?.name}>
              <CircleIcon
                style={iconStyle}
                className={`transation-status-icon ${find_status?.class}`}
              />
            </Tooltip>

            <div>
              {/* <Tooltip title="testt" className="pointer"> */}
              <p className="mb-0">{row.user_name ? row.user_name : "N/A"} </p>
              {/* </Tooltip> */}
            </div>
          </div>
        );
      },
    },
    {
      id: "transaction_status",
      className: "text-capitalize",
      label: "Transaction Type",
      renderData: (row) => {
        let find_status = TRANSACTION_STATUS_NEW.find(
          (status) => status.value == row.transaction_status
        );
        const iconStyle = {
          color: "blue !important",
        };

        let t_type = "Sale Page";
        let transaction_type = row.transaction_type;
        let sale_page = row?.sale_page_info?.sale_page_title;
        let plan_title = row?.plan_info?.plan_title;
        let request_title = row?.payment_request_info?.request_title;
        if (transaction_type == "payment_plan" && sale_page) {
          t_type =
            "Sale Page (" +
            sale_page +
            " | " +
            plan_title +
            " | " +
            row.transaction_request_type +
            ")";
        } else if (transaction_type == "dynamite_product_purchase") {
          t_type = "Dynamite Shop";
        } else if (transaction_type == "payment_request" && request_title) {
          t_type = "Payment Request (" + request_title + ")";
        }

        return (
          <div className="d-flex pointer">
            <div>{t_type}</div>
          </div>
        );
      },
    },
    { id: "totat_amount", label: "Amount" },
    { id: "dynamite_commissions", label: "Dynamite Digtal" },
    { id: "referral_commissions", label: "Referral Commission" },
    {
      id: "affliliate_info",
      label: "Referral User",
      renderData: (row) => get_affliliate_info(row),
    },
    {
      id: "transaction_referral_commissions",
      label: "Transaction Referral Commission",
    },
    {
      id: "transaction_referral",
      label: "Transaction Referral",
      renderData: (row) => get_transaction_referral(row),
    },
    { id: "transactionDate", label: "Transaction Date" },
    {
      id: "payment_made_by",
      label: "Payment Made By",
      renderData: (row) => {
        return show_proper_words(row.payment_made_by);
      },
    },
    {
      id: "tickets_count",
      label: "Total Tickets",
      className: "cursor-pointer",
      handleClick: handleViewTickets,
    },
    {
      id: "agrement_pdf_url",
      label: "Agreement PDF",
      renderData: (row) => {
        return (
          row.agrement_pdf_url && (
            <a
              href={s3baseUrl + row.agrement_pdf_url}
              target="_blank"
              className="anchor-style"
            >
              Preview
            </a>
          )
        );
      },
    },
    {
      id: "t_created_by",
      label: "Created By",
      className: "text-capitalize",
      renderData: (row) => {
        return <p>{show_proper_words(row.created_by)}</p>;
      },
    },
    {
      id: "other_info_menu",
      label: "Other Information",
      renderData: (row) => {
        return row.other_info_menu == "N/A" ? (
          <p>{row.other_info_menu}</p>
        ) : (
          <>
            <div>
              <span className="main-menu-heading">Main Menu: </span>
              <span>{row.other_info?.main_menu}</span>
            </div>
            <div>
              <span className="main-menu-heading">starter menu: </span>
              <span>{row.other_info?.starter_menu}</span>
            </div>
            <div>
              <span className="main-menu-heading">tea and coffee menu : </span>
              <span>{row.other_info?.tea_and_coffee_menu}</span>
            </div>
            <div>
              <span className="main-menu-heading">dietary requirements : </span>
              <span>{row.other_info?.dietary_requirements}</span>
            </div>
            <div>
              <span className="main-menu-heading">dessert menu : </span>
              <span>{row.other_info?.dessert_menu}</span>
            </div>
          </>
        );
      },
    },
    { id: "transaction_mode", label: "Transaction Mode" },
  ];

  useEffect(() => {
    getTransactionsListing();
  }, [tabData]);
  const headers = [
    { label: "Name", key: "user_name_for_export" },
    { label: "Email", key: "user_email_for_export" },
    { label: "Amount", key: "transaction_amount" },
    { label: "Dynamite Digtal", key: "dynamite_commissions" },
    {
      label: "Referral Commission",
      key: "referral_commissions",
    },
    { label: "Transaction Date", key: "transactionDate" },
    {
      label: "Transaction Referral Commission",
      key: "transaction_referral_commissions",
    },
    {
      label: "Total Tickets",
      key: "tickets_count",
    },
    { label: "Request Title", key: "payment_request_title" },
    { label: "Transaction Type", key: "get_transaction_type" },
    { label: "Other Information", key: "other_info_menu_export" },
  ];
  const handleExportClick = () => {
    download_csv_file(headers, transactionsData);
  };
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="mt-3">
      <div className="col-lg-12 col-sm-12 text-end d-flex justify-content-end">
        <button
          className="small-contained-button me-2 mt-1 mb-4"
          onClick={showPopUPcsv}
        >
          Request For CSV &nbsp;&nbsp; <Icon icon={arrowCircleDownFill} />
        </button>
        <button
          className="small-contained-button ms-1 mt-1 mb-4"
          onClick={handleOpenFilterDrawer}
        >
          Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
        </button>
      </div>
      <FilteredChip
        data={filterStateUpdated}
        tempState={filterData}
        EMPTY_FILTER={EMPTY_FILTER}
        ALTER_FILTER={ALTER_FILTER}
        onDeleteChip={handleDeleteChip}
        onClear={handleClearFilter}
      />
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={transactionsData}
        custom_pagination={custom_pagination}
        custom_search={{
          searchText: searchText,
          setSearchText: setSearchText,
          handleSubmit: searchFunction,
        }}
        pageCount={pageCount}
        totalPages={totalPages}
        handleChangePages={handleChangePages}
        pagePagination={true}
        className="card-with-background"
      />
      <GeneralPopUpModel
        open={showExportcsvFile}
        setOpen={setShowExportcsvFile}
        title="Export CSV file request"
        componentToPassDown={
          <GeneralRequestForCsv
            options_array={member_options}
            collection_name={"history_transactions"}
            filter_data={filterQuery}
            setShowExportcsvFile={setShowExportcsvFile}
          />
        }
      />
    </div>
  );
}
