import { invokeApi } from "../../bl_libs/invokeApi";

export const questionReplyHistory = async (data) => {
  const requestObj = {
    path: `/api/member/get_answer_stat`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateSubscriberApi = async (id, data) => {
  const requestObj = {
    path: `/api/event_subscriber/edit_subscriber/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addPersonalNote = async (data) => {
  const requestObj = {
    path: `/api/member/update_personal_note`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const noteListing = async (id) => {
  const requestObj = {
    path: `/api/member/list_personal_note/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const unsubscribed_members_list = async (page, limit, data) => {
  const requestObj = {
    path: `/api/member/unsubscribed_members_list?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_email_subscription_status = async (data) => {
  const requestObj = {
    path: `/api/member/update_email_subscription_status`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const EditNote = async (data) => {
  const requestObj = {
    path: `/api/member/edit_personal_note`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteNote = async (data) => {
  const requestObj = {
    path: `/api/member/remove_personal_note`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const memberDetailBeforeEditApi = async (id) => {
  const requestObj = {
    path: `/api/member/member_detail_before_edit/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const affiliateSearchInEditMember = async (search, type) => {
  const requestObj = {
    path: `/app/affiliate_search?search_text=${search}&type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const activeMemberListing = async (data) => {
  const requestObj = {
    path: `/api/member/active_member_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberDetailApi = async (id, data) => {
  const requestObj = {
    path: `/api/member/${id}?start_date=${data?.start_date}&end_date=${data?.end_date}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const memberDetailProfileApi = async (id, tab_name, data) => {
  const requestObj = {
    path: `/admin_users/get_member/complete/info/${id}?tab_name=${tab_name}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const memberDetailApiList = async (id) => {
  const requestObj = {
    path: `/api/member/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const wheelOfLifeMemberDetail = async (id) => {
  const requestObj = {
    path: `/api/member/member_wheel_of_life_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const memberListing = async (page, limit, data, type) => {
  const requestObj = {
    path: `/api/member/member_list_v2?page=${page}&limit=${limit}&type=${type}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const memberListingActivity = async (id, page, limit) => {
  const requestObj = {
    path: `/admin_users/get_coins_history/member/${id}?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_client_dynamite_events_api = async (member_id) => {
  const requestObj = {
    path: `/api/member/get_client_dynamite_events?member_id=${member_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const historyListingApi = async (_id, type) => {
  const requestObj = {
    path: `/api/member/get_member_portal_history_with_type/${_id}/${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Users_api = async (id) => {
  const requestObj = {
    path: `/api/challenge/get_member_list_for_completed_challenge/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const active_Users_api = async (id, page, rowsPerPage, searchText) => {
  const requestObj = {
    path: `/api/challenge/list_people_doing/${id}?page=${page}&limit=${rowsPerPage}&search_text=${
      searchText ? searchText : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const LeadStatusListing = async (id) => {
  const requestObj = {
    path: `/api/lead_status/list_lead_status/for_member/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const event_listing_by_member = async (start_date, end_date, id) => {
  const requestObj = {
    path: `/api/member/get_member_events_for_admin/${id}?start_date=${start_date}&end_date=${end_date}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const pods_listing_by_member = async (id) => {
  const requestObj = {
    path: `/api/member/get_member_pods_for_admin/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberSubscriberListingApi = async (id, page, limit, search) => {
  const requestObj = {
    path: `/api/member/event_subscriber_list/member_id/${id}?page=${page}&limit=${limit}&search_text=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const memberPodsListing = async (id) => {
  const requestObj = {
    path: `/api/member/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getSubscriberDetailApi = async (id) => {
  const requestObj = {
    path: `/api/event_subscriber/subscriber/detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberCompleteListing = async (id) => {
  const requestObj = {
    path: `/api/member`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberAndGroupsListApi = async (search) => {
  const requestObj = {
    path: `/api/member/members_and_groups/list?search_text=${
      search ? search : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const memberInCalendarGroupsListApi = async (search) => {
  const requestObj = {
    path: `/api/member/members_for_select/list?search_text=${
      search ? search : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberAndGroupsEventInCalendarGroupsListApi = async (search) => {
  const requestObj = {
    path: `/api/member/members_and_programs/list?search_text=${
      search ? search : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const payment_plan_list_api = async (data) => {
  const requestObj = {
    path: `/api/payment_plan`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const list_members_and_programs_v2 = async (data) => {
  const requestObj = {
    path: `/api/member/list_members_and_programs_v2`,
    method: "POST",
    postData: data,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const memberGoalStatementListApi = async (id) => {
  const requestObj = {
    path: `/api/member/get_goal_statement_by_member_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberSelfImageRepliesListApi = async (data) => {
  const requestObj = {
    path: `/api/questionnaire/list_comments_for_reply`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const selfImageRepliesShowApi = async (data) => {
  const requestObj = {
    path: `/api/questionnaire/set/show_replies`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const memberTransactionListApi = async (id) => {
  const requestObj = {
    path: `/api/member/all_transaction_by_member_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberEventSubscriberListing = async (id) => {
  // console.log(search, "search");
  const requestObj = {
    path: `/api/member/member_event_subscriber_list/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberBillingListApi = async (id) => {
  const requestObj = {
    path: `/api/payment_request/payment_request_list_by_member/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const paymentTemplateListApi = async (id) => {
  const requestObj = {
    path: `/api/payment_template`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const productListApi = async (id) => {
  const requestObj = {
    path: `/api/product`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberBillingDetailApi = async (slug) => {
  const requestObj = {
    path: `/api/payment_request/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const memberGroupListApi = async (id) => {
  const requestObj = {
    path: `/api/member/member_group_list/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const add_member_from_proggram_event_Api = async (data) => {
  const requestObj = {
    path: `/api/member/assign_program_members_to_events`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const AddMemberApi = async (data) => {
  const requestObj = {
    path: `/api/member`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addPaymentRequest = async (data) => {
  const requestObj = {
    path: `/api/payment_request`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const AddKimsReplyApi = async (data) => {
  const requestObj = {
    path: `/api/goal_statement_reply/add_reply`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const replyAgainstSelfImageApi = async (data) => {
  const requestObj = {
    path: `/api/questionnaire/reply_on_member/answers`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const importMemberApi = async (data) => {
  const requestObj = {
    path: `/api/member/add_member_from_csv`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_member_from_csv_for_event = async (data) => {
  const requestObj = {
    path: `/api/member/add_member_from_csv_for_event`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const programmeAccessApi = async (id, data) => {
  console.log(data, "hi i m here");
  const requestObj = {
    path: `/api/member/add_program_against_member/v1/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const LevelAccessApi = async (id, data) => {
  console.log(data, "hi i m here");
  const requestObj = {
    path: `/api/member/level/add/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const rewardAccessApi = async (id, data) => {
  const requestObj = {
    path: `/api/member/add_reward_against_member/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditMemberApi = async (data, id) => {
  const requestObj = {
    path: `/api/member/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteSelfImageReplyApi = async (data) => {
  const requestObj = {
    path: `/api/questionnaire/delete_reply_on_member/answers`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteMemberApi = async (id) => {
  const requestObj = {
    path: `/api/member/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const reset_assement_api = async (member_id) => {
  const requestObj = {
    path: `/api/member/reset_attitude_assessment_history/${member_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const afflicateChangeid = async (postData, id) => {
  const requestObj = {
    path: `/app/affiliate_url_name/update/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const DeleteSubscriberMemberApi = async (id) => {
  const requestObj = {
    path: `/api/event_subscriber/delete_event_subscriber/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DeleteMultiMemberApi = async (data) => {
  // console.log(...data, "new arraysapi side");
  const requestObj = {
    path: `/api/member/delete_all_and_specific_member`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeleteQuestionCommentApi = async (id) => {
  const requestObj = {
    path: `/api/goal_statement_question/comment/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DeleteSelfImageQuestionCommentApi = async (data) => {
  const requestObj = {
    path: `/api/questionnaire/delete_reply_by/admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DeletePaymentRequestApi = async (slug) => {
  const requestObj = {
    path: `/api/payment_request/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DeleteCommentApi = async (slug) => {
  const requestObj = {
    path: `/api/goal_statement_reply/delete_reply/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const changeMemberPassword = async (data, id) => {
  const requestObj = {
    path: `/api/member/change_password/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// ---------------------------------------------------------------
export const memberHistoryApi = async (data, page, limit) => {
  const requestObj = {
    path: `/api/feeds/member_feed_with_pagination?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const member_login_api = async (data) => {
  const requestObj = {
    path: `/app/token_generate_from_impersonate`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const member_diary_api = async (id, page, limit) => {
  const requestObj = {
    path: `/api/dynamite_diary/list_member_diaries_for_admin/${id}?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_dynamite_event_against_member = async (id, data) => {
  const requestObj = {
    path: `/api/member/add_dynamite_event_against_member/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const add_users_by_csv = async (data, type) => {
  const requestObj = {
    path: `/admin_users/import/subscriber_from_csv?type=${type ? type : ""}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const bonus_content_category_list = async (member_id) => {
  const requestObj = {
    path: `/api/member/bonus_content_categories/get/${member_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const update_bonus_content_category_api = async (plan_id, data) => {
  const requestObj = {
    path: `/api/member/bonus_content_categories/update/${plan_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
