import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { answers_detail_by_user_and_question_api } from "src/DAL/GeneralQuestions/GeneralQuestions";
import SelfImageAnswersList from "src/components/GeneralComponents/GeneralQuestions/SelfImageAnswersList";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function SelfImageQuestionsAnswers() {
  const classes = useStyles();
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [userInfo, setUserInfo] = useState({});
  const [questions, setQuestions] = useState([]);
  const created_for_id = new URLSearchParams(search).get("created_for_id");
  const member_id = new URLSearchParams(search).get("member_id");
  const created_for = new URLSearchParams(search).get("created_for");
  const check_user = new URLSearchParams(search).get("check_user");

  const self_image_answers = window.location.pathname.includes(
    "/self-image-answers/answers-detail"
  );
  const self_image_completed_answers = window.location.pathname.includes(
    "/self-image-completed-answers/answers-detail"
  );
  const self_image_completed_inanswers = window.location.pathname.includes(
    "/self-image-incompleted-answers/answers-detail"
  );
  const self_image_completed_responded = window.location.pathname.includes(
    "/self-image-responded-answers/answers-detail"
  );

  const get_questions_list = async () => {
    let postData = {
      created_for_id: created_for_id,
      member_id: member_id,
      created_for: created_for,
      check_user: check_user ? check_user : false,
    };
    const result = await answers_detail_by_user_and_question_api(postData);
    if (result.code === 200) {
      let data = result.questionnaire.map((question) => {
        return {
          ...question,
          isExpanded: true,
        };
      });
      setUserInfo(result.member);
      setQuestions(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  let breadCrumbMenu = [
    {
      title: self_image_answers
        ? "Self Image ( Answers )"
        : self_image_completed_answers
        ? "Self Image ( Completed Answers )"
        : self_image_completed_inanswers
        ? "Self Image ( Incompleted Answers )"
        : self_image_completed_responded
        ? "Self Image ( Responded Answers )"
        : "Self Image Answers",
      navigation: self_image_answers
        ? `/self-image-answers`
        : self_image_completed_answers
        ? `/self-image-completed-answers`
        : self_image_completed_inanswers
        ? `/self-image-incompleted-answers`
        : self_image_completed_responded
        ? `/self-image-responded-answers`
        : ``,
      active: false,
    },
    {
      title:
        userInfo.first_name +
        " " +
        userInfo.last_name +
        " (" +
        userInfo.email +
        ")",
      navigation: ``,
      active: true,
    },
  ];

  useEffect(() => {
    get_questions_list();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>
      <SelfImageAnswersList questions={questions} reload={get_questions_list} />
    </div>
  );
}
