import { useEffect, useState } from "react";
import {
  Card,
  Chip,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import jsPDF from "jspdf";
import Label from "src/components/Label";
import ReactDOMServer from "react-dom/server";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { orderDetailList } from "src/DAL/Transaction/transactionApi";
import { useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import { Icon } from "@iconify/react";
import { convertCurrencyToSign, replace_created_for } from "src/utils/constant";
import moment from "moment";
import { download_csv_file } from "src/utils/convertHtml";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },

  {
    id: "table_avatar",
    label: "Product Image",
    alignRight: false,
    type: "thumbnail",
  },
  { id: "quantity", label: "Quantity", alignRight: false },
  { id: "price", label: "Price", alignRight: false },
];
const TABLE_HEAD_tickets = [
  { id: "number", label: "#", type: "number" },
  { id: "name", label: "Name" },

  { id: "email", label: "Email" },
  { id: "contact_number", label: "Phone" },
  { id: "venue_title", label: "Venue" },
];
const headers = [
  { label: "Title", key: "title" },
  { label: "Quantity", key: "quantity" },
  { label: "Price", key: "price" },
];
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function OrderDetail() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);
  const [ticketsList, setTicketsList] = useState([]);
  const [ProductList, setProductList] = useState([]);
  const [ProductListDetail, setProductListDetail] = useState([]);
  const [ProductListDetailUpdated, setProductListDetailUpdated] = useState();
  const [orderDeatail, setOrderDeatail] = useState();
  const [isDonwloadingPdf, setIsDonwloadingPdf] = useState(false);

  const getBookingStatusList = async () => {
    setIsLoading(true);
    const result = await orderDetailList(params.id);
    if (result.code == 200) {
      setOrderDeatail(result.order_detail);

      let detail = result.order_detail;

      let data = [];
      let product_data = [];
      if (
        result.order_detail.tickets &&
        result.order_detail.tickets.length > 0
      ) {
        data = result.order_detail.tickets.map((user) => {
          return {
            ...user,
            venue_title: user.venue_id ? user.venue_id.title : "N/A",
            name: user.first_name + " " + user.last_name,
          };
        });
      }

      setTicketsList(data);
      if (
        result.order_detail?.transaction_type == "dynamite_product_purchase" ||
        result.order_detail?.transaction_type == "payment_plan"
      ) {
        setProductListDetail(result.order_detail.product_order_id);
        setProductListDetailUpdated(result.order_detail);
        if (result.order_detail.product_order_id) {
          product_data = result.order_detail.product_order_id.products.map(
            (item) => {
              return {
                ...item,
                title: item.product_id.name,
                price: item.price,
                quantity: item.quantity,
                table_avatar: {
                  src: s3baseUrl + item.product_id.image[0].image,
                  alt: item.product_id.name,
                },
              };
            }
          );
        }
        setProductList(product_data);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleExportClick = () => {
    download_csv_file(headers, ProductList);
  };

  const get_transaction_type = (row) => {
    let t_type = "N/A";
    let transaction_type = row.transaction_type;
    let sale_page = row?.sale_page?.sale_page_title;
    let plan_title = row?.plan?.plan_title;
    let request_title = row?.payment_request?.request_title;

    if (transaction_type == "payment_plan" && sale_page) {
      t_type =
        "Sale Page (" +
        sale_page +
        " | " +
        plan_title +
        " | " +
        row.transaction_request_type +
        ")";
    } else if (transaction_type == "dynamite_product_purchase") {
      t_type = "Dynamite Shop";
    } else if (transaction_type == "payment_request") {
      t_type = "Payment Request ";
      if (request_title) {
        t_type = t_type + "(" + request_title + ")";
      }
    }
    return t_type;
  };
  const get_affliliate_info = (row) => {
    let affiliate_user_name = "Master Link";
    let refUser = row.affliliate;
    let affiliate_user = refUser?.affiliate_user_info;
    if (affiliate_user) {
      affiliate_user_name =
        affiliate_user.first_name + " " + affiliate_user.last_name;
    }
    return referralName(affiliate_user_name, refUser?.affiliate_url_name);
  };

  const referralName = (refferal_name, affiliate_url_name) => {
    return (
      <div>
        {`${refferal_name} `}
        {affiliate_url_name && (
          <span className="affiliate_url_name">({affiliate_url_name})</span>
        )}
      </div>
    );
  };

  function convertHtmlToString(html) {
    return ReactDOMServer.renderToString(html);
  }
  const handleGeneratePdf = () => {
    setIsDonwloadingPdf(true);
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    const html = (
      <div>
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-4 col-sm-12">
              <h2>Products</h2>
            </div>
            <div className="col-lg-8 col-sm-12 text-end d-flex justify-content-end">
              <div>
                <button
                  className="small-contained-button me-2 mt-1 mb-4"
                  onClick={handleExportClick}
                >
                  Export Csv &nbsp;&nbsp; <Icon icon={arrowCircleDownFill} />
                </button>
              </div>
            </div>
          </div>
          {/* table */}
          <div
            className="row mt-3 p-3 card-with-background"
            style={{ borderRadius: "16px" }}
          >
            <table class="table table-nowrap ">
              <thead>
                <tr>
                  <th scope="col" className="size-table">
                    #
                  </th>
                  <th scope="col" className="size-table fixed-width">
                    Title
                  </th>

                  <th scope="col" className="size-table fixed-width">
                    Quantity
                  </th>
                  <th scope="col" className="size-table fixed-width">
                    Unit Price
                  </th>

                  <th scope="col" className="size-table fixed-width">
                    Total Price
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <th className="size-table" scope="row"></th>
                    <td className="size-table"></td>
                    <td className="size-table"></td>
                    <td className="size-table"></td>
                    <td className="size-table">
                      <CircularProgress color="primary" />
                    </td>
                    <td className="size-table"></td>
                    <td className="size-table"></td>
                    <td className="size-table"></td>
                  </tr>
                ) : (
                  <>
                    {ProductList.length > 0 &&
                      ProductList?.map((items, index) => {
                        return (
                          <tr>
                            <th className="size-table" scope="row">
                              {index + 1}
                            </th>
                            <td className="size-table">{items.title}</td>

                            <td className="size-table">
                              {`${items.quantity}`}
                            </td>
                            <td className="size-table">
                              {convertCurrencyToSign(items.product_id.currency)}
                              {items.price.toFixed(2)}
                            </td>
                            <td className="size-table">
                              {convertCurrencyToSign(items.product_id.currency)}
                              {(items.quantity * items.price).toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
            {/* bottom prices box */}
            <div class="row border-div mb-4">
              <div className="col-7"></div>
              <div className="col-5 text-end px-4 mb-2">
                <div className="justify-content-between d-flex">
                  <div>
                    <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                      Sub Total:
                    </Typography>
                  </div>
                  <div>
                    <Typography sx={{ fontSize: "14px" }}>
                      {convertCurrencyToSign(ProductListDetail.currency)}
                      {ProductListDetail.sub_total.toFixed(2)}
                    </Typography>
                  </div>
                </div>
                {ProductListDetail?.tax_rate_id && (
                  <div className="mt-2 justify-content-between d-flex">
                    <div>
                      <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                        {ProductListDetail?.tax_rate_id
                          ? ProductListDetail?.tax_rate_id?.tax_type_title
                          : ""}
                        Tax
                      </Typography>
                    </div>
                    <div>
                      <Typography sx={{ fontSize: "14px" }}>
                        {convertCurrencyToSign(ProductListDetail.currency)}
                        {ProductListDetail.tax_charges.toFixed(2)}
                      </Typography>
                    </div>
                  </div>
                )}
                <div className="mt-2 justify-content-between d-flex">
                  <div>
                    <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                      Shipping Charges:
                    </Typography>
                  </div>
                  <div>
                    <Typography sx={{ fontSize: "14px" }}>
                      £
                      {ProductListDetail.shipping_charge
                        ? ProductListDetail.shipping_charge.toFixed(2)
                        : "0"}
                    </Typography>
                  </div>
                </div>
                <hr></hr>
                <div className="mt-2 justify-content-between d-flex">
                  <div>
                    <Typography
                      sx={{
                        mr: 12,
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      Grand Total:
                    </Typography>
                  </div>
                  <div>
                    <Typography sx={{ fontSize: "14px" }}>
                      £{ProductListDetail.total.toFixed(2)}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    const html2 = convertHtmlToString(html);
    doc.html(html2, {
      margin: [15, 25, 20, 20],
      autoPaging: "text",
      callback: (doc) => {
        doc.save();
        setIsDonwloadingPdf(false);
      },
    });
  };

  let breadCrumbMenu = [
    {
      title: "Transaction",
      navigation: -1,
      active: false,
    },
    {
      title: orderDeatail?.member
        ? orderDeatail?.user_name + "(" + orderDeatail?.member?.email + ")"
        : orderDeatail?.user_name,
      active: true,
    },
  ];

  useEffect(() => {
    getBookingStatusList();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12 display-flex mt-1">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
        </div>
        {orderDeatail.transaction_type == "dynamite_product_purchase" ? (
          <Card className="payment-card mb-3">
            <div className="row p-3">
              <div className="col-12">
                <div className="row">
                  <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                    Member:
                  </div>
                  {orderDeatail?.member ? (
                    <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                      {orderDeatail.user_name +
                        "(" +
                        orderDeatail?.member?.email +
                        ")"}
                    </div>
                  ) : (
                    <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                      {orderDeatail.user_name}
                    </div>
                  )}
                  <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                    <p className="finalPrice">Total Amount:</p>
                  </div>
                  <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 text-muted">
                    {convertCurrencyToSign(orderDeatail.currency)}
                    {orderDeatail.amount.toFixed(2)}
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                    Transaction Date:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                    <Icon icon="uiw:date" className="finalPrice" />
                    <span className="ms-1 mt-1">
                      {moment(orderDeatail.transaction_date).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </span>
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mt-5 mb-3 mt-lg-0 request-type">
                    Payment Made By:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3  mt-5 mb-3 mt-lg-0 text-muted">
                    {orderDeatail.payment_made_by
                      ? orderDeatail.payment_made_by
                      : "N/A"}
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mt-5  mb-3 mt-lg-0 request-type">
                    Transaction Mode:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted">
                    {orderDeatail.transaction_mode}
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mb-3 mt-5 mt-lg-0 request-type">
                    Transaction Type:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted text-capitalize">
                    {get_transaction_type(orderDeatail)}
                  </div>
                  {orderDeatail?.transaction_note && (
                    <>
                      <div className="col-5 col-md-6 col-lg-3 mb-3 mt-5 mt-lg-0 request-type">
                        Transaction Note:
                      </div>
                      <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted text-capitalize">
                        {orderDeatail?.transaction_note}
                      </div>
                    </>
                  )}
                  {orderDeatail.other_fire_payment_info && (
                    <>
                      <div className="col-5 col-md-6 col-lg-2 mt-5 mt-lg-0 request-type">
                        Transaction Made In:
                      </div>
                      <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 ">
                        <div className="col-7 col-md-6 col-lg-4  mt-5 mt-lg-0 text-muted">
                          {convertCurrencyToSign(
                            orderDeatail.other_fire_payment_info.currency
                          ) +
                            orderDeatail.other_fire_payment_info.amount.toFixed(
                              2
                            )}
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    className={`col-5 col-md-6 ${
                      orderDeatail.other_fire_payment_info
                        ? "col-lg-3"
                        : "col-lg-2"
                    }   mt-5 mt-lg-0 request-type`}
                  >
                    Transaction Status:
                  </div>
                  <div
                    className={`col-7 col-md-6 ${
                      orderDeatail.other_fire_payment_info
                        ? "col-lg-9"
                        : "col-lg-2"
                    }  mt-5 mt-lg-0 `}
                  >
                    <Label
                      variant="ghost"
                      color={
                        orderDeatail.transaction_status === "succeeded"
                          ? "success"
                          : "error"
                      }
                    >
                      {replace_created_for(orderDeatail.transaction_status)}
                    </Label>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        ) : (
          <Card className="payment-card mb-3">
            <div className="row p-3">
              <div className="col-12">
                <div className="row">
                  <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                    Member:
                  </div>
                  {orderDeatail?.member ? (
                    <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                      {orderDeatail.user_name +
                        "(" +
                        orderDeatail?.member?.email +
                        ")"}
                    </div>
                  ) : (
                    <div className="col-7 col-md-6 col-lg-9 text-muted mb-3">
                      {orderDeatail.user_name}
                    </div>
                  )}
                  <div className="col-5 col-md-6 col-lg-3 mt-5 mt-lg-0 request-type">
                    <p className="finalPrice">Total Amount:</p>
                  </div>
                  <div className="col-7 col-md-6 col-lg-4  mt-5 mt-lg-0 text-muted">
                    {convertCurrencyToSign(orderDeatail.currency)}
                    {orderDeatail.amount.toFixed(2)}
                  </div>
                  <div className="col-5 col-md-6 col-lg-2 mb-3 request-type">
                    Transaction Date:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3 text-muted mb-3">
                    <Icon icon="uiw:date" className="finalPrice" />
                    <span className="ms-1 mt-1">
                      {moment(orderDeatail.transaction_date).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </span>
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mt-5 mb-3 mt-lg-0 request-type">
                    Payment Made By:
                  </div>
                  <div className="col-7 col-md-6 col-lg-4  mt-5 mb-3 mt-lg-0 text-muted">
                    {orderDeatail.payment_made_by
                      ? orderDeatail.payment_made_by
                      : "N/A"}
                  </div>
                  <div className="col-5 col-md-6 col-lg-2 mt-5  mb-3 mt-lg-0 request-type">
                    Transaction Mode:
                  </div>
                  <div className="col-7 col-md-6 col-lg-3 mb-3  mt-5 mt-lg-0 text-muted">
                    {orderDeatail.transaction_mode}
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mt-5 mb-3 mt-lg-0 request-type">
                    Referral User:
                  </div>
                  <div className="col-7 col-md-6 col-lg-4  mt-5 mt-lg-0 text-muted">
                    {get_affliliate_info(orderDeatail)}
                  </div>
                  {orderDeatail.other_fire_payment_info && (
                    <>
                      <div className="col-5 col-md-6 col-lg-2 mt-5 mt-lg-0 request-type">
                        Transaction Made In:
                      </div>
                      <div className="col-7 col-md-6 col-lg-3  mt-5 mt-lg-0 ">
                        <div className="col-7 col-md-6 col-lg-4  mt-5 mt-lg-0 text-muted">
                          {convertCurrencyToSign(
                            orderDeatail.other_fire_payment_info.currency
                          ) +
                            orderDeatail.other_fire_payment_info.amount.toFixed(
                              2
                            )}
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    className={`col-5 col-md-6 ${
                      orderDeatail.other_fire_payment_info
                        ? "col-lg-3"
                        : "col-lg-2"
                    }   mt-5 mt-lg-0 request-type`}
                  >
                    Transaction Status:
                  </div>
                  <div
                    className={`col-7 col-md-6 ${
                      orderDeatail.other_fire_payment_info
                        ? "col-lg-9"
                        : "col-lg-2"
                    }  mt-5 mt-lg-0 `}
                  >
                    <Label
                      variant="ghost"
                      color={
                        orderDeatail.transaction_status === "succeeded"
                          ? "success"
                          : "error"
                      }
                    >
                      {replace_created_for(orderDeatail.transaction_status)}
                    </Label>
                  </div>
                  <div className="col-5 col-md-6 col-lg-3 mb-3 mt-2 request-type">
                    Transaction Type:
                  </div>
                  <div className="col-7 col-md-6 col-lg-9  mt-2 text-muted ">
                    {get_transaction_type(orderDeatail)}
                  </div>
                  {orderDeatail?.transaction_note && (
                    <>
                      <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                        Transaction Note:
                      </div>
                      <div className="col-7 col-md-6 col-lg-9 text-muted ">
                        {orderDeatail?.transaction_note}
                      </div>
                    </>
                  )}
                  {orderDeatail.other_info && (
                    <>
                      <div className="mt-3 mb-3">
                        <h3 className="text-center">Other Information</h3>
                      </div>
                      {orderDeatail.other_info.dessert_menu && (
                        <>
                          <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                            Dessert Menu :
                          </div>
                          <div className="col-7 col-md-6 col-lg-9 text-muted ">
                            {orderDeatail.other_info.dessert_menu}
                          </div>
                        </>
                      )}
                      {orderDeatail.other_info.dietary_requirements && (
                        <>
                          <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                            Dietary Requirements :
                          </div>
                          <div className="col-7 col-md-6 col-lg-9 text-muted ">
                            {orderDeatail.other_info.dietary_requirements}
                          </div>
                        </>
                      )}
                      {orderDeatail.other_info.main_menu && (
                        <>
                          <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                            Main Menu :
                          </div>
                          <div className="col-7 col-md-6 col-lg-9 text-muted ">
                            {orderDeatail.other_info.main_menu}
                          </div>
                        </>
                      )}
                      {orderDeatail.other_info.starter_menu && (
                        <>
                          <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                            Starter Menu :
                          </div>
                          <div className="col-7 col-md-6 col-lg-9 text-muted ">
                            {orderDeatail.other_info.starter_menu}
                          </div>
                        </>
                      )}
                      {orderDeatail.other_info.tea_and_coffee_menu && (
                        <>
                          <div className="col-5 col-md-6 col-lg-3 mb-3 request-type">
                            Tea And Coffee Menu :
                          </div>
                          <div className="col-7 col-md-6 col-lg-9 text-muted ">
                            {orderDeatail.other_info.tea_and_coffee_menu}
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {orderDeatail.shipping_object && (
                    <div className="ps-4 pe-4">
                      <hr></hr>
                    </div>
                  )}
                  {orderDeatail.shipping_object &&
                    Object.keys(orderDeatail.shipping_object).length > 0 && (
                      <div
                        className={
                          orderDeatail.billing_object ? `col-lg-6` : "col-lg-12"
                        }
                      >
                        <div className="mt-3 mb-4">
                          <h3
                            className={`${
                              !orderDeatail.billing_object ? "text-center" : ""
                            }  mb-4 finalPrice`}
                          >
                            Shipping Info
                          </h3>
                        </div>
                        <div className="row">
                          <div
                            className={`${
                              !orderDeatail.billing_object
                                ? "col-7 col-md-6 col-lg-3"
                                : "col-lg-4"
                            }  mb-2 request-type`}
                          >
                            Country:
                          </div>
                          <div
                            className={`${
                              !orderDeatail.billing_object
                                ? "col-7 col-md-6 col-lg-3"
                                : "col-lg-5"
                            }  mb-2 text-muted`}
                          >
                            {orderDeatail.shipping_object.address
                              ? orderDeatail.shipping_object.address.country
                              : orderDeatail.shipping_object.country}
                          </div>
                          <div
                            className={`${
                              !orderDeatail.billing_object
                                ? "col-5 col-md-6 col-lg-3 mb-3"
                                : "col-lg-4"
                            }  mb-3 request-type`}
                          >
                            State:
                          </div>
                          <div
                            className={`${
                              !orderDeatail.billing_object
                                ? "col-7 col-md-6  col-lg-3"
                                : "col-lg-7"
                            }  mb-3 text-muted`}
                          >
                            {orderDeatail.shipping_object.address
                              ? orderDeatail.shipping_object.address.state
                              : orderDeatail.shipping_object.state}
                          </div>
                          <div
                            className={`${
                              !orderDeatail.billing_object
                                ? "col-5 col-md-6 col-lg-3 mb-3"
                                : "col-lg-4"
                            }  mb-3 request-type`}
                          >
                            City:
                          </div>
                          <div
                            className={`${
                              !orderDeatail.billing_object
                                ? "col-7 col-md-6  col-lg-3"
                                : "col-lg-7"
                            }  mb-3 text-muted`}
                          >
                            {orderDeatail.shipping_object.address
                              ? orderDeatail.shipping_object.address.city
                              : orderDeatail.shipping_object.city}
                          </div>
                          <>
                            <div
                              className={`${
                                !orderDeatail.billing_object
                                  ? "col-5 col-md-6 col-lg-3 mb-3"
                                  : "col-lg-4"
                              }  mb-3 request-type`}
                            >
                              Zip Code:
                            </div>
                            <div
                              className={`${
                                !orderDeatail.billing_object
                                  ? "col-7 col-md-6  col-lg-3"
                                  : "col-lg-7"
                              }  mb-3 text-muted`}
                            >
                              {orderDeatail.shipping_object.address
                                ?.postal_code ??
                                orderDeatail?.shipping_object?.address
                                  ?.zip_code ??
                                "N/A"}
                            </div>
                          </>
                          {orderDeatail.shipping_object.address && (
                            <>
                              <div
                                className={`${
                                  !orderDeatail.billing_object
                                    ? "col-5 col-md-6 col-lg-3 mb-3"
                                    : "col-lg-4"
                                }  mb-3 request-type`}
                              >
                                Street 1:
                              </div>
                              <div
                                className={`${
                                  !orderDeatail.billing_object
                                    ? "col-7 col-md-6  col-lg-3"
                                    : "col-lg-7"
                                }  mb-3 text-muted`}
                              >
                                {orderDeatail.shipping_object.address.line1
                                  ? orderDeatail.shipping_object.address.line1
                                  : orderDeatail.shipping_object.address.street
                                  ? orderDeatail.shipping_object.address.street
                                  : "N/A"}
                              </div>
                            </>
                          )}
                          {orderDeatail.shipping_object.address && (
                            <>
                              <div
                                className={`${
                                  !orderDeatail.billing_object
                                    ? "col-5 col-md-6 col-lg-3 mb-3"
                                    : "col-lg-4"
                                }  mb-3 request-type`}
                              >
                                Street 2:
                              </div>
                              <div
                                className={`${
                                  !orderDeatail.billing_object
                                    ? "col-7 col-md-6  col-lg-3"
                                    : "col-lg-7"
                                }  mb-3 text-muted`}
                              >
                                {orderDeatail.shipping_object.address.line2 ??
                                  orderDeatail.shipping_object.address
                                    .street2 ??
                                  "N/A"}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  {orderDeatail.billing_object &&
                  Object.keys(orderDeatail.billing_object).length > 0 ? (
                    <div className="col-lg-6">
                      <div className="mt-3 mb-3">
                        <h3 className="finalPrice">Billing Info</h3>
                      </div>
                      <div className="row">
                        {orderDeatail.billing_object.name && (
                          <>
                            <div className="col-lg-4 mb-3 request-type">
                              Name
                            </div>
                            <div className="col-lg-7 text-muted mb-3">
                              {orderDeatail.billing_object.name}
                            </div>
                          </>
                        )}
                        {orderDeatail.billing_object.address.country && (
                          <>
                            <div className="col-lg-4 mb-3 request-type">
                              Country:
                            </div>
                            <div className="col-lg-7 text-muted mb-3">
                              {orderDeatail.billing_object.address.country}
                            </div>
                          </>
                        )}
                        <div className="col-lg-4 mb-3 request-type">State:</div>
                        <div className="col-lg-7 text-muted mb-3">
                          {orderDeatail.billing_object.address.state}
                        </div>
                        <div className="col-lg-4 mt-lg-0 request-type">
                          <p className="">City:</p>
                        </div>
                        <div className="col-lg-7  mt-5 mt-lg-0 text-muted">
                          {orderDeatail.billing_object.address.city}
                        </div>
                        <div className="col-lg-4 mb-3 request-type">
                          Zip Code:
                        </div>
                        <div className="col-lg-7 text-muted mb-3">
                          {orderDeatail.billing_object.address.postal_code}
                        </div>
                        {(orderDeatail.billing_object.address.line1 ||
                          orderDeatail.billing_object.address.street2) && (
                          <>
                            <div className="col-lg-4 mb-3 request-type">
                              Street 1:
                            </div>
                            <div className="col-lg-7 text-muted mb-3">
                              {orderDeatail.billing_object.address.line1 ??
                                orderDeatail.billing_object.address.street2}
                            </div>
                          </>
                        )}
                        {(orderDeatail?.billing_object?.address?.line2 ||
                          orderDeatail?.billing_object?.address?.street2) && (
                          <>
                            <div className="col-lg-4 mb-3 request-type">
                              Street 2:
                            </div>
                            <div className="col-lg-7 text-muted mb-3">
                              {orderDeatail.billing_object.address.line2 ??
                                orderDeatail?.billing_object?.address?.street2}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      {orderDeatail.billing_object &&
                        Object.keys(orderDeatail.billing_object).length > 0 && (
                          <div className="col-lg-6">
                            <div className="mt-3 mb-3">
                              <h3 className="finalPrice">Billing Info</h3>
                            </div>
                            <div className="row">
                              {/* {orderDeatail.billing_object.name && (
                              <>
                                
                                <div className="col-lg-4 mb-3 request-type">
                                  Name
                                </div>
                                <div className="col-lg-7 text-muted mb-3">
                                  {orderDeatail.billing_object.name}
                                </div>
                              </>
                            )} */}
                              {orderDeatail.billing_object.address ? (
                                <>
                                  <div className="col-lg-4 mb-3 request-type">
                                    Country:
                                  </div>
                                  <div className="col-lg-7 text-muted mb-3">
                                    {
                                      orderDeatail.billing_object.address
                                        .country
                                    }
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="col-lg-4 mb-3 request-type">
                                    Country:
                                  </div>
                                  <div className="col-lg-7 text-muted mb-3">
                                    {orderDeatail.billing_object.country}
                                  </div>
                                </>
                              )}
                              <div className="col-lg-4 mb-3 request-type">
                                State:
                              </div>
                              <div className="col-lg-7 text-muted mb-3">
                                {orderDeatail.billing_object.address
                                  ? orderDeatail.billing_object.address.state
                                  : orderDeatail.billing_object.state}
                              </div>
                              <div className="col-lg-4 mt-lg-0 request-type">
                                <p className="">City:</p>
                              </div>
                              <div className="col-lg-7  mt-5 mt-lg-0 text-muted">
                                {orderDeatail.billing_object.address
                                  ? orderDeatail.billing_object.address.city
                                  : orderDeatail.billing_object.city}
                              </div>
                              <div className="col-lg-4 mb-3 request-type">
                                Zip Code:
                              </div>
                              <div className="col-lg-7 text-muted mb-3">
                                {orderDeatail.billing_object.address
                                  ? orderDeatail?.billing_object.address
                                      ?.zip_code
                                  : orderDeatail?.billing_object?.zip_code}
                              </div>
                            </div>
                          </div>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Card>
        )}
        <div className="row mb-1 mt-4">
          <div className="col-lg-6 ms-2  col-sm-12">
            <div className="d-flex">{/* <h3>Products</h3> */}</div>
          </div>
        </div>

        {ticketsList && ticketsList.length > 0 ? (
          <>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-4 col-sm-12">
                  <h2>Tickets</h2>
                </div>
              </div>
            </div>
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD_tickets}
              data={ticketsList}
              is_hide={true}
              className="card-with-background"
              pagePagination={true}
            />
          </>
        ) : (
          <span>
            {orderDeatail.transaction_type == "dynamite_product_purchase" ||
            orderDeatail.transaction_type == "payment_plan" ? (
              <>
                <div>
                  {ProductListDetail && (
                    <>
                      <div className="container-fluid">
                        <div className="row ">
                          <div className="col-lg-4 col-sm-12">
                            <h3>Billing Information</h3>
                          </div>
                        </div>
                        <div
                          className="row mt-3 p-3 card-with-background"
                          style={{ borderRadius: "16px" }}
                        >
                          <table class="table table-nowrap ">
                            <thead>
                              <tr>
                                <th scope="col" className="size-table">
                                  #
                                </th>
                                <th
                                  scope="col"
                                  className="size-table fixed-width"
                                >
                                  Title
                                </th>

                                <th
                                  scope="col"
                                  className="size-table fixed-width"
                                >
                                  Quantity
                                </th>
                                <th
                                  scope="col"
                                  className="size-table fixed-width"
                                >
                                  Unit Price
                                </th>

                                <th
                                  scope="col"
                                  className="size-table fixed-width"
                                >
                                  Total Price
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {isLoading ? (
                                <tr>
                                  <th className="size-table" scope="row"></th>
                                  <td className="size-table"></td>
                                  <td className="size-table"></td>
                                  <td className="size-table"></td>
                                  <td className="size-table">
                                    <CircularProgress color="primary" />
                                  </td>
                                  <td className="size-table"></td>
                                  <td className="size-table"></td>
                                  <td className="size-table"></td>
                                </tr>
                              ) : (
                                <>
                                  {ProductList.length > 0 &&
                                    ProductList?.map((items, index) => {
                                      return (
                                        <tr>
                                          <th
                                            className="size-table"
                                            scope="row"
                                          >
                                            {index + 1}
                                          </th>
                                          <td className="size-table">
                                            {items.title}
                                          </td>

                                          <td className="size-table">
                                            {`${items.quantity}`}
                                          </td>
                                          <td className="size-table">
                                            {convertCurrencyToSign(
                                              ProductListDetail
                                                ? ProductListDetail.currency
                                                : ProductListDetailUpdated.currency
                                            )}
                                            {items.price.toFixed(2)}
                                          </td>
                                          <td className="size-table">
                                            {convertCurrencyToSign(
                                              ProductListDetail
                                                ? ProductListDetail.currency
                                                : ProductListDetailUpdated.currency
                                            )}
                                            {(
                                              items.quantity * items.price
                                            ).toFixed(2)}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* bottom prices box */}
                      {ProductListDetail && (
                        <div className="container-fluid">
                          <div class="row  mt-1 mb-4">
                            <div className="col-6"></div>
                            <div
                              className="col-6 border-div p-3 card text-end px-4 mb-2"
                              style={{ borderRadius: "16px" }}
                            >
                              <div className="justify-content-between d-flex">
                                <div>
                                  <Typography sx={{ fontSize: "16px" }}>
                                    Sub Total:
                                  </Typography>
                                </div>
                                <div>
                                  <Typography
                                    sx={{ fontSize: "16px" }}
                                    className="text-muted"
                                  >
                                    {convertCurrencyToSign(
                                      ProductListDetail
                                        ? ProductListDetail.currency
                                        : ProductListDetailUpdated.currency
                                    )}
                                    {ProductListDetail.sub_total.toFixed(2)}
                                  </Typography>
                                </div>
                              </div>
                              <div className="justify-content-between d-flex">
                                <div>
                                  <Typography sx={{ fontSize: "16px" }}>
                                    Shipping Fee:
                                  </Typography>
                                </div>
                                <div>
                                  <Typography
                                    sx={{ fontSize: "16px" }}
                                    className="text-muted"
                                  >
                                    £
                                    {ProductListDetail.shipping_charges.toFixed(
                                      2
                                    )}
                                  </Typography>
                                </div>
                              </div>

                              {ProductListDetail.tax_info && (
                                <div className="justify-content-between d-flex">
                                  <div>
                                    <Typography sx={{ fontSize: "16px" }}>
                                      {ProductListDetail.tax_info.tax_type_title
                                        ? ProductListDetail.tax_info
                                            .tax_type_title
                                        : ""}
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography
                                      sx={{ fontSize: "16px" }}
                                      className="text-muted"
                                    >
                                      {convertCurrencyToSign(
                                        ProductListDetail
                                          ? ProductListDetail.currency
                                          : ProductListDetailUpdated.currency
                                      )}
                                      {ProductListDetail.tax_charges.toFixed(2)}
                                    </Typography>
                                  </div>
                                </div>
                              )}
                              <div className="justify-content-between d-flex">
                                <div>
                                  <Typography
                                    sx={{
                                      mr: 12,
                                      fontSize: "16px",
                                    }}
                                  >
                                    Grand Total:
                                  </Typography>
                                </div>
                                <div>
                                  <Typography
                                    sx={{ fontSize: "16px" }}
                                    className="text-muted"
                                  >
                                    £{ProductListDetail.total?.toFixed(2)}
                                  </Typography>
                                </div>
                              </div>
                              <hr></hr>
                              <div className="justify-content-between d-flex">
                                <div>
                                  <Typography
                                    sx={{ fontSize: "16px" }}
                                    className="finalPrice"
                                  >
                                    Charged Amount:
                                  </Typography>
                                </div>
                                <div>
                                  <Typography sx={{ fontSize: "16px" }}>
                                    {convertCurrencyToSign(
                                      ProductListDetail.other_info
                                        ?.converted_currency
                                    )}
                                    {
                                      ProductListDetail.other_info
                                        ?.converted_total
                                    }
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            ) : (
              ""
            )}
          </span>
        )}
      </div>
    </>
  );
}
