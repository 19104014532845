import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import {
  road_Delete_Api,
  _bonus_listApi,
} from "src/DAL/BonusRoadMAp/BonusRoadMApApi";
import { s3baseUrl } from "src/config/config";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { COMMUNITY_LEVELS } from "src/utils/constant";
import StripeSubscriptionList from "./StripeSubscriptionList";
import StripeTransactionsList from "./StripeTransactionsList";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false, type: "action" },
  { id: "number", label: "#", alignRight: false, type: "number" },
  { id: "title", label: "Title", alignRight: false },
  {
    id: "table_avatar",
    label: "Reached Status",
    alignRight: false,
    type: "thumbnail",
  },
  {
    id: "without_border_icon",
    label: "Before Status",
    alignRight: false,
    type: "thumbnail",
  },
  { id: "coins", label: "Coins", alignRight: false },
  { id: "order", label: "order", alignRight: false },
  { id: "status", label: "Status", alignRight: false, type: "row_status" },
];

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const RenderStripeTransactions = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [levelsList, setLevelsList] = useState([]);
  const classes = useStyles();
  let selected_index = 0;

  const [tabValue, setTabValue] = useState(selected_index);

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TABS_OPTIONS = [
    {
      title: "All",
      component: <StripeTransactionsList status="all" state={state} />,
    },
    {
      title: "Succeeded",
      component: <StripeTransactionsList status="succeeded" state={state} />,
    },

    {
      title: "Failed",
      component: <StripeTransactionsList status="failed" state={state} />,
    },
  ];
  let breadCrumbMenu = [
    {
      title: state
        ? state?.first_name + " " + state?.last_name + " (" + state.email + ")"
        : "Member",
      navigation: -1,
      active: false,
    },
    {
      title: "Stripe Transactions",
      active: true,
    },
  ];

  return (
    <>
      <div className="container-fluid">
        {state?.email ? (
          <div className="row">
            <div className="col-12 display-flex mb-4">
              <span>
                <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
              </span>
            </div>
          </div>
        ) : (
          <div className="row mb-4">
            <div className="col-lg-8 col-sm-12">
              <h2>Stripe Transactions</h2>
            </div>
          </div>
        )}

        <MUICustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
      </div>
    </>
  );
};

export default RenderStripeTransactions;
