import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import {
  ForgetPassword,
  GoalStatement,
  Pods,
  Gratitude,
  Quotes,
  Memories,
  MemoriesDetails,
  DailyActivities,
  AffirmationList,
} from "./pages/index";
import Login from "./pages/Login";
import DashboardApp from "./pages/DashboardApp";
import NotFound from "./pages/Page404";
import ConsultantList from "./pages/consultant/consultantList";
import AddConsultant from "./pages/consultant/AddConsultant";
import EditConsultant from "./pages/consultant/EditConsultant";
import ManageDelegateProgrammeAccess from "src/pages/consultant/ManageProgrammeAccess";
import ChangeConsultantPassword from "./pages/consultant/Changepassword";
import ConsultantRecordings from "./pages/consultant/ConsultantRecordings";
import ConsultantRecordingDetail from "./pages/consultant/RecordingDetail";
import ConsultantGroupsList from "./pages/consultant/ConsultantGroups";
import ConsultantGroupDetail from "./pages/consultant/ConsultantGroupDetail";
import ConsultantPodsList from "./pages/consultant/PodsListing";
import MembersListConsultant from "./pages/consultant/MemberList";
import ConsultantGoalStatementList from "./pages/consultant/ConsultantGoalStatement";
import ConsultantPodsDetail from "./pages/consultant/PodsDetail";
import MemberGroupsList from "./pages/Members/MembersGroupList";
import MemberPersonalNote from "./pages/Members/MemberPersonalNote";
import MemberGoalstatementList from "./pages/Members/MemberGoalstatementList";
import PodsListMemberProfile from "./pages/Members/PodsListMemberProfile";
import ConsultantGoalstatementDetail from "./pages/consultant/ConsultantGoalstatementDetail";
import AddOrUpdateQuestion from "./pages/GoalStatement/AddOrUpdateQuestion";
import DetailQuestion from "./pages/GoalStatement/DetailQuestion";
import { QuestionsReply } from "./pages/Members/QuestionsReply";
import { AnswerHistory } from "./pages/Members/AnswerHistory";
import NinetyDayQuestion from "./pages/90DayQuestion/90DayQuestion";
import QuotesList from "./pages/Quotes/QuotesList";
import GratitudeDetail from "./pages/GratitudeList/GratitudeDetail";
import AffirmationDetail from "./pages/Affirmation/AffirmationDetail";
import AffirmationCategories from "./pages/AffirmationCategories/AffirmationCategories";
import StripeProductsList from "./pages/Payments/StripeProductsList";
import AddStripeProduct from "./pages/Payments/AddStripeProduct";
import EditStripeProduct from "./pages/Payments/EditStripeProduct";
import PaymentTemplateList from "./pages/PaymentTemplate/PaymentTemplateList";
import PaymentRequestList from "./pages/PaymentRequest/PaymentRequestList";
import PaymentRequestInfo from "./pages/PaymentRequest/PaymentRequestInfo";
import CalendarGroupsList from "./pages/CalendarGroups/CalendarGroupsList";
import CalendarGroupDetail from "./pages/CalendarGroups/CalendarGroupDetail";
import CalendarPodsList from "./pages/CalendarPods/CalendarPodsList";
import AddCalendarPods from "./pages/CalendarPods/AddPods";
import EditCalendarPods from "./pages/CalendarPods/EditPods";
import CalendarPodsDetail from "./pages/CalendarPods/CalendarPodsDetail";
import AddAutomatedRoomUser from "./pages/CalendarPods/AddRoomUser";
import EditAutomatedRoomUser from "./pages/CalendarPods/EditRoomUser";
import DepartmentList from "./pages/Departments/DepartmentsList";
import { SupportTicket } from "./pages/index";
import UpdateGeneralSetting from "./pages/ClientSetting/GeneralSetting";
import ClientDashboardSetting from "./pages/ClientSetting/ClientDashboard";
import SupportTicketDetail from "./pages/SupportTickets/DetailSupportTicket";
import UpdateContentSetting from "./pages/ClientSetting/ContentSetting";
import UpdateConsultantGeneralSetting from "./pages/ConsultantPortal/GeneralSetting";
import AddSendGrid from "./pages/GeneralSetting/SendGrid";
import AddCompaignSetting from "./pages/GeneralSetting/CompaingSetting";
import AddStripeSetting from "./pages/GeneralSetting/StripeSetting";
import EmailSetting from "./pages/GeneralSetting/EmailSetting";
import EmailTemplates from "./pages/GeneralSetting/EmailTemplates/EmailTemplates";
import EditEmailTemplate from "./pages/GeneralSetting/EmailTemplates/EditEmailTemplate";
import { EmailTemplatePreview } from "./pages/GeneralSetting/EmailTemplates/EmailTemplatePreview";
import ToDoTracker from "./pages/Members/ToDoTracker";
import AddOrUpdatePage from "./pages/WebPages/WebsitePages/AddOrUpdatePage";
import UpdatePageContent from "./pages/WebPages/WebsitePages/UpdatePageContent";
import PodCastList from "./pages/WebsitePodCasts/PodCastList";
import EditProfile from "./pages/EditProfile/EditProfile";
import CommunityList from "./pages/Community/Rewards/RewardsList";
import AddCommunity from "./pages/Community/Rewards/AddOrUpdateReward";
import SeriesVideosList from "./pages/Community/SeriesVideos/SeriesVideosList";
import AddSeriesVideos from "./pages/Community/SeriesVideos/AddOrUpdateSeriesVideos";
import FeedsList from "./pages/Community/Feeds/FeedsList";
import AddFeeds from "./pages/Community/Feeds/AddFeed";
import EditFeeds from "./pages/Community/Feeds/EditFeed";
import FeedSetting from "./pages/Community/FeedSetting";
import SeriesCategoryList from "./pages/Community/SeriesCategories/SeriesCategoryList";
import AddSeriesCategory from "./pages/Community/SeriesCategories/AddOrUpdateSeriesCategory";
import DynamiteEventsList from "./pages/DynamiteEvents/DynamiteEventsList";
import BusinessStrategyList from "./pages/WebPages/BusinessStrategy/BusinessStrategyList";
import AddBusinessStrategy from "./pages/WebPages/BusinessStrategy/AddOrUpdateBusinessStrategy";
import AddOrUpdateWebPageTestimonial from "./pages/WebPages/WebPageTestimonials/AddOrUpdateWebPageTestimonial";
import WebPageTestimonials from "./pages/WebPages/WebPageTestimonials/WebPageTestimonials";
import AddWebPageEvent from "./pages/WebPageEvents/AddWebPageEvent";
import EditWebPageEvent from "./pages/WebPageEvents/EditWebPageEvent";
import WebPageEvents from "./pages/WebPageEvents/WebPageEvents";
import WebsiteProgrammesList from "./pages/WebPages/WebsiteProgrammes/WebsiteProgrammesList";
import AddWebsiteProgramme from "./pages/WebPages/WebsiteProgrammes/AddOrUpdateProgramme";
import EventSubscribers from "./pages/Members/EventSubscriber";
import { QuestionSurvey } from "./pages/WebPages/QuestionSurvey/QuestionSurvey";
import WebPageFaqs from "./pages/WebPages/WebPageFaqs/WebPageFaqs";
import AddOrUpdateWebPageFaq from "./pages/WebPages/WebPageFaqs/AddOrUpdateWebPageFaq";
import Meditation from "./pages/Meditations/Meditations/Meditation";
import MeditationCategories from "./pages/Meditations/Categories/MeditationCategories";
import AddMeditationCategory from "./pages/Meditations/Categories/AddOrUpdateCategory";
import AddMeditation from "./pages/Meditations/Meditations/AddOrUpdateMeditation";
import AddOrUpdateCategory from "./pages/HelpVideos/Categories/AddOrUpdateCategory";
import HelpVideoCategories from "./pages/HelpVideos/Categories/HelpVideoCategories";
import HelpVideoList from "./pages/HelpVideos/Videos/HelpVideoList";
import AddOrUpdateVideo from "./pages/HelpVideos/Videos/AddOrUpdateVideo";
import ManageTemplateAccess from "./pages/consultant/ManageTemplateAccess";
import TemplatePages from "./pages/WebPages/TemplatePages/TemplatePages";
import ManageTemplateConsultantAccess from "./pages/WebPages/TemplatePages/ManageConsultantAccess";
import ManageTemplateModuleAccess from "./pages/WebPages/TemplatePages/ManageModuleAccess";
import WebPagePaymentPlans from "./pages/WebPages/WebPagePaymentPlans/WebPagePaymentPlans";
import AddOrUpdatePaymentPlan from "./pages/WebPages/WebPagePaymentPlans/AddOrUpdatePaymentPlan";
import ProgrammeAccessPaymentPlan from "./pages/WebPages/WebPagePaymentPlans/ProgrammeAccessPaymentPlan";
import { MenuItems } from "./pages/MenuItems/MenuItems";
import MenuList from "./pages/MenuItems/MenuList";
import SuccessScheduleList from "./pages/WebPages/SuccessSchedule/successScheduleList";
import AddOrUpdateSuccessSchedule from "./pages/WebPages/SuccessSchedule/AddOrUpdateSuccessSchedule";
import BannerSliderList from "./pages/WebPages/BannerSlider/BannerSliderList";
import AddBannerSlider from "./pages/WebPages/BannerSlider/AddBannerSlider";
import AddChallenge from "./pages/Challenge/AddChallenge";
import ChallengeDetail from "./pages/Challenge/ChallengeDetail";
import TestCustomMUITable from "./pages/MUITable/TestCustomMUITable";
import ShopCategoryList from "./pages/Shop/ShopCategoryList";
import AddShopCategory from "./pages/Shop/AddShopCategory";
import ShopProductList from "./pages/ShopProducts/ShopProductList";
import AddShopProduct from "./pages/ShopProducts/AddShopProduct";
import AffiliateTransactions from "./pages/Payments/AffiliateTransactions/AffiliateTransactions";
import AddOrUpdateAffiliateTransactions from "./pages/Payments/AffiliateTransactions/AddOrUpdateAffiliateTransactions";
import ManageCommissionAccess from "./pages/consultant/ManageCommissionAccess";
import ProgrammeGoalStatement from "./pages/ProgrammeGoalStatement/ProgrammeGoalStatement";
import ProgrammeAddOrUpdateQuestion from "./pages/ProgrammeGoalStatement/ProgrammeAddOrUpdateQuestion";
import InvoiceSetting from "./pages/GeneralSetting/InvoiceSetting";
import { WheelOfLife } from "./pages/WebPages/wheelOflife/WheelOfLife";
import { AssessmentQuestions } from "./pages/WebPages/wheelOflife/AssessmentQuestions";
import UpdateWheelOfLifeSetting from "./pages/ClientSetting/WheelOfLifeSetting";
import WheelOfLifeMemberList from "./pages/WheelOfLifeMemberList.js/WheelOfLifeMemberList";
import WheelOfLifeMemberDetailQuestion from "./pages/WheelOfLifeMemberList.js/DetailQuestion";
import SiteSetting from "./pages/GeneralSetting/SiteSetting";
import UpdateThanksContent from "./pages/WebPages/WebsitePages/UpdateThanksContent";
import ZoomSetting from "./pages/GeneralSetting/ZoomSetting";
import DelegateQuestionsListView from "./pages/consultant/Delegate90DaysQuestionDropDown";
import DelegatePodsList from "./pages/DelegatePods/DelegatePodsList";
import AddDelegatePods from "./pages/DelegatePods/AddDelegatePods";
import EditDelegatePods from "./pages/DelegatePods/EditDelegatePods";
import DelegateGroupList from "./pages/DelegateGroups/DelegateGroupsList";
import QuartersList from "./pages/Quarters/QuartersList";
import AddDelegateGroup from "./pages/DelegateGroups/AddDelegateGroups";
import EditDelegateGroup from "./pages/DelegateGroups/EditDelegateGroups";
import DelegateMembers from "./pages/consultant/DelegateMembers";
import DelegateGroupDetail from "./pages/DelegateGroups/DelegateGroupDetail";
import DelegatePodsDetail from "./pages/DelegatePods/DelegatePodsDetail";
import ManageEventAccess from "./pages/consultant/ManageEventAccess";
import DelegateEventDetailList from "./pages/DelegateCalenderEvents/DelegateEventDetailList";
import AllSales from "./pages/SaleSection/allSales";
import AddOrUpdateSales from "./pages/SaleSection/AddOrUpdateSales";
import AllSalesPage from "./pages/SaleSection/AllSalesPage";
import ConsultantCalender from "./pages/ConsultantEvents/ConsultantCalender";
import DelegatesRecordings from "./pages/DelegatesRecordings/DelegatesRecordings";
import AddOrUpdateDelegatesRecording from "./pages/DelegatesRecordings/AddOrUpdateDelegatesRecording";
import ConsultantMemberSubscribersList from "./pages/consultant/ConsultantMemberSubscribers";
import DelegateToDoTrackerList from "./DAL/delegateGroups/DelegateToDoTrackerList";
import UnpublishSetting from "./pages/WebPages/WebsitePages/UnpublishSetting";
import AttitudeAccessmentQuestions from "./pages/WebPages/AttitudeAccessmentQuestions/AttitudeAccessmentQuestions";
import AssessmentSetting from "./pages/WebPages/AttitudeAccessmentQuestions/AssessmentSetting";
import DailyMessages from "./pages/Community/DailyMessages/DailyMessages";
import AddOrUpdateDailyMessage from "./pages/Community/DailyMessages/AddOrUpdateDailyMessage";
import DelegateAssessmentStudy from "./pages/consultant/DelegateAssessmentStudy";
import ManageCommissionAccessFromPages from "./pages/consultant/ManageCommissionAccessFromPages";
import SocialSharingSetting from "./pages/WebPages/TemplatePages/SoicalSharingSetting";
import ChallengeTabs from "./pages/Challenge/ChallengeTabs";
import CoinConfiguration from "./pages/Challenge/CoinConfiguration";
import DynamiteDiarySetting from "./pages/DynamiteDiarySetting/DynamiteDiarySetting";
import CoinConfigurations from "./pages/coinConfiguration/CoinConfiguration";
import BonusRoadMap from "./pages/BonusRoadMap/BonusRoadMap";
import AddBonusRoadMap from "./pages/BonusRoadMap/AddBonusRoadMap";
import EditBonusRoadMap from "./pages/BonusRoadMap/EditBonusRoadMap";
import GratitudeDailySetting from "./pages/GratitudeDailySetting/GratitudeDailySetting";
import AddTransaction from "./pages/Payments/AddTransaction";
import AddOrUpdateVaultCategory from "./pages/KimsRecordings/Categories/AddOrUpdateVaultCategory";
import CategoriesList from "./pages/KimsRecordings/Categories/CategoriesList";
import RecordingsList from "./pages/KimsRecordings/Recordings/RecordingsList";
import AddOrUpdateRecordings from "./pages/KimsRecordings/Recordings/AddOrUpdateRecordings";
import RecordingDetail from "./pages/KimsRecordings/Recordings/RecordingDetail";
import MeditationSetting from "./pages/MeditationSetting/MeditationSetting";
import SessionsList from "./pages/StudySessions/Sessions/SessionsList";
import AddOrUpdateSession from "./pages/StudySessions/Sessions/AddOrUpdateSession";
import SessionsRecordingsList from "./pages/StudySessions/Recordings/SessionsRecordingsList";
import AddOrUpdateSessionRecordings from "./pages/StudySessions/Recordings/AddOrUpdateSessionRecordings";
import AddOrUpdateQuotes from "./pages/Quotes/AddOrUpdateQuotes";
import AddOrUpdatedAffirmationCategory from "./pages/AffirmationCategories/AddOrUpdatedAffirmationCategory";
import MeditationChallengeSetting from "./pages/MeditationChallengeSetting/MeditationChallengeSetting";
import SourceSessionsList from "./pages/SourceSessions/Sessions/SourceSessionsList";
import AddOrUpdateSourceSession from "./pages/SourceSessions/Sessions/AddOrUpdateSourceSession";
import SourceSessionsRecordings from "./pages/SourceSessions/Recordings/SourceSessionsRecordings";
import AddOrUpdateSourceSessionRecordings from "./pages/SourceSessions/Recordings/AddOrUpdateSourceSessionRecordings";
import CoinConfigurationTabs from "./pages/coinConfiguration/CoinConfigurationTabs";
import PlanSubsribers from "./pages/WebPages/WebPagePaymentPlans/PlanSubsribers";
import PagePlanSubscribersList from "./pages/WebPages/WebPagePaymentPlans/PagePlanSubscribersList";
import AutoRespondedMessage from "./pages/SupportTickets/AutoRespondedMessage/AutoRespondedMessage";
import AddAutoRespondedMessage from "./pages/SupportTickets/AutoRespondedMessage/AddAutoRespondedMessage";
import UpdateAutoRespondedMessage from "./pages/SupportTickets/AutoRespondedMessage/UpdateAutoRespondedMessage";
import BookingList from "./pages/Booking/BookingList";
import BookCallTypes from "./pages/Booking/BookCallTypes/BookCallTypes";
import AddOrUpdateBookCallType from "./pages/Booking/BookCallTypes/AddOrUpdateBookCallType";
import BookingStatus from "./pages/Booking/BookingStatus/BookingStatus";
import WebPageQuestions from "./pages/WebPages/WebPageQuestions/WebPageQuestions";
import AddOrUpdateWebPageQuestion from "./pages/WebPages/WebPageQuestions/AddOrUpdateWebPageQuestion";
import QuestionsAnswersList from "./pages/Questions/QuestionsAnswersList";
import QuestionsAnswers from "./pages/Questions/QuestionsAnswers";
import QuestionsAnswersAsCreatedFor from "./pages/Questions/QuestionsAnswersAsCreatedFor";
import QuestionsAnswersForUser from "./pages/Questions/QuestionsAnswersForUser";
import AddOrUpdateProgramme from "./pages/ProgrammesList/AddOrUpdateProgramme";
import { GeneralQuestions } from "./pages/GeneralQuestions/GeneralQuestions";
import BenefitList from "./pages/WebPages/ProgressReport/BenefitList";
import AddOrUpdateBenefit from "./pages/WebPages/ProgressReport/AddOrUpdateBenefit";
import { ProgrammeDocuments } from "./pages/ProgrammesList/ProgrammeDocuments/ProgrammeDocuments";
import ProgrammesList from "./pages/ProgrammesList/ProgrammesList";
import ProgrammesDetail from "./pages/ProgrammesList/ProgrammesDetail";
import AddOrUpdateProgramDocument from "./pages/ProgrammesList/ProgrammeDocuments/AddOrUpdateProgramDocument";
import ProgrammeSectionList from "./pages/ProgrammesList/ProgrammeSectionList/ProgrammeSectionList";
import AddOrUpdateProgrammeSection from "./pages/ProgrammesList/ProgrammeSectionList/AddOrUpdateProgrammeSection";
import ProgrammeReviews from "./pages/ProgrammesList/ProgramReviews/ProgrammeReviews";
import AddOrUpdateProgramReview from "./pages/ProgrammesList/ProgramReviews/AddOrUpdateProgramReview";
import AutomatedGroups from "./pages/ProgrammesList/AutomatedGroups/AutomatedGroups";
import AddOrUpdateAutomatedGroup from "./pages/ProgrammesList/AutomatedGroups/AddOrUpdateAutomatedGroup";
import LockedProgramInfo from "./pages/ProgrammesList/LockedProgramInfo";
import LessonListing from "./pages/ProgrammesList/Lessons/LessonListing";
import AddOrUpdateLesson from "./pages/ProgrammesList/Lessons/AddOrUpdateLesson";
import LessonsDetail from "./pages/ProgrammesList/Lessons/LessonsDetail";
import { LessonDocuments } from "./pages/ProgrammesList/Lessons/LessonDocuments/LessonDocuments";
import AddOrUpdateLessonDocument from "./pages/ProgrammesList/Lessons/LessonDocuments/AddOrUpdateLessonDocument";
import QuestionConfigurationForLesson from "./pages/ProgrammesList/Lessons/QuestionConfigurationForLesson";
import LessonRecordings from "./pages/ProgrammesList/Lessons/LessonRecordings/LessonRecordings";
import AddOrUpdateLessonRecordings from "./pages/ProgrammesList/Lessons/LessonRecordings/AddOrUpdateLessonRecordings";
import LessonRecordingDetail from "./pages/ProgrammesList/Lessons/LessonRecordings/LessonRecordingDetail";
import ProgressCategory from "./pages/ProgressCategory/ProgressCategoryList";
import AddProgressCategory from "./pages/ProgressCategory/AddProgressCategory";
import MemberGoalStatement from "./pages/MemberGoalStatement/MemberGoalStatement";
import AddOrUpdateDynamiteEvent from "./pages/DynamiteEvents/AddOrUpdateDynamiteEvent";
import GeneralEvents from "./pages/DynamiteEvents/GeneralEvents/GeneralEvents";
import AddOrUpdateGeneralEvents from "./pages/DynamiteEvents/GeneralEvents/AddOrUpdateGeneralEvents";
import EventVideoCategories from "./pages/DynamiteEvents/Categories/EventVideoCategories";
import AddOrUpdateEventCategory from "./pages/DynamiteEvents/Categories/AddOrUpdateEventCategory";
import DynamiteEventVideoList from "./pages/DynamiteEvents/Categories/Videos/DynamiteEventVideoList";
import AddOrUpdateDynamiteEventVideo from "./pages/DynamiteEvents/Categories/Videos/AddOrUpdateDynamiteEventVideo";
import RegisterEventsUsers from "./pages/DynamiteEvents/RegisterEventsUsers";
import DynamiteEventMembers from "./pages/DynamiteEvents/EventMembers/DynamiteEventMembers";
import AddDynamiteEventMember from "./pages/DynamiteEvents/EventMembers/AddDynamiteEventMember";
import EditDynamiteEventMember from "./pages/DynamiteEvents/EventMembers/EditDynamiteEventMember";
import QuestionConfigurationForEvent from "./pages/DynamiteEvents/Categories/Videos/QuestionConfigurationForEvent";
import GratitudeList from "./pages/GratitudeList/GratitudeList";
import AddOrUpdateGratitude from "./pages/GratitudeList/AddOrUpdateGratitude";
import AffirmationsList from "./pages/Affirmation/AffirmationsList";
import AddOrUpdateAffirmation from "./pages/Affirmation/AddOrUpdateAffirmation";
import AgreementConfiguration from "./pages/WebPages/WebPagePaymentPlans/AgreementConfiguration";
import MemberChangeStartExpiryDate from "./pages/DynamiteEvents/MemberChangeStartExpiryDate";
import ProgrammeMembers from "./pages/ProgrammesList/ProgrammeMembers";
import MembersList from "./pages/MembersList/MembersList";
import UpdateLockEventContent from "./pages/DynamiteEvents/LockEventContent";
import ManageRewardAccess from "./pages/MembersList/ManageRewardAccess";
import MemberDiaryList from "./pages/MembersList/MemberDiaryList";
import ManageProgrammeAccess from "./pages/MembersList/ManageProgrammeAccess";
import MemberSubscriptions from "./pages/MembersList/MemberSubscriptions/MemberSubscriptions";
import UpdateSubscription from "./pages/MembersList/MemberSubscriptions/UpdateSubscription";
import MemberTransactions from "./pages/MembersList/MemberTransactions";
import ViewBillings from "./pages/MembersList/ViewBillings/ViewBillings";
import EventConfiguration from "./pages/DynamiteEvents/EventConfiguration";
import ProgrammeBulkAccess from "./pages/ProgrammesList/ProgrammeBulkAccess";
import MemberFeedsHistory from "./pages/MembersList/MemberFeedsHistory";
import NewCalendar from "./pages/CalendarEvents/NewCalendar";
import AddBillingsRequest from "./pages/MembersList/ViewBillings/AddBillingsRequest";
import ViewBillingDetail from "./pages/MembersList/ViewBillings/ViewBillingDetail";
import ImportProgrammeMembers from "./pages/MembersList/ImportProgrammeMembers";
import MemberProfile from "./pages/MembersList/MemberProfile";
import AddOrUpdateMember from "./pages/MembersList/AddOrUpdateMember";
import DynamiteGallery from "./pages/DynamiteGallery/DynamiteGallery";
import CalendarEventMembers from "./pages/CalendarEvents/CalendarEventMembers";
import EventBulkDelegateAccess from "./pages/DynamiteEvents/EventBulkDelegateAccess";
import CalendarEventsList from "./pages/CalendarEvents/CalendarEventsList";
import VenuesList from "./pages/WebPages/Venues/VenuesList";
import AddOrUpdateVenues from "./pages/WebPages/Venues/AddOrUpdateVenues";
import ProgramModuleListing from "./pages/ProgrammesList/ProgramModules/ProgramModuleListing";
import AddOrUpdateProgramModule from "./pages/ProgrammesList/ProgramModules/AddOrUpdateProgramModule";
import ProgramModuleDetail from "./pages/ProgrammesList/ProgramModules/ProgramModuleDetail";
import ViewTicketsList from "./pages/Payments/ViewTicketsList";
import ProgrammeTabConfiguration from "./pages/ProgrammesList/TabConfiguration";
import TabConfiguration from "./pages/ProgrammesList/Lessons/TabConfiguration";
import GeneralQuestionUsers from "./pages/GeneralQuestions/GeneralQuestionUsers";
import DigitalAssestsDetail from "./pages/DigitalAssets/DigitalAssestsDetail";
import AddOrUpdateDepartment from "./pages/Departments/AddOrUpdateDepartment";
import SupportTeam from "./pages/SupportTickets/SupportTeam";
import SupportTicketDelegate from "./pages/SupportTickets/SupportTicketDelegate";
import SubscriptionList from "./pages/SubscriptionList/SubscriptionList";
import MissionControlGraphics from "./pages/MissionControlGraphics/MissionControlGraphics";
import AddMissionControlGraphics from "./pages/MissionControlGraphics/AddMissionControlGraphics";
import ReminderConfiguration from "./pages/WebPages/WebPagePaymentPlans/ReminderConfiguration";
import CalendarSetting from "./pages/GeneralSetting/CalendarSetting";
import StatsFilter from "./pages/StatsFilter";
import NotificationSetting from "./pages/GeneralSetting/NotificationSetting";
import NotificationsSetting from "./pages/Community/NotificationsSetting";
import ManageMainPorTalProgrammeAccess from "./pages/consultant/ManageMainPorTalProgrammeAccess";
import DinnerMenuList from "./pages/WebPages/DinnerMenu/DinnerMenuList";
import AddOrUpdateDinnerMenu from "./pages/WebPages/DinnerMenu/AddOrUpdateDinnerMenu";
import AdminUserList from "./pages/AdminUser/AdminUserList";
import AddOrUpdateAdminUser from "./pages/AdminUser/AddOrUpdateAdminUser";
import AdminChangepassword from "./pages/AdminUser/AdminChangepassword";
import TransactionsList from "./pages/Payments/TransactionsList";
import SelfImageQuestionsAnswers from "./pages/Questions/SelfImageQuestionsAnswers";
import BillingTicketsList from "./pages/Payments/BillingTicketsList";
import AddEmailTemplate from "./pages/GeneralSetting/EmailTemplates/AddEmailTemplate";
import BookingNotes from "./pages/Booking/BookingNotes";
import PageSubscribers from "./pages/WebPages/TemplatePages/PageSubscribers";
import AddPaidTicket from "./pages/Payments/AddPaidTicket";
import RoadMapLevels from "./pages/RoadMapLevels/RoadMapLevels";
import AddOrUpdateLevels from "./pages/RoadMapLevels/AddOrUpdateLevels";
import BillingCheckedIn from "./pages/Payments/BillingCheckedIn";
import Welcome from "./pages/Welcome";
import EmailLogs from "./pages/EmailLogs/EmailLogs";
import { EmailLogsPreview } from "./pages/EmailLogs/EmailLogsPreview";
import MobileAppSetting from "./pages/GeneralSetting/MobileAppSetting";
import DefaultSettings from "./pages/GeneralSetting/DefaultSettings";
import Order from "./pages/Order/Order";
import TaxesConfiguration from "./pages/Taxes/Taxes";
import TaxList from "./pages/Taxes/TaxList";
import OrderDetail from "./pages/Payments/AffiliateTransactions/OrderDetail";
import StripeReader from "./pages/StripeReader/StripeReader";
import ManageStripeReader from "./pages/AdminUser/ManageStripeReader";
import LeadStatus from "./pages/SaleSection/LeadStatus";
import AddOrUpdateLeadStatus from "./pages/SaleSection/AddOrUpdateLeadStatus";
import WhatsAppSetting from "./pages/GeneralSetting/WhatsAppSetting";
import ManageLevelAccess from "./pages/MembersList/ManageLevelAccess";
import EventAccessPaymentPlan from "./pages/WebPages/WebPagePaymentPlans/EventAccessPaymentPlan";
import PaymentAgreementTemplateConfiguration from "./pages/PaymentTemplate/PaymentAgreementTemplateConfiguration";
import PaymentRequestAgreementConfiguration from "./pages/PaymentRequest/PaymentRequestAgreementConfiguration";
import ManageCommissionPaymentTemplate from "./pages/PaymentTemplate/ManageCommissionPaymentTemplate";
import CallTypeConfig from "./pages/consultant/CallTypeConfig";
import CommissionConfiguration from "./pages/PaymentTemplate/CommissionConfiguration";
import PaymentRequestCommissionConfiguration from "./pages/PaymentRequest/PaymentRequestCommissionConfiguration";
import PayCommission from "./pages/consultant/PayCommission";
import CallReminderSetting from "./pages/WebPages/WebPagePaymentPlans/CallReminderSetting";
import DetailDelegateTicket from "./pages/SupportTickets/DetailDelegateTicket";
import AddFireSetting from "./pages/GeneralSetting/FireSetting";
import WelcomeReminderSetting from "./pages/WebPages/WebPagePaymentPlans/WelcomeReminderSetting";
import MemberCalender from "./pages/MembersList/MemberCalender";
import MemberPods from "./pages/MembersList/MemberPods";
import ManageCommission from "./pages/Payments/ManageCommission";
import MembersListConsultantNew from "./pages/consultant/MemberListNew";
import DigitalAssetsCategories from "./pages/DigitalAssets/Categories/DigitalAssetsCategories";
import AddOrUpdateDigitalAssetsCategory from "./pages/DigitalAssets/Categories/AddOrUpdateDigitalAssetsCategory";
import ReminderUser from "./pages/ReminderUsers/ReminderUser";
import AddOrUpdateReminderUser from "./pages/ReminderUsers/AddOrUpdateReminderUser";
import AddReminderUser from "./pages/ReminderUsers/AddReminderUser";
import DelegateRecordingDetail from "./pages/DelegatesRecordings/DelegateRecordingDetail";
import CallStats from "./pages/CallStats/CallStats";
import InternalNotes from "./pages/Payments/InternalNotes";
import TransactionHistory from "./pages/Payments/AffiliateTransactions/TransactionHistory";
import DetailSupportTicketTrash from "./pages/SupportTickets/DetailSupportTicketTrash";
import MemberProfileNew from "./pages/MembersList/MemberProfileNew";
import ProgrammesQuestionsAnswers from "./pages/ProgrammesList/ProgrammesQuestionsAnswers";
import QuestionsAnswersTab from "./pages/MembersList/ProfileTabs/QuestionsAnswersTab";
import ShopOrder from "./pages/Shop/ShopOrder";
import TrackingHistory from "./components/TracKingHistory/TrackingHistory";
import MentorshipCategories from "./pages/Mentorship/MentorshipCategories";
import AddOrUpdateMentorshipCategory from "./pages/Mentorship/AddOrUpdateMentorshipCategory";
import MentorshipVideos from "./pages/Mentorship/CategoryVideos/MentorshipVideos";
import AddOrUpdateMentorshipVideos from "./pages/Mentorship/CategoryVideos/AddOrUpdateMentorshipVideos";
import ManageMemberEventAccess from "./pages/MembersList/ManageMemberEventAccess";
import TransactionsListFire from "./pages/Payments/TransactionsListFire";
import TransactionsListNew from "./pages/Payments/TransactionsListNew";
import ProductAccessPaymentPlan from "./pages/WebPages/WebPagePaymentPlans/ProductAccessPaymentPlan";
import ShopSetting from "./pages/GeneralSetting/ShopSetting";
import CommissionHistory from "./pages/consultant/CommissionHistory";
import CommissionHistoryTabs from "./pages/consultant/CommissionHistoryTabs";
import ProgrammeAccessPaymentRequest from "./pages/PaymentTemplate/ProgrammeAccessPaymentRequest";
import ProgrammeAccessPaymentTemplate from "./pages/PaymentTemplate/ProgrammeAccessPaymentRequest";
import EventAccessPaymentTemplate from "./pages/PaymentTemplate/EventAccessPaymentTemplate";
import EventAccessPaymentRequest from "./pages/PaymentRequest/EventAccessPaymentRequest";
import ProgrammeAccessPaymentRequestnew from "./pages/PaymentRequest/ProgrammeAccessPaymentRequest";
import CalenderPodsMianList from "./pages/CalendarPods/CalenderPodsMianList";
import ManageCountry from "./pages/GeneralSetting/ManageCountry";
import CalendarPodsListCallPods from "./pages/CalendarPods/CalendarPodsListCallPods";
import CalendarPodsBookCallDetail from "./pages/CalendarPods/CalendarPodsBookCallDetail";
import ProgramReminderConfiguration from "./pages/ProgrammesList/ProgramReminderConfiguration";
import DynamitePayment from "./pages/Payments/DynamitePayment";
import AddDynamitePayment from "./pages/Payments/AddDynamitePayment";
import DigitalPayment from "./pages/Payments/DigitalPayment ";
import ManageNotifications from "./pages/GeneralSetting/ManageNotifications";
import { EmailTemplateNotification } from "./pages/GeneralSetting/EmailTemplateNotification";
import TodayHistory from "./pages/TodayHistory/TodayHistory";
import Commissions from "./pages/Commissions/Commissions";
import TrackerSettings from "./pages/GeneralSetting/TrackerSettings";
import CommissionsDetail from "./pages/Commissions/CommissionsDetail";
import PaidCommissions from "./pages/Commissions/PaidCommissions";
import PendingCommissions from "./pages/Commissions/PendingCommissions";
import CommissionPay from "./pages/Commissions/CommissionPay";
import GeneralNavItems from "./pages/NavItems/GeneralNavItems";
import ManageNavItemsAccess from "./pages/NavItems/ManageNavItemsAccess";
import AddOrUpdateEvent from "./pages/CalendarEvents/AddOrUpdateEvent";
import EventDetailList from "./pages/CalendarEvents/EventDetailList";
import StripeSubscriptionList from "./pages/Stripe/StripeSubscriptionList";
import ShopProduct from "./pages/ShopProducts/ShopProduct";
import RenderStripeSubscriptions from "./pages/Stripe/RenderStripeSubscriptions";
import RenderStripeTransactions from "./pages/Stripe/RenderStripeTransactions";
import CatagoresTemplates from "./pages/FacebookTemplates/ProgressCategory/CatagoresTemplates";
import AddCategory from "./pages/FacebookTemplates/ProgressCategory/AddCategory";
import FacebookTemplates from "./pages/FacebookTemplates/ProgressCategory/Templates/FacebookTemplates";
import Badges from "./pages/Badges/Badges";
import AddOrUpdateBadges from "./pages/Badges/AddOrUpdateBadges";
import BadgeConfiguration from "./pages/RoadMapLevels/BadgeConfiguration";
import ProductMeasureConfiguration from "./pages/ShopProducts/ProductMeasureConfiguration";
import StoreHouseContentSettings from "./pages/StoreHouseContentSettings/StoreHouseContentSettings";
import DhlApi from "./pages/GeneralSetting/DhlApi";
import ManagePageOptions from "./pages/WebPages/TemplatePages/ManagePageOptions";
import ChallengeRewardSetting from "./pages/Challenge/ChallengeRewardSetting";
import CardsClient from "./pages/Cards/CardsClient";
import AddOrUpdateCard from "./pages/Cards/AddOrUpdateCard";
import NewCalendarNew from "./pages/CalendarEvents/NewCalendarNew";
import WebsiteCockieSetting from "./pages/WebsiteCockieSetting/WebsiteCockieSetting";
import MonthlyReportSettings from "./pages/MonthlyReportSettings/MonthlyReportSettings";
import AddOrUpdateQuarter from "./pages/Quarters/AddOrUpdateQuarter";
import MissionControlSetting from "./pages/WebPages/TemplatePages/MissionControlSetting";
import ManageBonusContentAccess from "./pages/WebPages/WebPagePaymentPlans/ManageBonusContentAccess";
import ManageBonusContentAccessForUser from "./pages/MembersList/ManageBonusContentAccessForUser";
import CommissionConfigurationPaymentPlan from "./pages/WebPages/WebPagePaymentPlans/CommissionConfigurationPaymentPlan";
import ManageCommissionAccessFromPlans from "./pages/consultant/ManageCommissionAccessFromPlans";
import AddOrder from "./pages/Order/AddOrder ";
import ManageNotificationSequence from "./pages/WebPages/WebPagePaymentPlans/ManageNotificationSequence";
import FreePlanPopupSetting from "./pages/WebPages/WebsitePages/FreePlanPopupSetting";
import PaymentThemeSetting from "./pages/WebPages/WebsitePages/PaymentThemeSetting";
import AddOrUpdatePaymentRequest from "./pages/PaymentRequest/AddOrUpdatePaymentRequest";
import AddOrUpdatePaymentTemplate from "./pages/PaymentTemplate/AddOrUpdatePaymentTemplate";
import AddOrUpdateCalendarGroup from "./pages/CalendarGroups/AddOrUpdateCalendarGroup";
import AddOrUpdateDigitalAsset from "./pages/DigitalAssets/AddOrUpdateDigitalAsset";
import DigitalAssetsList from "./pages/DigitalAssets/DigitalAssetsList";
import SalesTeam from "./pages/DelegatesSalesTeam/SalesTeam";
import ManageSalesTeamNavItemsAccess from "./pages/NavItems/ManageSalesTeamNavItemsAccess";
import DelegateRoutes from "./RoutesManagement/DelegetRoutes";
import ConsultantRoutes from "./RoutesManagement/ConsultantRoutes";
import MarketingRoutes from "./RoutesManagement/MarketingRoutes";
import InnerCircleRoutes from "./RoutesManagement/InnerCircleRoutes";
import SalesTeamMain from "./pages/DelegatesSalesTeam/SalesTeamMain";
import MemberNavItems from "./pages/MembersList/MemberNavItems";
import ProgrammeNavItems from "./pages/ProgrammesList/ProgrammeNavItems";
import TeamGeneralSettings from "./pages/DelegatesTeamSettings/TeamGeneralSettings";
import AccountTerminationRequests from "./pages/AccountTerminationRequests/AccountTerminationRequests";
import RequestedCsvFIle from "./pages/RequestedCsvFIle/RequestedCsvFIle";

import WebPagePlanPackege from "./pages/WebPages/WebPagePlanPackege/WebPagePlanPackege";
import AddOrUpdateWebPagePlanPackage from "./pages/WebPages/WebPagePlanPackege/AddOrUpdateWebPagePlanPackage";
import DynamiteEventsMain from "./pages/DynamiteEvents/DynamiteEventsMain";
import EventTemplateDelegateAccess from "./pages/DynamiteEvents/EventTemplateDelegateAccess";
import TeamGeneralSettingsSpacificData from "./pages/DelegatesTeamSettings/TeamGeneralSettingsSpacificData";
import NinetyDayContentSetting from "./pages/90DayQuestion/NinetyDayContentSetting";
import ManageEventTemplateAccess from "./pages/consultant/ManageEventTemplateAccess";
import ShortLinksList from "./pages/ShortLinks/ShortLinksList";
import AddOrUpdateShortList from "./pages/ShortLinks/AddOrUpdateShortList";
import BitlySetting from "./pages/GeneralSetting/BitlySetting";
import AnalyticsReport from "./pages/AnalyticsReport/AnalyticsReport";
import AnalyticsEvent from "./pages/AnalyticsReport/AnalyticsEvent";
import AnalyticsCountry from "./pages/AnalyticsReport/AnalyticsCountry";
import AnalyticsEventDetail from "./pages/AnalyticsReport/AnalyticsEventDetail";
import SaleCommissionReport from "./pages/Sales&CommissionRepost/SalesCommissionRepost";
import Engagements from "./pages/ShortLinks/Engagements";
import AnalaticsalReport from "./pages/AnalyticsReport/AnalaticsalReport";
import FunnelExploration from "./pages/AnalyticsReport/FunnelExploration";
import AddOrUpdateFunnel from "./pages/AnalyticsReport/AddOrUpdateFunnel";
import TechDetail from "./pages/AnalyticsReport/TechDetail";
import FunnelStep from "./pages/AnalyticsReport/FunnelStep";
import TwilioSettings from "./pages/GeneralSetting/TwilioSettings";
import RealTimeOverview from "./pages/AnalyticsReport/RealTimeOverview";
import ProductAccessCommunity from "./pages/Community/Rewards/ProductAccessCommunity";
import TemplateConfig from "./pages/WebPages/TemplatePages/TemplateConfig";
import AddorUpdateConfig from "./pages/WebPages/TemplatePages/AddorUpdateConfig";
import UnsubscribeMembers from "./pages/MembersList/UnsubscribeMembers";
import DynamiteLifestyleTeamMembersRoutes from "./RoutesManagement/DynamiteLifestyleTeamMembersRoutes";
import CoupenList from "./pages/Coupen/CoupenList";
import AddOrUpDateCoupen from "./pages/Coupen/AddOrUpDateCoupen";
import PlanChart from "./pages/WebPages/TemplatePages/PlanChart";
import AssessmentSettingTabs from "./pages/WebPages/AttitudeAccessmentQuestions/AssessmentSettingTabs";
// --------------------./pages/WebPages/TemplatePages/PlanChart----
const Authentication = () => {
  if (localStorage.getItem("token")) {
    return <Navigate to="/welcome"> </Navigate>;
  }
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
};

export default function Router() {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/dashboard" element={<DashboardApp />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/stats" element={<StatsFilter />} />
        <Route path="/call-stats" element={<CallStats />} />
        <Route path="/programmes" element={<ProgrammesList />} />
        <Route path="/programmes/members/:id" element={<ProgrammeMembers />} />
        <Route
          path="/programmes/navitems/:module_id"
          element={<ProgrammeNavItems />}
        />
        <Route
          path="/programmes/programmes-detail/:programme_slug"
          element={<ProgrammesDetail />}
        />
        <Route
          path="/programmes/programm-configuration/:programme_slug"
          element={<ProgrammeTabConfiguration />}
        />
        <Route
          path="/programmes/add-programme"
          element={<AddOrUpdateProgramme />}
        />
        <Route
          path="/programmes/edit-programme/:programme_slug"
          element={<AddOrUpdateProgramme />}
        />{" "}
        <Route
          path="/programmes/reminder-config/:programme_slug"
          element={<ProgramReminderConfiguration />}
        />
        <Route
          path="/programmes/programm-document/:programme_slug"
          element={<ProgrammeDocuments />}
        />
        <Route
          path="/programmes/bulk-access/:program_access_id"
          element={<ProgrammeBulkAccess />}
        />
        <Route path="/programmes/:type/:id" element={<ProgrammeBulkAccess />} />
        <Route
          path="/programmes/programm-document/:programme_slug/add-document"
          element={<AddOrUpdateProgramDocument />}
        />
        <Route
          path="/programmes/programm-document/:programme_slug/edit-document/:document_slug"
          element={<AddOrUpdateProgramDocument />}
        />
        <Route
          path="/programmes/sections/:programme_slug"
          element={<ProgrammeSectionList />}
        />
        <Route
          path="/programmes/sections/:programme_slug/add-section"
          element={<AddOrUpdateProgrammeSection />}
        />
        <Route
          path="/programmes/sections/:programme_slug/edit-section/:section_slug"
          element={<AddOrUpdateProgrammeSection />}
        />
        <Route
          path="/programmes/reviews/:programme_slug"
          element={<ProgrammeReviews />}
        />
        <Route
          path="/programmes/reviews/:programme_slug/add-review"
          element={<AddOrUpdateProgramReview />}
        />
        <Route
          path="/programmes/reviews/:programme_slug/edit-review/:review_id"
          element={<AddOrUpdateProgramReview />}
        />
        {/* ProgrammeAutomatedGroups */}
        <Route
          path="/programmes/automated-groups/:programme_slug"
          element={<AutomatedGroups />}
        />
        <Route
          path="/programmes/automated-groups/:programme_slug/add-group"
          element={<AddOrUpdateAutomatedGroup />}
        />
        <Route
          path="/programmes/automated-groups/:programme_slug/edit-group/:group_slug"
          element={<AddOrUpdateAutomatedGroup />}
        />
        {/* ProgrammeAutomatedGroups end  */}
        <Route
          path="/programmes/locked-programme/:programme_slug"
          element={<LockedProgramInfo />}
        />
        <Route path="/lessons/:programme_slug" element={<LessonListing />} />
        <Route
          path="/lessons/module/:programme_slug"
          element={<LessonListing />}
        />
        <Route
          path="/programmes/modules/:programme_slug"
          element={<ProgramModuleListing />}
        />
        <Route
          path="/programmes/modules/:programme_slug/add"
          element={<AddOrUpdateProgramModule />}
        />
        <Route
          path="/programmes/modules/:programme_slug/edit/:module_slug"
          element={<AddOrUpdateProgramModule />}
        />
        <Route
          path="/programmes/modules-detail/:programme_slug/:module_slug"
          element={<ProgramModuleDetail />}
        />
        <Route
          path="/lessons/:programme_slug/add-lesson"
          element={<AddOrUpdateLesson />}
        />
        <Route
          path="/lessons/edit-lesson/:programme_slug/:lesson_slug"
          element={<AddOrUpdateLesson />}
        />
        <Route
          path="/lessons/tab-configuration/:programme_slug/:lesson_slug"
          element={<TabConfiguration />}
        />
        <Route
          path="/lessons/lesson-detail/:lesson_slug"
          element={<LessonsDetail />}
        />
        <Route
          path="/lessons/lesson-document/:lesson_slug"
          element={<LessonDocuments />}
        />
        <Route
          path="/lessons/lesson-document/:lesson_slug/add-document"
          element={<AddOrUpdateLessonDocument />}
        />
        <Route
          path="/lessons/lesson-document/:lesson_slug/edit-document/:document_slug"
          element={<AddOrUpdateLessonDocument />}
        />
        <Route
          path="/lessons/lesson-recordings/:lesson_slug"
          element={<LessonRecordings />}
        />
        <Route
          path="/lessons/lesson-recordings/recording-detail/:recording_slug"
          element={<LessonRecordingDetail />}
        />
        <Route
          path="/lessons/lesson-recordings/:lesson_slug/add-recording"
          element={<AddOrUpdateLessonRecordings />}
        />
        <Route
          path="/lessons/lesson-recordings/edit-recording/:recording_slug"
          element={<AddOrUpdateLessonRecordings />}
        />
        <Route
          path="/consultants-general-setting"
          element={<UpdateConsultantGeneralSetting />}
        />
        <Route
          path="/lessons/questions-configuration/:id"
          element={<QuestionConfigurationForLesson />}
        />
        <Route
          path="/lessons/lessons-questions"
          element={
            <GeneralQuestions
              created_for="lesson"
              question_page_title="Lesson Questions"
            />
          }
        />
        <Route
          path="/lessons/lessons-questions-answers"
          element={<QuestionsAnswersAsCreatedFor created_for="lesson" />}
        />
        <Route
          path="/lessons/lessons-questions-answers/answers-detail"
          element={<QuestionsAnswers />}
        />
        <Route
          path="/members-answers-list"
          element={
            <QuestionsAnswersList
              user_type="member"
              heading="Member Answers List"
            />
          }
        />{" "}
        <Route
          path="/approved"
          element={
            <SalesTeam
              user_type="member"
              heading="Approved Sales Team"
              is_approved={true}
            />
          }
        />
        <Route
          path="/pending"
          element={
            <SalesTeam
              user_type="member"
              heading="Pending Sales Team"
              is_approved={false}
            />
          }
        />
        <Route
          path="/members-answers-list/answers-detail"
          element={<QuestionsAnswers />}
        />
        <Route
          path="/delegate-answers-list"
          element={
            <QuestionsAnswersList
              user_type="delegate"
              heading="Delegate Answers List"
            />
          }
        />
        <Route
          path="/delegate-answers-list/answers-detail"
          element={<QuestionsAnswers />}
        />
        {/* PaymentTransactions */}
        <Route path="/payment-transactions" element={<TransactionsList />} />
        {/* <Route path="/payment-transactions" element={<TransactionsListNew />} /> */}
        <Route
          path="/fire-payment-transactions"
          element={<TransactionsListFire />}
        />{" "}
        <Route
          path="/fire-payment-transactions/add-transaction"
          element={<AddTransaction />}
        />
        <Route
          path="/fire-payment-transactions/edit-transaction/:transaction_id"
          element={<AddTransaction />}
        />
        <Route
          path="/payment-transactions/add-transaction"
          element={<AddTransaction />}
        />
        <Route
          path="/payment-transactions/edit-transaction/:transaction_id"
          element={<AddTransaction />}
        />
        <Route
          path="/payment-transactions/manage-commission/:transaction_id"
          element={<ManageCommission />}
        />{" "}
        <Route
          path="/fire-payment-transactions/manage-commission/:transaction_id"
          element={<ManageCommission />}
        />
        <Route
          path="/payment-transactions/view-tickets/:transaction_id"
          element={<ViewTicketsList />}
        />
        <Route path="/billing-tickets" element={<BillingTicketsList />} />
        <Route path="/checked-in" element={<BillingCheckedIn />} />
        <Route
          path="/billing-tickets/add-paid-tickets"
          element={<AddPaidTicket />}
        />
        {/* programms Doc routes */}
        {/* PodCastList */}
        <Route path="/pod-casts" element={<PodCastList />} />
        {/* PodCastList */}
        <Route path="/website-programmes" element={<WebsiteProgrammesList />} />
        <Route
          path="/website-programmes/add-programmes"
          element={<AddWebsiteProgramme />}
        />
        <Route
          path="/website-programmes/edit-programmes/:programme_slug"
          element={<AddWebsiteProgramme />}
        />
        <Route path="/payment-plans" element={<WebPagePaymentPlans />} />
        <Route
          path="/payment-plans/add-plan"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/payment-plans/edit-plan/:plan_id"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/digital-asset-categories"
          element={<DigitalAssetsCategories />}
        />
        <Route
          path="/digital-asset-categories/add-category"
          element={<AddOrUpdateDigitalAssetsCategory />}
        />
        <Route
          path="/digital-asset-categories/edit-category/:category_id"
          element={<AddOrUpdateDigitalAssetsCategory />}
        />
        <Route path="/digital-assets" element={<DigitalAssetsList />} />
        <Route path="/card-of-the-day" element={<CardsClient />} />
        <Route
          path="/deleted-members"
          element={<AccountTerminationRequests />}
        />
        <Route path="/card-of-the-day/add-card" element={<AddOrUpdateCard />} />
        <Route
          path="/card-of-the-day/edit-card/:id"
          element={<AddOrUpdateCard />}
        />
        <Route
          path="/digital-assets/edit-asset-document/:asset_id"
          element={<AddOrUpdateDigitalAsset />}
        />
        <Route
          path="/digital-assets/add-asset-document"
          element={<AddOrUpdateDigitalAsset />}
        />
        {/* programms Doc routes end */}
        {/* program > section */}
        {/* program > section end */}
        {/* Programme reviews */}
        {/* Programme reviews end*/}
        {/* program section  */}
        <Route
          path="/dynamite-diary-setting"
          element={<DynamiteDiarySetting />}
        />
        <Route path="/taxes/add-taxes" element={<TaxesConfiguration />} />
        <Route path="/taxes/edit-taxes/:id" element={<TaxesConfiguration />} />
        <Route path="/taxes" element={<TaxList />} />
        <Route path="/meditation-setting" element={<MeditationSetting />} />
        <Route
          path="/monthly-report-settings"
          element={<MonthlyReportSettings />}
        />
        <Route
          path="/website-cockie-setting"
          element={<WebsiteCockieSetting />}
        />
        <Route
          path="/meditation-challenge-setting"
          element={<MeditationChallengeSetting />}
        />
        <Route
          path="/challenges-reward-setting"
          element={<ChallengeRewardSetting />}
        />
        <Route
          path="/store-house-content-setting"
          element={<StoreHouseContentSettings />}
        />
        <Route
          path="/gradtitude-daily-setting"
          element={<GratitudeDailySetting />}
        />
        {/* program section end */}
        {/* Member */}
        <Route path="/member" element={<MembersList />} />
        <Route
          path="/stripe-transactions"
          element={<RenderStripeTransactions />}
        />
        <Route
          path="/member/stripe-transactions"
          element={<RenderStripeTransactions />}
        />
        <Route
          path="/member/stripe-subscriptions"
          element={<RenderStripeSubscriptions />}
        />
        <Route
          path="/stripe-subscriptions"
          element={<RenderStripeSubscriptions />}
        />
        <Route
          path="/member/program-status"
          element={<RenderStripeTransactions />}
        />
        <Route path="/unsubscribe-members" element={<UnsubscribeMembers />} />
        <Route path="/done-listing" element={<MembersList />} />
        <Route path="/order" element={<Order />} />
        <Route
          path="/member/import-member"
          element={<ImportProgrammeMembers />}
        />
        <Route path="/member/events/:id" element={<MemberCalender />} />
        <Route path="/member/pods/:id" element={<MemberPods />} />
        <Route
          path="/member/questions-answers"
          element={<QuestionsAnswersForUser user_type="member" />}
        />
        <Route
          path="/done-listing/questions-answers"
          element={<QuestionsAnswersForUser user_type="member" />}
        />
        <Route
          path="/member/questions-answers/answer-detail"
          element={<QuestionsAnswers />}
        />
        <Route
          path="/member/member-profile/answer-detail"
          element={<QuestionsAnswersTab />}
        />
        <Route
          path="/dynamite-events/register-events"
          element={<RegisterEventsUsers />}
        />
        <Route
          path="/member/member-diary/:member_id"
          element={<MemberDiaryList />}
        />
        <Route
          path="/done-listing/member-diary/:member_id"
          element={<MemberDiaryList />}
        />
        <Route path="/member/add-member" element={<AddOrUpdateMember />} />
        <Route
          path="/done-listing/add-member"
          element={<AddOrUpdateMember />}
        />
        <Route
          path="/member/edit-member/:member_id"
          element={<AddOrUpdateMember />}
        />
        <Route
          path="/done-listing/edit-member/:member_id"
          element={<AddOrUpdateMember />}
        />
        <Route
          path="/member/member-profile/:id"
          element={<MemberProfileNew />}
        />
        <Route
          path="/done-listing/member-profile/:id"
          element={<MemberProfile />}
        />
        <Route
          path="/member/member-history/:member_id"
          element={<MemberFeedsHistory />}
        />
        <Route
          path="/done-listing/member-history/:member_id"
          element={<MemberFeedsHistory />}
        />
        <Route
          path="/member/member-subscribers/:member_id"
          element={<MemberSubscriptions />}
        />
        <Route
          path="/done-listing/member-subscribers/:member_id"
          element={<MemberSubscriptions />}
        />
        <Route path="/subscription-list" element={<SubscriptionList />} />
        <Route
          path="/stripe-transactions"
          element={<RenderStripeTransactions />}
        />
        <Route
          path="/member/edit-member-subscribers/:id"
          element={<UpdateSubscription />}
        />
        <Route
          path="/member/member-groups/:id"
          element={<MemberGroupsList />}
        />
        <Route
          path="/done-listing/member-personal-notes/:id"
          element={<MemberPersonalNote />}
        />
        <Route
          path="/member/member-personal-notes/:id"
          element={<MemberPersonalNote />}
        />
        <Route
          path="/member/member-goal-statement/:id"
          element={<MemberGoalstatementList />}
        />
        <Route
          path="/member/goal-statement-question-reply/:id"
          element={<QuestionsReply />}
        />
        <Route
          path="/member/event-subscriber/:id"
          element={<EventSubscribers />}
        />
        <Route
          path="/member/goal-statement-question-history/:id"
          element={<AnswerHistory />}
        />
        <Route
          path="/member/programme-access/:member_id"
          element={<ManageProgrammeAccess />}
        />
        <Route
          path="/member/bonus-content-access/:member_id"
          element={<ManageBonusContentAccessForUser />}
        />
        <Route
          path="/member/manage-navitems-access/:module_id"
          element={<MemberNavItems />}
        />
        <Route
          path="/done-listing/programme-access/:member_id"
          element={<ManageProgrammeAccess />}
        />
        <Route
          path="/member/event-access/:member_id"
          element={<ManageMemberEventAccess />}
        />
        <Route
          path="/done-listing/event-access/:member_id"
          element={<ManageMemberEventAccess />}
        />
        <Route
          path="/member/level-access/:member_id"
          element={<ManageLevelAccess />}
        />
        <Route
          path="/done-listing/level-access/:member_id"
          element={<ManageLevelAccess />}
        />
        <Route
          path="/member/reward-access/:member_id"
          element={<ManageRewardAccess />}
        />{" "}
        <Route
          path="/done-listing/reward-access/:member_id"
          element={<ManageRewardAccess />}
        />
        <Route
          path="/member/billing-detail/:billing_id"
          element={<ViewBillingDetail />}
        />
        <Route
          path="/member/all-transaction/:member_id"
          element={<MemberTransactions />}
        />
        <Route
          path="/done-listing/all-transaction/:member_id"
          element={<MemberTransactions />}
        />
        <Route
          path="/member/view-billing-list/:member_id"
          element={<ViewBillings />}
        />
        <Route
          path="/done-listing/view-billing-list/:member_id"
          element={<ViewBillings />}
        />
        <Route
          path="/done-listing/view-billing-list/:member_id"
          element={<ViewBillings />}
        />
        <Route
          path="/member/add-payment-request/:member_id"
          element={<AddBillingsRequest />}
        />
        <Route path="/member/member-pods" element={<PodsListMemberProfile />} />
        <Route path="/member/to-do-tracker/:id" element={<ToDoTracker />} />
        {/* <Route path="/member/to-do-tracker/:id" element={<ChartJs />} /> */}
        {/* member end  */}
        {/* consultant   */}
        {/* <Route
          path="/consultant"
          element={<ConsultantList type={"delegate"} heading={"Delegates"} />}
        /> */}
        <Route path="/consultant/*" element={<DelegateRoutes />} />
        <Route path="/delegate/*" element={<ConsultantRoutes />} />
        <Route path="/marketing/*" element={<MarketingRoutes />} />
        <Route path="/inner-circle/*" element={<InnerCircleRoutes />} />
        <Route
          path="/dynamite-lifestyle-team-members/*"
          element={<DynamiteLifestyleTeamMembersRoutes />}
        />
        <Route path="/requested-csv-files" element={<RequestedCsvFIle />} />
        <Route path="/short-links" element={<ShortLinksList />} />
        <Route path="/short-links/engagements/:id" element={<Engagements />} />
        <Route path="/analytics-overview" element={<AnalyticsReport />} />
        <Route path="/real-time-overview" element={<RealTimeOverview />} />
        <Route path="/analytics-event" element={<AnalyticsEvent />} />
        <Route path="/platform-details" element={<TechDetail />} />
        <Route
          path="/analytics-event/event-detail/:event_name"
          element={<AnalyticsEventDetail />}
        />
        <Route path="/analytics-country" element={<AnalyticsCountry />} />
        <Route
          path="/short-links/add-short-link"
          element={<AddOrUpdateShortList />}
        />{" "}
        <Route
          path="/short-links/edit-short-link/:id"
          element={<AddOrUpdateShortList />}
        />
        <Route
          path="/manage-sales-team-navitems"
          element={
            <ManageSalesTeamNavItemsAccess
              type="delegate_sales_team"
              title="Manage  SubTeam NavItems"
              navigate="/consultant"
            />
          }
        />
        <Route
          path="/manage-delegate-user-navitems"
          element={
            <ManageSalesTeamNavItemsAccess
              type="delegate_user"
              title="Manage  Delegates NavItems"
              navigate="/consultant"
            />
          }
        />{" "}
        <Route
          path="/manage-consultant-user-navitems"
          element={
            <ManageSalesTeamNavItemsAccess
              type="consaltant_team"
              title="Manage Consultant  NavItems"
              navigate="/consultant"
            />
          }
        />{" "}
        <Route
          path="/manage-inner-circle-navitems"
          element={
            <ManageSalesTeamNavItemsAccess
              type="inner_circle"
              title="Manage Inner Circle NavItems"
              navigate="/consultant"
            />
          }
        />
        <Route
          path="manage-marketing-team-navitems"
          element={
            <ManageSalesTeamNavItemsAccess
              type="marketing_team"
              title="Marketing Team Navitems"
              navigate="/consultant"
            />
          }
        />
        <Route
          path="/support-team/manage-navitems-access/:user_id"
          element={
            <ManageNavItemsAccess
              type="delegate"
              title="Support Team"
              navigate="/support-team"
            />
          }
        />
        <Route
          path="/support-team/change-password/:id"
          element={<ChangeConsultantPassword />}
        />
        {/* <Route
          path="/consultant/delegate-90-days-questions/:id"
          element={<Delegate90DaysQuestions />}
        /> */}
        <Route path="/all-leads/:id" element={<AllSalesPage />} />
        <Route path="/history" element={<TodayHistory />} />
        <Route path="/sub-team" element={<SalesTeamMain />} />
        <Route
          path="/sub-team/change-password/:id"
          element={<ChangeConsultantPassword />}
        />
        <Route
          path="/sub-team/event-access/:id"
          element={<ManageEventAccess heading={"Sub Team"} />}
        />{" "}
        <Route
          path="/sub-team/template-event-access/:id"
          element={<ManageEventTemplateAccess type={"Sub Team"} />}
        />
        <Route
          path="/sub-team/manage-access/:id"
          element={
            <TeamGeneralSettingsSpacificData
              title={"Manage  Access Settings"}
            />
          }
        />
        <Route
          path="/sub-team/programme-access/:id"
          element={<ManageMainPorTalProgrammeAccess type={"sub Team"} />}
        />
        <Route path="/all-leads" element={<AllSalesPage />} />
        {/* consultant end  */}
        {/* Delegate event */}
        <Route path="/delegate-recordings" element={<DelegatesRecordings />} />
        <Route
          path="/delegate-recordings/add-recording"
          element={<AddOrUpdateDelegatesRecording />}
        />
        <Route
          path="/delegate-recordings/edit-recording/:recording_id"
          element={<AddOrUpdateDelegatesRecording />}
        />
        <Route
          path="/delegate-recordings/recording-detail/:recording_slug"
          element={<DelegateRecordingDetail />}
        />
        <Route
          path="/delegate-events/event-detail"
          element={<DelegateEventDetailList />}
        />
        <Route
          path="/delegate-events/event-detail/:event_slug"
          element={<DelegateEventDetailList />}
        />
        <Route
          path="/calendar-events/event-detail/:event_slug"
          element={<EventDetailList />}
        />
        {/* Delegate event end*/}
        <Route path="/bookings" element={<BookingList />} />
        <Route path="/bookings/answers-detail" element={<QuestionsAnswers />} />
        <Route path="/book-call-types" element={<BookCallTypes />} />
        <Route path="/booking-status" element={<BookingStatus />} />
        <Route path="/bookings/notes/:id" element={<BookingNotes />} />
        <Route
          path="/book-call-types/add-book-call-type"
          element={<AddOrUpdateBookCallType />}
        />
        <Route
          path="/book-call-types/edit-book-call-type/:book_call_type_id"
          element={<AddOrUpdateBookCallType />}
        />
        {/* /goal-statement start */}
        <Route
          path="/wheel-of-life-member-list"
          element={<WheelOfLifeMemberList />}
        />
        <Route
          path="/wheel-of-life-member-list/:id"
          element={<WheelOfLifeMemberDetailQuestion />}
        />
        <Route
          path="/programmes/delegate-questions"
          element={
            <GeneralQuestions
              created_for="programme"
              question_page_title="Delegate Questions"
            />
          }
        />
        <Route
          path="/programmes/delegate-questions/answers"
          element={<QuestionsAnswersAsCreatedFor created_for="programme" />}
        />
        <Route
          path="/programmes/delegate-questions/answers/answers-detail"
          element={<ProgrammesQuestionsAnswers />}
        />
        {/* <Route
          path="/programmes/programmes-detail/:id/add-goal-statement"
          element={<ProgrammeAddOrUpdateQuestion />}
        /> */}
        {/* <Route
          path="/programmes/programmes-detail/:id/edit-goal-statement/:question_id"
          element={<ProgrammeAddOrUpdateQuestion />}
        /> */}
        {/* <Route path="/TablePage" element={<TablePage />} /> */}
        <Route path="/test-custom-mui-table" element={<TestCustomMUITable />} />
        <Route
          path="/goal-statement"
          element={<GoalStatement type="goal_statement" />}
        />
        <Route
          path="/goal-statement/add-question"
          element={<AddOrUpdateQuestion type="goal_statement" />}
        />
        <Route
          path="/goal-statement/detail-question/:id"
          element={<DetailQuestion type="goal_statement" />}
        />
        <Route
          path="/goal-statement/edit-question/:question_slug"
          element={<AddOrUpdateQuestion type="goal_statement" />}
        />
        <Route
          path="/self-image-answers"
          element={
            <QuestionsAnswersAsCreatedFor
              created_for="self_image"
              page_title="Self Image ( Answers )"
            />
          }
        />
        <Route
          path="/self-image-completed-answers"
          element={
            <QuestionsAnswersAsCreatedFor
              created_for="self_image"
              type="completed"
              page_title="Self Image ( Completed Answers )"
            />
          }
        />
        <Route
          path="/self-image-incompleted-answers"
          element={
            <QuestionsAnswersAsCreatedFor
              created_for="self_image"
              type="incompleted"
              page_title="Self Image ( Incompleted Answers )"
            />
          }
        />
        <Route
          path="/self-image-responded-answers"
          element={
            <QuestionsAnswersAsCreatedFor
              created_for="self_image"
              type="responded"
              page_title="Self Image ( Responded Answers )"
            />
          }
        />
        <Route
          path="/self-image-incompleted-answers/answers-detail"
          element={<SelfImageQuestionsAnswers />}
        />
        <Route
          path="/self-image-completed-answers/answers-detail"
          element={<SelfImageQuestionsAnswers />}
        />
        <Route
          path="/self-image-responded-answers/answers-detail"
          element={<SelfImageQuestionsAnswers />}
        />
        <Route
          path="/self-image-answers/answers-detail"
          element={<SelfImageQuestionsAnswers />}
        />
        <Route
          path="/self-image-completed-answers/answers-detail"
          element={<SelfImageQuestionsAnswers />}
        />
        <Route
          path="/self-image-questions"
          element={
            <GeneralQuestions
              created_for="self_image"
              question_page_title="Self Image Questions"
              allowed_users={true}
              show_back_button={false}
            />
          }
        />
        <Route
          path="/self-image-answers"
          element={
            <GeneralQuestions
              created_for="self_image"
              question_page_title="Self Image Questions"
              allowed_users={true}
              show_back_button={false}
            />
          }
        />
        <Route
          path="/self-image-questions/users-detail/:question_id"
          element={<GeneralQuestionUsers type="self_image" />}
        />
        {/* /goal-statement end */}
        {/* /website-pages */}
        <Route path="/website-pages" element={<TemplatePages />} />
        <Route path="/template-configuration" element={<TemplateConfig />} />
        <Route
          path="/template-configuration/add-template"
          element={<AddorUpdateConfig />}
        />
        <Route
          path="/template-configuration/edit-template/:id"
          element={<AddorUpdateConfig />}
        />
        <Route path="/dynamite-gallery" element={<DynamiteGallery />} />
        <Route
          path="/website-pages/:page_id/payment-plans/:plan_id/agreement-config"
          element={<AgreementConfiguration />}
        />
        <Route
          path="/payment-template/payment-agreement-config/:plan_id"
          element={<PaymentAgreementTemplateConfiguration />}
        />
        <Route
          path="/payment-request/payment-agreement-config/:plan_id"
          element={<PaymentRequestAgreementConfiguration />}
        />{" "}
        <Route
          path="/payment-template/payment-program-access/:plan_id"
          element={<ProgrammeAccessPaymentTemplate />}
        />
        <Route
          path="/payment-request/payment-program-access/:plan_id"
          element={<ProgrammeAccessPaymentRequestnew />}
        />
        <Route
          path="/payment-template/payment-event-access/:plan_id"
          element={<EventAccessPaymentTemplate />}
        />{" "}
        <Route
          path="/payment-request/payment-event-access/:plan_id"
          element={<EventAccessPaymentRequest />}
        />
        <Route
          path="/payment-request/commission-configuration/:id"
          element={<PaymentRequestCommissionConfiguration />}
        />
        <Route path="/reminder-setting" element={<ReminderConfiguration />} />
        <Route
          path="/call-reminder-setting"
          element={<CallReminderSetting />}
        />
        <Route
          path="/welcome-reminder-setting"
          element={<WelcomeReminderSetting />}
        />
        <Route
          path="/website-pages/manage-commission-access/:page_id"
          element={<ManageCommissionAccessFromPages />}
        />
        <Route
          path="/website-pages/answers"
          element={<QuestionsAnswersAsCreatedFor created_for="page" />}
        />
        <Route
          path="/website-pages/answers/answers-detail"
          element={<QuestionsAnswers />}
        />
        <Route
          path="/website-pages/social-sharing-setting/:page_id"
          element={<SocialSharingSetting />}
        />
        <Route
          path="/website-pages/page-subscriber/:page_id"
          element={<PageSubscribers />}
        />
        <Route
          path="/template-pages/page-subscriber/:page_id"
          element={<PageSubscribers />}
        />
        <Route path="/website-pages/add-page" element={<AddOrUpdatePage />} />
        <Route
          path="/website-pages/edit-page/:page_slug"
          element={<AddOrUpdatePage />}
        />
        <Route
          path="/website-pages/plan-packege/:page_slug"
          element={<WebPagePlanPackege />}
        />{" "}
        <Route
          path="/template-pages/plan-packege/:page_slug"
          element={<WebPagePlanPackege />}
        />
        <Route
          path="/website-pages/update-page-content/:id"
          element={<UpdatePageContent />}
        />{" "}
        <Route
          path="/manage-inner-circle-settings"
          element={
            <TeamGeneralSettings
              type={"inner_circle"}
              title={"Manage Inner Circle Access Settings"}
            />
          }
        />
        <Route
          path="/manage-delegates-settings"
          element={
            <TeamGeneralSettings
              type={"delegate_user"}
              title={"Manage Delegates Access Settings"}
            />
          }
        />
        <Route
          path="/manage-consultant-settings"
          element={
            <TeamGeneralSettings
              type={"consaltant_team"}
              title={"Manage Consultant Access Settings"}
            />
          }
        />{" "}
        <Route
          path="/marketing-team-settings"
          element={
            <TeamGeneralSettings
              type={"marketing_team"}
              title={"Manage Marketing Team Access Settings"}
            />
          }
        />{" "}
        <Route
          path="/sub-team-settings"
          element={
            <TeamGeneralSettings
              type={"sub_team"}
              title={"Manage Sub  Team Access Settings"}
            />
          }
        />
        <Route
          path="/website-pages/theme-setting/:id"
          element={<PaymentThemeSetting />}
        />
        <Route
          path="/website-pages/update-thanks-content/:id"
          element={<UpdateThanksContent />}
        />
        <Route
          path="/website-pages/unpublish-setting/:id"
          element={<UnpublishSetting />}
        />
        <Route
          path="/template-pages/unpublish-setting/:id"
          element={<UnpublishSetting />}
        />
        <Route
          path="/website-pages/free-plan-popup-setting/:page_slug"
          element={<FreePlanPopupSetting />}
        />
        <Route
          path="/template-pages/free-plan-popup-setting/:page_slug"
          element={<FreePlanPopupSetting />}
        />
        <Route
          path="/website-pages/:page_id/testimonials"
          element={<WebPageTestimonials />}
        />
        <Route
          path="/website-pages/plan-charts/:page_id"
          element={<PlanChart />}
        />
        <Route
          path="/website-pages/:page_id/testimonials/add-testimonial"
          element={<AddOrUpdateWebPageTestimonial />}
        />
        <Route
          path="/website-pages/:page_id/plan-packege/add-plan-packege"
          element={<AddOrUpdateWebPagePlanPackage />}
        />
        <Route
          path="/template-pages/:page_id/plan-packege/add-plan-packege"
          element={<AddOrUpdateWebPagePlanPackage />}
        />
        <Route
          path="/website-pages/:page_id/plan-packege/edit-plan-packege/:testimonial_id"
          element={<AddOrUpdateWebPagePlanPackage />}
        />{" "}
        <Route
          path="/template-pages/:page_id/plan-packege/edit-plan-packege/:testimonial_id"
          element={<AddOrUpdateWebPagePlanPackage />}
        />
        <Route
          path="/website-pages/:page_id/testimonials/edit-testimonial/:testimonial_id"
          element={<AddOrUpdateWebPageTestimonial />}
        />
        <Route
          path="/website-pages/:page_id/website-faq"
          element={<WebPageFaqs />}
        />
        <Route
          path="/website-pages/:page_id/website-faq/add-faq"
          element={<AddOrUpdateWebPageFaq />}
        />
        <Route
          path="website-pages/:page_id/website-faq/edit-faq/:faq_id"
          element={<AddOrUpdateWebPageFaq />}
        />
        <Route
          path="/website-pages/:page_id/website-questions"
          element={<WebPageQuestions />}
        />
        <Route
          path="/website-pages/:page_id/website-questions/add-question"
          element={<AddOrUpdateWebPageQuestion />}
        />
        <Route
          path="/website-pages/:page_id/website-questions/edit-question/:question_id"
          element={<AddOrUpdateWebPageQuestion />}
        />
        <Route
          path="/website-pages/general-questions"
          element={
            <GeneralQuestions
              created_for="page"
              question_page_title="Website Page Questions"
            />
          }
        />
        <Route
          path="/website-pages/:page_id/payment-plans"
          element={<WebPagePaymentPlans />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/add-plan"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/edit-plan/:plan_id"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/:plan_id/manage-program-access"
          element={<ProgrammeAccessPaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/:plan_id/manage-notification-sequence"
          element={<ManageNotificationSequence />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/:plan_id/manage-bonus-content-access"
          element={<ManageBonusContentAccess />}
        />
        <Route path="/manage-country" element={<ManageCountry />} />
        <Route path="/manage-notifications" element={<ManageNotifications />} />
        <Route
          path="/manage-notifications/preview/:slug"
          element={<EmailTemplateNotification />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/:plan_id/manage-product-access"
          element={<ProductAccessPaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/:plan_id/manage-events-access"
          element={<EventAccessPaymentPlan />}
        />{" "}
        <Route
          path="/website-pages/:page_id/payment-plans/:plan_id/commission-configuration"
          element={<CommissionConfigurationPaymentPlan />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/:plan_id/manage-commission-access"
          element={<ManageCommissionAccessFromPlans />}
        />
        <Route
          path="/website-pages/:page_id/payment-plans/:plan_id/mission-control-setting"
          element={<MissionControlSetting />}
        />
        <Route
          path="/website-pages/:page_id/subscriber/:plan_id"
          element={<PagePlanSubscribersList />}
        />
        <Route
          path="/website-pages/:page_id/questions"
          element={<QuestionSurvey />}
        />
        <Route
          path="/website-pages/:page_id/website-programmes"
          element={<WebsiteProgrammesList />}
        />
        <Route
          path="/website-pages/:page_id/website-programmes/add-programme"
          element={<AddWebsiteProgramme />}
        />
        <Route
          path="/website-pages/:page_id/website-programmes/edit-programme/:programme_slug"
          element={<AddWebsiteProgramme />}
        />
        <Route
          path="/website-pages/sale-page/:page_id"
          element={<WebPageEvents />}
        />
        <Route
          path="/website-pages/add-page-event/:page_id"
          element={<AddWebPageEvent />}
        />
        <Route
          path="/website-pages/:page_id/edit-page-event/:id"
          element={<EditWebPageEvent />}
        />
        <Route
          path="/website-pages/:page_id/business-strategy"
          element={<BusinessStrategyList />}
        />{" "}
        <Route
          path="/website-pages/:page_id/food-menu"
          element={<DinnerMenuList />}
        />
        <Route
          path="/website-pages/:page_id/business-strategy/add-business-strategy"
          element={<AddBusinessStrategy />}
        />{" "}
        <Route
          path="/website-pages/:page_id/food-menu/add-food-menu"
          element={<AddOrUpdateDinnerMenu />}
        />
        <Route
          path="/website-pages/:page_id/business-strategy/edit-business-strategy/:strategy_id"
          element={<AddBusinessStrategy />}
        />
        <Route
          path="/website-pages/:page_id/food-menu/edit-food-menu/:strategy_id"
          element={<AddOrUpdateDinnerMenu />}
        />
        <Route
          path="/website-pages/:page_id/success-schedule"
          element={<SuccessScheduleList />}
        />
        <Route path="/website-pages/:page_id/venues" element={<VenuesList />} />
        <Route
          path="/website-pages/:page_id/add-success-schedule"
          element={<AddOrUpdateSuccessSchedule />}
        />{" "}
        <Route
          path="/website-pages/:page_id/add-venues"
          element={<AddOrUpdateVenues />}
        />
        <Route
          path="/website-pages/:page_id/edit-success-schedule/:success_id"
          element={<AddOrUpdateSuccessSchedule />}
        />
        <Route
          path="/website-pages/:page_id/edit-venues/:success_id"
          element={<AddOrUpdateVenues />}
        />
        <Route
          path="/website-pages/:page_id/benifits"
          element={<BenefitList />}
        />
        <Route
          path="/website-pages/:page_id/add-benefits"
          element={<AddOrUpdateBenefit />}
        />
        <Route
          path="/website-pages/:page_id/edit-benifits/:benefits_id"
          element={<AddOrUpdateBenefit />}
        />
        <Route
          path="/template-pages/:page_id/benifits"
          element={<BenefitList />}
        />
        <Route
          path="/template-pages/:page_id/add-benefits"
          element={<AddOrUpdateBenefit />}
        />
        <Route
          path="/template-pages/:page_id/edit-benifits/:benefits_id"
          element={<AddOrUpdateBenefit />}
        />
        <Route
          path="/website-pages/:page_id/banner-slider"
          element={<BannerSliderList />}
        />
        <Route
          path="/website-pages/:page_id/add-banner-slider"
          element={<AddBannerSlider />}
        />
        <Route
          path="/website-pages/:page_id/edit-banner-slider/:banner_id"
          element={<AddBannerSlider />}
        />
        <Route path="/template-pages" element={<TemplatePages />} />
        <Route
          path="/template-pages/:page_id/payment-plans/:plan_id/agreement-config"
          element={<AgreementConfiguration />}
        />
        <Route
          path="/template-pages/edit-page/:page_slug"
          element={<AddOrUpdatePage />}
        />
        <Route
          path="/template-pages/update-page-content/:id"
          element={<UpdatePageContent />}
        />
        <Route
          path="/template-pages/theme-setting/:id"
          element={<PaymentThemeSetting />}
        />
        <Route
          path="/template-pages/plan-charts/:page_id"
          element={<PlanChart />}
        />
        <Route
          path="/template-pages/:page_id/subscriber/:plan_id"
          element={<PagePlanSubscribersList />}
        />
        <Route
          path="/template-pages/update-thanks-content/:id"
          element={<UpdateThanksContent />}
        />
        <Route
          path="/template-pages/answers"
          element={<QuestionsAnswersAsCreatedFor created_for="page" />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/:plan_id/commission-configuration"
          element={<CommissionConfigurationPaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/:plan_id/manage-commission-access"
          element={<ManageCommissionAccessFromPlans />}
        />
        <Route
          path="/template-pages/answers/answers-detail"
          element={<QuestionsAnswers />}
        />
        <Route
          path="/template-pages/manage-commission-access/:page_id"
          element={<ManageCommissionAccessFromPages />}
        />
        <Route
          path="/template-pages/social-sharing-setting/:page_id"
          element={<SocialSharingSetting />}
        />
        <Route
          path="/template-pages/manage-consultant-access/:page_slug"
          element={<ManageTemplateConsultantAccess />}
        />
        <Route
          path="/template-pages/manage-module-access/:page_slug"
          element={<ManageTemplateModuleAccess />}
        />{" "}
        <Route
          path="/website-pages/manage-module-access/:page_slug"
          element={<ManageTemplateModuleAccess />}
        />
        <Route
          path="/template-pages/manage-page-option/:page_slug"
          element={<ManagePageOptions />}
        />{" "}
        <Route
          path="/website-pages/manage-page-option/:page_slug"
          element={<ManagePageOptions />}
        />
        <Route
          path="/template-pages/:page_id/banner-slider"
          element={<BannerSliderList />}
        />
        <Route
          path="/template-pages/:page_id/add-banner-slider"
          element={<AddBannerSlider />}
        />
        <Route
          path="/template-pages/:page_id/edit-banner-slider/:banner_id"
          element={<AddBannerSlider />}
        />
        <Route
          path="/template-pages/:page_id/testimonials/add-testimonial"
          element={<AddOrUpdateWebPageTestimonial />}
        />
        <Route
          path="/template-pages/:page_id/testimonials/edit-testimonial/:testimonial_id"
          element={<AddOrUpdateWebPageTestimonial />}
        />
        <Route
          path="/template-pages/:page_id/testimonials"
          element={<WebPageTestimonials />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans"
          element={<WebPagePaymentPlans />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/add-plan"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/edit-plan/:plan_id"
          element={<AddOrUpdatePaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/:plan_id/manage-program-access"
          element={<ProgrammeAccessPaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/:plan_id/manage-notification-sequence"
          element={<ManageNotificationSequence />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/:plan_id/manage-bonus-content-access"
          element={<ManageBonusContentAccess />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/:plan_id/manage-product-access"
          element={<ProductAccessPaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/:plan_id/manage-events-access"
          element={<EventAccessPaymentPlan />}
        />
        <Route
          path="/template-pages/:page_id/payment-plans/:plan_id/mission-control-setting"
          element={<MissionControlSetting />}
        />
        <Route
          path="/template-pages/:page_id/website-programmes"
          element={<WebsiteProgrammesList />}
        />
        <Route
          path="/template-pages/:page_id/website-programmes/add-programme"
          element={<AddWebsiteProgramme />}
        />
        <Route
          path="/template-pages/:page_id/website-programmes/edit-programme/:programme_slug"
          element={<AddWebsiteProgramme />}
        />
        <Route
          path="/template-pages/:page_id/business-strategy"
          element={<BusinessStrategyList />}
        />{" "}
        <Route
          path="/template-pages/:page_id/food-menu"
          element={<DinnerMenuList />}
        />
        <Route
          path="/template-pages/:page_id/food-menu/add-food-menu"
          element={<AddOrUpdateDinnerMenu />}
        />
        <Route
          path="/template-pages/:page_id/business-strategy/add-business-strategy"
          element={<AddBusinessStrategy />}
        />
        <Route
          path="/template-pages/:page_id/business-strategy/edit-business-strategy/:strategy_id"
          element={<AddBusinessStrategy />}
        />
        <Route
          path="/template-pages/:page_id/food-menu/edit-food-menu/:strategy_id"
          element={<AddOrUpdateDinnerMenu />}
        />
        <Route
          path="/template-pages/:page_id/website-faq"
          element={<WebPageFaqs />}
        />
        <Route
          path="/template-pages/:page_id/website-faq/add-faq"
          element={<AddOrUpdateWebPageFaq />}
        />
        <Route
          path="/template-pages/:page_id/website-faq/edit-faq/:faq_id"
          element={<AddOrUpdateWebPageFaq />}
        />
        <Route
          path="/template-pages/:page_id/website-questions"
          element={<WebPageQuestions />}
        />
        <Route
          path="/template-pages/:page_id/website-questions/edit-question/:question_id"
          element={<AddOrUpdateWebPageQuestion />}
        />
        <Route
          path="/template-pages/:page_id/website-questions/add-question"
          element={<AddOrUpdateWebPageQuestion />}
        />
        <Route
          path="/template-pages/general-questions"
          element={
            <GeneralQuestions
              created_for="page"
              question_page_title="Template Page Questions"
            />
          }
        />
        <Route
          path="/template-pages/:page_id/questions"
          element={<QuestionSurvey />}
        />
        <Route
          path="/template-pages/:page_id/success-schedule"
          element={<SuccessScheduleList />}
        />{" "}
        <Route
          path="/template-pages/:page_id/venues"
          element={<VenuesList />}
        />
        <Route
          path="/template-pages/:page_id/add-success-schedule"
          element={<AddOrUpdateSuccessSchedule />}
        />{" "}
        <Route
          path="/template-pages/:page_id/add-venues"
          element={<AddOrUpdateVenues />}
        />
        <Route
          path="/template-pages/:page_id/edit-success-schedule/:success_id"
          element={<AddOrUpdateSuccessSchedule />}
        />{" "}
        <Route
          path="/template-pages/:page_id/edit-venues/:success_id"
          element={<AddOrUpdateVenues />}
        />
        <Route
          path="/auto-responded-message"
          element={<AutoRespondedMessage />}
        />
        <Route
          path="/auto-responded-message/add-message"
          element={<AddAutoRespondedMessage />}
        />
        <Route
          path="/auto-responded-message/edit-message/:message_id"
          element={<UpdateAutoRespondedMessage />}
        />
        <Route path="/wheel_of_life" element={<WheelOfLife />} />
        <Route
          path="/attitude-assessment-questions"
          element={<AttitudeAccessmentQuestions />}
        />
        {/* <Route
          path="/attitude-assessment-setting"
          element={<AssessmentSetting />}
        /> */}
        <Route
          path="/attitude-assessment-setting"
          element={<AssessmentSettingTabs />}
        />
        <Route path="/assessment" element={<AssessmentQuestions />} />
        <Route
          path="/website-faq/add-faq"
          element={<AddOrUpdateWebPageFaq />}
        />
        <Route
          path="website-faq/edit-faq/:faq_id"
          element={<AddOrUpdateWebPageFaq />}
        />
        <Route path="/website-faq" element={<WebPageFaqs />} />
        <Route
          path="/website-questions/add-question"
          element={<AddOrUpdateWebPageQuestion />}
        />
        <Route
          path="/website-questions/edit-question/:question_id"
          element={<AddOrUpdateWebPageQuestion />}
        />
        <Route path="/website-questions" element={<WebPageQuestions />} />
        <Route path="/business-strategy" element={<BusinessStrategyList />} />
        <Route
          path="/business-strategy/add-business-strategy"
          element={<AddBusinessStrategy />}
        />
        <Route
          path="/business-strategy/edit-business-strategy/:strategy_id"
          element={<AddBusinessStrategy />}
        />
        <Route path="/website-events" element={<WebPageEvents />} />
        <Route
          path="/website-events/add-website-event"
          element={<AddWebPageEvent />}
        />
        <Route
          path="/website-events/edit-website-event/:id"
          element={<EditWebPageEvent />}
        />
        <Route path="/website-testimonials" element={<WebPageTestimonials />} />
        <Route
          path="/website-testimonials/add-website-testimonial"
          element={<AddOrUpdateWebPageTestimonial />}
        />
        <Route
          path="/website-testimonials/edit-website-testimonial/:testimonial_id"
          element={<AddOrUpdateWebPageTestimonial />}
        />
        {/* /90-day-questions */}
        <Route
          path="/90-day-content-setting"
          element={<NinetyDayContentSetting />}
        />
        <Route
          path="/90-day-questions"
          element={
            <GeneralQuestions
              created_for="90-day-questions"
              show_back_button={false}
              question_page_title="90 Day Questions"
            />
          }
        />
        <Route
          path="/90-day-answers/answers-detail"
          element={<QuestionsAnswers />}
        />
        <Route
          path="/90-day-answers"
          element={
            <QuestionsAnswersAsCreatedFor
              created_for="90-day-questions"
              page_title="90 Day ( Answers )"
            />
          }
        />
        <Route
          path="/delegate-questions"
          element={
            <GeneralQuestions
              created_for="delegate-90-day-questions"
              show_back_button={false}
              question_page_title="90 Day Questions For Delegate"
            />
          }
        />
        {/* <Route
          path="/delegate-questions/add-question"
          element={<DelegateAddOrUpdateQuestion />}
        />
        <Route
          path="/delegate-questions/edit-question/:question_id"
          element={<DelegateAddOrUpdateQuestion />}
        /> */}
        {/* <Route path="/90-day-questions" element={<NinetyDayQuestion />} /> */}
        <Route
          path="/dynamite-event-videos/video-questions"
          element={
            <GeneralQuestions
              created_for="dynamite_event_video"
              question_page_title="Dynamite Event Video Questions"
            />
          }
        />
        {/* /90-day-questions end */}
        {/* ProgrammeCategories */}
        {/* recording /recordings */}
        <Route path="/recording-list" element={<RecordingsList />} />
        <Route
          path="/recording-list/recording-detail/:recording_slug"
          element={<RecordingDetail />}
        />
        <Route
          path="/recording-list/add-recording"
          element={<AddOrUpdateRecordings />}
        />
        <Route
          path="/recording-list/edit-recording/:recording_slug"
          element={<AddOrUpdateRecordings />}
        />
        {/* recording end */}
        {/* vault VaultCategory */}
        <Route path="/vault" element={<CategoriesList />} />
        <Route path="/vault/add-vault" element={<AddOrUpdateVaultCategory />} />
        <Route
          path="/vault/edit-category/:category_slug"
          element={<AddOrUpdateVaultCategory />}
        />
        {/* vault end */}
        {/* community */}
        <Route path="/community" element={<CommunityList />} />
        <Route
          path="/community/add-community-reward"
          element={<AddCommunity />}
        />
        <Route
          path="/community/edit-community-reward/:reward_id"
          element={<AddCommunity />}
        />{" "}
        <Route
          path="/community/manage-community-reward/:plan_id"
          element={<ProductAccessCommunity />}
        />
        <Route path="/series-categories/" element={<SeriesCategoryList />} />
        <Route
          path="/series-categories/add-category"
          element={<AddSeriesCategory />}
        />
        <Route
          path="/series-categories/edit-category/:category_id"
          element={<AddSeriesCategory />}
        />
        {/* community end */}
        {/* /series-videos */}
        <Route path="/series-videos" element={<SeriesVideosList />} />
        <Route
          path="/series-videos/add-series-videos"
          element={<AddSeriesVideos />}
        />
        <Route
          path="/series-videos/edit-series-videos/:video_id"
          element={<AddSeriesVideos />}
        />
        {/* fees */}
        <Route path="/feeds" element={<FeedsList />} />
        <Route path="/feeds/add-feed" element={<AddFeeds />} />
        <Route path="/feeds/edit-feed/:id" element={<EditFeeds />} />
        <Route path="/daily-messages" element={<DailyMessages />} />
        <Route path="/manage-notification" element={<NotificationSetting />} />
        <Route
          path="/daily-messages/add-message"
          element={<AddOrUpdateDailyMessage />}
        />
        <Route
          path="/daily-messages/edit-message/:message_id"
          element={<AddOrUpdateDailyMessage />}
        />
        <Route path="/feed/setting" element={<FeedSetting />} />
        {/* Sessions end */}
        <Route path="/sessions" element={<SessionsList />} />
        <Route path="/sessions/add-session" element={<AddOrUpdateSession />} />
        <Route
          path="/sessions/edit-session/:session_slug"
          element={<AddOrUpdateSession />}
        />
        <Route path="/session-recording" element={<SessionsRecordingsList />} />
        <Route
          path="/session-recording/add-study-session-recording"
          element={<AddOrUpdateSessionRecordings />}
        />
        <Route
          path="/session-recording/edit-study-session-recording/:recording_slug"
          element={<AddOrUpdateSessionRecordings />}
        />
        <Route path="/source-sessions" element={<SourceSessionsList />} />
        <Route
          path="/source-sessions/add-session"
          element={<AddOrUpdateSourceSession />}
        />
        <Route
          path="/source-sessions/edit-session/:session_slug"
          element={<AddOrUpdateSourceSession />}
        />
        <Route
          path="/source-session-recordings"
          element={<SourceSessionsRecordings />}
        />
        <Route
          path="/source-session-recordings/add-source-session-recording"
          element={<AddOrUpdateSourceSessionRecordings />}
        />
        <Route
          path="/source-session-recordings/edit-source-session-recording/:recording_slug"
          element={<AddOrUpdateSourceSessionRecordings />}
        />
        <Route path="/meditation" element={<Meditation />} />
        <Route path="/meditation/add-meditation" element={<AddMeditation />} />
        <Route
          path="/meditation/edit-meditation/:meditation_slug"
          element={<AddMeditation />}
        />
        <Route
          path="/meditations-category"
          element={<MeditationCategories />}
        />
        <Route
          path="/meditations-category/add-category"
          element={<AddMeditationCategory />}
        />
        <Route
          path="/meditations-category/edit-category/:category_slug"
          element={<AddMeditationCategory />}
        />
        <Route path="/mentorship-category" element={<MentorshipCategories />} />
        <Route
          path="/mentorship-category/add-category"
          element={<AddOrUpdateMentorshipCategory />}
        />
        <Route
          path="/mentorship-category/edit-category/:category_slug"
          element={<AddOrUpdateMentorshipCategory />}
        />
        <Route path="/mentorship-video" element={<MentorshipVideos />} />
        <Route
          path="/mentorship-video/add-video"
          element={<AddOrUpdateMentorshipVideos />}
        />
        <Route
          path="/mentorship-video/edit-video/:video_slug"
          element={<AddOrUpdateMentorshipVideos />}
        />
        {/* Sessions */}
        {/* QuotesList */}
        <Route path="/quotes-list" element={<QuotesList />} />
        <Route path="/quotes-list/add-quotes" element={<AddOrUpdateQuotes />} />
        <Route
          path="/quotes-list/edit-quotes/:quote_id"
          element={<AddOrUpdateQuotes />}
        />
        {/* QuotesList end */}
        {/* gratitude  */}
        <Route path="/gratitude" element={<GratitudeList />} />
        <Route path="/gratitude/:gratitude_id" element={<GratitudeDetail />} />
        <Route
          path="/gratitude/add-gratitude"
          element={<AddOrUpdateGratitude />}
        />
        <Route
          path="/gratitude/edit-gratitude/:gratitude_id"
          element={<AddOrUpdateGratitude />}
        />
        {/* gratitude end */}
        {/* Affirmation  */}
        <Route path="/affirmation" element={<AffirmationsList />} />
        <Route
          path="/affirmation/add-affirmation"
          element={<AddOrUpdateAffirmation />}
        />
        <Route
          path="/affirmation/edit-affirmation/:affirmation_id"
          element={<AddOrUpdateAffirmation />}
        />
        <Route
          path="/affirmation/:affirmation_id"
          element={<AffirmationDetail />}
        />
        {/* Affirmation end */}
        {/* /affirmation-categories */}
        <Route
          path="/affirmations-categories"
          element={<AffirmationCategories />}
        />
        <Route
          path="/affirmations-categories/add-category"
          element={<AddOrUpdatedAffirmationCategory />}
        />
        <Route
          path="/affirmations-categories/edit-category/:category_slug"
          element={<AddOrUpdatedAffirmationCategory />}
        />
        {/* /affirmation-categories end */}
        <Route
          path="/help-video-categories"
          element={<HelpVideoCategories type="admin" />}
        />{" "}
        <Route path="/badges" element={<Badges type="admin" />} />
        <Route
          path="/help-video-categories/add-category"
          element={<AddOrUpdateCategory type="admin" />}
        />{" "}
        <Route
          path="/badges/add-badge"
          element={<AddOrUpdateBadges type="admin" />}
        />
        <Route
          path="/help-video-categories/edit-category/:category_slug"
          element={<AddOrUpdateCategory type="admin" />}
        />
        <Route
          path="/help-video-categories/help-videos/:type/:slug"
          element={<HelpVideoList />}
        />{" "}
        <Route
          path="/badges/edit-badge/:slug"
          element={<AddOrUpdateBadges />}
        />
        <Route
          path="/help-video-categories/help-videos/add-video/:type/:slug"
          element={<AddOrUpdateVideo />}
        />{" "}
        <Route
          path="/help-video-categories/help-videos/edit-video/:video_slug"
          element={<AddOrUpdateVideo />}
        />
        {/* /delegate */}
        <Route
          path="/delegate-help-video-categories"
          element={<HelpVideoCategories type="delegate" />}
        />{" "}
        <Route
          path="/client-help-video-categories"
          element={<HelpVideoCategories type="client" />}
        />
        <Route
          path="/delegate-help-video-categories/help-video-categories/add-category-delegate"
          element={<AddOrUpdateCategory type="delegate" />}
        />{" "}
        <Route
          path="/client-help-video-categories/help-video-categories/add-category-delegate"
          element={<AddOrUpdateCategory type="client" />}
        />
        <Route
          path="/delegate-help-video-categories/help-video-categories/edit-category-delegate/:category_slug"
          element={<AddOrUpdateCategory type="delegate" />}
        />{" "}
        <Route
          path="/client-help-video-categories/help-video-categories/edit-category-delegate/:category_slug"
          element={<AddOrUpdateCategory type="client" />}
        />
        <Route
          path="/delegate-help-video-categories/help-videos/:type/:slug"
          element={<HelpVideoList />}
        />
        <Route
          path="/client-help-video-categories/help-videos/:type/:slug"
          element={<HelpVideoList />}
        />
        <Route
          path="/delegate-help-video-categories/help-videos/add-video/:type/:slug"
          element={<AddOrUpdateVideo />}
        />
        <Route
          path="/client-help-video-categories/help-videos/add-video/:type/:slug"
          element={<AddOrUpdateVideo />}
        />
        <Route
          path="/delegate-help-video-categories/help-videos/edit-video/:video_slug"
          element={<AddOrUpdateVideo />}
        />
        <Route
          path="/client-help-video-categories/help-videos/edit-video/:video_slug"
          element={<AddOrUpdateVideo />}
        />
        <Route
          path="/affiliate-transactions"
          element={<AffiliateTransactions />}
        />
        <Route
          path="/dynamite-payment"
          element={<DynamitePayment type={"Dynamite Payment"} />}
        />
        <Route
          path="/dynamite-digital-payment"
          element={<DigitalPayment type={"Digital Payment"} />}
        />
        <Route
          path="/affiliate-transactions/add-transaction"
          element={<AddOrUpdateAffiliateTransactions />}
        />
        <Route
          path="/dynamite-payment/add-dynamite-payment"
          element={<AddDynamitePayment type={"Dynamite Payment"} />}
        />
        <Route
          path="/dynamite-digital-payment/add-digital-payment"
          element={<AddDynamitePayment type={"Digital Payment"} />}
        />
        <Route
          path="/affiliate-transactions/add-transaction/:delegate_id"
          element={<AddOrUpdateAffiliateTransactions />}
        />
        <Route
          path="/affiliate-transactions/edit-transaction/:transaction_id"
          element={<AddOrUpdateAffiliateTransactions />}
        />
        <Route path="/stripe-products" element={<StripeProductsList />} />
        <Route
          path="/stripe-products/add-product"
          element={<AddStripeProduct />}
        />
        <Route
          path="/stripe-products/edit-product/:id"
          element={<EditStripeProduct />}
        />
        {/* PaymentTransactions end */}
        {/* PaymentTemplateList  */}
        <Route path="/payment-template" element={<PaymentTemplateList />} />
        <Route
          path="/payment-template/add-template"
          element={<AddOrUpdatePaymentTemplate />}
        />
        <Route
          path="/payment-template/edit-template/:template_id"
          element={<AddOrUpdatePaymentTemplate />}
        />
        <Route
          path="/payment-template/commission-configuration/:id"
          element={<CommissionConfiguration />}
        />
        <Route
          path="/payment-template/manage-commission/:id"
          element={<ManageCommissionPaymentTemplate />}
        />
        {/* PaymentTemplateList end  */}
        {/* PaymentRequest   */}
        <Route path="/payment-request" element={<PaymentRequestList />} />
        <Route
          path="/payment-request/add-payment-request"
          element={<AddOrUpdatePaymentRequest />}
        />
        <Route
          path="/payment-request/payment-request-detail/:request_slug"
          element={<PaymentRequestInfo />}
        />
        <Route
          path="/payment-request/edit-payment-request/:request_slug"
          element={<AddOrUpdatePaymentRequest />}
        />
        {/* PaymentRequest end   */}
        <Route path="/calendar-groups" element={<CalendarGroupsList />} />
        <Route
          path="/calendar-groups/group-detail/:id"
          element={<CalendarGroupDetail />}
        />
        <Route path="/coupon" element={<CoupenList />} />
        <Route path="/coupon/add-coupon" element={<AddOrUpDateCoupen />} />
        <Route path="/coupon/edit-coupon/:id" element={<AddOrUpDateCoupen />} />
        <Route
          path="/calendar-groups/add-group"
          element={<AddOrUpdateCalendarGroup />}
        />
        <Route
          path="/calendar-groups/edit-group/:group_slug"
          element={<AddOrUpdateCalendarGroup />}
        />
        <Route path="/delegate-groups" element={<DelegateGroupList />} />
        <Route
          path="/delegate-groups/add-group"
          element={<AddDelegateGroup />}
        />
        <Route
          path="/delegate-groups/edit-group/:id"
          element={<EditDelegateGroup />}
        />
        <Route
          path="/delegate-groups/group-detail/:id"
          element={<DelegateGroupDetail />}
        />
        <Route path="/quarters" element={<QuartersList />} />
        <Route path="/quarters/add-quarter" element={<AddOrUpdateQuarter />} />
        <Route
          path="/quarters/edit-quarter/:quarter_slug"
          element={<AddOrUpdateQuarter />}
        />
        <Route
          path="/quarters/quarter-questions"
          element={
            <GeneralQuestions
              created_for="quarter"
              question_page_title="Quarter Questions"
            />
          }
        />
        <Route
          path="/quarters/quarter-questions/answers"
          element={
            <QuestionsAnswersAsCreatedFor
              created_for="quarter"
              page_title="Quarter Questions ( Questions Answers List )"
            />
          }
        />
        <Route
          path="/quarters/quarter-questions/answers/answers-detail"
          element={<QuestionsAnswers />}
        />
        {/*Calendar end  */}
        {/*DepartmentList   */}
        <Route path="/departments" element={<DepartmentList />} />
        <Route
          path="/departments/add-department"
          element={<AddOrUpdateDepartment />}
        />
        <Route
          path="/departments/edit-department/:department_id"
          element={<AddOrUpdateDepartment />}
        />
        {/*DepartmentList end  */}
        {/*SupportTicket   */}
        <Route path="/support-ticket" element={<SupportTicket />} />
        <Route
          path="/delegate-support-ticket"
          element={<SupportTicketDelegate />}
        />
        <Route path="/support-team" element={<SupportTeam />} />
        <Route
          path="/support-team/add-support-team"
          element={<AddConsultant />}
        />
        <Route
          path="/support-team/edit-support-team/:id"
          element={<EditConsultant />}
        />
        <Route
          path="/trash-support-ticket"
          element={<SupportTicket trash="trash" />}
        />
        <Route
          path="/support-ticket/detail/:id"
          element={<SupportTicketDetail />}
        />
        <Route
          path="/trash-support-ticket/detail/:id"
          element={<DetailSupportTicketTrash />}
        />
        <Route
          path="/delegate-support-ticket/detail/:id"
          element={<DetailDelegateTicket />}
        />
        {/*SupportTicket end  */}
        {/*Calendarpods  */}
        <Route path="/calendar-pods" element={<CalendarPodsList />} />
        <Route path="/book-call-pods" element={<CalendarPodsListCallPods />} />
        {/* <Route path="/calendar-pods" element={<CalenderPodsMianList />} /> */}
        <Route
          path="/calendar-pod/call-pods"
          element={<CalenderPodsMianList />}
        />
        <Route
          path="/book-call-pods/edit-pod/:id"
          element={<EditCalendarPods />}
        />
        <Route path="/calendar-pods/add-pod" element={<AddCalendarPods />} />
        <Route
          path="/calendar-pods/edit-pod/:id"
          element={<EditCalendarPods />}
        />
        <Route
          path="/calendar-pods/pods-detail/:id"
          element={<CalendarPodsDetail />}
        />
        <Route
          path="/book-call-pods/pods-detail/:id"
          element={<CalendarPodsBookCallDetail />}
        />
        <Route
          path="/calendar-pods/add-room-user/:id"
          element={<AddAutomatedRoomUser />}
        />
        <Route
          path="/calendar-pods/edit-room-user/:id"
          element={<EditAutomatedRoomUser />}
        />
        <Route path="/delegate-pods" element={<DelegatePodsList />} />
        <Route
          path="/calendar-events"
          element={<NewCalendar heading="Events List" />}
        />
        <Route
          path="/calendar-events-new"
          element={<NewCalendarNew heading="Events List" />}
        />
        <Route
          path="/calendar-events/add-event"
          element={<AddOrUpdateEvent />}
        />
        <Route
          path="/calendar-events/edit-event/:event_slug"
          element={<AddOrUpdateEvent />}
        />
        <Route
          path="/calendar-events/edit-iteration/:event_slug"
          element={<AddOrUpdateEvent />}
        />
        <Route
          path="/delegate-events/add-event"
          element={<AddOrUpdateEvent created_for="consultant_user" />}
        />
        <Route
          path="/delegate-events/edit-event/:event_slug"
          element={<AddOrUpdateEvent created_for="consultant_user" />}
        />
        <Route
          path="/delegate-events/edit-iteration/:event_slug"
          element={<AddOrUpdateEvent created_for="consultant_user" />}
        />
        <Route
          path="/delegate-events"
          element={
            <NewCalendar
              created_for="consultant_user"
              heading="Delegate Events"
            />
          }
        />
        <Route
          path="/calendar-events/event-list"
          element={<CalendarEventsList />}
        />
        <Route
          path="/delegate-events/event-list"
          element={<CalendarEventsList created_for="consultant_user" />}
        />
        <Route
          path="/calendar-events/members/:event_slug/:iteration_id"
          element={<CalendarEventMembers />}
        />
        <Route
          path="/delegate-pods/add-delegate-pod"
          element={<AddDelegatePods />}
        />
        <Route path="/bonus-road-map" element={<BonusRoadMap />} />
        <Route
          path="/bonus-road-map/add-bonus-road-map"
          element={<AddBonusRoadMap />}
        />
        <Route
          path="/bonus-road-map/edit-bonus-road-map/:id"
          element={<EditBonusRoadMap />}
        />
        <Route path="/all-sales/add-sales" element={<AddOrUpdateSales />} />
        <Route path="/all-sales" element={<AllSales />} />
        <Route path="/lead-status" element={<LeadStatus />} />
        <Route path="/pending-commissions" element={<PendingCommissions />} />
        <Route path="/paid-commissions" element={<Commissions />} />
        <Route
          path="/pending-commissions/commission-detail/:id"
          element={<CommissionsDetail />}
        />
        <Route
          path="/pending-commissions/commission-paid/:id"
          element={<PaidCommissions />}
        />
        <Route
          path="/paid-commissions/commission-detail/:id"
          element={<CommissionsDetail type_paid="pending" />}
        />
        <Route
          path="/pending-commissions/pay-commission/:id"
          element={<CommissionPay />}
        />
        <Route
          path="/paid-commissions/commission-paid/:id"
          element={<PaidCommissions type_paid="pending" />}
        />
        <Route
          path="/lead-status/add-lead-status"
          element={<AddOrUpdateLeadStatus />}
        />
        <Route
          path="/lead-status/edit-lead-status/:sale_id"
          element={<AddOrUpdateLeadStatus />}
        />
        <Route
          path="/delegate-pods/edit-delegate-pod/:id"
          element={<EditDelegatePods />}
        />
        <Route
          path="/all-sales/edit-sales/:sale_id"
          element={<AddOrUpdateSales />}
        />
        <Route
          path="/delegate-pods/pods-detail/:id"
          element={<DelegatePodsDetail />}
        />{" "}
        <Route
          path="/digital-assets/detail/:id"
          element={<DigitalAssestsDetail />}
        />
        {/*Calendarpods  end*/}
        <Route
          path="/goalstament-completed"
          element={
            <MemberGoalStatement type="complete" date_type="Complete Date" />
          }
        />
        <Route
          path="/goalstament-incompleted"
          element={<MemberGoalStatement type="incomplete" />}
        />
        <Route
          path="/goalstament-responded"
          element={
            <MemberGoalStatement type="responded" date_type="Responded Date" />
          }
        />
        <Route
          path="/goalstament-completed/:id"
          element={<MemberGoalstatementList />}
        />
        <Route
          path="/goalstament-incompleted/:id"
          element={<MemberGoalstatementList />}
        />
        <Route
          path="/goalstament-responded/:id"
          element={<MemberGoalstatementList />}
        />
        {/*Progress Category*/}
        <Route path="/progress-category" element={<ProgressCategory />} />
        <Route path="/facebook-catagories" element={<CatagoresTemplates />} />
        <Route path="/facebook-template" element={<FacebookTemplates />} />
        <Route
          path="/facebook-catagories/add-catagory"
          element={<AddCategory />}
        />
        <Route path="/facebook-catagories/:id" element={<AddCategory />} />
        <Route
          path="/progress-category-add"
          element={<AddProgressCategory />}
        />
        <Route
          path="/progress-category/:id"
          element={<AddProgressCategory />}
        />
        {/* /general-setting */}
        <Route path="/general-setting" element={<UpdateGeneralSetting />} />
        <Route path="/client-dashboard" element={<ClientDashboardSetting />} />
        <Route path="/analytics-report" element={<AnalaticsalReport />} />
        <Route path="/funnel-exploration" element={<FunnelStep />} />
        <Route
          path="/funnel-exploration/funnel-detail/:id"
          element={<FunnelExploration />}
        />
        <Route
          path="/funnel-exploration/add-step"
          element={<AddOrUpdateFunnel />}
        />
        <Route
          path="/wheel-of-life-setting"
          element={<UpdateWheelOfLifeSetting />}
        />
        <Route path="/content-setting" element={<UpdateContentSetting />} />
        <Route path="/website-events" element={<WebPageEvents />} />
        <Route path="/website-events/add-event" element={<AddWebPageEvent />} />
        <Route path="/dynamite-events" element={<DynamiteEventsMain />} />
        <Route
          path="/dynamite-events/access/:event_id"
          element={<EventBulkDelegateAccess />}
        />{" "}
        <Route
          path="/dynamite-events/template-access/:event_id"
          element={<EventTemplateDelegateAccess />}
        />
        <Route
          path="/dynamite-events/change-start-date/:start_date_event"
          element={<MemberChangeStartExpiryDate />}
        />
        <Route
          path="/dynamite-events/change-expiry-date/:expiry_date_event"
          element={<MemberChangeStartExpiryDate />}
        />
        <Route
          path="/dynamite-events/lock-event-content/:event_id"
          element={<UpdateLockEventContent />}
        />
        <Route
          path="/dynamite-events/configuration/:event_id"
          element={<EventConfiguration />}
        />
        {/* <Route
          path="/dynamite-events/:event_id/members"
          element={<DynamiteEventMembers />}
        /> */}
        <Route
          path="/dynamite-events/add-event"
          element={<AddOrUpdateDynamiteEvent />}
        />
        <Route
          path="dynamite-events/edit-event/:event_slug"
          element={<AddOrUpdateDynamiteEvent />}
        />
        <Route
          path="/dynamite-events/:dynamite_event_id/general-events"
          element={<GeneralEvents />}
        />
        <Route
          path="/dynamite-events/:dynamite_event_id/general-events/add-event"
          element={<AddOrUpdateGeneralEvents />}
        />
        <Route
          path="/dynamite-events/general-events/edit-event/:event_slug"
          element={<AddOrUpdateGeneralEvents />}
        />
        <Route
          path="/website-events/edit-event/:id"
          element={<EditWebPageEvent />}
        />
        <Route
          path="/dynamite-events/:event_id/members"
          element={<DynamiteEventMembers />}
        />
        <Route
          path="/dynamite-events/:event_id/add-members"
          element={<AddDynamiteEventMember />}
        />
        <Route
          path="/dynamite-events/:event_id/edit-member/:member_id"
          element={<EditDynamiteEventMember />}
        />
        <Route
          path="/dynamite-event-categories/:event_id"
          element={<EventVideoCategories />}
        />
        <Route
          path="/dynamite-event-categories/:event_id/add-category"
          element={<AddOrUpdateEventCategory />}
        />
        <Route
          path="/dynamite-event-categories/:event_id/edit-category/:category_slug"
          element={<AddOrUpdateEventCategory />}
        />
        <Route
          path="/dynamite-event-videos/:category_id"
          element={<DynamiteEventVideoList />}
        />
        <Route
          path="/dynamite-event-videos/:category_id/add-video"
          element={<AddOrUpdateDynamiteEventVideo />}
        />
        <Route
          path="/dynamite-event-videos/:category_id/edit-video/:video_id"
          element={<AddOrUpdateDynamiteEventVideo />}
        />
        <Route
          path="/dynamite-event-videos/questions-configuration/:video_id"
          element={<QuestionConfigurationForEvent />}
        />
        <Route
          path="/dynamite-event-videos/video-question/answers"
          element={
            <QuestionsAnswersAsCreatedFor
              created_for="dynamite_event_video"
              page_title="Dynamite Event ( Questions Answers List )"
            />
          }
        />
        <Route
          path="/dynamite-event-videos/video-question/answers/answers-detail"
          element={<QuestionsAnswers />}
        />
        {/* ConsultantGeneralSetting */}
        <Route path="/send-grid" element={<AddSendGrid />} />
        <Route path="/add-stripe" element={<AddStripeSetting />} />
        <Route path="/add-fire" element={<AddFireSetting />} />
        <Route path="/email-setting" element={<EmailSetting />} />
        <Route path="/default-setting" element={<DefaultSettings />} />
        <Route path="/app-setting" element={<MobileAppSetting />} />
        <Route path="/invoice-setting" element={<InvoiceSetting />} />
        <Route path="/site-setting" element={<SiteSetting />} />
        <Route path="/email-templates" element={<EmailTemplates />} />
        <Route path="/add-campaign" element={<AddCompaignSetting />} />
        <Route path="/zoom-setting" element={<ZoomSetting />} />
        <Route path="/DHl-setting" element={<DhlApi />} />
        <Route path="/calendar-setting" element={<CalendarSetting />} />
        <Route path="/whatsapp-setting" element={<WhatsAppSetting />} />
        <Route path="/shop-setting" element={<ShopSetting />} />
        <Route path="/tracker-settings" element={<TrackerSettings />} />
        <Route path="/notification-setting" element={<NotificationSetting />} />
        <Route path="/notifications" element={<NotificationsSetting />} />
        <Route path="/bitly-settings" element={<BitlySetting />} />
        <Route path="/twilio-settings" element={<TwilioSettings />} />
        <Route
          path="/email-templates/preview/:id"
          element={<EmailTemplatePreview />}
        />
        <Route path="/email-logs/preview" element={<EmailLogsPreview />} />
        <Route
          path="/email-templates/edit-temail-emplate/:id"
          element={<EditEmailTemplate />}
        />
        <Route
          path="/email-templates/add-temail-emplate"
          element={<AddEmailTemplate />}
        />
        <Route path="/goal_statement" element={<GoalStatement />} />
        <Route path="/profile" element={<EditProfile />} />
        <Route path="/pods" element={<Pods />} />
        <Route path="/affirmations" element={<AffirmationsList />} />
        <Route
          path="/affirmations/affirmationList"
          element={<AffirmationList />}
        />
        <Route path="/gratitude" element={<Gratitude />} />
        <Route path="/quotes" element={<Quotes />} />
        <Route path="/memories" element={<Memories />} />
        <Route path="/memories/memoriesdetails" element={<MemoriesDetails />} />
        <Route path="/daily_activities" element={<DailyActivities />} />
        <Route path="/menu" element={<MenuList />} />
        {/* <Route path="/menu" element={<MenuItems />} /> */}
        <Route path="/menu/add-menu" element={<MenuItems />} />
        <Route path="/menu/:id" element={<MenuItems />} />
        {/* challenge section */}
        <Route path="/challenge" element={<ChallengeTabs />} />
        <Route
          path="/challenge/challenge-detail/:challenge_id"
          element={<ChallengeDetail />}
        />
        <Route path="/shop-category" element={<ShopCategoryList />} />
        <Route path="/shop-order" element={<ShopOrder />} />
        <Route path="/shop-order/add-order" element={<AddOrder />} />
        <Route
          path="/delegate-navitems"
          element={
            <GeneralNavItems
              type="delegate"
              value="delegate_nav_items"
              heading="Delegate"
            />
          }
        />
        <Route
          path="/admin-navitems"
          element={
            <GeneralNavItems
              type="admin"
              value="admin_nav_items"
              heading="Admin"
            />
          }
        />
        <Route
          path="/client-navitems"
          element={
            <GeneralNavItems
              type="member"
              value="member_nav_items"
              members
              heading="Client Portal"
            />
          }
        />
        <Route path="/stripe-reader" element={<StripeReader />} />
        <Route
          path="/shop-category/add-category"
          element={<AddShopCategory />}
        />
        <Route
          path="/mission-control-graphics/add-mission-control-graphics"
          element={<AddMissionControlGraphics />}
        />{" "}
        <Route
          path="/admin-user/add-admin-user"
          element={<AddOrUpdateAdminUser />}
        />
        <Route
          path="/admin-user/manage-navitems-access/:user_id"
          element={
            <ManageNavItemsAccess
              type="admin"
              title="Admin User"
              navigate="/admin-user"
            />
          }
        />
        <Route
          path="/mission-control-graphics/edit-mission-control-graphics/:id"
          element={<AddMissionControlGraphics />}
        />
        <Route
          path="/admin-user/edit-admin-user/:id"
          element={<AddOrUpdateAdminUser />}
        />
        <Route
          path="/reminder-user/edit-reminder-user/:id"
          element={<AddOrUpdateReminderUser />}
        />
        <Route
          path="/reminder-user/add-reminder-user"
          element={<AddReminderUser />}
        />
        <Route
          path="/admin-user/change-password/:id"
          element={<AdminChangepassword />}
        />
        <Route
          path="/admin-user/manage-stripe-reader/:id"
          element={<ManageStripeReader />}
        />
        <Route
          path="/shop-category/edit-category/:category_id"
          element={<AddShopCategory />}
        />
        <Route
          path="/shop-category/:category_id/products"
          element={<ShopProductList />}
        />{" "}
        <Route
          path="/sale-commission-report"
          element={<SaleCommissionReport />}
        />
        <Route path="/shop-products" element={<ShopProduct />} />
        <Route
          path="/shop-category/:category_id/products/add-product"
          element={<AddShopProduct />}
        />
        {/* <Route path="/coin-configuration" element={<CoinConfigurations />} /> */}
        <Route path="/coin-configuration" element={<CoinConfigurationTabs />} />
        <Route
          path="/mission-control-graphics"
          element={<MissionControlGraphics />}
        />{" "}
        <Route path="/admin-user" element={<AdminUserList />} />
        <Route path="/reminder-user" element={<ReminderUser />} />
        <Route
          path="/shop-category/:category_id/products/edit-product/:product_id"
          element={<AddShopProduct />}
        />
        <Route
          path="/shop-category/:category_id/products/measure-configuration/:product_id"
          element={<ProductMeasureConfiguration />}
        />
        <Route
          path="/shop-products/measure-configuration/:product_id"
          element={<ProductMeasureConfiguration type={"main"} />}
        />
        <Route
          path="/payment-transactions/Order-detail/:id"
          element={<OrderDetail />}
        />
        <Route
          path="/fire-payment-transactions/Order-detail/:id"
          element={<OrderDetail />}
        />
        <Route
          path="/payment-transactions/history/:id"
          element={<TransactionHistory />}
        />{" "}
        <Route
          path="/fire-payment-transactions/history/:id"
          element={<TransactionHistory />}
        />
        <Route
          path="/payment-transactions/internal-notes/:id"
          element={<InternalNotes />}
        />{" "}
        <Route
          path="/fire-payment-transactions/internal-notes/:id"
          element={<InternalNotes />}
        />
        {/* <Route path="/challenge" element={<AddChallenge />} /> */}
        <Route path="/challenge/add-challenge" element={<AddChallenge />} />
        <Route
          path="/challenge/coin-configuration/:id"
          element={<CoinConfiguration />}
        />
        <Route
          path="/challenge/edit-challenge/:challenge_id"
          element={<AddChallenge />}
        />
        <Route path="/road-map-levels" element={<RoadMapLevels />} />
        <Route path="/email-logs" element={<EmailLogs />} />
        <Route
          path="/road-map-levels/add-road-map-levels"
          element={<AddOrUpdateLevels />}
        />
        <Route
          path="/road-map-levels/edit-road-map-levels/:road_map_levels_type_id"
          element={<AddOrUpdateLevels />}
        />{" "}
        <Route
          path="/road-map-levels/badge-configuration/:road_map_levels_type_id"
          element={<BadgeConfiguration />}
        />
      </Route>

      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Authentication />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="404" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget_password" element={<ForgetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      {/* <PublicRouteWithLayout component={Login} exact layout={LogoOnlyLayout} path="/login" />
      <PublicRouteWithLayout component={Register} exact layout={LogoOnlyLayout} path="/register" /> */}
      {/* --------------------------------------- Dashboard --------------------------------------- */}
      {/* <PrivateRouteWithLayout
        component={DashboardApp}
        exact
        layout={DashboardLayout}
        path="/dashboard/app"
      /> */}
      {/* --------------------------------------- Change Password --------------------------------------- */}
      {/* <PrivateRouteWithLayout
        component={Products}
        exact
        layout={DashboardLayout}
        path="/dashboard/products"
      /> */}
    </Routes>
  );
}
//   return useRoutes([
//     {
//       path: '/dashboard',
//       element: <DashboardLayout />,
//       children: [
//         { element: <Navigate to="/dashboard/app" replace /> },
//         { path: 'app', element: <DashboardApp /> },
//         { path: 'user', element: <User /> },
//         { path: 'products', element: <Products /> },
//         { path: 'blog', element: <Blog /> }
//       ]
//     },
//     {
//       path: '/',
//       element: <LogoOnlyLayout />,
//       children: [
//         { path: 'login', element: <Login /> },
//         { path: 'register', element: <Register /> },
//         { path: '404', element: <NotFound /> },
//         { path: '/', element: <Navigate to="/dashboard" /> },
//         { path: '*', element: <Navigate to="/404" /> }
//       ]
//     },
//     { path: '*', element: <Navigate to="/404" replace /> }
//   ]);
//
