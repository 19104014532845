import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Autocomplete, Tooltip } from "@mui/material";
import { useEffect } from "react";
import {
  add_affiliate_transaction,
  get_user_list_for_affiliate_transaction,
} from "src/DAL/AffiliateTransactions/AffiliateTransactions";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function CommissionPay() {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [FirstLoad, setFirstLoad] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [serchText, setSerchText] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [userList, setUserList] = useState([]);
  const [inputs, setInputs] = useState({
    amount: 0,
    net_remaining: 166,
    transaction_for: "public",
    transaction_note: "",
    currency: "gbp",
    method: "stripe",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = {
      user_id: selectedUser._id,
      amount: inputs.amount,
      transaction_note: inputs.transaction_note,
      currency: inputs.currency,
      transaction_for: inputs.transaction_for,
    };
    if (inputs.transaction_for == "delegates") {
      formData.method = inputs.method;
    }

    if (inputs.amount >= 1) {
      const result = await add_affiliate_transaction(formData);
      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        if (params.id) {
          navigate(`/pending-commissions/commission-paid/${params.id}`);
        }
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } else {
      enqueueSnackbar("payable amount must be greater 0", {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    if (name === "transaction_for") {
      setSelectedUser(null);
      setSerchText("");
    }
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const getUserList = async () => {
    let id = serchText.length < 1 ? params?.id : "";

    let transaction_for = inputs.transaction_for;
    if (state?.inputs?.user_type == "delegate_user") {
      transaction_for = "delegates";
    }
    const result = await get_user_list_for_affiliate_transaction(
      transaction_for,
      serchText,
      id
    );
    if (result.code === 200) {
      setUserList(result.users);

      if (params.id && FirstLoad) {
        let selected = result.users.find((val) => val._id == params.id);
        setSelectedUser(selected);
        setIsLoading(false);
        setFirstLoad(false);
      }

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };

  let breadCrumbMenu = [
    {
      title: "Pending Commissions",
      navigation: `/pending-commissions`,
      active: false,
    },
    {
      title: state
        ? state?.first_name + " " + state?.last_name + " (" + state?.email + ")"
        : "Pay Commission",
      active: true,
    },
  ];

  useEffect(() => {
    if (serchText.length % 2 === 0) {
      getUserList();
    }
  }, [serchText]);

  useEffect(() => {
    if (state?.inputs?.user_type == "delegate_user") {
      setInputs((values) => ({
        ...values,
        ["transaction_for"]: "delegates",
      }));
    }
  }, [params]);

  useEffect(() => {
    getUserList();
  }, [inputs.transaction_for]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 mb-3">
          <span>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h2>Add Affiliate Transaction</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Transaction For *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="transaction_for"
                required
                value={inputs.transaction_for}
                label="Transaction For *"
                onChange={handleChange}
              >
                <MenuItem value="public">Public User</MenuItem>
                <MenuItem value="delegates">Delegate User</MenuItem>
              </Select>
            </FormControl>
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <Autocomplete
              loading
              id="tags-outlined"
              options={userList}
              getOptionLabel={(option) =>
                option.first_name +
                " " +
                option.last_name +
                " (" +
                option.email +
                ")"
              }
              filterSelectedOptions
              value={selectedUser}
              onChange={(event, newValue) => {
                setSelectedUser(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`Choose ${
                    inputs.transaction_for === "public" ? "Public" : "Delegate"
                  } User`}
                  placeholder={`Choose ${
                    inputs.transaction_for === "public" ? "Public" : "Delegate"
                  } User`}
                  onChange={handleSearch}
                />
              )}
            />
          </div>
          {selectedUser && (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Net Remaining"
                variant="outlined"
                fullWidth
                disabled
                name="net_remaining"
                value={
                  selectedUser?.remaining_commission == undefined
                    ? 0
                    : selectedUser?.remaining_commission
                }
                onChange={handleChange}
              />
            </div>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Payable Amount"
              variant="outlined"
              fullWidth
              required
              type="float"
              name="amount"
              value={inputs.amount}
              InputProps={{
                inputProps: {
                  min: 1,
                  max: selectedUser?.remaining_commission,
                },
              }}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Plan Currency *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="currency"
                value={inputs.currency}
                label="Plan currency"
                onChange={handleChange}
              >
                <MenuItem value="usd">Dollar</MenuItem>
                <MenuItem value="gbp">UK Pounds</MenuItem>
                <MenuItem value="eur">Euro</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.transaction_for == "delegates" ? (
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Payment Method *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="method"
                  required
                  value={inputs.method}
                  label="Payment Method *"
                  onChange={handleChange}
                >
                  <MenuItem value="stripe">Stripe</MenuItem>
                  <MenuItem value="cash">Cash</MenuItem>
                </Select>
              </FormControl>
            </div>
          ) : (
            ""
          )}
          <div className="col-12 mt-4">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Transaction Note"
                multiline
                rows={6}
                name="transaction_note"
                value={inputs.transaction_note}
                onChange={handleChange}
              />
            </FormControl>
          </div>
          <div className="text-end mt-4">
            {formType == "ADD" && selectedUser?.remaining_commission === 0 ? (
              <Tooltip title="You can't pay this user because this user has 0 remaining commission">
                <span
                  className="small-contained-button cursor-pointer"
                  disabled
                >
                  {formType == "ADD" ? "Submit" : "Update"}
                </span>
              </Tooltip>
            ) : (
              <button className="small-contained-button">
                {formType == "ADD" ? "Submit" : "Update"}
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
