import React, { useState, useEffect } from "react";
import {
  Typography,
  CircularProgress,
  Card,
  Tooltip,
  Chip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Label from "../Label";
import { transaction_info_detail } from "src/DAL/Transaction/transactionApi";
import {
  convertCurrencyToSign,
  dd_date_format,
  show_proper_words,
} from "src/utils/constant";
import MemberEventPopover from "./memberEventPopOver";
import { get_kmb_number } from "src/utils/formatNumber";
import SalePageInfoPopover from "./SalePageInfoPopover";
import MemberProgramPopover from "./MemberProgramPopover";
import { wheelIcon } from "src/assets";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment";
import SalePageInfoPopoverBouns from "./SalePageInfoPopoverBouns";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function MemberDetail({ infoDetail }) {
  const classes = useStyles();
  const [otherInfo, setOtherInfo] = useState({});
  const [OderDetail, setOderDetail] = useState({});
  const [responseData, setResponseData] = useState({});
  const [memberInfo, setMemberInfo] = useState([]);
  const [salePageInfo, setSalePageInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [program, setprogram] = useState([]);
  const [saleProgram, setsaleProgram] = useState([]);
  const [memberEvent, setMemberEvent] = useState([]);
  const [defaultCommission, setDefaultCommission] = useState([]);
  const [transactionType, setTransactionType] = useState([]);
  const [saleEvent, setsaleEvent] = useState([]);
  const [firstFiveprograms, setFirstFiveprograms] = useState([]);
  const [firstFiveEvents, setFirstFiveEvents] = useState([]);
  const [firstFivesaleprograms, setFirstFivesaleprograms] = useState([]);
  const [upsellInfo, setUpsellInfo] = useState([]);
  const [requestProgram, setRequestProgram] = useState([]);
  const [firstFivesaleEvents, setFirstFivesaleEvents] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorElSaleEvent, setAnchorElSaleEvent] = useState(null);
  const [anchorElSaleProgramme, setAnchorElSaleProgramme] = useState(null);
  const [anchorUpSellProgamme, setAnchorUpSellProgamme] = useState(null);
  const [upSellProgamme, setUpSellProgamme] = useState([]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popover" : undefined;
  const openSaleProgramme = Boolean(anchorElSaleProgramme);
  const idSaleProgramme = openSaleProgramme ? "simple-popover" : undefined;
  const openSaleEvent = Boolean(anchorElSaleEvent);
  const idSaleEvent = openSaleEvent ? "simple-popover" : undefined;
  const openUpSellProgamme = Boolean(anchorUpSellProgamme);
  const idUpSellProgamme = openUpSellProgamme ? "simple-popover" : undefined;

  const get_detail_order = async () => {
    const response = await transaction_info_detail(infoDetail?._id);
    if (response.code === 200) {
      setOtherInfo(response?.other_info);
      setUpsellInfo(response.upsell_plans_info);
      setResponseData(response);
      setMemberInfo(response?.member_info);
      setDefaultCommission(response?.is_default_commission);
      setTransactionType(response?.transaction_type);
      setOderDetail(response.products_info);
      setSalePageInfo(response?.sale_page_info);
      setFirstFivesaleEvents(response?.sale_page_info?.event?.slice(0, 5));
      setFirstFiveprograms(response?.member_info?.program?.slice(0, 5));
      setFirstFiveEvents(response?.member_info?.event?.slice(0, 5));
      if (response?.transaction_type === "payment_request") {
        console.log(
          response?.sale_page_info?.programs,
          "response?.sale_page_info?.programsresponse?.sale_page_info?.programs"
        );
        setRequestProgram(response?.sale_page_info?.programs);
      } else {
        setFirstFivesaleprograms(
          response?.sale_page_info?.program?.slice(0, 5)
        );
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleClickPopUP = (event, row) => {
    setprogram(row.program);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };

  const handleClickEventPopUP = (event, row) => {
    setMemberEvent(row.event);
    setAnchorEl1(event.currentTarget);
  };

  const handleCloseEventPopUp = () => {
    setAnchorEl1(null);
  };

  const handleClicksaleEventPopUP = (event, row) => {
    setsaleEvent(row.event);
    setAnchorElSaleEvent(event.currentTarget);
  };

  const handleClosesaleEventPopUp = () => {
    setAnchorElSaleEvent(null);
  };

  const handleClicksaleProgramPopUP = (event, row) => {
    setsaleProgram(row.program);
    setAnchorElSaleProgramme(event.currentTarget);
  };

  const handleClickUpsellProgram = (event, row) => {
    setUpSellProgamme(row.bonus_content_categories);
    setAnchorUpSellProgamme(event.currentTarget);
  };

  const handleCloseUpsellProgram = () => {
    setAnchorUpSellProgamme(null);
  };

  const handleClosesaleProgramPopUp = () => {
    setAnchorElSaleProgramme(null);
  };

  useEffect(() => {
    get_detail_order();
  }, []);

  let currency_change = convertCurrencyToSign(salePageInfo?.plan_currency);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid px-4">
        <div className="row mt-3 solid-border">
          <div className="col-md-6 col-lg-6 mt-3">
            <div className="row">
              <div className="col-6  mt-1   mb-3">Name:</div>
              <div className="col-6  mt-1   text-muted">{memberInfo?.name}</div>
              <div className="col-6  mt-1  mb-3">Referral User:</div>
              {memberInfo?.referral_user ? (
                <>
                  <div className="col-6  mt-1  text-muted">
                    {memberInfo?.referral_user}
                    {memberInfo?.affiliate_url_name && (
                      <span className="affiliate_url_name">
                        {" "}
                        {`(${memberInfo?.affiliate_url_name})`}
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <div className="col-6  mt-1  text-muted">Master Link</div>
              )}

              <div className="col-6  mt-1  mb-3">Nurture:</div>
              <div className="col-6  mt-1   text-muted">
                {memberInfo?.nurture}
              </div>
              <div className="col-6  mt-1  mb-3">Delegate:</div>
              <div className="col-6  mt-1  text-muted">
                {memberInfo?.delegate}
              </div>

              {memberInfo?.is_wheel_of_life && (
                <>
                  <div className="col-6  mt-1  mb-3">Wheel of Life:</div>
                  <div className="col-6  mt-1  text-muted">
                    <Tooltip title={"Wheel of life is completed"} arrow>
                      <img
                        src={wheelIcon}
                        style={{ maxWidth: "10%", display: "inline-block" }}
                      />
                    </Tooltip>
                  </div>
                </>
              )}

              {memberInfo.lead_status &&
                Object.keys(memberInfo?.lead_status).length > 0 && (
                  <>
                    <div className="col-6  mt-1  mb-3">Lead Status:</div>
                    <div className="col-6  mt-1  text-muted">
                      <>
                        <Chip
                          label={memberInfo?.lead_status?.title}
                          style={{
                            backgroundColor:
                              memberInfo?.lead_status?.background_color,
                            color: memberInfo?.lead_status?.text_color,
                            height: "25px",
                          }}
                        />
                      </>
                    </div>
                  </>
                )}

              <div className="col-6  mt-1  mb-3">Events:</div>
              <div className="col-6  mt-1  text-muted">
                {firstFiveEvents && firstFiveEvents.length > 0 ? (
                  <>
                    {firstFiveEvents?.map((event, i) => {
                      return (
                        <>
                          <div className="d-flex">
                            <CircleIcon
                              style={{
                                color: event?._id?.status ? "green" : "red",
                                fill: event?._id?.status ? "green" : "red",
                              }}
                              className="transation-status-icon"
                            />
                            <Tooltip
                              key={i}
                              title={
                                event.expiry_date ? (
                                  <div>
                                    {event?.start_date ? (
                                      <>
                                        Start Date:
                                        {dd_date_format(event?.start_date)}
                                        <br />
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    Expiry: {dd_date_format(event?.expiry_date)}
                                    <br />
                                    Status:{" "}
                                    {event?._id?.status === true
                                      ? "Active"
                                      : "InActive"}
                                  </div>
                                ) : (
                                  <div>
                                    {event?.start_date ? (
                                      <>
                                        Start Date:
                                        {dd_date_format(event?.start_date)}
                                        <br />
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    No Expiry
                                    <br />
                                    Status:{" "}
                                    {event?._id?.status === true
                                      ? "Active"
                                      : "InActive"}
                                  </div>
                                )
                              }
                              arrow
                            >
                              <p className="mb-1 program-hover">
                                {event?._id?.title}
                              </p>
                            </Tooltip>
                          </div>
                          {i == 4 && (
                            <p
                              className="mb-1 mt-1 ms-4 view-more"
                              aria-describedby={id}
                              variant="contained"
                              onClick={(e) =>
                                handleClickEventPopUP(e, memberInfo)
                              }
                            >
                              view more
                            </p>
                          )}
                        </>
                      );
                    })}
                  </>
                ) : (
                  <p>N/A</p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 mt-3">
            <div className="row">
              <div className="col-6  mt-1    mb-3">Email:</div>
              <div className="col-6  mt-1    text-muted">
                {memberInfo?.email}
              </div>
              <div className="col-6  mt-1    mb-3">Community Level:</div>
              {memberInfo?.community_level === "pta" ? (
                <div className="col-6  mt-1 capitalaize text-muted">PTA</div>
              ) : (
                <div className="col-6  mt-1 capitalaize text-muted">
                  {memberInfo?.community_level}
                </div>
              )}

              <div className="col-6  mt-1   mb-3">Total Coins:</div>
              <div className="col-6  mt-1    text-muted mb-3">
                {get_kmb_number(memberInfo?.coins_count)}
              </div>
              {memberInfo?.membership_purchase_expiry && (
                <>
                  <div className="col-6  mt-1    mb-3">Membership Expiry:</div>
                  <div className="col-6  mt-1    text-muted">
                    {memberInfo?.membership_purchase_expiry}
                  </div>
                </>
              )}
              <div className="col-6  mt-1  mb-3">Created At:</div>
              <div className="col-6  mt-1  text-muted">
                {dd_date_format(memberInfo?.createdAt)}
              </div>
              <div className="col-6  mt-1    mb-3">Programmes:</div>
              <div className="col-6  mt-1    text-muted">
                {firstFiveprograms && firstFiveprograms.length > 0 ? (
                  <>
                    {firstFiveprograms?.map((program, i) => {
                      return (
                        <>
                          <div className="d-flex">
                            <CircleIcon
                              style={{
                                color: program?._id?.status ? "green" : "red",
                                fill: program?._id?.status ? "green" : "red",
                              }}
                              className="transation-status-icon"
                            />
                            <Tooltip
                              key={i}
                              title={
                                program.expiry_date ? (
                                  <div>
                                    Start Date:{" "}
                                    {dd_date_format(
                                      program?.purchase_date_time
                                    )}
                                    <br />
                                    Expiry:{" "}
                                    {dd_date_format(program?.expiry_date)}
                                    <br />
                                    Status:{" "}
                                    {program?._id?.status === true
                                      ? "Active"
                                      : "InActive"}
                                  </div>
                                ) : (
                                  <div>
                                    Start Date:{" "}
                                    {dd_date_format(
                                      program?.purchase_date_time
                                    )}
                                    <br />
                                    No Expiry
                                    <br />
                                    Status:{" "}
                                    {program?._id?.status === true
                                      ? "Active"
                                      : "InActive"}
                                  </div>
                                )
                              }
                              arrow
                            >
                              <p
                                className="mb-1 program-hover"
                                // onClick={() => handleChangeProgramDetail(program)}
                              >
                                {program?._id?.title}
                              </p>
                            </Tooltip>
                          </div>
                          {i == 4 && (
                            <p
                              className="mb-1 mt-1 ms-4 view-more"
                              aria-describedby={id}
                              variant="contained"
                              onClick={(e) => handleClickPopUP(e, memberInfo)}
                            >
                              view more
                            </p>
                          )}
                        </>
                      );
                    })}
                  </>
                ) : (
                  <p>N/A</p>
                )}
              </div>
            </div>
          </div>
        </div>
        {salePageInfo && Object.keys(salePageInfo).length > 0 && (
          <>
            <div className="row mt-3">
              <Typography variant="h5" component="h2" sx={{ color: "#f6bd4b" }}>
                {transactionType === "payment_request"
                  ? "Payment Request Info"
                  : "Sale Page Info"}
              </Typography>
            </div>
            <div className="row mt-3 solid-border">
              {transactionType === "payment_request" ? (
                <>
                  <div className="col-md-6 col-lg-6 mt-3">
                    <div className="row">
                      <div className="col-6  mt-1  mb-3">Request Title:</div>
                      <div className="col-6  mt-1 capitalaize text-muted">
                        {salePageInfo?.request_title}
                      </div>

                      <div className="col-6  mt-1    mb-3">
                        Payment Template:
                      </div>
                      <div className="col-6  mt-1     text-muted">
                        {salePageInfo?.payment_template?.title}
                      </div>

                      <div className="col-6  mt-1  mb-3">Plan Total Price:</div>
                      <div className="col-6  mt-1  text-muted">
                        {salePageInfo.is_plan_free === true
                          ? currency_change + 0
                          : currency_change + salePageInfo?.plan_price}
                      </div>
                      <div className="col-6 mt-1 mb-3">Programme:</div>
                      <div className="col-6  mt-1 text-muted mb-2">
                        {requestProgram.length > 0 ? (
                          requestProgram.map((item, index) => {
                            return (
                              <>
                                <Tooltip
                                  title={
                                    item?.program_access_type === "limited" ? (
                                      <div>
                                        No. of Start Days:{" "}
                                        {item?.no_of_start_days}
                                        <br />
                                        No. of Limited Days:{" "}
                                        {item?.no_of_limited_days}
                                      </div>
                                    ) : (
                                      "Unlimited"
                                    )
                                  }
                                  arrow
                                >
                                  <p className="mb-1 program-hover">
                                    {item?.title}
                                  </p>
                                </Tooltip>
                              </>
                            );
                          })
                        ) : (
                          <p>N/A</p>
                        )}
                      </div>
                      {(salePageInfo?.payment_access === "Recurring" ||
                        salePageInfo?.payment_access === "recursion") && (
                        <>
                          <div className="col-6  mt-1  mb-3">
                            Initial Amount:
                          </div>
                          <div className="col-6  mt-1   text-muted">
                            {convertCurrencyToSign(
                              salePageInfo?.plan_currency
                            ) + salePageInfo?.initial_amount}
                          </div>
                          <div className="col-6  mt-1  mb-3">
                            Installment Amount:
                          </div>
                          <div className="col-6  mt-1  text-muted">
                            {convertCurrencyToSign(
                              salePageInfo?.plan_currency
                            ) + salePageInfo?.installment_amount}
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6 mt-3">
                    <div className="row">
                      <div className="col-6  mt-1   mb-3">Request Type:</div>
                      <div className="col-6  mt-1  capitalaize  text-muted">
                        {salePageInfo.is_plan_free === true
                          ? "Free"
                          : salePageInfo?.payment_access}
                      </div>

                      {(salePageInfo?.payment_access === "Recurring" ||
                        salePageInfo?.payment_access === "recursion") && (
                        <>
                          <div className="col-6  mt-1  mb-3">
                            No. of Installments:
                          </div>
                          <div className="col-6  mt-1  text-muted">
                            {salePageInfo?.number_of_installment}
                          </div>
                          <div className="col-6  mt-1    mb-3">
                            Stripe Product:
                          </div>
                          <div className="col-6  mt-1    text-muted mb-3">
                            {salePageInfo?.product?.name}
                          </div>
                          <div className="col-6  mt-1  mb-3">
                            Plan Payment Type:
                          </div>
                          <div className="col-6  mt-1 capitalaize  text-muted mb-3">
                            {salePageInfo?.request_iteration_type}
                          </div>
                          {salePageInfo?.request_iteration_type ===
                            "custom" && (
                            <>
                              <div className="col-6  mt-1    mb-3">
                                No. of Days:
                              </div>
                              <div className="col-6  mt-1    text-muted mb-3">
                                {salePageInfo?.number_of_days}
                              </div>
                            </>
                          )}
                        </>
                      )}
                      <div className="col-6 mt-1 mb-3">Agreement Required:</div>
                      <div className="col-6 mt-1 text-muted mb-3">
                        {salePageInfo?.is_aggrement_required ? "Yes" : "No"}
                      </div>

                      <div className="col-6 mt-1 mb-3">Events:</div>
                      <div className="col-6 mt-1 text-muted mb-3">
                        {firstFivesaleEvents &&
                        firstFivesaleEvents.length > 0 ? (
                          <>
                            {firstFivesaleEvents?.map((event, i) => {
                              return (
                                <>
                                  <Tooltip
                                    key={i}
                                    title={
                                      event.no_of_days || event.expiry_date ? (
                                        <div>
                                          {event.type == "day" ? (
                                            <span>
                                              No. of Days: {event?.no_of_days}
                                            </span>
                                          ) : (
                                            <span>
                                              Expiry date:
                                              {dd_date_format(
                                                event?.expiry_date
                                              )}
                                            </span>
                                          )}
                                          <br />
                                          Start Date:{" "}
                                          {dd_date_format(event?.start_date)}
                                        </div>
                                      ) : (
                                        "No Expiry"
                                      )
                                    }
                                    arrow
                                  >
                                    <p className="mb-1 program-hover">
                                      {event?.title}
                                    </p>
                                  </Tooltip>
                                  {i == 4 && (
                                    <p
                                      className="mb-1 mt-1 view-more"
                                      aria-describedby={id}
                                      variant="contained"
                                      onClick={(e) =>
                                        handleClicksaleEventPopUP(
                                          e,
                                          salePageInfo
                                        )
                                      }
                                    >
                                      view more
                                    </p>
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <p>N/A</p>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-6 col-lg-6 mt-3">
                    <div className="row">
                      <div className="col-6  mt-1   mb-3">Page Title:</div>
                      <div className="col-6  mt-1   text-muted">
                        {salePageInfo?.title}
                      </div>
                      <div className="col-6  mt-1   mb-3">Request Type:</div>
                      <div className="col-6  mt-1 capitalaize text-muted">
                        {salePageInfo.is_plan_free === true
                          ? "Free"
                          : salePageInfo?.payment_access}
                      </div>
                      {(salePageInfo?.payment_access === "Recurring" ||
                        salePageInfo?.payment_access === "recursion") && (
                        <>
                          <div className="col-6  mt-1  mb-3">
                            Initial Amount:
                          </div>
                          <div className="col-6  mt-1   text-muted">
                            {convertCurrencyToSign(
                              salePageInfo?.plan_currency
                            ) + salePageInfo?.initial_amount}
                          </div>

                          <div className="col-6  mt-1  mb-3">
                            No. of Installments:
                          </div>
                          <div className="col-6  mt-1  text-muted">
                            {salePageInfo?.number_of_installment}
                          </div>
                          <div className="col-6  mt-1    mb-3">
                            Stripe Product:
                          </div>
                          <div className="col-6  mt-1    text-muted mb-3">
                            {salePageInfo?.product?.name}
                          </div>
                        </>
                      )}
                      <div className="col-6  mt-1  mb-3">Basic Membership:</div>
                      <div className="col-6  mt-1  text-muted">
                        <Label
                          variant="ghost"
                          color={
                            salePageInfo?.is_basic_membership === "true" ||
                            salePageInfo?.is_basic_membership === true
                              ? "success"
                              : "error"
                          }
                        >
                          {salePageInfo?.is_basic_membership === "true" ||
                          salePageInfo?.is_basic_membership === true
                            ? "Yes"
                            : "No"}
                        </Label>
                      </div>
                      <div className="col-6  mt-1 mb-3">
                        Wheel of Life Enable:
                      </div>
                      <div className="col-6  mt-1 capitalaize text-muted mb-3">
                        <Label
                          variant="ghost"
                          color={
                            salePageInfo?.is_wheel_of_life_enable === "true" ||
                            salePageInfo?.is_wheel_of_life_enable === true
                              ? "success"
                              : "error"
                          }
                        >
                          {salePageInfo?.is_wheel_of_life_enable === "true" ||
                          salePageInfo?.is_wheel_of_life_enable === true
                            ? "Yes"
                            : "No"}
                        </Label>
                      </div>
                      <div className="col-6  mt-1    mb-3">
                        Default Commission:
                      </div>
                      <div className="col-6  mt-1    text-muted">
                        <Label
                          variant="ghost"
                          color={
                            defaultCommission === "true" ||
                            defaultCommission === true
                              ? "success"
                              : "error"
                          }
                        >
                          {defaultCommission === "true" ||
                          defaultCommission === true
                            ? "Yes"
                            : "No"}
                        </Label>
                      </div>

                      <div className="col-6  mt-1    mb-3">Events:</div>
                      <div className="col-6  mt-1    text-muted mb-3">
                        {firstFivesaleEvents &&
                        firstFivesaleEvents.length > 0 ? (
                          <>
                            {firstFivesaleEvents?.map((event, i) => {
                              return (
                                <>
                                  <Tooltip
                                    key={i}
                                    title={
                                      (event.type == "day" &&
                                        event.no_of_days) ||
                                      (event.type == "date" &&
                                        event.expiry_date) ? (
                                        <div>
                                          Start Date:
                                          {dd_date_format(event?.start_date)}
                                          <br />
                                          {event.type == "day" ? (
                                            <span>
                                              No. of Days: {event?.no_of_days}
                                            </span>
                                          ) : (
                                            <span>
                                              Expiry Date:{" "}
                                              {dd_date_format(
                                                event?.expiry_date
                                              )}
                                            </span>
                                          )}
                                        </div>
                                      ) : (
                                        "No Expiry"
                                      )
                                    }
                                    arrow
                                  >
                                    <p className="mb-1 program-hover">
                                      {event?.title}
                                    </p>
                                  </Tooltip>
                                  {i == 4 && (
                                    <p
                                      className="mb-1 mt-1 view-more"
                                      aria-describedby={id}
                                      variant="contained"
                                      onClick={(e) =>
                                        handleClicksaleEventPopUP(
                                          e,
                                          salePageInfo
                                        )
                                      }
                                    >
                                      view more
                                    </p>
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <p>N/A</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 mt-3">
                    <div className="row">
                      <div className="col-6  mt-1 mb-3">Plan Title:</div>
                      <div className="col-6  mt-1 text-muted">
                        {salePageInfo?.plan_title}
                      </div>
                      <div className="col-6  mt-1  mb-3">Plan Total Price:</div>
                      <div className="col-6  mt-1  text-muted">
                        {salePageInfo.is_plan_free === true
                          ? currency_change + 0
                          : currency_change + salePageInfo?.plan_price}
                      </div>

                      {(salePageInfo?.payment_access === "Recurring" ||
                        salePageInfo?.payment_access === "recursion") && (
                        <>
                          <div className="col-6  mt-1  mb-3">
                            Installment Amount:
                          </div>
                          <div className="col-6  mt-1  text-muted">
                            {convertCurrencyToSign(
                              salePageInfo?.plan_currency
                            ) + salePageInfo?.installment_amount}
                          </div>
                          <div className="col-6  mt-1    mb-3">
                            Plan Payment Type:
                          </div>
                          <div className="col-6  mt-1  capitalaize  text-muted mb-3">
                            {salePageInfo?.plan_type}
                          </div>
                          {salePageInfo?.plan_type === "custom" && (
                            <>
                              <div className="col-6  mt-1    mb-3">
                                No. of Days:
                              </div>
                              <div className="col-6  mt-1    text-muted mb-3">
                                {salePageInfo?.number_of_days}
                              </div>
                            </>
                          )}
                        </>
                      )}
                      <div className="col-6  mt-1  mb-3">Membership Days:</div>
                      <div className="col-6  mt-1  text-muted">
                        {salePageInfo?.basic_membership_days}
                      </div>
                      {salePageInfo.is_plan_free === false && (
                        <>
                          <div className="col-6  mt-1 mb-3">
                            Commission Pay to:
                          </div>
                          <div className="col-6  mt-1 capitalaize text-muted mb-3">
                            {salePageInfo?.commission_pay_to}
                          </div>
                        </>
                      )}
                      {defaultCommission === "true" ||
                        (defaultCommission === true &&
                          salePageInfo.is_plan_free === false && (
                            <>
                              <div className="col-6  mt-1    mb-3">
                                Default Commission Percentage:
                              </div>
                              <div className="col-6  mt-1    text-muted mb-3">
                                {salePageInfo?.default_commission_percentage
                                  ? `${salePageInfo?.default_commission_percentage}%`
                                  : ""}
                              </div>
                            </>
                          ))}
                      <div className="col-6 mt-1 mb-3">Agreement Required:</div>
                      <div className="col-6 mt-1 text-muted mb-3">
                        {salePageInfo?.is_aggrement_required ? "Yes" : "No"}
                      </div>
                      <div className="col-6  mt-1 mb-3">Programmes:</div>
                      <div className="col-6  mt-1  text-muted mb-3">
                        {firstFivesaleprograms &&
                        firstFivesaleprograms.length > 0 ? (
                          <>
                            {firstFivesaleprograms?.map((program, i) => {
                              return (
                                <>
                                  <Tooltip
                                    key={i}
                                    title={
                                      program?.program_access_type ===
                                      "limited" ? (
                                        <>
                                          {program.type == "day" ? (
                                            <div>
                                              No. of Start Days:{" "}
                                              {program?.no_of_start_days}
                                              <br />
                                              No. of Limited Days:{" "}
                                              {program?.no_of_limited_days}
                                            </div>
                                          ) : (
                                            <div>
                                              Start Date:{" "}
                                              {dd_date_format(
                                                program?.start_date
                                              )}
                                              <br />
                                              Expiry Date:{" "}
                                              {dd_date_format(
                                                program?.expiry_date
                                              )}
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        "Unlimited"
                                      )
                                    }
                                    arrow
                                  >
                                    <p className="mb-1 program-hover">
                                      {program?.title}
                                    </p>
                                  </Tooltip>
                                  {i == 4 && (
                                    <p
                                      className="mb-1 mt-1 view-more"
                                      aria-describedby={id}
                                      variant="contained"
                                      onClick={(e) =>
                                        handleClicksaleProgramPopUP(
                                          e,
                                          salePageInfo
                                        )
                                      }
                                    >
                                      view more
                                    </p>
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <p>N/A</p>
                        )}{" "}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="col-md-6 col-lg-6">
                <div className="row">
                  {salePageInfo?.commission_info && (
                    <>
                      {salePageInfo?.payment_access === "Recurring" ||
                      salePageInfo?.payment_access === "recursion" ? (
                        <>
                          <div className="col-6  mt-1   mb-3">
                            Inner Circle Commission Amount for First Recursion:
                          </div>
                          <div className="col-6  mt-1    text-muted mb-3">
                            {" "}
                            {salePageInfo?.commission_info
                              ?.inner_circle_commission_amount_for_first_transaction
                              ? convertCurrencyToSign(
                                  salePageInfo?.plan_currency
                                ) +
                                salePageInfo?.commission_info
                                  ?.inner_circle_commission_amount_for_first_transaction
                              : 0}
                          </div>
                          <div className="col-6  mt-1   mb-3">
                            Inner Circle Commission Amount for Next Recursion:
                          </div>
                          <div className="col-6  mt-1    text-muted mb-3">
                            {" "}
                            {salePageInfo?.commission_info
                              ?.inner_circle_commission_amount_for_next_transaction
                              ? convertCurrencyToSign(
                                  salePageInfo?.plan_currency
                                ) +
                                salePageInfo?.commission_info
                                  ?.inner_circle_commission_amount_for_next_transaction
                              : 0}
                          </div>
                        </>
                      ) : (
                        <>
                          {salePageInfo.is_plan_free === false && (
                            <>
                              <div className="col-6  mt-1   mb-3">
                                Inner Circle Commission Amount:
                              </div>
                              <div className="col-6  mt-1    text-muted mb-3">
                                {salePageInfo?.commission_info
                                  ?.inner_circle_commission_amount
                                  ? convertCurrencyToSign(
                                      salePageInfo?.plan_currency
                                    ) +
                                    salePageInfo?.commission_info
                                      ?.inner_circle_commission_amount
                                  : 0}
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {salePageInfo?.payment_access === "Recurring" ||
                      salePageInfo?.payment_access === "recursion" ? (
                        <>
                          <div className="col-6  mt-1   mb-3">
                            Associate User Commission Amount for First
                            Recursion:
                          </div>
                          <div className="col-6  mt-1    text-muted mb-3">
                            {salePageInfo?.commission_info
                              ?.associate_user_commission_amount_for_first_transaction
                              ? convertCurrencyToSign(
                                  salePageInfo?.plan_currency
                                ) +
                                salePageInfo?.commission_info
                                  ?.associate_user_commission_amount_for_first_transaction
                              : 0}
                          </div>
                          <div className="col-6  mt-1   mb-3">
                            Associate User Commission Amount for Next Recursion:
                          </div>
                          <div className="col-6  mt-1    text-muted mb-3">
                            {salePageInfo?.commission_info
                              ?.associate_user_commission_amount_for_next_transaction
                              ? convertCurrencyToSign(
                                  salePageInfo?.plan_currency
                                ) +
                                salePageInfo?.commission_info
                                  ?.associate_user_commission_amount_for_next_transaction
                              : 0}
                          </div>
                        </>
                      ) : (
                        <>
                          {salePageInfo.is_plan_free === false && (
                            <>
                              <div className="col-6  mt-1   mb-3">
                                Associate User Commisaion Amount:
                              </div>
                              <div className="col-6  mt-1    text-muted mb-3">
                                {salePageInfo?.commission_info
                                  ?.associate_user_commission_amount
                                  ? convertCurrencyToSign(
                                      salePageInfo?.plan_currency
                                    ) +
                                    salePageInfo?.commission_info
                                      ?.associate_user_commission_amount
                                  : 0}
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {salePageInfo?.payment_access === "Recurring" ||
                      salePageInfo?.payment_access === "recursion" ? (
                        <>
                          <div className="col-6  mt-1   mb-3">
                            Marketing Team Commission Amount for First
                            Recursion:
                          </div>
                          <div className="col-6  mt-1    text-muted mb-3">
                            {" "}
                            {salePageInfo?.commission_info
                              ?.marketing_team_commission_amount_for_first_transaction
                              ? convertCurrencyToSign(
                                  salePageInfo?.plan_currency
                                ) +
                                salePageInfo?.commission_info
                                  ?.marketing_team_commission_amount_for_first_transaction
                              : 0}
                          </div>
                          <div className="col-6  mt-1   mb-3">
                            Marketing Team Commission Amount for Next Recursion:
                          </div>
                          <div className="col-6  mt-1    text-muted mb-3">
                            {" "}
                            {salePageInfo?.commission_info
                              ?.marketing_team_commission_amount_for_next_transaction
                              ? convertCurrencyToSign(
                                  salePageInfo?.plan_currency
                                ) +
                                salePageInfo?.commission_info
                                  ?.marketing_team_commission_amount_for_next_transaction
                              : 0}
                          </div>
                        </>
                      ) : (
                        <>
                          {salePageInfo.is_plan_free === false && (
                            <>
                              <div className="col-6  mt-1   mb-3">
                                Marketing Team Commission Amount:
                              </div>
                              <div className="col-6  mt-1    text-muted mb-3">
                                {salePageInfo?.commission_info
                                  ?.marketing_team_commission_amount
                                  ? convertCurrencyToSign(
                                      salePageInfo?.plan_currency
                                    ) +
                                    salePageInfo?.commission_info
                                      ?.marketing_team_commission_amount
                                  : 0}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {salePageInfo?.lead_status &&
                    Object.keys(salePageInfo?.lead_status).length > 0 && (
                      <>
                        <>
                          <div className="col-6  mt-1  mb-3">Lead status</div>
                          <div className="col-6  mt-1  text-muted mb-3">
                            <Chip
                              label={salePageInfo.lead_status.title}
                              style={{
                                backgroundColor:
                                  salePageInfo.lead_status.background_color,
                                color: salePageInfo.lead_status.text_color,
                                height: "25px",
                              }}
                            />
                          </div>
                        </>
                      </>
                    )}
                  <>
                    {salePageInfo?.consider_purchasing_user && (
                      <>
                        <div className="col-6  mt-1   mb-3">
                          User Access Type
                        </div>
                        <div className="col-6  mt-1    text-muted mb-3 text-capitalize">
                          {salePageInfo?.consider_purchasing_user
                            ? salePageInfo.consider_purchasing_user
                            : "N/A"}
                        </div>
                      </>
                    )}
                  </>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="row">
                  {salePageInfo?.commission_info && (
                    <>
                      {salePageInfo?.payment_access === "Recurring" ||
                      salePageInfo?.payment_access === "recursion" ? (
                        <>
                          <div className="col-6  mt-1   mb-3">
                            Delegate User Commission Amount for First Recursion:
                          </div>
                          <div className="col-6  mt-1    text-muted mb-3">
                            {salePageInfo?.commission_info
                              ?.delegate_user_commission_amount_for_first_transaction
                              ? convertCurrencyToSign(
                                  salePageInfo?.plan_currency
                                ) +
                                salePageInfo?.commission_info
                                  ?.delegate_user_commission_amount_for_first_transaction
                              : 0}
                          </div>{" "}
                          <div className="col-6  mt-1   mb-3">
                            Delegate User Commission Amount for Next Recursion:
                          </div>
                          <div className="col-6  mt-1    text-muted mb-3">
                            {salePageInfo?.commission_info
                              ?.delegate_user_commission_amount_for_next_transaction
                              ? convertCurrencyToSign(
                                  salePageInfo?.plan_currency
                                ) +
                                salePageInfo?.commission_info
                                  ?.delegate_user_commission_amount_for_next_transaction
                              : 0}
                          </div>
                        </>
                      ) : (
                        <>
                          {salePageInfo.is_plan_free === false && (
                            <>
                              <div className="col-6  mt-1   mb-3">
                                Delegate User Commission Amount:
                              </div>
                              <div className="col-6  mt-1    text-muted mb-3">
                                {salePageInfo?.commission_info
                                  ?.delegate_user_commission_amount
                                  ? convertCurrencyToSign(
                                      salePageInfo?.plan_currency
                                    ) +
                                    salePageInfo?.commission_info
                                      ?.delegate_user_commission_amount
                                  : 0}
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {salePageInfo?.payment_access === "Recurring" ||
                      salePageInfo?.payment_access === "recursion" ? (
                        <>
                          <div className="col-6  mt-1   mb-3">
                            Public User Commission Amount for First Recursion:
                          </div>
                          <div className="col-6  mt-1    text-muted mb-3">
                            {" "}
                            {salePageInfo?.commission_info
                              ?.public_user_commission_amount_for_first_transaction
                              ? convertCurrencyToSign(
                                  salePageInfo?.plan_currency
                                ) +
                                salePageInfo?.commission_info
                                  ?.public_user_commission_amount_for_first_transaction
                              : 0}
                          </div>
                          <div className="col-6  mt-1   mb-3">
                            Public User Commission Amount for Next Recursion:
                          </div>
                          <div className="col-6  mt-1    text-muted mb-3">
                            {" "}
                            {salePageInfo?.commission_info
                              ?.public_user_commission_amount_for_next_transaction
                              ? convertCurrencyToSign(
                                  salePageInfo?.plan_currency
                                ) +
                                salePageInfo?.commission_info
                                  ?.public_user_commission_amount_for_next_transaction
                              : 0}
                          </div>
                        </>
                      ) : (
                        <>
                          {salePageInfo.is_plan_free === false && (
                            <>
                              <div className="col-6  mt-1   mb-3">
                                Public User Commission Amount:
                              </div>
                              <div className="col-6  mt-1    text-muted mb-3">
                                {salePageInfo?.commission_info
                                  ?.public_user_commission_amount
                                  ? convertCurrencyToSign(
                                      salePageInfo?.plan_currency
                                    ) +
                                    salePageInfo?.commission_info
                                      ?.public_user_commission_amount
                                  : 0}
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {salePageInfo?.payment_access === "Recurring" ||
                      salePageInfo?.payment_access === "recursion" ? (
                        <>
                          <div className="col-6  mt-1   mb-3">
                            Dynamite Digital User Commission Amount for First
                            Recursion:
                          </div>
                          <div className="col-6  mt-1    text-muted mb-3">
                            {salePageInfo?.commission_info
                              ?.dynamite_digital_user_commission_amount_for_first_transaction
                              ? convertCurrencyToSign(
                                  salePageInfo?.plan_currency
                                ) +
                                salePageInfo?.commission_info
                                  ?.dynamite_digital_user_commission_amount_for_first_transaction
                              : 0}
                          </div>{" "}
                          <div className="col-6  mt-1   mb-3">
                            Dynamite Digital User Commission Amount for Next
                            Recursion:
                          </div>
                          <div className="col-6  mt-1    text-muted mb-3">
                            {salePageInfo?.commission_info
                              ?.dynamite_digital_user_commission_amount_for_next_transaction
                              ? convertCurrencyToSign(
                                  salePageInfo?.plan_currency
                                ) +
                                salePageInfo?.commission_info
                                  ?.dynamite_digital_user_commission_amount_for_next_transaction
                              : 0}
                          </div>
                        </>
                      ) : (
                        <>
                          {salePageInfo.is_plan_free === false && (
                            <>
                              <div className="col-6  mt-1   mb-3">
                                Dynamite Digital User Commission Amount:
                              </div>
                              <div className="col-6  mt-1    text-muted mb-3">
                                {salePageInfo?.commission_info
                                  ?.dynamite_digital_user_commission_amount
                                  ? convertCurrencyToSign(
                                      salePageInfo?.plan_currency
                                    ) +
                                    salePageInfo?.commission_info
                                      ?.dynamite_digital_user_commission_amount
                                  : 0}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {upsellInfo.length > 0 && transactionType === "payment_plan" && (
          <>
            <div className="row mt-3">
              <Typography variant="h5" component="h2" sx={{ color: "#f6bd4b" }}>
                Upsell Plans Info
              </Typography>
            </div>
            <div className="row mt-3 solid-border">
              {upsellInfo.map((item, index) => {
                let first_five_program = item?.program?.slice(0, 5);
                let first_five_event = item?.event?.slice(0, 5);
                let bonus_content = item?.bonus_content_categories?.slice(0, 5);
                let Currency = convertCurrencyToSign(
                  responseData?.currency ? responseData?.currency : "gbp"
                );

                let plan_price = item?.plan_info?.plan_price
                  ? item?.plan_info?.plan_price
                  : 0;
                return (
                  <>
                    <div className="col-6 col-md-3 mt-1 mb-3">Plan Title:</div>
                    <div className="col-6 col-md-3 mt-1 text-muted">
                      {item.plan_info.plan_title}
                    </div>
                    <div className="col-6 col-md-3 mt-1 mb-3">Plan Price:</div>
                    <div className="col-6 col-md-3 mt-1 text-muted">
                      {Currency + plan_price}
                    </div>
                    <div className="col-6 col-md-3 mt-1 mb-3">
                      Referral Commission:
                    </div>
                    <div className="col-6 col-md-3 mt-1 text-muted">
                      {Currency + item?.referral_commission}
                    </div>
                    <div className="col-6 col-md-3 mt-1 mb-3">
                      Dynamite Digital Commission:
                    </div>
                    <div className="col-6 col-md-3 mt-1 text-muted">
                      {Currency + item?.dynamite_commission}
                    </div>
                    <div className="col-6 col-md-3 mt-1 mb-3">
                      Transaction Referral Commission:
                    </div>
                    <div className="col-6 col-md-3 mt-1 text-muted">
                      {Currency + item?.transaction_referral_commission}
                    </div>
                    <div className="col-6 col-md-3 mt-1 mb-3">Programmes:</div>
                    <div className="col-6 col-md-3 mt-1 text-muted mb-3">
                      {first_five_program && first_five_program.length > 0 ? (
                        <>
                          {first_five_program?.map((program, i) => {
                            return (
                              <>
                                <Tooltip
                                  key={i}
                                  title={
                                    program?.program_access_type ===
                                    "limited" ? (
                                      <>
                                        {program.type == "day" ? (
                                          <div>
                                            No. of Start Days:{" "}
                                            {program?.no_of_start_days}
                                            <br />
                                            No. of Limited Days:{" "}
                                            {program?.no_of_limited_days}
                                          </div>
                                        ) : (
                                          <div>
                                            Start Date:{" "}
                                            {dd_date_format(
                                              program?.start_date
                                            )}
                                            <br />
                                            Expiry Date:{" "}
                                            {dd_date_format(
                                              program?.expiry_date
                                            )}
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      "Unlimited"
                                    )
                                  }
                                >
                                  <p className="mb-1 program-hover">
                                    {program?.title}
                                  </p>
                                </Tooltip>
                                {i == 4 && (
                                  <p
                                    className="mb-1 mt-1 view-more"
                                    aria-describedby={id}
                                    variant="contained"
                                    onClick={(e) =>
                                      handleClicksaleProgramPopUP(e, item)
                                    }
                                  >
                                    view more
                                  </p>
                                )}
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <p>N/A</p>
                      )}{" "}
                    </div>
                    <div className="col-6 col-md-3 mt-1 mb-3">Events:</div>
                    <div className="col-6 col-md-3 mt-1 text-muted mb-3">
                      {first_five_event && first_five_event.length > 0 ? (
                        <>
                          {first_five_event?.map((event, i) => {
                            return (
                              <>
                                <Tooltip
                                  key={i}
                                  title={
                                    (event.type == "day" && event.no_of_days) ||
                                    (event.type == "date" &&
                                      event.expiry_date) ? (
                                      <div>
                                        Start Date:
                                        {dd_date_format(event?.start_date)}
                                        <br />
                                        {event.type == "day" ? (
                                          <span>
                                            No. of Days: {event?.no_of_days}
                                          </span>
                                        ) : (
                                          <span>
                                            Expiry Date:{" "}
                                            {dd_date_format(event?.expiry_date)}
                                          </span>
                                        )}
                                      </div>
                                    ) : (
                                      "No Expiry"
                                    )
                                  }
                                  arrow
                                >
                                  <p className="mb-1 program-hover">
                                    {event?.title}
                                  </p>
                                </Tooltip>
                                {i == 4 && (
                                  <p
                                    className="mb-1 mt-1 view-more"
                                    aria-describedby={id}
                                    variant="contained"
                                    onClick={(e) =>
                                      handleClicksaleEventPopUP(e, item)
                                    }
                                  >
                                    view more
                                  </p>
                                )}
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <p>N/A</p>
                      )}
                    </div>
                    <div className="col-6 col-md-3 mt-1 mb-3">
                      Bonus Content Categories:
                    </div>
                    <div className="col-6 col-md-3 mt-1 text-muted mb-3">
                      {bonus_content && bonus_content.length > 0 ? (
                        <>
                          {bonus_content?.map((program, i) => {
                            return (
                              <>
                                <Tooltip
                                  key={i}
                                  title={
                                    <>
                                      <div>
                                        {`Access Type: ${show_proper_words(
                                          program?.access_type
                                        )}`}
                                      </div>
                                      {program?.type == "days" ? (
                                        <div>
                                          No. of Start Days:{" "}
                                          {program?.no_of_start_days}
                                          <br />
                                          No. of Limited Days:{" "}
                                          {program?.no_of_limited_days}
                                        </div>
                                      ) : (
                                        <div>
                                          Start Date:{" "}
                                          {dd_date_format(program?.start_date)}
                                          <br />
                                          Expiry Date:{" "}
                                          {dd_date_format(program?.expiry_date)}
                                        </div>
                                      )}
                                    </>
                                  }
                                >
                                  <p className="mb-1 program-hover">
                                    {program?.title}
                                  </p>
                                </Tooltip>
                                {i == 4 && (
                                  <p
                                    className="mb-1 mt-1 view-more"
                                    aria-describedby={id}
                                    variant="contained"
                                    onClick={(e) =>
                                      handleClickUpsellProgram(e, item)
                                    }
                                  >
                                    view more
                                  </p>
                                )}
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <p>N/A</p>
                      )}{" "}
                    </div>
                    {index !== upsellInfo.length - 1 && <hr />}
                  </>
                );
              })}
            </div>
          </>
        )}
        {otherInfo && Object.keys(otherInfo).length > 0 && (
          <>
            <div className="row mt-3">
              <Typography variant="h5" component="h2" sx={{ color: "#f6bd4b" }}>
                Other Info
              </Typography>
            </div>
            <div className="row mt-3 solid-border">
              <div className="col-md-6 col-lg-6 mt-3">
                <div className="row">
                  <div className="col-6  mt-1 mb-3">Subscription Mode:</div>
                  <div className="col-6  mt-1 capitalaize text-muted">
                    {otherInfo?.subscription_mode}
                  </div>

                  <div className="col-6  mt-1   mb-3">Subscription Date:</div>
                  <div className="col-6  mt-1   text-muted">
                    {otherInfo?.subscription_date
                      ? moment
                          .utc(otherInfo?.subscription_date)
                          .format("DD-MM-YYYY")
                      : "N/A"}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mt-3">
                <div className="row">
                  {otherInfo?.subscriber_referral_user && (
                    <>
                      <div className="col-6  mt-1    mb-3">
                        Subscriber Referral:
                      </div>
                      <div className="col-6  mt-1    text-muted">
                        {otherInfo?.subscriber_referral_user}
                      </div>
                    </>
                  )}

                  {otherInfo?.transaction_request_type === "Recurring" &&
                    responseData.action_by !== "admin_user" && (
                      // otherInfo?.subscription_status &&
                      <>
                        <div className="col-6  mt-1   mb-3">
                          Subscription Status:
                        </div>
                        <div className="col-6  mt-1   text-muted">
                          <Label
                            variant="ghost"
                            color={
                              otherInfo?.subscription_status === "true" ||
                              otherInfo?.subscription_status === true
                                ? "success"
                                : "error"
                            }
                          >
                            {otherInfo?.subscription_status === "true" ||
                            otherInfo?.subscription_status === true
                              ? "Active"
                              : "InActive"}
                          </Label>
                        </div>
                      </>
                    )}
                </div>
              </div>
              {otherInfo?.transaction_request_type == "Recurring" &&
                responseData.action_by !== "admin_user" && (
                  <div className="col-12 mb-3">
                    <div>
                      Subscription Next Invoice Date:
                      <span className="text-muted ms-3">
                        {dd_date_format(otherInfo?.next_invoice_date)}
                      </span>{" "}
                    </div>
                  </div>
                )}
              {otherInfo?.subscription_type === "payment_plan" && (
                <div className="col-12 mb-3">
                  <div>
                    Subscriber Registration Link:
                    <span className="text-muted ms-4">
                      {otherInfo?.register_url
                        ? otherInfo?.register_url
                        : "N/A"}
                    </span>{" "}
                  </div>
                </div>
              )}{" "}
              {otherInfo?.request_url && (
                <div className="col-12 mb-3">
                  <div>
                    Request Url:
                    <span className="text-muted ms-4">
                      {otherInfo?.request_url ? otherInfo?.request_url : "N/A"}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {OderDetail && Object.keys(OderDetail).length > 0 && (
          <>
            <div className="row mt-3">
              <Typography variant="h5" component="h2" sx={{ color: "#f6bd4b" }}>
                Order Detail
              </Typography>
            </div>
            <div className="row mt-3 solid-border">
              {/* <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6">name</div>
                  <div className="col-lg-6">talha</div>
                </div>
              </div>
              <div className="col-lg-6">
                {" "}
                <div className="row">
                  <div className="col-lg-6">name</div>
                  <div className="col-lg-6">talha</div>
                </div>
              </div> */}
              {OderDetail.shipping_address &&
                Object.keys(OderDetail.shipping_address).length > 0 && (
                  <div
                    className={
                      OderDetail.billing_address &&
                      Object.keys(OderDetail.billing_address).length > 0
                        ? `col-lg-6`
                        : "col-lg-12"
                    }
                  >
                    <div className="mt-3 mb-4">
                      <h3
                        className={`${
                          !OderDetail.billing_address &&
                          Object.keys(OderDetail.billing_address).length > 0
                            ? "text-center"
                            : ""
                        }  mb-4 finalPrice`}
                      >
                        Shipping Info
                      </h3>
                    </div>
                    <div className="row">
                      <div
                        className={`${
                          !OderDetail.billing_address &&
                          Object.keys(OderDetail.billing_address).length > 0
                            ? "col-7 col-md-6 col-lg-3"
                            : "col-lg-4"
                        }  mb-3 request-type`}
                      >
                        Country:
                      </div>
                      <div
                        className={`${
                          !OderDetail.billing_address &&
                          Object.keys(OderDetail.billing_address).length > 0
                            ? "col-7 col-md-6 col-lg-3"
                            : "col-lg-5"
                        }  mb-3 text-muted`}
                      >
                        {OderDetail.shipping_address.country ?? "N/A"}
                      </div>
                      <div
                        className={`${
                          !OderDetail.billing_address &&
                          Object.keys(OderDetail.billing_address).length > 0
                            ? "col-5 col-md-6 col-lg-3 mb-3"
                            : "col-lg-4"
                        }  mb-3 request-type`}
                      >
                        State:
                      </div>
                      <div
                        className={`${
                          !OderDetail.billing_address &&
                          Object.keys(OderDetail.billing_address).length > 0
                            ? "col-7 col-md-6  col-lg-3"
                            : "col-lg-7"
                        }  mb-3 text-muted`}
                      >
                        {OderDetail.shipping_address.state ?? "N/A"}
                      </div>
                      <div
                        className={`${
                          !OderDetail.billing_address &&
                          Object.keys(OderDetail.billing_address).length > 0
                            ? "col-5 col-md-6 col-lg-3 mb-3"
                            : "col-lg-4"
                        }  mb-3 request-type`}
                      >
                        City:
                      </div>
                      <div
                        className={`${
                          !OderDetail.billing_address &&
                          Object.keys(OderDetail.billing_address).length > 0
                            ? "col-7 col-md-6  col-lg-3"
                            : "col-lg-7"
                        }  mb-3 text-muted`}
                      >
                        {OderDetail.shipping_address.city ?? "N/A"}
                      </div>
                      {OderDetail.shipping_address.postal_code && (
                        <>
                          <div
                            className={`${
                              !OderDetail.billing_address &&
                              Object.keys(OderDetail.billing_address).length > 0
                                ? "col-5 col-md-6 col-lg-3 mb-3"
                                : "col-lg-4"
                            }  mb-3 request-type`}
                          >
                            Zip Code:
                          </div>
                          <div
                            className={`${
                              !OderDetail.billing_address &&
                              Object.keys(OderDetail.billing_address).length > 0
                                ? "col-7 col-md-6  col-lg-3"
                                : "col-lg-7"
                            }  mb-3 text-muted`}
                          >
                            {OderDetail.shipping_address.postal_code ?? "N/A"}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              {OderDetail.billing_address.address &&
              Object.keys(OderDetail.billing_address.address).length > 0 ? (
                <div className="col-lg-6">
                  <div className="mt-3 mb-3">
                    <h3 className="finalPrice">Billing Info</h3>
                  </div>
                  <div className="row">
                    {OderDetail.billing_address.address.country && (
                      <>
                        <div className="col-lg-4 mb-3 request-type">
                          Country:
                        </div>
                        <div className="col-lg-7 text-muted mb-3">
                          {OderDetail.billing_address.address.country}
                        </div>
                      </>
                    )}
                    <div className="col-lg-4 mb-3 request-type">State:</div>
                    <div className="col-lg-7 text-muted mb-3">
                      {OderDetail.billing_address.address.state}
                    </div>
                    <div className="col-lg-4 mt-lg-0 request-type">
                      <p className="">City:</p>
                    </div>
                    <div className="col-lg-7  mt-5 mt-lg-0 text-muted">
                      {OderDetail.billing_address.address.city}
                    </div>
                    <div className="col-lg-4 mb-3 request-type">Zip Code:</div>

                    <div className="col-lg-7 text-muted mb-3">
                      {" "}
                      {OderDetail.billing_address.address.postal_code}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {OderDetail.billing_address &&
                    Object.keys(OderDetail.billing_address).length > 0 && (
                      <div className="col-lg-6">
                        <div className="mt-3 mb-3">
                          <h3 className="finalPrice">Billing Info</h3>
                        </div>
                        <div className="row">
                          {OderDetail.billing_address.country && (
                            <>
                              <div className="col-lg-4 mb-3 request-type">
                                Country:
                              </div>
                              <div className="col-lg-7 text-muted mb-3">
                                {OderDetail.billing_address.country}
                              </div>
                            </>
                          )}
                          <div className="col-lg-4 mb-3 request-type">
                            State:
                          </div>
                          <div className="col-lg-7 text-muted mb-3">
                            {OderDetail.shipping_address.state}
                          </div>
                          <div className="col-lg-4 mt-lg-0 request-type">
                            <p className="">City:</p>
                          </div>
                          <div className="col-lg-7  mt-5 mt-lg-0 text-muted">
                            {OderDetail.billing_address.city}
                          </div>
                          <div className="col-lg-4 mb-3 request-type">
                            Zip Code:
                          </div>

                          <div className="col-lg-7 text-muted mb-3">
                            {" "}
                            {OderDetail.billing_address.zip_code}
                          </div>
                        </div>
                      </div>
                    )}
                </>
              )}
              <>
                <div className="px-5">
                  <hr></hr>
                </div>
                <div className="container-fluid mt-3">
                  <div className="row ">
                    <div className="col-lg-4 col-sm-12">
                      <h3 className="finalPrice">Products</h3>
                    </div>
                  </div>
                  <div
                    className="row mt-3 p-3 card-with-background"
                    style={{ borderRadius: "16px" }}
                  >
                    <table class="table table-nowrap ">
                      <thead>
                        <tr>
                          <th scope="col" className="size-table">
                            #
                          </th>
                          <th scope="col" className="size-table fixed-width">
                            Name
                          </th>

                          <th scope="col" className="size-table fixed-width">
                            Quantity
                          </th>
                          <th scope="col" className="size-table fixed-width">
                            Unit Price
                          </th>

                          <th scope="col" className="size-table fixed-width">
                            Total Price
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <th className="size-table" scope="row"></th>
                            <td className="size-table"></td>
                            <td className="size-table"></td>
                            <td className="size-table"></td>
                            <td className="size-table">
                              <CircularProgress color="primary" />
                            </td>
                            <td className="size-table"></td>
                            <td className="size-table"></td>
                            <td className="size-table"></td>
                          </tr>
                        ) : (
                          <>
                            {OderDetail.products.length > 0 &&
                              OderDetail.products?.map((items, index) => {
                                return (
                                  <tr>
                                    <th className="size-table" scope="row">
                                      {index + 1}
                                    </th>
                                    <td className="size-table">
                                      {items.product_id.name}
                                    </td>

                                    <td className="size-table">
                                      {`${items.quantity}`}
                                    </td>
                                    <td className="size-table">
                                      {convertCurrencyToSign(
                                        OderDetail
                                          ? OderDetail.currency
                                          : OderDetail.currency
                                      )}
                                      {items.price.toFixed(2)}
                                    </td>
                                    <td className="size-table">
                                      {convertCurrencyToSign(
                                        OderDetail
                                          ? OderDetail.currency
                                          : OderDetail.currency
                                      )}
                                      {(items.quantity * items.price).toFixed(
                                        2
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* bottom prices box */}
                {OderDetail && (
                  <div className="container-fluid">
                    <div class="row  mt-1 mb-4">
                      <div className="col-6"></div>
                      <div
                        className="col-6 border-div p-3 card text-end px-4 mb-2"
                        style={{ borderRadius: "16px" }}
                      >
                        <div className="justify-content-between d-flex">
                          <div>
                            <Typography sx={{ fontSize: "16px" }}>
                              Sub Total:{" "}
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              sx={{ fontSize: "16px" }}
                              className="text-muted"
                            >
                              {convertCurrencyToSign(OderDetail.currency)}
                              {OderDetail.sub_total.toFixed(2)}
                            </Typography>
                          </div>
                        </div>
                        {OderDetail.tax_info && (
                          <div className="mt-2 justify-content-between d-flex">
                            <div>
                              <Typography sx={{ fontSize: "16px" }}>
                                {OderDetail.tax_info.tax_type_title
                                  ? OderDetail.tax_info.tax_type_title
                                  : ""}
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                sx={{ fontSize: "16px" }}
                                className="text-muted"
                              >
                                {convertCurrencyToSign(OderDetail.currency)}
                                {OderDetail.tax_charges.toFixed(2)}
                              </Typography>
                            </div>
                          </div>
                        )}

                        <hr></hr>
                        <div className="mt-2 justify-content-between d-flex">
                          <div>
                            <Typography
                              sx={{
                                mr: 12,
                                fontSize: "16px",
                              }}
                              className="finalPrice"
                            >
                              Grand Total:
                            </Typography>
                          </div>
                          <div>
                            <Typography sx={{ fontSize: "14px" }}>
                              {convertCurrencyToSign(OderDetail.currency)}
                              {OderDetail.total.toFixed(2)}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </div>
          </>
        )}
      </div>
      <MemberProgramPopover
        handleClick={handleClickPopUP}
        handleClose={handleClosePopUp}
        anchorEl={anchorEl}
        id={id}
        open={open}
        program={program}
      />

      <MemberEventPopover
        handleClick={handleClickEventPopUP}
        handleClose={handleCloseEventPopUp}
        anchorEl={anchorEl1}
        id={id1}
        open={open1}
        event={memberEvent}
      />
      <SalePageInfoPopover
        handleClick={handleClicksaleEventPopUP}
        handleClose={handleClosesaleEventPopUp}
        anchorEl={anchorElSaleEvent}
        id={idSaleEvent}
        open={openSaleEvent}
        data={saleEvent}
        title={"Events"}
      />
      <SalePageInfoPopover
        handleClick={handleClicksaleProgramPopUP}
        handleClose={handleClosesaleProgramPopUp}
        anchorEl={anchorElSaleProgramme}
        id={idSaleProgramme}
        open={openSaleProgramme}
        data={saleProgram}
        title={"Programmes"}
      />
      <SalePageInfoPopoverBouns
        handleClick={handleClickUpsellProgram}
        handleClose={handleCloseUpsellProgram}
        anchorEl={anchorUpSellProgamme}
        id={idUpSellProgamme}
        open={openUpSellProgamme}
        data={upSellProgamme}
        title={"Categories"}
      />
    </>
  );
}
