import React from "react";
import {
  FormControl,
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import { get_root_value } from "src/utils/domUtils";
import { Icon } from "@iconify/react";

export default function PodsFilter({
  filterData,
  handleChange,
  searchSubmitFilter,
  handleClearFilter,
  type,
}) {
  return (
    <>
      <div className="container-fluid new-memories">
        <FormControl fullWidth className="mt-3">
          <InputLabel id="demo-simple-select-label">Action By</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterData.action_by}
            name="action_by"
            label="Action By"
            onChange={handleChange}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="admin_user">Admin User</MenuItem>
            <MenuItem value="consultant_user">Consultant User</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth className="mt-3">
          <InputLabel id="demo-simple-select-label">Community Level</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterData.community_level}
            name="community_level"
            label="Community Level"
            onChange={handleChange}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="dynamite">Dynamite</MenuItem>
            <MenuItem value="pta">PTA</MenuItem>
            <MenuItem value="elite">Elite</MenuItem>
            <MenuItem value="mastery">Mastery</MenuItem>
          </Select>
        </FormControl>
        {type == "source_pods" && (
          <FormControl fullWidth className="mt-3">
            <InputLabel id="demo-simple-select-label">Pod Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filterData.pod_type}
              name="pod_type"
              label="Pod Type"
              onChange={handleChange}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="general">General</MenuItem>
              <MenuItem value="automated">Automated</MenuItem>
            </Select>
          </FormControl>
        )}
        <Box sx={{ py: 2 }}>
          <Button
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            onClick={searchSubmitFilter}
            startIcon={
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: 5 }}
              >
                <path
                  d="M13.1165 0.25H0.883548C0.321452 0.25 0.0378205 0.932013 0.436097 1.33029L5.3125 6.20743V11.6406C5.3125 11.8471 5.41325 12.0406 5.58242 12.1591L7.69179 13.6351C8.10794 13.9264 8.6875 13.6312 8.6875 13.1167V6.20743L13.564 1.33029C13.9615 0.932804 13.6798 0.25 13.1165 0.25Z"
                  fill={get_root_value("--portal-theme-primary")}
                />
              </svg>
            }
          >
            Filter
          </Button>
        </Box>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          className="mb-3"
          onClick={handleClearFilter}
          startIcon={<Icon icon={roundClearAll} />}
        >
          Clear All
        </Button>
      </div>
    </>
  );
}
