import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { project_mode, s3baseUrl } from "src/config/config";
import {
  TRANSACTION_STATUS,
  convertCurrencyToSign,
  dd_date_format,
  show_proper_words,
} from "src/utils/constant";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import moment from "moment";
import { download_csv_file } from "src/utils/convertHtml";
import {
  DeleteTransactionApi,
  Refund_transaction_api,
  transactions_list_api,
  transactions_list_new_api,
} from "src/DAL/Transaction/transactionApi";
import arrowCircleDownFill from "@iconify/icons-eva/arrow-circle-down-fill";
import CircleIcon from "@mui/icons-material/Circle";
import TransactionsFilter from "./TransactionsFilter";
import FilteredChip from "src/components/FilteredChip";
import VerifyNote from "./VerifyNote";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import goal_statement_yes_icons_new1 from "../../assets/images/goal_statement_yes_icons_new1.png";
import goal_statement_no_new_icons from "../../assets/images/goal_statement_no_new_icons.png";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import MemberDetail from "src/components/Transactions/MemberDetail";
import TransactionsStats from "./TransactionsStats";
import RefundPayment from "./AffiliateTransactions/RefundPayment";
import CustomConfirmationLoad from "src/components/ModalPopover/CustomConfirmationLoad";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const headers = [
  { label: "Name", key: "user_name_for_export" },
  { label: "Email", key: "user_email_for_export" },
  { label: "Amount", key: "transaction_amount" },
  { label: "Dynamite Digtal", key: "dynamite_commissions" },
  {
    label: "Referral Commission",
    key: "referral_commissions",
  },
  { label: "Transaction Date", key: "transactionDate" },
  { label: "Referral User", key: "transaction_affliliate_info" },
  {
    label: "Transaction Referral Commission",
    key: "transaction_referral_commissions",
  },
  {
    label: "Total Tickets",
    key: "tickets_count",
  },
  { label: "Request Title", key: "payment_request_title" },
  { label: "Transaction Type", key: "get_transaction_type" },
  { label: "Other Information", key: "other_info_menu_export" },
];

const referralName = (refferal_name, affiliate_url_name, userType) => {
  return (
    <div>
      {`${refferal_name} `}
      {affiliate_url_name && (
        <span className="affiliate_url_name">({affiliate_url_name})</span>
      )}
      {userType}
    </div>
  );
};

const get_affliliate_info = (row) => {
  let affiliate_user_name = "Master Link";
  let refUser = row.affiliate_info;
  let affiliate_user = refUser?.affiliate_user_info;
  let userType = "";
  if (affiliate_user) {
    userType =
      affiliate_user?.affiliate_user_created_for == "memberuser"
        ? " | Public User "
        : " | Delegate User";
  }

  if (affiliate_user) {
    affiliate_user_name =
      affiliate_user.first_name + " " + affiliate_user.last_name;
  }

  return referralName(
    affiliate_user_name,
    refUser?.affiliate_url_name,
    userType
  );
};

const get_transaction_referral = (row) => {
  let affiliate_info = row.transaction_referral_info;
  let affiliate_user = affiliate_info?.affiliate_user_info;
  let refferal_name = "N/A";
  let userType = "";
  if (affiliate_info && affiliate_info.affiliate_url_name && affiliate_user) {
    userType =
      affiliate_user?.affiliate_user_created_for == "memberuser"
        ? " | Public User "
        : " | Delegate User";
  }

  if (affiliate_info && affiliate_info.affiliate_url_name && affiliate_user) {
    refferal_name = affiliate_user.first_name + " " + affiliate_user.last_name;
  }
  return referralName(
    refferal_name,
    affiliate_info?.affiliate_url_name,
    userType
  );
};

const get_transaction_type = (row) => {
  let t_type = "Sale Page";
  let transaction_type = row.transaction_type;
  let sale_page = row?.sale_page_info?.sale_page_title;
  let plan_title = row?.plan_info?.plan_title;
  let request_title = row?.payment_request_info?.request_title;
  if (transaction_type == "payment_plan" && sale_page) {
    t_type =
      "Sale Page (" +
      sale_page +
      " | " +
      plan_title +
      " | " +
      row.transaction_request_type +
      ")";
  } else if (transaction_type == "dynamite_product_purchase") {
    t_type = "Dynamite Shop";
  } else if (transaction_type == "payment_request" && request_title) {
    t_type = "Payment Request (" + request_title + ")";
  }
  return t_type;
};

const replace_space = (string) => {
  return string.replace(/,/g, " ");
};

export default function TransactionsListNew() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [selectedObject, setSelectedObject] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isAllDates, setIsAllDates] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);
  const [changeStatusRefund, setChangeRefund] = useState(false);
  const [verifyTransaction, setVerifyTransaction] = useState();
  const [showPop, setshowPop] = useState(false);
  const [infoDetail, setInfoDetail] = useState({});
  const [transactionStats, setTransactionStats] = useState({});
  const [loadingPopUp, setloadingPopUp] = useState(false);

  const ALTER_FILTER = {
    transaction_status: "",
  };

  const EMPTY_FILTER = {
    start_date: null,
    end_date: null,
    date: null,
    payment_made_by: "",
    sale_page: null,
    payment_plan: null,
    filter_by_plateform: null,
    payment_request: [],
    search_text: searchText,
    transaction_type: "all",
    transaction_for: "all",
    user_id: null,
    transaction_status: "succeeded",
    transaction_mode: project_mode == "dev" ? ["sandbox"] : ["live"],
  };
  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);

  const handleChangeOthers = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const getTransactionsList = async (filter_data) => {
    setIsLoading(true);
    handleCloseFilterDrawer();
    let postData = { ...filter_data };

    if (postData.transaction_type == "sale_page") {
      postData.sale_page = filter_data.sale_page
        ? [filter_data.sale_page._id]
        : [];
    } else {
      postData.sale_page = [];
    }

    if (postData.payment_plan) {
      postData.payment_plan = filter_data.payment_plan._id;
    }

    if (postData.transaction_type == "payment_request") {
      postData.payment_request = filter_data.payment_request.map((item) => {
        return item._id;
      });
    }

    if (postData.start_date) {
      postData.start_date = moment(filter_data.start_date).format("YYYY-MM-DD");
    }

    if (postData.end_date) {
      postData.end_date = moment(filter_data.end_date).format("YYYY-MM-DD");
    }

    if (postData.filter_by_plateform) {
      postData.filter_by_plateform = filter_data.filter_by_plateform.chip_value;
    }

    if (postData.transaction_type !== "sale_page") {
      postData.sale_page = null;
      postData.payment_plan = null;
    }
    if (postData.transaction_type !== "payment_request") {
      postData.payment_request = [];
    }
    if (postData.payment_made_by == "all") {
      postData.payment_made_by = "";
    }

    if (postData.transaction_for == "delegates") {
      postData.user_type = "delegate_user";
    } else if (postData.transaction_for == "public") {
      postData.user_type = "public_user";
    } else {
      postData.user_type = postData.transaction_for;
    }

    if (postData.user_id) {
      postData.user_id = postData.user_id?._id;
    }

    if (postData.transaction_for == "all") {
      postData.user_id = null;
    }

    const result = await transactions_list_new_api(page, rowsPerPage, postData);
    if (result.code == 200) {
      let chipData = { ...filter_data };
      if (chipData.start_date) {
        let date = `Start Date : ${dd_date_format(
          chipData.start_date
        )} End Date :  ${dd_date_format(chipData.end_date)}`;
        chipData.date = {
          chip_label: date,
          chip_value: date,
        };
        delete chipData.start_date;
        delete chipData.end_date;
      }
      if (chipData.transaction_type == "all") {
        delete chipData.transaction_type;
      }
      if (chipData.transaction_for == "all") {
        delete chipData.transaction_for;
        delete chipData.user_id;
      }
      if (chipData.transaction_for == "public") {
        chipData.transaction_for = "Public User";
      }
      if (chipData.transaction_for == "delegates") {
        chipData.transaction_for = "Delegates User";
      }
      if (chipData.transaction_type !== "sale_page") {
        delete chipData.sale_page;
        delete chipData.payment_plan;
      }
      if (chipData.transaction_type !== "payment_request") {
        delete chipData.payment_request;
      }
      if (chipData.search_text) {
        delete chipData.search_text;
      }
      setFilterStateUpdated(chipData);
      const transactions = result.transaction?.map((transaction, index) => {
        let user_name = "";
        let other_info_menu = "";
        let user_name_for_export = "";
        let user_email_for_export = "";
        let other_if = transaction.other_info;
        let affiliate_info = transaction.transaction_referral_info;
        let affiliate_user = affiliate_info?.affiliate_user_info;
        let refferal_name_export = "N/A";
        if (
          affiliate_info &&
          affiliate_info.affiliate_url_name &&
          affiliate_user
        ) {
          refferal_name_export =
            affiliate_user.first_name + " " + affiliate_user.last_name;
        }
        let member_obj = transaction?.member_info;
        if (member_obj) {
          user_name =
            member_obj.first_name +
            " " +
            member_obj.last_name +
            " (" +
            member_obj.email +
            ")";
        }
        if (member_obj) {
          user_email_for_export = member_obj.email;
        }
        if (member_obj) {
          user_name_for_export =
            member_obj.first_name + " " + member_obj.last_name;
        }
        let concatenatedString;
        if (transaction.other_info) {
          let other_info = transaction.other_info;
          concatenatedString =
            '"' +
            `Main Menu: ${replace_space(
              other_info.main_menu
            )}\nStarter Menu: ${replace_space(
              other_info.starter_menu
            )}\nTea And Coffee Menu: ${replace_space(
              other_info.tea_and_coffee_menu
            )}\nDietary Requirements: ${replace_space(
              other_info.dietary_requirements
            )}\nDessert Menu: ${replace_space(other_info.dessert_menu)}` +
            '"';
        }

        let currency_sign = convertCurrencyToSign(transaction.currency);
        let transaction_amount = currency_sign + transaction.amount.toFixed(2);
        let dynamite_fire_payment =
          currency_sign + transaction.dynamite_fire_payment_fee_amount;
        let dynamite_commissions =
          currency_sign + transaction.dynamite_commission;
        let referral_commissions = currency_sign + 0;
        if (transaction.referral_commission) {
          referral_commissions =
            currency_sign + transaction.referral_commission;
        }
        let transaction_referral_commissions = currency_sign + 0;
        let t_referral_commissions =
          transaction.transaction_referral_commission;
        if (t_referral_commissions)
          transaction_referral_commissions =
            currency_sign + t_referral_commissions;

        let transactionDate = "N/A";
        if (transaction.transaction_date) {
          transactionDate = moment(transaction.transaction_date).format(
            "DD-MM-YYYY hh:mm A"
          );
        }

        let tickets_count = transaction.tickets?.length;
        let t_created_by = transaction.created_by;
        let payment_request_title = "N/A";
        let transaction_affliliate_info = "N/A";
        let t_affliliate_info =
          transaction?.affliliate_info?.affiliate_url_name;
        if (t_affliliate_info) {
          transaction_affliliate_info = t_affliliate_info;
        }
        if (transaction.payment_request_info) {
          payment_request_title =
            transaction.payment_request_info?.request_title;
        }

        return {
          ...transaction,
          user_name: user_name,
          user_name_for_export: user_name_for_export,
          user_email_for_export: user_email_for_export,
          transaction_amount: transaction_amount,
          dynamite_commissions: dynamite_commissions,
          referral_commissions: referral_commissions,
          transaction_referral_commissions: transaction_referral_commissions,
          transactionDate,
          payment_request_title: payment_request_title,
          get_transaction_type: get_transaction_type(transaction),
          transaction_affliliate_info: transaction_affliliate_info,
          refferal_name_export: refferal_name_export,
          tickets_count: tickets_count ? tickets_count : "0",
          other_info_menu: other_if ? other_info_menu : "N/A",
          other_info_menu_export: concatenatedString
            ? concatenatedString
            : "N/A",
          t_created_by: t_created_by == "admin_user" ? "Admin" : t_created_by,
          payment_made_by: transaction.payment_made_by
            ? transaction.payment_made_by.replace(/_/, " ")
            : "N/A",
          dynamite_fire_payment,
          MENU_OPTIONS: handleMenu(transaction),
        };
      });

      setTransactionData(transactions);
      setTotalCount(result.total_transaction_count);
      setTransactionStats(result.stats);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const handleChangeRoute = (route, data) => {
    delete data.get_transaction_type;
    delete data.MENU_OPTIONS;
    navigate(route, { state: data });
  };

  const handleViewTickets = (value) => {
    if (value.tickets.length > 0) {
      let path = `/payment-transactions/view-tickets/${value._id}`;
      handleChangeRoute(path, value);
    }
  };

  const handleEdit = (value) => {
    let path = `/payment-transactions/edit-transaction/${value._id}`;
    handleChangeRoute(path, value);
  };

  const handleManageCommission = (value) => {
    let path = `/payment-transactions/manage-commission/${value._id}`;
    handleChangeRoute(path, value);
  };

  const handleOrderDetail = (value) => {
    let path = `/payment-transactions/Order-detail/${value._id}`;
    handleChangeRoute(path, value);
  };
  const handleOrderHistory = (value) => {
    let path = `/payment-transactions/history/${value._id}`;
    handleChangeRoute(path, value);
  };

  const handleInternalNotes = (value) => {
    let path = `/payment-transactions/internal-notes/${value._id}`;
    handleChangeRoute(path, value);
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    {
      id: "user_name",
      label: "Member",
      renderData: (row) => {
        let find_status = TRANSACTION_STATUS.find(
          (status) => status.value == row.transaction_status
        );
        const iconStyle = {
          color: "blue !important",
        };

        let verify_name = row?.verified_by?.name;

        return (
          <div
            className="d-flex pointer"
            onClick={() => {
              setInfoDetail(row);
              if (row?.get_transaction_type === "Dynamite Shop") {
              } else {
                handleOpenPopup(row);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            <Tooltip
              title={find_status ? find_status.name : ""}
              className="pointer"
            >
              <CircleIcon
                style={iconStyle}
                className={`transation-status-icon ${
                  find_status ? find_status.class : ""
                }`}
              />
            </Tooltip>

            <div>
              {/* <Tooltip title="testt" className="pointer"> */}
              <p>{row.user_name ? row.user_name : "N/A"} </p>
              {/* </Tooltip> */}
            </div>

            <div className="verification_note_t">
              <Tooltip
                title={
                  row?.verified_status == true ? (
                    <div>
                      Verified by: {verify_name} <br />
                      <div className="d-flex"></div>
                    </div>
                  ) : (
                    "Not Verified"
                  )
                }
                className="pointer"
              >
                <img
                  src={
                    row.verified_status == true
                      ? goal_statement_yes_icons_new1
                      : goal_statement_no_new_icons
                  }
                  alt=""
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
    { id: "transaction_amount", label: "Amount" },
    { id: "transactionDate", label: "Transaction Date" },

    { id: "dynamite_commissions", label: "Dynamite Digtal" },
    { id: "referral_commissions", label: "Referral Commission" },
    {
      id: "affliliate_info",
      label: "Referral User",
      renderData: (row) => get_affliliate_info(row),
    },
    {
      id: "transaction_referral_commissions",
      label: "Transaction Referral Commission",
    },
    {
      id: "transaction_referral",
      label: "Transaction Referral",
      renderData: (row) => get_transaction_referral(row),
    },
    {
      id: "get_transaction_type",
      className: "text-capitalize",
      label: "Transaction Type",
    },
    {
      id: "payment_made_by",
      label: "Payment Made By",
      renderData: (row) => {
        return show_proper_words(row.payment_made_by);
      },
    },
    {
      id: "tickets_count",
      label: "Total Tickets",
      className: "cursor-pointer",
      handleClick: handleViewTickets,
    },

    {
      id: "agrement_pdf_url",
      label: "Agreement PDF",
      renderData: (row) => {
        return (
          row.agrement_pdf_url && (
            <a
              href={s3baseUrl + row.agrement_pdf_url}
              target="_blank"
              className="anchor-style"
            >
              Preview
            </a>
          )
        );
      },
    },

    {
      id: "t_created_by",
      label: "Created By",
      className: "text-capitalize",
      renderData: (row) => {
        return <p>{show_proper_words(row.created_by)}</p>;
      },
    },
    {
      id: "other_info_menu",
      label: "Other Information",
      renderData: (row) => {
        return row.other_info_menu == "N/A" ? (
          <p>{row.other_info_menu}</p>
        ) : (
          <>
            <div>
              <span className="main-menu-heading">Main Menu: </span>
              <span>{row.other_info?.main_menu}</span>
            </div>
            <div>
              <span className="main-menu-heading">starter menu: </span>
              <span>{row.other_info?.starter_menu}</span>
            </div>
            <div>
              <span className="main-menu-heading">tea and coffee menu : </span>
              <span>{row.other_info?.tea_and_coffee_menu}</span>
            </div>
            <div>
              <span className="main-menu-heading">dietary requirements : </span>
              <span>{row.other_info?.dietary_requirements}</span>
            </div>
            <div>
              <span className="main-menu-heading">dessert menu : </span>
              <span>{row.other_info?.dessert_menu}</span>
            </div>
          </>
        );
      },
    },

    { id: "transaction_mode", label: "Transaction Mode" },
    { id: "dynamite_fire_payment", label: "Dynamite Payment Fee" },
  ];
  const handleOpenPopup = (row) => {
    setshowPop(true);
  };
  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    if (filterData.transaction_for === "all") {
      filterData.user_id = null;
    }
    getTransactionsList(filterData);
    localStorage.setItem(
      "payment_transactions_filter",
      JSON.stringify(filterData)
    );
  };

  const handleClearFilter = () => {
    let clear_data = {
      ...EMPTY_FILTER,
      transaction_mode: [],
      transaction_status: "",
    };
    setFilterData(clear_data);
    getTransactionsList(clear_data);
    localStorage.removeItem("payment_transactions_filter");
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    data.search_text = searchText;
    if (!data.date) {
      delete data.date;
      data.start_date = null;
      data.end_date = null;
      setIsAllDates(false);
    }
    if (data.transaction_for === "all") {
      data.user_id = null;
    }
    getTransactionsList(data);
    setFilterData(data);
    setFilterStateUpdated(data);
    localStorage.setItem("payment_transactions_filter", JSON.stringify(data));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAgreeDelete = (value) => {
    setSelectedObject(value);
    setOpenDelete(true);
  };

  const handleVerifyTransaction = (value) => {
    setVerifyTransaction(value);
    setChangeStatus(true);
  };
  const handleRefundPayment = (value) => {
    setVerifyTransaction(value);
    setChangeRefund(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeleteTransactionApi(selectedObject._id);
    if (result.code === 200) {
      setTransactionData((prev) => {
        return prev.filter((data) => data._id !== selectedObject._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleRefund = async () => {
    setloadingPopUp(true);
    let postData = {
      transaction_id: verifyTransaction._id,
    };
    const result = await Refund_transaction_api(postData);
    if (result.code === 200) {
      setChangeRefund(false);
      enqueueSnackbar(result.message, { variant: "success" });
      getTransactionsList(filterData);
      setloadingPopUp(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setloadingPopUp(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleNavigate = () => {
    navigate(`/payment-transactions/add-transaction`);
  };

  const handleExportClick = () => {
    download_csv_file(headers, transactionData);
  };

  const handleMenu = (row) => {
    const MENU_OPTIONS = [
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      },
    ];

    if (row.transaction_type !== "dynamite_product_purchase") {
      MENU_OPTIONS.push(
        {
          label: "Edit",
          icon: "akar-icons:edit",
          handleClick: handleEdit,
        },
        {
          label: "Manage Commission",
          icon: "akar-icons:edit",
          handleClick: handleManageCommission,
        }
      );
    }
    if (
      row.payment_made_by == "stripe" &&
      row.transaction_status == "succeeded" &&
      row.payment_intent
    ) {
      MENU_OPTIONS.push({
        label: "Refund Transaction",
        icon: "akar-icons:edit",
        handleClick: handleRefundPayment,
      });
    }
    {
      if (row.tickets?.length > 0) {
        MENU_OPTIONS.push({
          label: "View Tickets",
          icon: "akar-icons:edit",
          handleClick: handleViewTickets,
        });
      }
    }

    MENU_OPTIONS.push({
      label: "View Detail",
      icon: "akar-icons:eye",
      handleClick: handleOrderDetail,
    });
    MENU_OPTIONS.push({
      label: "View History",
      icon: "akar-icons:eye",
      handleClick: handleOrderHistory,
    });
    if (row.verified_status !== true) {
      MENU_OPTIONS.push({
        label: "Verify Transaction",
        icon: "akar-icons:edit",
        handleClick: handleVerifyTransaction,
      });
    }
    MENU_OPTIONS.push({
      label: "Internal Notes",
      icon: "akar-icons:edit",
      handleClick: handleInternalNotes,
    });
    return MENU_OPTIONS;
  };

  useEffect(() => {
    let filter_data = filterData;
    let find_filter = localStorage.getItem("payment_transactions_filter");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterData(filter_data);
    setFilterStateUpdated(filter_data);
    getTransactionsList(filter_data);
  }, [rowsPerPage, page]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <h2>Transactions</h2>
          </div>
          <div className="col-lg-8 col-sm-12 text-end d-flex justify-content-end">
            <div>
              <button
                className="small-contained-button me-2 mt-1 mb-4"
                onClick={handleExportClick}
              >
                Export Csv &nbsp;&nbsp; <Icon icon={arrowCircleDownFill} />
              </button>
              <button
                className="small-contained-button me-2 mt-1 mb-4"
                onClick={handleOpenFilterDrawer}
              >
                Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
              </button>
              <button
                className="small-contained-button me-2"
                onClick={handleNavigate}
              >
                Add Transactions
              </button>
            </div>
          </div>
        </div>
        <FilteredChip
          data={filterStateUpdated}
          tempState={filterData}
          EMPTY_FILTER={EMPTY_FILTER}
          ALTER_FILTER={ALTER_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
        <div className="row">
          <div className="col-12">
            <TransactionsStats stats={transactionStats} />
          </div>
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={transactionData}
              className="card-with-background"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
              pagePagination={true}
            />
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <TransactionsFilter
            filterData={filterData}
            isAllDates={isAllDates}
            setIsAllDates={setIsAllDates}
            handleClearFilter={handleClearFilter}
            handleChangeOthers={handleChangeOthers}
            searchSubmitFilter={searchFunction}
          />
        }
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this transaction?"}
        handleAgree={handleDelete}
      />{" "}
      <CustomConfirmationLoad
        open={changeStatusRefund}
        setOpen={setChangeRefund}
        title={"Are you sure you want to refund this transaction?"}
        handleAgree={handleRefund}
        setloadingPopUp={setloadingPopUp}
        loadingPopUp={loadingPopUp}
      />
      <GeneralPopUpModel
        open={changeStatus}
        setOpen={setChangeStatus}
        title={"Verify Transaction"}
        componentToPassDown={
          <VerifyNote
            getTransactionsList={getTransactionsList}
            verifyTransaction={verifyTransaction}
            setChangeStatus={setChangeStatus}
            filterData={filterData}
          />
        }
      />
      <CustomPopover
        isOpenPop={showPop}
        isClosePop={setshowPop}
        title={"Member Detail"}
        componentToPassDown={<MemberDetail infoDetail={infoDetail} />}
      />
    </>
  );
}
