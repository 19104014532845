import { project_mode, project_name } from "src/config/config";
import SettingsIcon from "@mui/icons-material/Settings";
import { Icon } from "@iconify/react";
import { ListItemIcon } from "@mui/material";
import {
  AffirmationIcons,
  DashboardIcons,
  DayPlanIcons,
  GoalStatmentIcons,
  GratitudeIcons,
  meditationIcon,
  PodsIcons,
  ProfileIcons,
  ProgrammeIcons,
  QuotesIcons,
  thesourceIcons,
  VaultIcons,
} from "src/assets";
import { usePGIMode } from "src/Hooks/PGIModeContext";
// ----------------------------------------------------------------------
const getMUIIcon = (name) => {
  return <ListItemIcon className="navbar-icon">{name}</ListItemIcon>;
};

const getEvaIcon = (name) => (
  <Icon className="navbar-icon" icon={name} width={22} height={22} />
);
const getImageIcon = (name) => (
  <img className="navbar-icon" src={name} width={22} height={22} />
);
//
const sidebarConfig = (Navlist) => {
  const { userInfo } = usePGIMode();
  let sidebarMenus = [];
  sidebarMenus.push({
    title: "Welcome",
    path: "/welcome",
    icon: getImageIcon(DashboardIcons),
  });
  if (Navlist?.dashboard) {
    sidebarMenus.push({
      title: "Dashboard",
      path: "/dashboard",
      icon: getImageIcon(DashboardIcons),
    });
  }
  if (Navlist?.stats) {
    sidebarMenus.push();
  }
  // stats start

  if (Navlist?.general_stats) {
    let stats_child_array = [];
    // if (Navlist?.stats) {
    //   stats_child_array.push({
    //     title: "Stats",
    //     path: "/stats",
    //     icon: getImageIcon(DashboardIcons),
    //   });
    // }
    if (Navlist?.call_stats) {
      stats_child_array.push({
        title: "Call Stats",
        path: "/call-stats",
        icon: getImageIcon(DashboardIcons),
      });
    }
    sidebarMenus.push({
      title: "Stats",
      icon: getImageIcon(DashboardIcons),
      child_options: stats_child_array,
    });
  }

  // stats end

  if (Navlist?.programmes) {
    sidebarMenus.push({
      title: "Programmes",
      path: "/programmes",
      icon: getImageIcon(ProgrammeIcons),
      matches: [
        "/programmes",
        "/lessons",
        "/recordings",
        "/lessons-recordings",
      ],
    });
  }

  if (Navlist?.members) {
    sidebarMenus.push({
      title: "Members",
      path: "/member",
      icon: getImageIcon(ProfileIcons),
    });
  }
  if (Navlist?.done_members) {
    sidebarMenus.push({
      title: "Done Members",
      path: "/done-listing",
      icon: getImageIcon(ProfileIcons),
    });
  }

  if (Navlist?.dynamite_lifestyle_team) {
    let dynamite_lifestyle_team_list_child_array = [];
    if (Navlist?.inner_circle) {
      dynamite_lifestyle_team_list_child_array.push({
        title: "Dynamite Lifestyle Team",
        path: "/dynamite-lifestyle-team-members",
        icon: getImageIcon(ProfileIcons),
      });
    }
    if (Navlist?.inner_circle) {
      dynamite_lifestyle_team_list_child_array.push({
        title: "Inner Circle",
        path: "/inner-circle",
        icon: getImageIcon(ProfileIcons),
      });
    }
    if (Navlist?.consaltant) {
      dynamite_lifestyle_team_list_child_array.push({
        title: "Consultant",
        path: "/delegate",
        icon: getImageIcon(ProfileIcons),
      });
    }
    if (Navlist?.delegates) {
      dynamite_lifestyle_team_list_child_array.push({
        title: "Delegates",
        path: "/consultant",
        icon: getImageIcon(ProfileIcons),
      });
    }

    if (Navlist?.sub_team) {
      dynamite_lifestyle_team_list_child_array.push({
        title: "Sub Team",
        path: "/sub-team",
        icon: getImageIcon(ProfileIcons),
      });
    }
    if (Navlist?.marketing_team) {
      dynamite_lifestyle_team_list_child_array.push({
        title: "Marketing Team",
        path: "/marketing",
        icon: getImageIcon(ProfileIcons),
      });
    }

    sidebarMenus.push({
      title: "Dynamite Lifestyle Team",
      icon: getImageIcon(ProfileIcons),

      child_options: dynamite_lifestyle_team_list_child_array,
    });
  }
  // if (Navlist?.delegate_sales_team) {
  //   let questions_answers_list_child_array = [];
  //   if (Navlist?.approved_sales_team) {
  //     questions_answers_list_child_array.push({
  //       title: "Approved",
  //       path: "/approved",
  //       icon: getImageIcon(ProfileIcons),
  //     });
  //   }
  //   if (Navlist?.pending_sales_team) {
  //     questions_answers_list_child_array.push({
  //       title: "Pending",
  //       path: "/pending",
  //       icon: getImageIcon(ProfileIcons),
  //     });
  //   }
  //   sidebarMenus.push({
  //     title: "Delegate Sales Team",
  //     icon: getImageIcon(ProfileIcons),

  //     child_options: questions_answers_list_child_array,
  //   });
  // }
  if (Navlist?.support_team) {
    sidebarMenus.push({
      title: "Support Team",
      path: "/support-team",
      icon: getImageIcon(DashboardIcons),
    });
  }
  if (Navlist?.history) {
    sidebarMenus.push({
      title: "History",
      path: "/history",
      icon: getImageIcon(DashboardIcons),
    });
  }
  if (Navlist?.admin_users) {
    sidebarMenus.push({
      title: "Admin Users",
      path: "/admin-user",
      icon: getImageIcon(ProfileIcons),
    });
  }
  if (Navlist?.goal_statement) {
    sidebarMenus.push({
      title: "Goal Statement",
      path: "/goal-statement",
      icon: getImageIcon(GoalStatmentIcons),
    });
  }
  if (Navlist?.ninety_day_questions) {
    sidebarMenus.push({
      title: "Member 90 Day",
      icon: getImageIcon(DayPlanIcons),
      child_options: [
        {
          title: "Questions",
          path: "/90-day-questions",
          icon: getImageIcon(DayPlanIcons),
        },
        // {
        //   title: "Answers",
        //   path: "/90-day-answers",
        //   icon: getImageIcon(DayPlanIcons),
        // },
        {
          title: "Content Setting",
          path: "/90-day-content-setting",
          icon: getImageIcon(DayPlanIcons),
        },
      ],
    });
  }
  if (Navlist?.delegate_90_day_questions) {
    sidebarMenus.push({
      title: "Delegate 90 Day Questions",
      path: "/delegate-questions",
      icon: getImageIcon(DayPlanIcons),
    });
  }
  if (Navlist?.wheel_of_life_member_list) {
    sidebarMenus.push({
      title: "Wheel Of life Member List",
      path: "/wheel-of-life-member-list",
      icon: getImageIcon(DayPlanIcons),
    });
  }
  if (Navlist?.dynamite_gallery) {
    sidebarMenus.push({
      title: "Dynamite Gallery",
      path: "/dynamite-gallery",
      icon: getImageIcon(DayPlanIcons),
    });
  }
  if (Navlist?.subscription_list) {
    sidebarMenus.push({
      title: "Subscription List",
      path: "/subscription-list",
      icon: getImageIcon(DayPlanIcons),
    });
  }

  if (Navlist?.progress_category) {
    sidebarMenus.push({
      title: "Progress Category",
      path: "/progress-category",
      icon: getImageIcon(DayPlanIcons),
    });
  }
  if (Navlist?.road_map_levels) {
    sidebarMenus.push({
      title: "Road Map Levels",
      path: "/road-map-levels",
      icon: getImageIcon(DayPlanIcons),
    });
  }

  if (Navlist?.mentorship) {
    let mentorship = [];
    if (Navlist?.mentorship_category) {
      mentorship.push({
        title: "Bonus Content Categories",
        path: "/mentorship-category",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.mentorship_videos) {
      mentorship.push({
        title: "Bonus Content Videos",
        path: "/mentorship-video",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    sidebarMenus.push({
      title: "Bonus Content",
      icon: getImageIcon(DayPlanIcons),
      child_options: mentorship,
    });
  }

  if (Navlist?.self_image) {
    let child_options = [];
    if (Navlist?.questions) {
      child_options.push({
        title: "Questions",
        path: "/self-image-questions",
        icon: getImageIcon(GoalStatmentIcons),
      });
    }
    if (Navlist?.answers) {
      child_options.push({
        title: "Answers",
        path: "/self-image-answers",
        icon: getImageIcon(GoalStatmentIcons),
      });
    }
    if (Navlist?.completed_answers) {
      child_options.push({
        title: "Completed Answers",
        path: "/self-image-completed-answers",
        icon: getImageIcon(GoalStatmentIcons),
      });
    }
    if (Navlist?.incompleted_answers) {
      child_options.push({
        title: "InCompleted Answers",
        path: "/self-image-incompleted-answers",
        icon: getImageIcon(GoalStatmentIcons),
      });
    }
    if (Navlist?.responded_answers) {
      child_options.push({
        title: "Responded Answers",
        path: "/self-image-responded-answers",
        icon: getImageIcon(GoalStatmentIcons),
      });
    }
    sidebarMenus.push({
      title: "Self Image",
      icon: getImageIcon(GoalStatmentIcons),
      child_options: child_options,
    });
  }
  if (Navlist?.question_answer_list) {
    let questions_answers_list_child_array = [];
    if (Navlist?.member_answers_list) {
      questions_answers_list_child_array.push({
        title: "Member Answers List",
        path: "/members-answers-list",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.delegate_answers_list) {
      questions_answers_list_child_array.push({
        title: "Delegate Answers List",
        path: "/delegate-answers-list",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    sidebarMenus.push({
      title: "Questions Answers List",
      icon: getImageIcon(DayPlanIcons),

      child_options: questions_answers_list_child_array,
    });
  }
  if (Navlist?.member_goal_statements) {
    let member_goal_statement_list_child_array = [];
    if (Navlist?.completed) {
      member_goal_statement_list_child_array.push({
        title: "Completed",
        path: "/goalstament-completed",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.incompleted) {
      member_goal_statement_list_child_array.push({
        title: "Incompleted",
        path: "/goalstament-incompleted",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.responded) {
      member_goal_statement_list_child_array.push({
        title: "Responded",
        path: "/goalstament-responded",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    sidebarMenus.push({
      title: "Member Goal Statements",
      icon: getImageIcon(DayPlanIcons),
      child_options: member_goal_statement_list_child_array,
    });
  }
  // booking
  if (Navlist?.booking) {
    let booking_list_child_array = [];
    if (Navlist?.booking_list) {
      booking_list_child_array.push({
        title: "Booking List",
        path: "/bookings",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.book_call_types) {
      booking_list_child_array.push({
        title: "Call Types",
        path: "/book-call-types",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.booking_status) {
      booking_list_child_array.push({
        title: "Booking Status",
        path: "/booking-status",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    sidebarMenus.push({
      title: "Booking",
      icon: getImageIcon(DayPlanIcons),
      child_options: booking_list_child_array,
    });
  }
  if (Navlist?.shop) {
    let shop_child_array = [];
    if (Navlist?.shop_categories) {
      shop_child_array.push({
        title: "Shop Categories",
        path: "/shop-category",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.shop_products) {
      shop_child_array.push({
        title: "Shop Products",
        path: "/shop-products",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.taxes) {
      shop_child_array.push({
        title: "Taxes",
        path: "/taxes",
        icon: getImageIcon(ProfileIcons),
      });
    }
    if (Navlist?.order) {
      shop_child_array.push({
        title: "Order",
        path: "/order",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.order_history) {
      shop_child_array.push({
        title: "Order History",
        path: "/shop-order",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    sidebarMenus.push({
      title: "Shop",
      icon: getImageIcon(DayPlanIcons),
      child_options: shop_child_array,
    });
  }

  if (Navlist?.stripe_reader) {
    sidebarMenus.push({
      title: "Stripe Reader",
      path: "/stripe-reader",
      icon: getImageIcon(DayPlanIcons),
    });
  }

  // manage website
  if (Navlist?.manage_website) {
    let manage_website_list_child_array = [];
    if (Navlist?.pages) {
      manage_website_list_child_array.push({
        title: "Pages",
        path: "/website-pages",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.template_pages) {
      manage_website_list_child_array.push({
        title: "Template Pages",
        path: "/template-pages",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.menu) {
      manage_website_list_child_array.push({
        title: "Menu",
        path: "/menu",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.site_setting) {
      manage_website_list_child_array.push({
        title: "Site Setting",
        path: "/site-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.website_cookie_setting) {
      manage_website_list_child_array.push({
        title: "Website Cookie Setting",
        path: "/website-cockie-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (userInfo.role == "super_admin") {
      if (Navlist?.template_configuration) {
        manage_website_list_child_array.push({
          title: "Template Configuration",
          path: "/template-configuration",
          icon: getImageIcon(DashboardIcons),
        });
      }
    }

    if (Navlist?.coupons) {
      manage_website_list_child_array.push({
        title: "Coupon",
        path: "/coupon",
        icon: getImageIcon(DashboardIcons),
      });
    }

    sidebarMenus.push({
      title: "Manage Website",
      icon: getImageIcon(DashboardIcons),
      child_options: manage_website_list_child_array,
    });
  }

  // kims recording
  if (Navlist?.kims_recording) {
    let recordings_child_array = [];
    if (Navlist?.recordings) {
      recordings_child_array.push({
        title: "Recordings",
        path: "/recording-list",
        icon: getImageIcon(VaultIcons),
      });
    }
    if (Navlist?.categories) {
      recordings_child_array.push({
        title: "Categories",
        path: "/vault",
        icon: getImageIcon(VaultIcons),
      });
    }

    sidebarMenus.push({
      title: "Kim's Recording",
      icon: getImageIcon(VaultIcons),
      child_options: recordings_child_array,
    });
  }
  // Study Session
  if (Navlist?.study_session) {
    let study_session_child_array = [];
    if (Navlist?.sessions) {
      study_session_child_array.push({
        title: "Sessions",
        path: "/sessions",
        icon: getImageIcon(ProgrammeIcons),
      });
    }
    if (Navlist?.session_recording) {
      study_session_child_array.push({
        title: "Session Recording",
        path: "/session-recording",
        icon: getImageIcon(ProgrammeIcons),
      });
    }
    sidebarMenus.push({
      title: "Study Session",
      icon: getImageIcon(ProgrammeIcons),
      child_options: study_session_child_array,
    });
  }
  // Source Session
  if (Navlist?.source_session) {
    let source_session = [];
    if (Navlist?.source_session_sessions) {
      source_session.push({
        title: "Sessions",
        path: "/source-sessions",
        icon: getImageIcon(ProgrammeIcons),
      });
    }
    if (Navlist?.source_session_session_recording) {
      source_session.push({
        title: "Session Recording",
        path: "/source-session-recordings",
        icon: getImageIcon(ProgrammeIcons),
      });
    }

    sidebarMenus.push({
      title: "Source Session",
      icon: getImageIcon(ProgrammeIcons),
      child_options: source_session,
    });
  }

  // Meditation
  if (Navlist?.meditation) {
    let meditation = [];
    if (Navlist?.meditations) {
      meditation.push({
        title: "Meditations",
        path: "/meditation",
        icon: getImageIcon(meditationIcon),
      });
    }
    if (Navlist?.meditations_categories) {
      meditation.push({
        title: "Meditations Categories",
        path: "/meditations-category",
        icon: getImageIcon(meditationIcon),
      });
    }
    sidebarMenus.push({
      title: "Meditation",
      icon: getImageIcon(meditationIcon),
      child_options: meditation,
    });
  }
  if (Navlist?.help_tech) {
    sidebarMenus.push({
      title: "Help Tech",
      path: "/help-video-categories",
      icon: getImageIcon(VaultIcons),
    });
  }
  if (Navlist?.extras) {
    let extras_child_array = [];
    if (Navlist?.quotes) {
      extras_child_array.push({
        title: "Quotes",
        path: "/quotes-list",
        icon: getImageIcon(QuotesIcons),
      });
    }
    if (Navlist?.gratitude) {
      extras_child_array.push({
        title: "Gratitude",
        path: "/gratitude",
        icon: getImageIcon(GratitudeIcons),
      });
    }
    if (Navlist?.affirmation) {
      extras_child_array.push({
        title: "Affirmation",
        path: "/affirmation",
        icon: getImageIcon(AffirmationIcons),
      });
    }
    if (Navlist?.affirmation_categories) {
      extras_child_array.push({
        title: "Affirmation Categories",
        path: "/affirmations-categories",
        icon: getImageIcon(AffirmationIcons),
      });
    }
    sidebarMenus.push({
      title: "Extras",
      icon: getImageIcon(AffirmationIcons),
      child_options: extras_child_array,
    });
  }

  if (Navlist?.payments) {
    let payments_child_array = [];
    if (Navlist?.stripe_products) {
      payments_child_array.push({
        title: "Stripe Products",
        path: "/stripe-products",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.transactions) {
      payments_child_array.push({
        title: "Transactions",
        path: "/payment-transactions",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.fire_transactions) {
      payments_child_array.push({
        title: "Fire Transactions",
        path: "/fire-payment-transactions",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.payment_templates) {
      payments_child_array.push({
        title: "Payment Templates",
        path: "/payment-template",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.payment_request) {
      payments_child_array.push({
        title: "Payment Request",
        path: "/payment-request",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.affiliate_transactions) {
      payments_child_array.push({
        title: "Affiliate Transactions",
        path: "/affiliate-transactions",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (project_mode == "dev") {
      if (Navlist?.affiliate_transactions) {
        payments_child_array.push({
          title: "Dynamite Payment",
          path: "/dynamite-payment",
          icon: getImageIcon(DashboardIcons),
        });
      }
      if (Navlist?.affiliate_transactions) {
        payments_child_array.push({
          title: "Dynamite Digital Payment",
          path: "/dynamite-digital-payment",
          icon: getImageIcon(DashboardIcons),
        });
      }
    }

    if (Navlist?.bliing_tickets) {
      payments_child_array.push({
        title: "Billing Tickets",
        path: "/billing-tickets",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.checked_in_tickets) {
      payments_child_array.push({
        title: "Checked In",
        path: "/checked-in",
        icon: getImageIcon(DashboardIcons),
      });
    }

    sidebarMenus.push({
      title: "Payments",
      icon: getImageIcon(DashboardIcons),
      child_options: payments_child_array,
    });
  }

  if (Navlist?.commissions) {
    let commissions_array = [];
    if (Navlist?.pending_commissions) {
      commissions_array.push({
        title: "Pending Commissions",
        path: "/pending-commissions",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.paid_commissions) {
      commissions_array.push({
        title: "Paid Commissions",
        path: "/paid-commissions",
        icon: getImageIcon(DashboardIcons),
      });
    }

    sidebarMenus.push({
      title: "Commissions",
      icon: getImageIcon(DashboardIcons),
      child_options: commissions_array,
    });
  }

  if (Navlist?.calendar) {
    let calender_child_array = [];
    if (Navlist?.groups) {
      calender_child_array.push({
        title: "Groups",
        path: "/calendar-groups",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.calendar_events) {
      calender_child_array.push({
        title: "Calendar Events",
        path: "/calendar-events",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (userInfo.role == "super_admin") {
      calender_child_array.push({
        title: "Calendar Events New",
        path: "/calendar-events-new",
        icon: getImageIcon(DashboardIcons),
      });
    }

    sidebarMenus.push({
      title: "Calendar",
      icon: getImageIcon(DashboardIcons),
      child_options: calender_child_array,
    });
  }

  if (Navlist?.pods) {
    let pods_child_array = [];
    pods_child_array.push({
      title: "Source Pods",
      path: "/calendar-pods",
      icon: getImageIcon(PodsIcons),
    });
    pods_child_array.push({
      title: "Book Call Pods",
      path: "/book-call-pods",
      icon: getImageIcon(PodsIcons),
    });
    sidebarMenus.push({
      title: "Pods",
      icon: getImageIcon(DashboardIcons),
      child_options: pods_child_array,
    });
  }
  // support
  if (Navlist?.support) {
    let support_child_array = [];
    if (Navlist?.departments) {
      support_child_array.push({
        title: "Departments",
        path: "/departments",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.member_support_tickets) {
      support_child_array.push({
        title: "Member Support Tickets",
        path: "/support-ticket",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.delegate_support_tickets) {
      support_child_array.push({
        title: "Delegate Support Tickets",
        path: "/delegate-support-ticket",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.trash_support_tickets) {
      support_child_array.push({
        title: "Trash Support Tickets",
        path: "/trash-support-ticket",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.auto_respoded_messages) {
      support_child_array.push({
        title: "Auto Responded Messages",
        path: "/auto-responded-message",
        icon: getImageIcon(DashboardIcons),
      });
    }
    sidebarMenus.push({
      title: "Support",
      icon: getImageIcon(DashboardIcons),
      child_options: support_child_array,
    });
  }
  //communitty

  if (Navlist?.community) {
    let community_child_array = [];
    if (Navlist?.community_rewards) {
      community_child_array.push({
        title: "Community Reward",
        path: "/community",
        icon: getImageIcon(thesourceIcons),
      });
    }
    if (Navlist?.series_categories) {
      community_child_array.push({
        title: "Series Categories",
        path: "/series-categories",
        icon: getImageIcon(VaultIcons),
      });
    }
    if (Navlist?.series_videos) {
      community_child_array.push({
        title: "Series Videos",
        path: "/series-videos",
        icon: getImageIcon(VaultIcons),
      });
    }
    if (Navlist?.feeds) {
      community_child_array.push({
        title: "Feeds",
        path: "/feeds",
        icon: getImageIcon(thesourceIcons),
      });
    }
    if (Navlist?.feed_setting) {
      community_child_array.push({
        title: "Feed Setting",
        path: "/feed/setting",
        icon: getImageIcon(thesourceIcons),
      });
    }
    if (Navlist?.daily_messages) {
      community_child_array.push({
        title: "Daily Messages",
        path: "/daily-messages",
        icon: getImageIcon(thesourceIcons),
      });
    }
    if (Navlist?.bonus_road_map) {
      community_child_array.push({
        title: "Bonus Road Map",
        path: "/bonus-road-map",
        icon: getImageIcon(thesourceIcons),
      });
    }
    if (Navlist?.badge) {
      community_child_array.push({
        title: "Badges",
        path: "/badges",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    sidebarMenus.push({
      title: "Community",
      icon: getImageIcon(thesourceIcons),
      child_options: community_child_array,
    });
  }

  // client portal
  if (Navlist?.client_portal) {
    let client_child_array = [];
    if (Navlist?.client_general_setting) {
      client_child_array.push({
        title: "General Setting",
        path: "/general-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.client_dashboard) {
      client_child_array.push({
        title: "Client Dashboard",
        path: "/client-dashboard",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.content_setting) {
      client_child_array.push({
        title: "Content Setting",
        path: "/content-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.feed_sidebar_events) {
      client_child_array.push({
        title: "Feed Sidebar Events",
        path: "/website-events",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.wheel_of_life) {
      client_child_array.push({
        title: "Wheel Of Life",
        path: "/wheel_of_life",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.assessment) {
      client_child_array.push({
        title: "Assessment",
        path: "/assessment",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.wheel_of_life_setting) {
      client_child_array.push({
        title: "Wheel Of Life Setting",
        path: "/wheel-of-life-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.dynamite_events) {
      client_child_array.push({
        title: "Dynamite Events",
        path: "/dynamite-events",
        matches: [
          "/dynamite-events",
          "/dynamite-event-categories",
          "/dynamite-event-videos",
          "/dynamite-event-categories",
          "/dynamite-event-videos",
        ],
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.attitude_assessment_questions) {
      client_child_array.push({
        title: "Attitude Assessment Questions",
        path: "/attitude-assessment-questions",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.attitude_assessment_setting) {
      client_child_array.push({
        title: "Attitude-Assessment Setting",
        path: "/attitude-assessment-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.dynamite_dairy_setting) {
      client_child_array.push({
        title: "Dynamite Diary Setting",
        path: "/dynamite-diary-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.gratitude_daily_setting) {
      client_child_array.push({
        title: "Gradtitude Daily Setting",
        path: "/gradtitude-daily-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.meditation_setting) {
      client_child_array.push({
        title: "Meditation Setting",
        path: "/meditation-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.challenge_meditation_Setting) {
      client_child_array.push({
        title: "Challenge Meditation Setting",
        path: "/meditation-challenge-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.challenge_reward_setting) {
      client_child_array.push({
        title: "Challenge Reward Setting",
        path: "/challenges-reward-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }

    if (Navlist?.challenges) {
      client_child_array.push({
        title: "Challenges",
        path: "/challenge",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.coins_configuration) {
      client_child_array.push({
        title: "Coins Configuration",
        path: "/coin-configuration",
        icon: getImageIcon(DashboardIcons),
      });
    }

    if (Navlist?.mission_control_graphics) {
      client_child_array.push({
        title: "Mission Control Graphics",
        path: "/mission-control-graphics",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.client_help_tech) {
      client_child_array.push({
        title: "Help Tech",
        path: "/client-help-video-categories",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.notifications) {
      client_child_array.push({
        title: "Notifications",
        path: "/notifications",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.storehouse_content_setting) {
      client_child_array.push({
        title: "Store House Content Setting",
        path: "/store-house-content-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }

    sidebarMenus.push({
      title: "Client Portal",
      icon: getImageIcon(DashboardIcons),
      child_options: client_child_array,
    });
  }
  // delegate portal

  if (Navlist?.delegate_portal) {
    let delegate_child_array = [];
    if (Navlist?.delegate_general_setting) {
      delegate_child_array.push({
        title: "General Setting",
        path: "/consultants-general-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.delegate_groups) {
      delegate_child_array.push({
        title: "Delegate Groups",
        path: "/delegate-groups",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.quarter) {
      delegate_child_array.push({
        title: "Quarter",
        path: "/quarters",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.delegate_pods) {
      delegate_child_array.push({
        title: "Delegate Pods",
        path: "/delegate-pods",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.delegate_events) {
      delegate_child_array.push({
        title: "Delegate Events",
        path: "/delegate-events",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.delegate_recordings) {
      delegate_child_array.push({
        title: "Delegate Recordings",
        path: "/delegate-recordings",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.delegate_assets) {
      delegate_child_array.push(
        {
          title: "Digital Assets Categories",
          path: "/digital-asset-categories",
          icon: getImageIcon(DashboardIcons),
        },
        {
          title: "Digital Assets",
          path: "/digital-assets",
          icon: getImageIcon(DashboardIcons),
        }
      );
    }
    if (Navlist?.delegate_help_tech) {
      delegate_child_array.push({
        title: "Help Tech",
        path: "/delegate-help-video-categories",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.dynamite_accountability_tracker) {
      delegate_child_array.push({
        title: "Accountability Tracker Settings",
        path: "/tracker-settings",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.dynamite_accountability_tracker) {
      delegate_child_array.push({
        title: "Monthly Report Settings",
        path: "/monthly-report-settings",
        icon: getImageIcon(DashboardIcons),
      });
    }
    sidebarMenus.push({
      title: "Delegates Portal",
      icon: getImageIcon(DashboardIcons),
      child_options: delegate_child_array,
    });
  }
  //communitty

  if (Navlist?.general_setting) {
    let General_Setting_child = [];
    if (Navlist?.default_setting) {
      General_Setting_child.push({
        title: "Default Setting",
        path: "/default-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.default_setting) {
      General_Setting_child.push({
        title: "App Setting",
        path: "/app-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.email_setting) {
      General_Setting_child.push({
        title: "Email Setting",
        path: "/email-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.stripe_setting) {
      General_Setting_child.push({
        title: "Stripe Setting",
        path: "/add-stripe",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.campaign_setting) {
      General_Setting_child.push({
        title: "Campaign Setting",
        path: "/add-campaign",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.email_templates) {
      General_Setting_child.push({
        title: "Email Templates",
        path: "/email-templates",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.invoice_setting) {
      General_Setting_child.push({
        title: "Invoice Setting",
        path: "/invoice-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.zoom_setting) {
      General_Setting_child.push({
        title: "Zoom Setting",
        path: "/zoom-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.reminder_setting) {
      General_Setting_child.push({
        title: "Invoice Reminder Setting",
        path: "/reminder-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.call_reminder_setting) {
      General_Setting_child.push({
        title: "Call Reminder Setting",
        path: "/call-reminder-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.welcome_reminder_setting) {
      General_Setting_child.push({
        title: "Welcome Reminder Setting",
        path: "/welcome-reminder-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.fire_setting) {
      General_Setting_child.push({
        title: "Fire Setting",
        path: "/add-fire",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.calendar_setting) {
      General_Setting_child.push({
        title: "Calendar Setting",
        path: "/calendar-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.notification_setting) {
      General_Setting_child.push({
        title: "Notification Statements",
        path: "/notification-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.whatsapp_setting) {
      General_Setting_child.push({
        title: "WhatsApp Setting",
        path: "/whatsapp-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.shop_setting) {
      General_Setting_child.push({
        title: "Mintsoft  content Setting",
        path: "/shop-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.manage_crunncies) {
      General_Setting_child.push({
        title: "Manage Currencies",
        path: "/manage-country",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.notification_actions) {
      General_Setting_child.push({
        title: "Notifications Action",
        path: "/manage-notifications",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.dhl_api_setting) {
      General_Setting_child.push({
        title: "DHL API settings",
        path: "/DHl-setting",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.bitly_settings) {
      General_Setting_child.push({
        title: "Bitly Settings",
        path: "/bitly-settings",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.twilio_setting) {
      General_Setting_child.push({
        title: "Twilio Settings",
        path: "/twilio-settings",
        icon: getImageIcon(DashboardIcons),
      });
    }
    sidebarMenus.push({
      title: "General Setting",
      icon: getImageIcon(DashboardIcons),
      child_options: General_Setting_child,
    });
  }
  // support
  // if (Navlist?.sale_lead) {
  //   let support_child_array = [];
  //   if (Navlist?.section) {
  //     support_child_array.push({
  //       title: "Section",
  //       path: "/all-sales",
  //       icon: getImageIcon(DashboardIcons),
  //     });
  //   }
  //   if (Navlist?.leads) {
  //     support_child_array.push({
  //       title: "Leads",
  //       path: "/all-leads",
  //       icon: getImageIcon(DashboardIcons),
  //     });
  //   }

  //   sidebarMenus.push({
  //     title: "Sale Lead",
  //     icon: getImageIcon(DashboardIcons),
  //     child_options: support_child_array,
  //   });
  // }
  // if (Navlist?.email_logs) {
  //   sidebarMenus.push({
  //     title: "Email Logs",
  //     path: "/email-logs",
  //     icon: getImageIcon(DashboardIcons),
  //   });
  // }
  if (Navlist?.lead_status) {
    sidebarMenus.push({
      title: "Lead Status",
      path: "/lead-status",
      icon: getImageIcon(DashboardIcons),
    });
  }
  if (Navlist?.reminder_users) {
    sidebarMenus.push({
      title: "Welcome Message Reminders",
      path: "/reminder-user",
      icon: getImageIcon(DashboardIcons),
    });
  }

  if (Navlist?.nav_items) {
    let nav_items_child_array = [];
    if (Navlist?.admin_nav_items) {
      nav_items_child_array.push({
        title: "Admin Navitems",
        path: "/admin-navitems",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.delegate_nav_items) {
      nav_items_child_array.push({
        title: "Delegate Portal Navitems",
        path: "/delegate-navitems",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.client_nav_items) {
      nav_items_child_array.push({
        title: "Client Portal Navitems",
        path: "/client-navitems",
        icon: getImageIcon(DayPlanIcons),
      });
    }

    sidebarMenus.push({
      title: "Nav Items",
      icon: getImageIcon(DayPlanIcons),
      child_options: nav_items_child_array,
    });
  }
  if (Navlist?.dl_team_nav_items) {
    let nav_items_child_array = [];

    if (Navlist?.inner_circle_nav_items) {
      nav_items_child_array.push({
        title: "Inner Circle NavItems",
        path: "/manage-inner-circle-navitems",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.delegates_nav_items) {
      nav_items_child_array.push({
        title: "Delegates NavItems",
        path: "/manage-delegate-user-navitems",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.consaltant_nav_items) {
      nav_items_child_array.push({
        title: "Consultant NavItems",
        path: "/manage-consultant-user-navitems",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.sub_team_nav_items) {
      nav_items_child_array.push({
        title: "Sub Team Navitems",
        path: "/manage-sales-team-navitems",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.sub_team_nav_items) {
      nav_items_child_array.push({
        title: "Marketing Team Navitems",
        path: "/manage-marketing-team-navitems",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.inner_circle_settings) {
      nav_items_child_array.push({
        title: "Inner Circle Settings",
        path: "/manage-inner-circle-settings",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.delegates_user_settings) {
      nav_items_child_array.push({
        title: "Delegates Settings",
        path: "/manage-delegates-settings",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.consaltant_user_settings) {
      nav_items_child_array.push({
        title: "Consultant Settings",
        path: "/manage-consultant-settings",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.sub_team_settings) {
      nav_items_child_array.push({
        title: "Sub Team Settings",
        path: "/sub-team-settings",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.marketing_team_settings) {
      nav_items_child_array.push({
        title: "Marketing Team Settings",
        path: "/marketing-team-settings",
        icon: getImageIcon(DayPlanIcons),
      });
    }

    sidebarMenus.push({
      title: "Dynamite Lifestyle Team Settings ",
      icon: getImageIcon(DayPlanIcons),
      child_options: nav_items_child_array,
    });
  }

  if (Navlist?.stripe) {
    let stripe_child_array = [];
    if (Navlist?.stripe_subscription) {
      stripe_child_array.push({
        title: "Stripe Subscriptions",
        path: "/stripe-subscriptions",
        icon: getImageIcon(DayPlanIcons),
      });
    }
    if (Navlist?.stripe_transaction) {
      stripe_child_array.push({
        title: "Stripe Transactions",
        path: "/stripe-transactions",
        icon: getImageIcon(DayPlanIcons),
      });
    }

    sidebarMenus.push({
      title: "Stripe",
      icon: getImageIcon(DayPlanIcons),
      child_options: stripe_child_array,
    });
  }
  if (Navlist?.card_of_the_day) {
    sidebarMenus.push({
      title: "Cards Of The Day",
      path: "/card-of-the-day",
      icon: getImageIcon(DashboardIcons),
    });
  }
  if (Navlist?.account_termination_request) {
    sidebarMenus.push({
      title: "Account Termination Requests",
      path: "/deleted-members",
      icon: getImageIcon(DashboardIcons),
    });
  }
  if (Navlist?.requested_csv_fils) {
    sidebarMenus.push({
      title: "Requested CSV files",
      path: "/requested-csv-files",
      icon: getImageIcon(DashboardIcons),
    });
  }
  if (Navlist?.short_links) {
    sidebarMenus.push({
      title: "Short Links",
      path: "/short-links",
      icon: getImageIcon(DashboardIcons),
    });
  }

  if (Navlist?.sales_and_commission_report) {
    sidebarMenus.push({
      title: "Sales & Commission Report",
      path: "/sale-commission-report",
      icon: getImageIcon(DashboardIcons),
    });
  }

  if (Navlist?.analytics_report) {
    let analytics_array = [];
    if (Navlist?.analytics_overview) {
      analytics_array.push({
        title: "Overview",
        path: "/analytics-overview",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.real_time_report) {
      analytics_array.push({
        title: "Realtime Report",
        path: "/real-time-overview",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.stream_report) {
      analytics_array.push({
        title: "Detailed Analytics Report",
        path: "/analytics-report",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.demographic_analytics) {
      analytics_array.push({
        title: "Demographic Report",
        path: "/analytics-country",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.funnel_exploration) {
      analytics_array.push({
        title: "Funnel Exploration",
        path: "/funnel-exploration",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.platform_report) {
      analytics_array.push({
        title: "Platform Details",
        path: "/platform-details",
        icon: getImageIcon(DashboardIcons),
      });
    }
    if (Navlist?.event_analytics) {
      analytics_array.push({
        title: "Events",
        path: "/analytics-event",
        icon: getImageIcon(DashboardIcons),
      });
    }

    sidebarMenus.push({
      title: "Analytics Reports",
      icon: getImageIcon(DayPlanIcons),
      child_options: analytics_array,
    });
  }
  if (Navlist?.unsubscribed_members) {
    sidebarMenus.push({
      title: "Unsubscribed Members",
      path: "/unsubscribe-members",
      icon: getImageIcon(DashboardIcons),
    });
  }

  return sidebarMenus;
};
export default sidebarConfig;
// const sidebarConfig = [

//

//   {
//     title: "General Setting",
//     icon: getImageIcon(DashboardIcons),
//     child_options: [
//       {
//         title: "Default Setting",
//         path: "/default-setting",
//         icon: getImageIcon(DashboardIcons),
//       },
//       {
//         title: "Email Setting",
//         path: "/email-setting",
//         icon: getImageIcon(DashboardIcons),
//       },
//       {
//         title: "Stripe Setting",
//         path: "/add-stripe",
//         icon: getImageIcon(DashboardIcons),
//       },
//       {
//         title: "Campaign Setting",
//         path: "/add-campaign",
//         icon: getImageIcon(DashboardIcons),
//       },
//       // {
//       //   title: "SendGrid Setting",
//       //   path: "/send-grid",
//       //   icon: getImageIcon(DashboardIcons),
//       // },
//       {
//         title: "Email Templates",
//         path: "/email-templates",
//         icon: getImageIcon(DashboardIcons),
//       },
//       {
//         title: "Invoice Setting",
//         path: "/invoice-setting",
//         icon: getImageIcon(DashboardIcons),
//       },
//       {
//         title: "Zoom Setting",
//         path: "/zoom-setting",
//         icon: getImageIcon(DashboardIcons),
//       },
//       {
//         title: "Reminder Setting",
//         path: "/reminder-setting",
//         icon: getImageIcon(DashboardIcons),
//       },
//       {
//         title: "Calendar Setting",
//         path: "/calendar-setting",
//         icon: getImageIcon(DashboardIcons),
//       },
//       {
//         title: "Notification Setting",
//         path: "/notification-setting",
//         icon: getImageIcon(DashboardIcons),
//       },
//     ],
//   },

//   // {
//   //   title: "Shop",
//   //   path: "/shop-category",
//   //   icon: getImageIcon(DashboardIcons),
//   // },
//   {
//     title: "Sale Lead",
//     icon: getImageIcon(DashboardIcons),
//     child_options: [
//       {
//         title: "Section",
//         path: "/all-sales",
//         icon: getImageIcon(DashboardIcons),
//       },
//       {
//         title: "Leads",
//         path: "/all-leads",
//         icon: getImageIcon(DashboardIcons),
//       },
//     ],
//   },
// ];

// if (project_mode == "dev") {
//   sidebarConfig.push({
//     title: "Calendar Events",
//     path: "/calendar-events",
//     icon: getImageIcon(DashboardIcons),
//   });
// }

// export default sidebarConfig;
