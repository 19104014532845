import * as React from "react";
import { useState } from "react";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import FomulaHistory from "src/components/MembersList/FomulaHistory";
import TrackerHistory from "src/components/MembersList/TrackerHistory";

export default function NinetyDayHistory({ tabData, userInfo }) {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TABS_OPTIONS = [
    {
      title: "90 Day Tracker",
      component: <TrackerHistory tabData={tabData} userInfo={userInfo} />,
    },
    {
      title: "90 Day Formula",
      component: <FomulaHistory tabData={tabData} userInfo={userInfo} />,
    },
  ];

  return (
    <div className="container-fluid">
      <div class="row">
        <div class="col-12 mt-4">
          <h2>90 Day History</h2>
          <MUICustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
