import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { clientPortalUrl, s3baseUrl } from "src/config/config";
import { DeleteMemberApi, memberListing } from "src/DAL/member/member";
import HistoryIcon from "@mui/icons-material/History";
import { get_kmb_number } from "src/utils/formatNumber";
import {
  COMMUNITY_LEVELS,
  api_date_format,
  dd_date_format,
  replace_created_for,
  show_proper_words,
} from "src/utils/constant";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { Icon } from "@iconify/react";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import { DeleteMultiMemberApi } from "src/DAL/member/member";
import { reset_assement_api } from "src/DAL/member/member";
import { member_login_api } from "src/DAL/member/member";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import ChangeAffiliateID from "src/components/MembersList/ChangeAffiliateID";
import ChangePassword from "src/components/MembersList/ChangePassword";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import MemberFilter from "src/components/MembersList/MemberFilter";
import moment from "moment";
import { download_csv_file } from "src/utils/convertHtml";
import MemberProgramPopover from "./memberProgramPopOver";
import ChangeLeadStatus from "./ChangeLeadStatus";
import FilteredChip from "src/components/FilteredChip";
import {
  downloadIcon,
  dynamite,
  dynamite_icon,
  ignite,
  mastery,
  mastery_icon,
  notDownloadIcon,
  pta,
  wheelIcon,
} from "src/assets";
import LeadStatusHistory from "./LeadStatusHistory";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import History from "./History";
import HistoryGoalStatus from "./HistoryGoalStatus";
import GeneralRequestForCsv from "./GeneralRequestForCsv";
import { countries } from "src/utils/country";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import MembersListStats from "./MembersListStats";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const headers = [
  { label: "First Name", key: "first_name" },
  { label: "Last Name", key: "last_name" },
  { label: "Email", key: "email" },
  { label: "Contact Number", key: "phone" },
  { label: "Referred User", key: "affiliate_name" },
  { label: "Nurture", key: "nurture" },
  { label: "Delegate", key: "delegate_name" },
  { label: "Community Level", key: "community_level_export" },
  { label: "Created At", key: "created_at" },
  { label: "Membership Expire", key: "membership_expire" },
  { label: "Last Login Activity", key: "last_login_activity" },
  { label: "Status", key: "status_export" },
];

let member_options = [
  {
    label: "First name",
    value: "first_name",
    is_checked: true,
    is_disabled: true,
  },
  {
    label: "Last name",
    value: "last_name",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Email", value: "email", is_checked: true, is_disabled: true },
  {
    label: "Phone number",
    value: "contact_number",
    is_checked: true,
    is_disabled: true,
  },
  { label: "Delegate", value: "consultant", is_checked: false },
  { label: "Referred User", value: "affliliate", is_checked: false },
  { label: "Nurture", value: "nurture", is_checked: false },
  {
    label: "Marketing affliliate",
    value: "marketing_affliliate",
    is_checked: false,
  },
  // {
  //   label: "Event subscriber count",
  //   value: "event_subscriber_count",
  //   is_checked: false,
  // },
  // {
  //   label: "Dynamite diary coins count",
  //   value: "dynamite_diary_coins_count",
  //   is_checked: false,
  // },
  // {
  //   label: "Attitude assessment coins count",
  //   value: "attitude_assessment_coins_count",
  //   is_checked: false,
  // },
  // {
  //   label: "Visioneering challange coins count",
  //   value: "visioneering_challange_coins_count",
  //   is_checked: false,
  // },
  // {
  //   label: "Dynamite gratitude coins count",
  //   value: "dynamite_gratitude_coins_count",
  //   is_checked: false,
  // },
  // {
  //   label: "Wheel of life completed date",
  //   value: "wheel_of_life_completed_date",
  //   is_checked: false,
  // },
  // {
  //   label: "Meditation coins count",
  //   value: "meditation_coins_count",
  //   is_checked: false,
  // },
  // { label: "Register url", value: "register_url", is_checked: false },
  {
    label: "Last login activity",
    value: "last_login_activity",
    is_checked: false,
  },
  {
    label: "Total commission",
    value: "total_commission",
    is_checked: false,
  },
  {
    label: "Commission paid",
    value: "commission_paid",
    is_checked: false,
  },
  {
    label: "Commission due",
    value: "commission_due",
    is_checked: false,
  },

  {
    label: "Membership  expire",
    value: "membership_purchase_expiry",
    is_checked: false,
  },
  // { label: "CreatedAt", value: "createdAt", is_checked: false },
  // { label: "UpdatedAt", value: "updatedAt", is_checked: false },
];
export default function MembersList() {
  const navigate = useNavigate();
  const path = useLocation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openDelete, setOpenDelete] = useState(false);
  const [openMultiDelete, setOpenMultiDelete] = useState(false);
  const [openResetAssessment, setOpenResetAssessment] = useState(false);
  const [changeAffiliateOpen, setChangeAffiliateOpen] = useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [membersData, setMembersData] = useState([]);
  const [selectedObject, setSelectedObject] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState([]);
  const [leadStatusData, setleadStatusData] = useState({});
  const [changeStatusOpen, setChangeStatusOpen] = useState(false);
  const [referredpop, setReferredpop] = useState(false);
  const [ChangeDelegateOpen, setChangeDelegateOpen] = useState(false);
  const [openMarketingAffliliate, setOpenMarketingAffliliate] = useState(false);
  const [goalStatemtnHistory, setgoalStatemtnHistory] = useState(false);
  const [ChangeLeadstatus, setChangeLeadstatus] = useState(false);
  const done_member = window.location.pathname.includes("/done-listing");
  const [anchorEl, setAnchorEl] = useState(null);
  const [program, setprogram] = useState([]);
  const [changeStatus, setChangeStatus] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});
  const [showExportcsvFile, setShowExportcsvFile] = useState(false);
  const [filterQuery, setFilterQuery] = useState({});
  const [statsArray, setStatsArray] = useState([]);
  const showPopUPcsv = () => {
    setShowExportcsvFile(true);
  };

  let listing_name = "/member/";
  if (done_member) {
    listing_name = "/done-listing/";
  }

  const handleClickPopUP = (event, row) => {
    setprogram(row.program);
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopUp = () => {
    setAnchorEl(null);
  };
  const change_lead_status = (value) => {
    setSelectedMember(value);
    setChangeStatus(true);
  };

  const EMPTY_FILTER = {
    program: [],
    community: [],
    lead_status: [],
    is_date_range: false,
    from_date: null,
    to_date: null,
    date: null,
    goal_statement: "",
    expiry_in: 3,
    member_ship_expiry: "",
    member_user_type: "",
    member_created_by_platform: "",
    membership_purchase_expiry_from: new Date(),
    membership_purchase_expiry_to: new Date(),
    status: "",
    country: null,
    program_status: "all",
    downloaded_app: null,
    delegates: [],
    nurtures: [],
  };

  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const ViewAllMembers = (value) => {
    setPage(0);
    setPageCount(1);
    localStorage.setItem("member_page_number", 1);
    const newFilterData = { ...filterData, community: [value] };
    localStorage.setItem("member_filter_data", JSON.stringify(newFilterData));
    setFilterData(newFilterData);
    getMembersList(newFilterData);
  };

  const handleIsDateRange = (event) => {
    const value = event.target.checked;
    setFilterData((values) => ({ ...values, is_date_range: value }));
  };
  const handleIsCountry = (event) => {
    setFilterData((values) => ({ ...values, country: event }));
  };

  const handleChangeOthers = (event, name) => {
    setFilterData((values) => ({ ...values, [name]: event }));
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };

  const handleChangeProgramDetail = (value) => {
    navigate(`/programmes/programmes-detail/${value._id.program_slug}`, {
      state: value._id,
    });
  };

  const getMembersList = async (filterData) => {
    setIsLoading(true);
    let postData = { ...filterData };
    if (postData.program.length > 0) {
      postData.program = postData.program.map((program) => {
        return program._id;
      });
      postData.program_status = postData.program_status;
    } else {
      delete postData.program_status;
    }
    if (postData.downloaded_app) {
      postData.downloaded_app =
        postData.downloaded_app.value == "all"
          ? ""
          : postData.downloaded_app.value;
    }
    if (postData.community.length > 0) {
      postData.community = postData.community.map((community) => {
        return community.name;
      });
    }
    if (postData.lead_status.length > 0) {
      postData.lead_status = postData.lead_status.map((item) => {
        return item._id;
      });
    }
    if (postData.is_date_range) {
      if (postData.from_date) {
        postData.from_date = moment(postData.from_date).format("YYYY-MM-DD");
      }
      if (postData.to_date) {
        postData.to_date = moment(postData.to_date).format("YYYY-MM-DD");
      }
    }
    if (postData.country) {
      postData.country = postData.country.code;
    }
    if (postData.member_ship_expiry == "not_expired") {
      postData.membership_purchase_expiry_from = api_date_format(
        postData.membership_purchase_expiry_from
      );
      postData.membership_purchase_expiry_to = api_date_format(
        postData.membership_purchase_expiry_to
      );
      if (postData.expiry_in !== "custom") {
        postData.membership_purchase_expiry_to = moment(
          postData.membership_purchase_expiry_from,
          "YYYY-MM-DD"
        )
          .add(postData.expiry_in, "days")
          .format("YYYY-MM-DD");
      }
    }
    if (postData.delegates.length > 0) {
      postData.delegates = postData.delegates.map((delegate) => {
        return delegate._id;
      });
    }
    if (postData.nurtures.length > 0) {
      postData.nurtures = postData.nurtures.map((nurture) => {
        return nurture._id;
      });
    }

    let type = done_member ? "done_users" : "";
    const result = await memberListing(page, rowsPerPage, postData, type);
    if (result.code == 200) {
      let chipData = { ...filterData };
      if (chipData.program.length < 0) {
        delete chipData.program_status;
      }
      if (chipData.member_ship_expiry == "not_expired") {
        let membership_date = "";
        if (chipData.expiry_in !== "custom") {
          membership_date = `Expiry in ${chipData.expiry_in} days`;
        } else {
          membership_date = `Membership Expiry Start Date : ${dd_date_format(
            chipData.membership_purchase_expiry_from
          )} - Membership Expiry End Date :  ${moment(
            chipData.membership_purchase_expiry_to,
            "YYYY-MM-DD"
          ).format("DD-MM-YYYY")}`;
        }

        chipData.membership_expiry = {
          chip_label: membership_date,
          chip_value: membership_date,
        };
      }
      if (done_member) {
        localStorage.setItem(
          "done_member_filter_data",
          JSON.stringify({ ...chipData })
        );
      } else {
        localStorage.setItem(
          "member_filter_data",
          JSON.stringify({ ...chipData })
        );
      }

      setFilterData({ ...chipData });

      delete chipData.membership_purchase_expiry_from;
      delete chipData.membership_purchase_expiry_to;
      delete chipData.expiry_in;

      if (chipData.from_date) {
        let date = `Start Date : ${dd_date_format(
          chipData.from_date
        )} End Date :  ${dd_date_format(chipData.to_date)}`;
        chipData.date = {
          chip_label: date,
          chip_value: date,
        };
        delete chipData.from_date;
        delete chipData.to_date;
      }
      if (chipData.search_text) {
        delete chipData.search_text;
      }

      setFilterStateUpdated(chipData);
      let stats_array = [
        {
          title: "Dynamite",
          count: result.dynamiteCount,
          icon: dynamite_icon,
          value: {
            name: "dynamite",
            title: "Dynamite",
            chip_label: "Dynamite",
            chip_value: "dynamite",
          },
        },
        {
          title: "PTA",
          count: result.ptaCount,
          icon: pta,
          value: {
            name: "pta",
            title: "PTA",
            chip_label: "PTA",
            chip_value: "pta",
          },
        },
        {
          title: "Elite",
          count: result.eliteCount,
          icon: ignite,
          value: {
            name: "elite",
            title: "Elite",
            chip_label: "Elite",
            chip_value: "elite",
          },
        },
        {
          title: "Mastery",
          count: result.masteryCount,
          icon: mastery_icon,
          value: {
            name: "mastery",
            title: "Mastery",
            chip_label: "Mastery",
            chip_value: "mastery",
          },
        },
      ];
      setStatsArray(stats_array);
      const members = result.member?.map((member, index) => {
        let membership_expire = member?.membership_purchase_expiry
          ? member?.membership_purchase_expiry
          : "N/A";

        //Getting Referred User
        let affiliate_name = "Master Link";
        let market_affiliate_name = "N/A";
        let market_affiliate_info =
          member.marketing_affliliate?.affiliate_user_info;
        let affiliate_info = member.affliliate?.affiliate_user_info;
        let marketing_affliliate_url_name;
        if (member?.marketing_affliliate?.affiliate_url_name) {
          marketing_affliliate_url_name =
            member.marketing_affliliate?.affiliate_url_name;
        }

        if (market_affiliate_info) {
          market_affiliate_name =
            market_affiliate_info.first_name +
            " " +
            market_affiliate_info.last_name;
          if (member.affliliate?.affiliate_url_name && affiliate_info) {
            market_affiliate_name =
              market_affiliate_name +
              " (" +
              marketing_affliliate_url_name +
              " | " +
              "Sales Team" +
              ")";
          }
        }
        if (affiliate_info) {
          affiliate_name =
            affiliate_info.first_name + " " + affiliate_info.last_name;
        }

        if (
          member?.marketing_affliliate?.affiliate_url_name &&
          market_affiliate_info
        ) {
          affiliate_name =
            affiliate_name +
            " (" +
            member.affliliate?.affiliate_url_name +
            " | " +
            show_proper_words(affiliate_info?.affiliate_user_created_for) +
            ")";
        }

        // Getting Delegate name
        let delegate_name = "N/A";
        let delegate = member.consultant;
        if (delegate) {
          delegate_name = delegate.first_name + " " + delegate.last_name;
        }

        let goal_statement_status = member.goal_statement_completed_status;
        let goal_statement_text = "Incomplete";
        if (goal_statement_status && member.save_and_close_status) {
          goal_statement_text =
            "Responded" +
            " (" +
            dd_date_format(member.save_and_close_date) +
            ")";
        } else if (goal_statement_status && !member.save_and_close_status) {
          goal_statement_text =
            "Completed" +
            " (" +
            dd_date_format(member.goal_statement_completed_date) +
            ")";
        }

        let wheel_of_life_enable = "No";
        if (member.is_wheel_of_life_enable) {
          wheel_of_life_enable = "Yes";
        }

        let replace_user_type = "IOS";
        if (member.member_created_by_platform !== "ios") {
          replace_user_type = replace_created_for(
            member.member_created_by_platform
          );
        }
        let lead_status_export = "N/A";
        if (member.lead_status && member.lead_status.title) {
          lead_status_export = member.lead_status.title;
        }

        return {
          ...member,
          market_affiliate_name,
          name: member.first_name + " " + member.last_name,
          replace_user_type: replace_user_type,
          commission_due: "£" + member.commission_due.toFixed(2),
          commission_paid: "£" + member.commission_paid.toFixed(2),
          total_commission: "£" + member.total_commission.toFixed(2),
          register_url: member.register_url ? member.register_url : "N/A",
          member_user_type: member.member_user_type
            ? member.member_user_type
            : "N/A",
          community_level_export: member.community_level,
          lead_status_export,
          affiliate_name: affiliate_name,
          delegate_name: delegate_name,
          nurture: member.nurture
            ? member.nurture.first_name + " " + member.nurture.last_name
            : "N/A",
          completeNurture: member.nurture,
          goal_statement_text: goal_statement_text,
          created_at: dd_date_format(member.createdAt),
          last_login_activity: member.last_login_activity
            ? moment(member.last_login_activity).format("DD-MM-YYYY hh:mm A")
            : "N/A",
          user_coins: get_kmb_number(member.coins_count),
          diary_coins_count: get_kmb_number(member.dynamite_diary_coins_count),
          meditation_coins: get_kmb_number(member.meditation_coins_count),
          wheel_of_life_enable: wheel_of_life_enable,
          phone: member.contact_number ? member.contact_number : "N/A",
          assessment_coins_count: get_kmb_number(
            member.attitude_assessment_coins_count
          ),
          gratitude_coins: get_kmb_number(
            member.dynamite_gratitude_coins_count
          ),
          membership_expire: membership_expire,
          table_avatar: {
            src: member.profile_image ? s3baseUrl + member.profile_image : "/",
            alt: member.first_name,
          },
          lead_status_title: member.lead_status?.title,
          status_export: member.status === true ? "Active" : "Inactive",
          MENU_OPTIONS: handleMenu(member),
        };
      });

      setMembersData(members);
      setTotalCount(result.total_member_count);
      setTotalPages(result.total_pages);
      setFilterQuery(result.query_member);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      setIsLoading(false);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const show_history = (value) => {
    setleadStatusData(value);
    setChangeStatusOpen(true);
  };
  const show_history_reffered = (value) => {
    setleadStatusData(value);
    setReferredpop(true);
  };
  const show_marketing_history_reffered = (value) => {
    setleadStatusData(value);
    setOpenMarketingAffliliate(true);
  };
  const show_history_delegate = (value) => {
    setleadStatusData(value);
    setChangeDelegateOpen(true);
  };
  const show_goalstatemt_delegate = (value) => {
    setleadStatusData(value);
    setgoalStatemtnHistory(true);
  };
  const show_history_lead_status = (value) => {
    setleadStatusData(value);
    setChangeLeadstatus(true);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterData.search_text = searchText;
    getMembersList(filterData);
    if (done_member) {
      localStorage.setItem(
        "done_member_filter_data",
        JSON.stringify(filterData)
      );
    } else {
      localStorage.setItem("member_filter_data", JSON.stringify(filterData));
    }

    handleCloseFilterDrawer();
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    data.search_text = searchText;
    if (!data.date) {
      delete data.date;
      data.from_date = null;
      data.is_date_range = false;
      data.to_date = null;
    }
    if (!data.membership_expiry) {
      delete data.membership_expiry;
      data.membership_purchase_expiry_from = new Date();
      data.membership_purchase_expiry_to = new Date();
      data.expiry_in = 3;
      data.member_ship_expiry = "";
    }
    getMembersList(data);
    if (done_member) {
      localStorage.setItem("done_member_filter_data", JSON.stringify(data));
    } else {
      localStorage.setItem("member_filter_data", JSON.stringify(data));
    }
    setFilterData(data);
    handleCloseFilterDrawer();
  };

  const handleClearFilter = () => {
    setFilterData(EMPTY_FILTER);
    if (done_member) {
      localStorage.removeItem("done_member_filter_data");
    } else {
      localStorage.removeItem("member_filter_data");
    }
    getMembersList(EMPTY_FILTER);
    setFilterDrawerState(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAgreeDelete = (value) => {
    setSelectedObject(value);
    setOpenDelete(true);
  };

  const handleAgreeMultiDelete = () => {
    setOpenMultiDelete(true);
  };

  const handleAgreeResetAssessment = (value) => {
    setSelectedObject(value);
    setOpenResetAssessment(true);
  };
  const handleChangeAffiliateOpen = (value) => {
    setSelectedObject(value);
    setChangeAffiliateOpen(true);
  };
  const handleChangePasswordOpen = (value) => {
    setSelectedObject(value);
    setChangePasswordOpen(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeleteMemberApi(selectedObject._id);
    if (result.code === 200) {
      setMembersData((prev) => {
        return prev.filter((data) => data._id !== selectedObject._id);
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleMultipleDelete = async () => {
    setOpenMultiDelete(false);
    const member_ids = [];
    selected.map((member) => {
      member_ids.push(member._id);
    });
    const formData = new FormData();
    formData.append("type", "specific");
    formData.append("member", JSON.stringify(member_ids));
    const result = await DeleteMultiMemberApi(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setSelected([]);
      setMembersData((prev) => {
        return prev.filter((data) => !member_ids.includes(data._id));
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleResetAssement = async () => {
    setOpenResetAssessment(false);
    const result = await reset_assement_api(selectedObject._id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  const LoginAsMember = async (value) => {
    setIsLoading(true);
    const data = {
      email: value.email,
      user_type: "client",
    };
    const result = await member_login_api(data);
    if (result.code === 200) {
      window.open(`${clientPortalUrl}login-by-admin/${result.token}`);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeRoute = (route, data) => {
    delete data.MENU_OPTIONS;
    navigate(route, { state: data });
  };

  const handleNavigateImportMember = () => {
    navigate(`/member/import-member`);
  };

  const handleNavigate = () => {
    navigate(`${listing_name}add-member`);
  };

  const handleMemberSampleFile = () => {
    let search_keyword = localStorage.getItem("member_import_file");
    window.open(search_keyword ? s3baseUrl + search_keyword : "", "_self");
  };

  const handleCopyReferralId = async (e) => {
    await navigator.clipboard.writeText(e.affiliate_url_name);
    enqueueSnackbar("Referral Id Copied to clipboard", { variant: "success" });
  };

  const handleEdit = (value) => {
    let route = `${listing_name}edit-member/${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleChangeEvents = (value) => {
    let route = `${listing_name}events/${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleChangePods = (value) => {
    let route = `${listing_name}pods/${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleQuestionsAnswers = (value) => {
    let route = `${listing_name}questions-answers?member_id=${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleChangeRewardAccess = (value) => {
    let route = `${listing_name}reward-access/${value._id}`;
    handleChangeRoute(route, value);
  };

  const ManageNavItemsAccess = (value) => {
    let route = `${listing_name}manage-navitems-access/${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleDiary = (value) => {
    let route = `${listing_name}member-diary/${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleChangeAccess = (value) => {
    let route = `${listing_name}programme-access/${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleBonusContentAccess = (value) => {
    let route = `${listing_name}bonus-content-access/${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleEventAccess = (value) => {
    let route = `${listing_name}event-access/${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleChangeLevelAccess = (value) => {
    let route = `${listing_name}level-access/${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleMemberSubscribers = (value) => {
    let route = `${listing_name}member-subscribers/${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleChangeAllTransaction = (value) => {
    let route = `${listing_name}all-transaction/${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleMemberHistory = (value) => {
    let route = `${listing_name}member-history/${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleChangeBilling = (value) => {
    let route = `${listing_name}view-billing-list/${value._id}`;
    handleChangeRoute(route, value);
  };

  const handleNotesNavigate = (value) => {
    navigate(`${listing_name}member-personal-notes/${value._id}`);
  };

  const handleStripeSubscriptions = (value) => {
    let route = `${listing_name}stripe-subscriptions`;
    handleChangeRoute(route, value);
  };
  const handleStripeTransactions = (value) => {
    let route = `${listing_name}stripe-transactions`;
    handleChangeRoute(route, value);
  };

  const handleUpdateStatus = (value) => {
    let index = membersData.findIndex((user) => user._id == value._id);
    let temp_state = [...membersData];
    let temp_element = { ...temp_state[index] };
    temp_element.lead_status = value.lead_status;
    temp_element.lead_status_title = value.lead_status.title;
    temp_state[index] = temp_element;
    setMembersData(temp_state);
  };

  const handleProfile = (value) => {
    let route = `${listing_name}member-profile/${value._id}`;
    handleChangeRoute(route, value);
  };

  // const handleProfileNew = (value) => {
  //   let route = `${listing_name}member-profilenew/${value._id}`;
  //   handleChangeRoute(route, value);
  // };

  const handleExportClick = () => {
    download_csv_file(headers, membersData);
  };

  let MENU_OPTIONS_MAIN = [
    // {
    //   label: "Export Csv",
    //   icon: "ic:round-cloud-download",
    //   handleClick: handleExportClick,
    // },
    {
      label: "Request For Csv",
      icon: "ic:round-cloud-download",
      handleClick: showPopUPcsv,
    },
    {
      label: "Member Sample File",
      icon: "ic:round-cloud-download",
      handleClick: handleMemberSampleFile,
    },
    {
      label: "Import Member",
      icon: "basil:upload-solid",
      handleClick: handleNavigateImportMember,
    },
  ];

  const handleMenu = (row) => {
    const MENU_OPTIONS = [
      {
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleEdit,
      },
      {
        label: "Login As Member",
        icon: "ant-design:lock",
        handleClick: LoginAsMember,
      },
      {
        label: "Manage Programme Access",
        icon: "ant-design:key",
        handleClick: handleChangeAccess,
      },
      {
        label: "Manage Bonus Content Access",
        icon: "ant-design:key",
        handleClick: handleBonusContentAccess,
      },
      {
        label: "Manage Dynamite Event Access",
        icon: "ant-design:key",
        handleClick: handleEventAccess,
      },
      {
        label: "Manage Level Access",
        icon: "ant-design:key",
        handleClick: handleChangeLevelAccess,
      },
      {
        label: "Manage Reward Access",
        icon: "streamline:shopping-gift-reward-box-social-present-gift-media-rating-bow",
        handleClick: handleChangeRewardAccess,
      },
      {
        label: "Manage Nav Items Access",
        icon: "fluent:video-recording-20-regular",
        handleClick: ManageNavItemsAccess,
      },
      {
        label: "View Profile",
        icon: "iconoir:profile-circled",
        handleClick: handleProfile,
      },
      // {
      //   label: "View Profile New",
      //   icon: "iconoir:profile-circled",
      //   handleClick: handleProfileNew,
      // },
      {
        label: "View Diary",
        icon: "eva:eye-fill",
        handleClick: handleDiary,
      },
      {
        label: "View Events",
        icon: "fluent:video-recording-20-regular",
        handleClick: handleChangeEvents,
      },
      {
        label: "View Pods",
        icon: "fluent:video-recording-20-regular",
        handleClick: handleChangePods,
      },
      {
        label: "View Subscriptions",
        icon: "eva:eye-fill",
        handleClick: handleMemberSubscribers,
      },
      {
        label: "All Transactions",
        icon: "icon-park-outline:transaction-order",
        handleClick: handleChangeAllTransaction,
      },
      {
        label: "View Billings",
        icon: "icon-park-outline:transaction-order",
        handleClick: handleChangeBilling,
      },
      {
        label: "Client Notes",
        icon: "icon-park-outline:transaction-order",
        handleClick: handleNotesNavigate,
      },
      {
        label: "Change Lead Status",
        icon: "akar-icons:edit",
        handleClick: change_lead_status,
      },
      {
        label: "Change Password",
        icon: "ant-design:lock",
        handleClick: handleChangePasswordOpen,
      },
      {
        label: "Questions Answers",
        icon: "ant-design:lock",
        handleClick: handleQuestionsAnswers,
      },
      {
        label: "Change Affiliate ID",
        icon: "ant-design:lock",
        handleClick: handleChangeAffiliateOpen,
      },
      {
        label: "Member History",
        icon: "eva:eye-fill",
        handleClick: handleMemberHistory,
      },
      {
        label: "Reset Attitude Assessment",
        icon: "ant-design:lock",
        handleClick: handleAgreeResetAssessment,
      },
      {
        label: "Stripe Subscriptions",
        icon: "ant-design:lock",
        handleClick: handleStripeSubscriptions,
      },
      {
        label: "Stripe Transactions",
        icon: "ant-design:lock",
        handleClick: handleStripeTransactions,
      },
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      },
    ];
    if (row.affiliate_url_name) {
      MENU_OPTIONS.push({
        label: "Copy Referral ID",
        icon: "eva:eye-fill",
        handleClick: handleCopyReferralId,
      });
    }
    return MENU_OPTIONS;
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "user_coins",
      label: "Coins",
      alignRight: false,
    },
    {
      id: "is_wheel_of_life",
      label: "Wheel Of Life",
      renderData: (row) => {
        return (
          <>
            {row.is_wheel_of_life && (
              <span className="me-2">
                <Tooltip title={"Wheel of life is completed"} arrow>
                  <img
                    src={wheelIcon}
                    style={{ maxWidth: "20%", display: "inline-block" }}
                  />
                </Tooltip>
              </span>
            )}
          </>
        );
      },
      alignRight: false,
    },
    {
      id: "downloaded_app",
      label: "Downloaded App",

      renderData: (row) => {
        return (
          <span>
            <Tooltip title={row.downloaded_app ? "Downloaded" : "Not Yet !"}>
              <span>
                {row.downloaded_app ? (
                  <img
                    src={downloadIcon}
                    alt="download"
                    className="download-icon"
                  />
                ) : (
                  <img
                    src={notDownloadIcon}
                    alt="not-download"
                    className="download-icon"
                  />
                )}
              </span>
            </Tooltip>
          </span>
        );
      },
    },
    {
      id: "table_avatar",
      label: "Image",
      alignRight: false,
      type: "thumbnail",
    },

    {
      id: "name",
      label: "Name",
      alignRight: false,
      renderData: (row) => {
        let country;
        if (row.country) {
          country = row.country.toLowerCase();
        }
        // let countryName = countries.find((item) => item.code == row.country);

        return (
          <>
            <div
              onClick={() => handleProfile(row)}
              className="d-flex"
              style={{ alignItems: "center" }}
            >
              {/* <span>
                {row.downloaded_app ? (
                  <DownloadDoneIcon />
                ) : (
                  <FileDownloadOffIcon />
                )}
              </span> */}
              <span>{row.name}</span>
              {country && (
                <img
                  className="flag"
                  src={`https://flagpedia.net/data/flags/mini/${country}.png`}
                />
              )}
            </div>
          </>
        );
      },
      className: "pointer",
    },
    { id: "email", label: "Email", alignRight: false },
    { id: "phone", label: "Phone Number", alignRight: false },
    {
      id: "affiliate_name",
      label: "Referred User",
      renderData: (row) => {
        return (
          <p className="mb-0">
            <span
              className="me-2"
              style={{ cursor: "pointer" }}
              onClick={() => show_history_reffered(row)}
            >
              <Tooltip title="View Referred History">
                <HistoryIcon />
              </Tooltip>
            </span>
            {row.affiliate_name ? row.affiliate_name : "N/A"}
          </p>
        );
      },
      alignRight: false,
    },
    {
      id: "market_affiliate_name",
      label: "Marketing Referred User",
      renderData: (row) => {
        return (
          <p className="mb-0">
            <span
              className="me-2"
              style={{ cursor: "pointer" }}
              onClick={() => show_marketing_history_reffered(row)}
            >
              <Tooltip title="View Referred History">
                <HistoryIcon />
              </Tooltip>
            </span>
            {row.market_affiliate_name ? row.market_affiliate_name : "N/A"}
          </p>
        );
      },
      alignRight: false,
    },
    {
      id: "nurture",
      label: "Nurture",
      renderData: (row) => {
        return (
          <p className="mb-0">
            <span
              className="me-2"
              style={{ cursor: "pointer" }}
              onClick={() => show_history(row)}
            >
              <Tooltip title="View Nurture History">
                <HistoryIcon />
              </Tooltip>
            </span>
            {row.nurture ? row.nurture : "N/A"}
          </p>
        );
      },
      alignRight: false,
    },
    {
      id: "delegate_name",
      label: "Delegate",
      renderData: (row) => {
        return (
          <p className="mb-0">
            <span
              className="me-2"
              style={{ cursor: "pointer" }}
              onClick={() => show_history_delegate(row)}
            >
              <Tooltip title="View Delegate History">
                <HistoryIcon />
              </Tooltip>
            </span>
            {row.delegate_name ? row.delegate_name : "N/A"}
          </p>
        );
      },
      alignRight: false,
    },
    {
      id: "total_commission",
      label: "Total Commission",
      alignRight: false,
      className: "text-capitalize",
    },
    {
      id: "commission_paid",
      label: "Paid Commission ",
      alignRight: false,
      className: "text-capitalize",
    },
    {
      id: "commission_due",
      label: "Due Commission ",
      alignRight: false,
      className: "text-capitalize",
    },
    {
      id: "program",
      label: "Programmes",
      renderData: (row) => {
        const firstFiveprograms = row.program?.slice(0, 5);
        return firstFiveprograms && firstFiveprograms.length > 0 ? (
          firstFiveprograms?.map((program, i) => {
            return (
              <>
                <Tooltip
                  key={i}
                  title={
                    program.expiry_date ? (
                      <div>
                        Start Date:{" "}
                        {dd_date_format(program?.purchase_date_time)}
                        <br />
                        Expiry: {dd_date_format(program?.expiry_date)}
                        <br />
                        Status:{" "}
                        {program?._id?.status === true ? "Active" : "InActive"}
                      </div>
                    ) : (
                      <div>
                        Start Date:{" "}
                        {dd_date_format(program?.purchase_date_time)}
                        <br />
                        No Expiry
                        <br />
                        Status:{" "}
                        {program?._id?.status === true ? "Active" : "InActive"}
                      </div>
                    )
                  }
                  arrow
                >
                  <p
                    className="mb-1 program-hover"
                    onClick={() => handleChangeProgramDetail(program)}
                  >
                    {program._id.title}
                  </p>
                </Tooltip>
                {i == 4 && (
                  <p
                    className="mb-1 mt-1 view-more"
                    aria-describedby={id}
                    variant="contained"
                    onClick={(e) => handleClickPopUP(e, row)}
                  >
                    view more
                  </p>
                )}
              </>
            );
          })
        ) : (
          <p>N/A</p>
        );
      },
      alignRight: false,
    },
    {
      label: "Community Level",
      alignRight: false,
      className: "text-capitalize",
      renderData: (row) => {
        let find_level = COMMUNITY_LEVELS.find(
          (level) => level.name == row.community_level
        );
        return find_level?.title ? find_level?.title : "N/A";
      },
    },

    {
      id: "wheel_of_life_enable",
      label: "Wheel Of Life Enable",
      alignRight: false,
      className: "text-capitalize",
    },
    {
      id: "diary_coins_count",
      label: "Daily Intention Coins",
      alignRight: false,
    },
    {
      id: "gratitude_coins",
      label: "Gratitude Coins",
      alignRight: false,
    },
    {
      id: "assessment_coins_count",
      label: "Assessment Coins",
      alignRight: false,
    },
    {
      id: "meditation_coins",
      label: "Meditation Coins",
      alignRight: false,
    },
    {
      id: "goal_statement_text",
      label: "Goal Statement",
      renderData: (row) => {
        let goal_statement_text = "Incomplete";
        let goal_statement_status = row.goal_statement_completed_status;
        if (goal_statement_status && row.save_and_close_status) {
          goal_statement_text =
            "Responded" + " (" + dd_date_format(row.save_and_close_date) + ")";
        } else if (goal_statement_status && !row.save_and_close_status) {
          goal_statement_text =
            "Completed" +
            " (" +
            dd_date_format(row.goal_statement_completed_date) +
            ")";
        }
        return (
          <p className="mb-0">
            <span
              className="me-2"
              style={{ cursor: "pointer" }}
              onClick={() => show_goalstatemt_delegate(row)}
            >
              <Tooltip title="View Goal Statement History">
                <HistoryIcon />
              </Tooltip>
            </span>
            {goal_statement_text}
          </p>
        );
      },
    },

    {
      id: "created_at",
      label: "Created At",
      alignRight: false,
    },
    {
      id: "membership_expire",
      label: "Membership Expire",
      alignRight: false,
    },
    {
      id: "last_login_activity",
      label: "Last Login Activity",
      alignRight: false,
    },
    {
      id: "replace_user_type",
      label: "Created By Platform",
      alignRight: false,
      className: "text-capitalize",
    },
    {
      id: "leadtitle",
      label: "Lead Satus",
      renderData: (row) => {
        let lead_status = row.lead_status;
        return (
          <p className="mb-0">
            {lead_status ? (
              <>
                <span
                  className="me-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => show_history_lead_status(row)}
                >
                  <Tooltip title="View Lead Status History">
                    <HistoryIcon />
                  </Tooltip>
                </span>

                <Chip
                  label={lead_status.title}
                  style={{
                    backgroundColor: lead_status.background_color,
                    color: lead_status.text_color,
                    height: "25px",
                  }}
                />
              </>
            ) : (
              ""
            )}
          </p>
        );
      },
      alignRight: false,
    },
    { id: "status", label: "Status", alignRight: false, type: "row_status" },
    {
      label: "Goal",
      renderData: (row) => {
        return (
          <Chip
            width="180px"
            label={row.goal_statement_status === true ? "Unlocked" : "Locked"}
            variant="contained"
            className={
              row.goal_statement_status === true
                ? `manage-program-chip-success`
                : ""
            }
            color={row.goal_statement_status === true ? "success" : "error"}
            size="small"
          />
        );
      },
      alignRight: false,
    },
    {
      id: "member_user_type",
      label: "User Type",
      alignRight: false,
      className: "text-capitalize",
    },
    { id: "register_url", label: "Registration Url", alignRight: false },
  ];

  if (!done_member) {
    TABLE_HEAD.splice(0, 0, {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    });
  }

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let filter_name = "member";
    if (done_member) {
      filter_name = "done_member";
    }
    let find_filter = localStorage.getItem(`${filter_name}_filter_data`);

    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterData(filter_data);
    setFilterStateUpdated(filter_data);
    getMembersList(filter_data);
  }, [rowsPerPage, page, done_member]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <h2>{done_member ? "Done Members" : "Members"}</h2>
          </div>

          <div className="col-lg-8 col-sm-12 text-end d-flex justify-content-end">
            <div>
              <button
                className="small-contained-button me-2 mt-1 mb-4"
                onClick={handleOpenFilterDrawer}
              >
                Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
              </button>
              <button
                className="small-contained-button me-2"
                onClick={handleNavigate}
              >
                Add Member
              </button>
            </div>
            <CustomPopoverSection menu={MENU_OPTIONS_MAIN} />
          </div>
          <FilteredChip
            data={filterStateUpdated}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
        </div>
        {statsArray.length > 0 && (
          <div>
            <MembersListStats
              statsArray={statsArray}
              ViewAllMembers={ViewAllMembers}
            />
          </div>
        )}
        <div className="row">
          <div className="col-12 mt-3">
            {selected.length > 0 && (
              <div class="text-start">
                <button
                  class="small-contained-button"
                  onClick={handleAgreeMultiDelete}
                >
                  Delete
                </button>
              </div>
            )}
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              data={membersData}
              selected={selected}
              setSelected={setSelected}
              checkbox_selection={true}
              className="card-with-background"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,
                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              custom_search={{
                searchText: searchText,
                setSearchText: setSearchText,
                handleSubmit: searchFunction,
              }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
              pagePagination={true}
            />
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <MemberFilter
            filterData={filterData}
            handleChange={handleChange}
            setFilterData={setFilterData}
            handleIsDateRange={handleIsDateRange}
            handleChangeOthers={handleChangeOthers}
            handleIsCountry={handleIsCountry}
            searchSubmitFilter={searchFunction}
            handleClearFilter={handleClearFilter}
          />
        }
      />
      <GeneralPopUpModel
        open={changeAffiliateOpen}
        setOpen={setChangeAffiliateOpen}
        title={"Change Affiliate ID"}
        componentToPassDown={
          <ChangeAffiliateID
            selectedObject={selectedObject}
            setMembersData={setMembersData}
            membersData={membersData}
            setOpen={setChangeAffiliateOpen}
          />
        }
      />
      <GeneralPopUpModel
        open={changePasswordOpen}
        setOpen={setChangePasswordOpen}
        title={"Change Password"}
        componentToPassDown={
          <ChangePassword
            selectedObject={selectedObject}
            setOpen={setChangePasswordOpen}
          />
        }
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this member?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openMultiDelete}
        setOpen={setOpenMultiDelete}
        title={"Are you sure you want to delete these members?"}
        handleAgree={handleMultipleDelete}
      />
      <CustomConfirmation
        open={openResetAssessment}
        setOpen={setOpenResetAssessment}
        title={"Are you sure you want to reset Assessment History?"}
        handleAgree={handleResetAssement}
      />
      <GeneralPopUpModel
        open={changeStatus}
        setOpen={setChangeStatus}
        title={"Change Lead Status"}
        componentToPassDown={
          <ChangeLeadStatus
            selectedObject={selectedMember}
            handleUpdateStatus={handleUpdateStatus}
            setOpen={setChangeStatus}
          />
        }
      />
      <WhatsappPopUpModel
        open={changeStatusOpen}
        setOpen={setChangeStatusOpen}
        title={"Nurture History"}
        show_date_and_income={true}
        componentToPassDown={
          <History
            setOpen={setChangeStatusOpen}
            history={history}
            type="nurture"
            leadStatusData={leadStatusData}
          />
        }
      />
      <WhatsappPopUpModel
        open={ChangeDelegateOpen}
        setOpen={setChangeDelegateOpen}
        title={"Delegate History"}
        show_date_and_income={true}
        componentToPassDown={
          <History
            setOpen={setChangeDelegateOpen}
            history={history}
            type="consultant"
            leadStatusData={leadStatusData}
          />
        }
      />
      <WhatsappPopUpModel
        open={openMarketingAffliliate}
        setOpen={setOpenMarketingAffliliate}
        title={"Marketing Affliliate History"}
        show_date_and_income={true}
        componentToPassDown={
          <History
            setOpen={setOpenMarketingAffliliate}
            history={history}
            type="marketing_affliliate"
            leadStatusData={leadStatusData}
          />
        }
      />
      <WhatsappPopUpModel
        open={goalStatemtnHistory}
        setOpen={setgoalStatemtnHistory}
        title={"Goal Statement History"}
        show_date_and_income={true}
        componentToPassDown={
          <HistoryGoalStatus
            setOpen={setgoalStatemtnHistory}
            history={history}
            type="goal_statement"
            leadStatusData={leadStatusData}
          />
        }
      />
      <WhatsappPopUpModel
        open={referredpop}
        setOpen={setReferredpop}
        title={"Referred History"}
        show_date_and_income={true}
        componentToPassDown={
          <History
            setOpen={setReferredpop}
            history={history}
            type="affiliate"
            leadStatusData={leadStatusData}
          />
        }
      />
      <WhatsappPopUpModel
        open={ChangeLeadstatus}
        setOpen={setChangeLeadstatus}
        title={"Lead Status History"}
        show_date_and_income={true}
        componentToPassDown={
          <LeadStatusHistory
            setOpen={setChangeLeadstatus}
            history={history}
            leadStatusData={leadStatusData}
          />
        }
      />
      <MemberProgramPopover
        handleClick={handleClickPopUP}
        handleClose={handleClosePopUp}
        anchorEl={anchorEl}
        id={id}
        open={open}
        program={program}
      />
      <GeneralPopUpModel
        open={showExportcsvFile}
        setOpen={setShowExportcsvFile}
        title="Export CSV file request"
        componentToPassDown={
          <GeneralRequestForCsv
            options_array={member_options}
            collection_name={"members"}
            filter_data={filterQuery}
            setShowExportcsvFile={setShowExportcsvFile}
          />
        }
      />
    </>
  );
}
