import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Box,
  CircularProgress,
  TextareaAutosize,
} from "@mui/material";
import { addInvoiceSettingApi } from "src/DAL/SiteSetting/siteSetting";
import { s3baseUrl } from "src/config/config";
import { useEffect } from "react";
import { clientSettingApi } from "src/DAL/ClientSetting/ClientSetting";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import { countries } from "src/utils/country";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function InvoiceSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = useState();
  const [invoiceImage, setInvoiceImage] = useState();
  const [oldInvoiceImage, setOldInvoiceImage] = useState("");
  const [oldLogo, setOldLogo] = useState("");
  const init_invoice_country = {
    code: "IE",
    label: "Ireland",
    phone: "353",
  };

  const [inputs, setInputs] = useState({
    logo: {},
    InvoiceImage: {},
    dynamite_digital_address: "",
    dynamite_digital_email: "",
    dynamite_lifestyle_address: "",
    dynamite_lifestyle_email: "",
    invoice_from: "",
    payment_info: "",
    pay_to: "",
    pay_to_address: "",
    pay_to_city: "",
    pay_to_state: "",
    pay_to_country: init_invoice_country,
  });

  const getDefaultSetting = async () => {
    setIsLoading(true);
    const result = await clientSettingApi();
    if (result.code === 200) {
      let settings = result.content_setting?.invoice_setting;
      if (settings.pay_to_country) {
        settings.pay_to_country = countries.find(
          (country) => country.code == settings.pay_to_country
        );
      }
      setInputs(result.content_setting?.invoice_setting);
      setOldInvoiceImage(result.content_setting.invoice_setting?.invoice_image);
      setOldLogo(result.content_setting.invoice_setting?.invoice_logo);
      setIsLoading(false);
    }
  };

  const fileChangedHandler = async (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", "600");
    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        ["logo"]: imageUpload.image_path,
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const fileChangedHandlerFB = async (e) => {
    setInvoiceImage(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", "600");

    const imageUpload = await uploadImageOns3(formData);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        ["InvoiceImage"]: imageUpload.image_path,
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let postData = {
      invoice_logo: inputs.logo,
      invoice_from: inputs.invoice_from,
      dynamite_lifestyle_email: inputs.dynamite_lifestyle_email,
      dynamite_digital_email: inputs.dynamite_digital_email,
      dynamite_lifestyle_address: inputs.dynamite_lifestyle_address,
      dynamite_digital_address: inputs.dynamite_digital_address,
      payment_info: inputs.payment_info,
      pay_to: inputs.pay_to,
      pay_to_address: inputs.pay_to_address,
      pay_to_city: inputs.pay_to_city,
      pay_to_state: inputs.pay_to_state,
      pay_to_country: inputs.pay_to_country?.code ?? "",
    };

    if (invoiceImage) {
      postData.invoice_image = inputs.InvoiceImage;
    } else {
      postData.invoice_image = oldInvoiceImage;
    }

    if (file) {
      postData.invoice_logo = inputs.logo;
    } else {
      postData.invoice_logo = oldLogo;
    }
    let formData = {
      invoice_setting: postData,
    };
    const result = await addInvoiceSettingApi(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    getDefaultSetting();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6">
          <h2>Invoice Setting</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Invoice From"
              variant="outlined"
              fullWidth
              name="invoice_from"
              required
              value={inputs.invoice_from}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Dynamite Lifestyle Email"
              variant="outlined"
              fullWidth
              name="dynamite_lifestyle_email"
              required
              value={inputs.dynamite_lifestyle_email}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Dynamite Digital Email"
              variant="outlined"
              fullWidth
              name="dynamite_digital_email"
              required
              value={inputs.dynamite_digital_email}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Pay to Name"
              variant="outlined"
              fullWidth
              name="pay_to"
              required
              value={inputs.pay_to}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Pay to Address"
              variant="outlined"
              fullWidth
              name="pay_to_address"
              value={inputs.pay_to_address}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="City"
              variant="outlined"
              fullWidth
              name="pay_to_city"
              value={inputs.pay_to_city}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="State"
              variant="outlined"
              fullWidth
              name="pay_to_state"
              value={inputs.pay_to_state}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <Autocomplete
              id="country-select-demo"
              options={countries}
              autoHighlight
              value={inputs.pay_to_country}
              onChange={(event, newValue) => {
                handleChangeOthers("pay_to_country", newValue);
              }}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.label} ({option.code}) +{option.phone}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a country"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </div>
          {/* <div className="col-12 mt-3">
            <p className="tracking_code_heading">Dynamite Digital Address</p>
            <TextareaAutosize
              aria-label="empty textarea"
              value={inputs.dynamite_digital_address}
              name="dynamite_digital_address"
              className="new-textarea-autosize"
              onChange={handleChange}
              placeholder="Dynamite Digital Address"
            />
            <h4>Dynamite Digital Address *</h4>
            <TinyEditor
              setDetailDescription={setDynamiteDigitalAddress}
              detailDescriptionCk={dynamiteDigitalAddress}
              editorHeight={300}
            />
          </div>
          <div className="col-12 mt-3">
            <p className="tracking_code_heading">Dynamite Lifestyle Address</p>
            <TextareaAutosize
              aria-label="empty textarea"
              value={inputs.dynamite_lifestyle_address}
              name="dynamite_lifestyle_address"
              className="new-textarea-autosize"
              onChange={handleChange}
              placeholder="Dynamite Lifestyle Address"
            />
            <h4>Dynamite Lifestyle Address *</h4>
            <TinyEditor
              setDetailDescription={setDynamiteLifestyleAddress}
              detailDescriptionCk={dynamiteLifestyleAddress}
              editorHeight={300}
            />
          </div> */}
          <div className="col-12 mt-3">
            <p className="tracking_code_heading">Payment Info</p>
            <TextareaAutosize
              aria-label="empty textarea"
              value={inputs.payment_info}
              name="payment_info"
              className="new-textarea-autosize"
              onChange={handleChange}
              placeholder="Payment Info"
            />
            {/* <h4>Payment Info *</h4>
            <TinyEditor
              setDetailDescription={setPaymentInfo}
              detailDescriptionCk={paymentInfo}
              editorHeight={300}
            /> */}
          </div>
          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Dynamite Lifestyle Address"
            variant="outlined"
            fullWidth
            name="dynamite_lifestyle_address"
            required
            value={inputs.dynamite_lifestyle_address}
            onChange={handleChange}
          />
        </div> */}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Invoice Logo</p>
                <FormHelperText className="pt-0">
                  (Recommended Size 250 X 100)
                </FormHelperText>
              </div>
              <div className="col-2">
                {file ? (
                  <img src={file} height="50" />
                ) : (
                  oldLogo && <img src={s3baseUrl + oldLogo} height="50" />
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.logo?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.logo?.name}</p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p className="">Invoice Image</p>
                <FormHelperText className="pt-0">
                  (Recommended Size 1000 X 250)
                </FormHelperText>
              </div>
              <div className="col-2">
                {invoiceImage ? (
                  <img src={invoiceImage} height="50" />
                ) : (
                  oldInvoiceImage && (
                    <img src={s3baseUrl + oldInvoiceImage} height="50" />
                  )
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-fileFb">
                  <Input
                    accept="image/*"
                    id="contained-button-fileFb"
                    multiple
                    type="file"
                    name="InvoiceImage"
                    onChange={fileChangedHandlerFB}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.logo?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.logo?.name}</p>
            )}
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button" id="fixedbutton">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
