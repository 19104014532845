import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import moment from "moment";
import { replace_created_for } from "src/utils/constant";
import { useNavigate, useParams } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Navitems({ tabData }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [filterType, setFilterType] = useState("unlocked");
  const params = useParams();
  const classes = useStyles();
  const [answersData, setAnswersData] = useState([
    {
      title: "Welcome",
      status: true,
      access_type: "Free Membership",
      programs: [
        "The Path to Achievement",
        "Welcome Getting Started",
        "Phase 4 - $ales $uccess",
      ],
    },
    {
      title: "Dashboard",
      status: false,
      access_type: [],
    },
    {
      title: "Stats",
      status: true,
      access_type: "Dynamite (6000)",
      programs: ["Welcome Getting Started", "Thinking Into Results"],
    },
    {
      title: "Programmes",
      status: true,
      access_type: "Free Membership",
      programs: [
        "The Path to Achievement",
        "Welcome Getting Started",
        "Thinking Into Results",
      ],
    },
    {
      title: "Taxes",
      status: false,
      access_type: [],
    },
    {
      title: "Members",
      status: true,
      access_type: "Elite (10000)",
      programs: ["The Path to Achievement", "Thinking Into Results"],
    },
    {
      title: "Done Members",
      status: false,
      access_type: [],
    },
    {
      title: "Delegates",
      status: true,
      access_type: "Mastery (190000)",
      programs: [
        "The Path to Achievement",
        "Welcome Getting Started",
        "Thinking Into Results",
        "Phase 4 - $ales $uccess",
      ],
    },
  ]);

  const TABLE_HEAD = [
    { id: "number", label: "#", alignRight: false, type: "number" },
    {
      id: "title",
      label: "Menu Item",
      alignRight: false,
      renderData: (row) => {
        const iconStyle = {
          color: "blue !important",
        };
        return (
          <div className="d-flex pointer">
            <CircleIcon
              style={iconStyle}
              className={`transation-status-icon ${
                row.status ? "succeeded_bg" : "inactive_bg_program"
              } `}
            />
            <div>
              <span>{row.title} </span>
            </div>
          </div>
        );
      },
    },
    {
      id: "access_type",
      label: "Accessed By",
      calignRight: false,
      renderData: (row) => {
        return (
          <>
            {row.programs?.length > 0 ? (
              <>
                <div className="d-flex members-navitems">
                  <h6>Coins Configuration : &nbsp;</h6>
                  <span className="me-2">{row.access_type}</span>
                </div>
                <div className="d-flex members-navitems">
                  <h6>Programmes : &nbsp;</h6>
                  {row.programs.map((item) => {
                    return (
                      <>
                        <span className="me-2">{item + ","}</span>
                      </>
                    );
                  })}
                </div>
              </>
            ) : (
              "N/A"
            )}
          </>
        );
      },
    },
  ];

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="row">
      <div className="col-12 col-md-4 mt-3 ms-auto">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Nav items type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="expired"
            required
            value={filterType}
            label="Nav items type"
            onChange={(e) => {
              setFilterType(e.target.value);
            }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="unlocked">Unlocked</MenuItem>
            <MenuItem value="locked">Locked</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="col-12 mt-3">
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={
            filterType == "all"
              ? answersData
              : filterType == "unlocked"
              ? answersData.filter((item) => item.programs?.length > 0)
              : answersData.filter(
                  (item) => !item.programs || item.programs?.length == 0
                )
          }
          className="card-with-background"
        />
      </div>
    </div>
  );
}
