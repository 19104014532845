import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";

export default function RemoveOrLocked(props) {
  const { item, handleChange, heading } = props;
  const [isField, setIsField] = useState(false);

  return (
    <div>
      {isField ? (
        <FormControl size="small">
          <InputLabel id="demo-simple-select-label">
            Remove or Locked
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Remove or Locked"
            name="is_locked"
            autoFocus
            open={isField}
            onClose={() => setIsField(false)}
            value={item?.is_locked}
            onChange={handleChange}
          >
            <MenuItem value={false}>Remove</MenuItem>
            <MenuItem value={true}>Locked</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <Tooltip
          title={
            <span
              dangerouslySetInnerHTML={{
                __html: `<div class="p-2">
                <p>From this dropdown menu, you'll manage the display settings for a specific nav item for ${heading}.</p>
                <strong>Lock Option:</strong>
                <p>If the "Lock" option is selected, the nav item will appear as locked on the ${heading} for members who do not have access to it.</p>
                <strong>Remove Option:</strong>
                <p>If the "Remove" option is selected, the nav item will be completely removed from the ${heading} for members who do not have access to it.</p>
                </div>`,
              }}
            />
          }
        >
          <div
            className="static-textfield cursor-pointer"
            onClick={() => setIsField(true)}
          >
            <div className="static-textfield-label px-1">Remove or Locked</div>
            <div className="field-value">
              {item?.is_locked ? "Locked" : "Remove"}
            </div>
            <div className="dropdown-arrow">
              <ArrowDropDownIcon />
            </div>
          </div>
        </Tooltip>
      )}
    </div>
  );
}
