import { invokeApi } from "../../bl_libs/invokeApi";

export const pending_commission_list = async (data, page, rowsPerPage) => {
  const requestObj = {
    path: `/admin_users/user_type_and_commission_list?limit=${rowsPerPage}&page=${page}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const pending_commission_list_detail = async (
  _id,
  data,
  page,
  rowsPerPage
) => {
  const requestObj = {
    path: `/app/transaction_list_with_filter/search/${_id}?page=${page}&limit=${rowsPerPage}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
