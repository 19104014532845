// const DynamiteDiarySetting = () => {
//   return <>dynamite</>;
// };

// export default DynamiteDiarySetting;
import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  clientSettingApi,
  UpdateDynamiteDiarySetting,
  UpdateMeditationChallengeSetting,
  UpdateMeditationSetting,
  UpdateShopContentSetting,
  updateWheelOfLifeSettingApi,
} from "src/DAL/ClientSetting/ClientSetting";
import { useEffect } from "react";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import {
  _get_attitude_accessment_setting,
  _get_store_house_setting,
} from "src/DAL/AttitudeAccessmentQuestions/AttitudeAccessmentSetting";
import { project_mode } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function StoreHouseContentSettings() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [inputs, setInputs] = React.useState({
    payment_mode: project_mode == "dev" ? "sandBox" : "live",
    shop_cart_heading: "",
    conutinue_shopping_button_text: "",
    clear_all_button_text: "",
    subtotal_text: "",
    tex_text: "",
    grand_total_text: "",
    proceed_to_checkout_button_text: "",
    check_out_heading: "",
    order_detail_heading: "",
    order_detail_subtotal_text: "",
    order_detail_grand_total_text: "",
    currency_conversion_text: "",
    payment_method_heading: "",
    bank_text: "",
    credit_card_text: "",
    pay_now_button_text: "",
    enter_card_details_heading: "",
    billing_details_heading: "",
    shopping_details_heading: "",
    same_as_billing_detail_text: "",
    send_to_friend_text: "",
    shop_thaks_page_content: "",
    thanks_page_button_text: "",
    custom_duty_text: "",
    calculate_custom_duty_button_text: "",
    custom_duty_result_text: "",
    save_in_profile_text: "",
    view_history_button_text: "",
  });

  const clientDetail = async () => {
    setIsLoading(true);
    const result = await _get_store_house_setting();
    if (result.code == 200) {
      if (
        result.storehouse_content_setting &&
        Object.keys(result.storehouse_content_setting).length > 0
      ) {
        setInputs(result.storehouse_content_setting);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);

    let postData = {
      storehouse_content_setting: inputs,
    };
    const result = await UpdateShopContentSetting(postData);
    if (result.code === 200) {
      setIsLoadingForm(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    clientDetail();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin">
        <div className="col-6">
          <h2>Store House Content Setting</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-6 mb-1 mt-3">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Payment Mode
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="payment_mode"
                required
                value={inputs.payment_mode}
                label="Section Status *"
                onChange={handleChange}
              >
                <MenuItem value="sandBox">SandBox</MenuItem>
                <MenuItem value="live">Live</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div class="col-12 wheel-of-life-setting-section-heading">
            <h3 className="mt-3">Shopping Cart List Setting</h3>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Shop Cart Heading"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="shop_cart_heading"
              value={inputs?.shop_cart_heading}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Continue Shopping Button Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="conutinue_shopping_button_text"
              value={inputs?.conutinue_shopping_button_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Clear All Button Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="clear_all_button_text"
              value={inputs?.clear_all_button_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Subtotal Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="subtotal_text"
              value={inputs?.subtotal_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Tax Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="tex_text"
              value={inputs?.tex_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Grand Total Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="grand_total_text"
              value={inputs?.grand_total_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Proceed To Checkout Button Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="proceed_to_checkout_button_text"
              value={inputs?.proceed_to_checkout_button_text}
              onChange={handleChange}
            />
          </div>
          <div class="col-12 wheel-of-life-setting-section-heading">
            <h3 className="mt-4">Check Out Content Setting</h3>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Check Out Heading"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="check_out_heading"
              value={inputs?.check_out_heading}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Order Detail Heading"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="order_detail_heading"
              value={inputs?.order_detail_heading}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Order Detail Subtotal Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="order_detail_subtotal_text"
              value={inputs?.order_detail_subtotal_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Order Detail Grand Total Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="order_detail_grand_total_text"
              value={inputs?.order_detail_grand_total_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Payment Method Heading"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="payment_method_heading"
              value={inputs?.payment_method_heading}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Bank Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="bank_text"
              value={inputs?.bank_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Credit Card Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="credit_card_text"
              value={inputs?.credit_card_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Pay Now Button Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="pay_now_button_text"
              value={inputs?.pay_now_button_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Enter Card Details Heading"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="enter_card_details_heading"
              value={inputs?.enter_card_details_heading}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Billing Details Heading"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="billing_details_heading"
              value={inputs?.billing_details_heading}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Shopping Details Heading"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="shopping_details_heading"
              value={inputs?.shopping_details_heading}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Same As Billing Detail Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="same_as_billing_detail_text"
              value={inputs?.same_as_billing_detail_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Send To Friend Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="send_to_friend_text"
              value={inputs?.send_to_friend_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Calculate Custom Duty Button Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="calculate_custom_duty_button_text"
              value={inputs?.calculate_custom_duty_button_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Custom Duty result Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="custom_duty_result_text"
              value={inputs?.custom_duty_result_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Save in Profile Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="save_in_profile_text"
              value={inputs?.save_in_profile_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-multiline-flexible"
              label="Custom Duty Text"
              multiline
              rows={6}
              name="custom_duty_text"
              value={inputs.custom_duty_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-multiline-flexible"
              label="Conversion Text"
              multiline
              rows={6}
              name="currency_conversion_text"
              value={inputs.currency_conversion_text}
              onChange={handleChange}
            />
          </div>
          <div class="col-12 wheel-of-life-setting-section-heading">
            <h3 className="mt-4"> Shop thanks Page Content Setting</h3>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Thanks page Button Text"
              variant="outlined"
              fullWidth
              required
              type="text"
              name="thanks_page_button_text"
              value={inputs?.thanks_page_button_text}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="View History Button Text"
              variant="outlined"
              fullWidth
              type="text"
              name="view_history_button_text"
              value={inputs?.view_history_button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="View History Button Text"
              variant="outlined"
              fullWidth
              type="text"
              name="view_history_button_text"
              value={inputs?.view_history_button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-4">
            <h4 className="mb-3">Shop Thanks Page Content</h4>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="shop_thaks_page_content"
              editorHeight={350}
            />
          </div>
          <div className="text-end mt-4" id="fixedbutton">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating" : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
