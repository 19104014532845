import { useEffect, useState } from "react";
import { CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomMUITable from "src/components/GeneralComponents/CustomMUITable/CustomMUITable";
import { convertCurrencyToSign, dd_date_format } from "src/utils/constant";
import { subscription_stripe_list } from "src/DAL/WebsitePages/WebsitePages";
import moment from "moment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import SubscriptionListFilter from "./SubscriptionListFilter";
import { Icon } from "@iconify/react";
import Label from "src/components/Label";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import CopyToClipboard from "react-copy-to-clipboard";
import SubscriptionDetail from "./SubscriptionDetail";

const useStyles = makeStyles(() => ({
  loadingSection: {
    marginLeft: "45%",
    marginTop: "20%",
    marginBottom: "20%",
    padding: 0,
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function StripeTransactionsList({ status, state }) {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [isLoadingSection, setIsLoadingSection] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [lastId, setLastId] = useState("");
  const [FirstId, setFirstId] = useState("");
  const [islastId, setIsLast] = useState(false); // for pagination [last id
  const [IsFirst, setIsFirst] = useState(false); // for pagination [last id

  const [showPop, setshowPop] = useState(false);
  const [getrecord, setGetrecord] = useState("");
  const [value, setValue] = useState({});

  const EMPTY_FILTER = {
    sale_page: null,
    payment_plan: null,
    expired: "active",
    expiry_in: "",
    start_date: new Date(),
    end_date: new Date(),
    filter: "",
    expiry_name: null,
    search_text: "",
  };

  let ALTER_FILTER = {
    expired: "",
  };

  const [filterState, setFilterState] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);
  const handleOpenPopup = (row) => {
    setValue(row);
    setshowPop(true);
  };
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const handleChangeOther = (name, value) => {
    setFilterState((values) => ({ ...values, [name]: value }));
  };

  const handleChangePage = (event, newPage) => {
    if (newPage <= 0) {
      if (IsFirst) {
        setGetrecord("previous");
        setPageCount(1);
        setPage(newPage);
      }
    } else {
      if (islastId) {
        setGetrecord("next");
        setPageCount(newPage + 1);
        setPage(newPage);
      }
    }
  };

  const getMembersList = async (filterData) => {
    setIsLoadingSection(true);
    let postData = {
      retrive_array: ["stripe_payments"],
      status: status,
    };

    let recordId = "";
    if (getrecord == "next") {
      recordId = lastId;
    } else if (getrecord == "previous") {
      recordId = FirstId;
    } else {
      recordId = "";
    }
    let searchTextData = searchText;
    if (state && state.email) {
      searchTextData = state.email;
      setSearchText(searchTextData);
    }
    const result = await subscription_stripe_list(
      recordId,
      rowsPerPage,
      searchTextData,
      postData,
      getrecord
    );
    if (result.code == 200) {
      let subscription_list = [];
      let array = [];
      let lastObject = {};
      let firstObject = {};
      let id = "";
      let firstId = "";
      if (result.stripe_payments.data) {
        array = result.stripe_payments.data;
        lastObject = array[array.length - 1];
      }
      if (array.length > 0) {
        firstObject = array[0];
      }
      if (lastObject?.id) {
        id = lastObject.id;
      }
      if (firstObject?.id) {
        firstId = firstObject.id;
      }
      if (firstId) {
        setFirstId(firstId);
      }
      if (id) {
        setLastId(id);
      }
      if (
        result.stripe_payments.data &&
        result.stripe_payments.data.length > 0
      ) {
        subscription_list = result.stripe_payments.data.map((event, index) => {
          let member_name = "N/A";
          if (event.customer.email) {
            member_name =
              event.customer.name + " (" + event.customer.email + ")";
          }
          let event_plan = event.plan;
          let plan_title = "N/A";
          if (event_plan?.plan_title) {
            plan_title = event_plan?.plan_title;
          }
          let payment_access = event_plan?.payment_access;
          if (plan_title != "N/A") {
            if (event_plan?.is_plan_free) {
              plan_title = plan_title + " (Free)";
            } else if (payment_access == "recursion") {
              plan_title = plan_title + " (Recurring)";
            } else if (payment_access == "onetime") {
              plan_title = plan_title + " (OneTime)";
            } else {
              plan_title = plan_title;
            }
          }

          let product = "N/A";
          if (event.product) {
            product = event.product.name;
          }
          return {
            ...event,
            product,
            member_name: member_name,
            created_at: moment(event.created * 1000).format("DD-MM-YYYY"),
            amount:
              convertCurrencyToSign(event.currency) +
              (event.amount / 100).toFixed(2),
          };
        });
      }

      setIsLast(result.has_more_next);
      setIsFirst(result.has_more_previous);
      setSubscriptionsData(subscription_list);
      setTotalCount(result.total_subscription_count);
      setTotalPages(result.total_pages);
      setIsLoadingSection(false);
    } else {
      enqueueSnackbar(result.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      setIsLoadingSection(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getMembersList();
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);

    if (data.sale_page == null) {
      data.payment_plan = null;
    }
    if (!data.expiry_name) {
      data.expiry_in = "";
      data.expired = "";
      data.start_date = new Date();
      data.end_date = new Date();
    }
    getMembersList(data);
    setFilterState(data);
    localStorage.setItem("subscription_list_data", JSON.stringify(data));
  };

  const handleClearFilter = () => {
    let clear_data = {
      ...EMPTY_FILTER,
      expired: "",
    };
    setPage(0);
    setPageCount(1);
    setFilterState(clear_data);
    getMembersList(clear_data);
    localStorage.removeItem("subscription_list_data");
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setGetrecord("");
    setFirstId("");
    SetLastId("");
    setPage(0);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      console.log("previous page");
      setPage(0);
      setPageCount(1);
    } else {
      console.log("next page");
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilterState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeDate = (name, value) => {
    setFilterState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const handleCopiedUrl = () => {
    enqueueSnackbar("Subscription Id Copied to clipboard", {
      variant: "success",
    });
  };
  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    ...(state?.email
      ? []
      : [
          {
            id: "member_name",
            label: "Member",
          },
        ]),
    { id: "amount", label: "Amount" },
    {
      id: "receipt_url",
      label: "Receipt URL",
      renderData: (row) => {
        return (
          <>
            {row.receipt_url ? (
              <a
                href={row.receipt_url}
                className="anchor-style tex-center"
                target="_blank"
                rel="noreferrer"
              >
                Preview
              </a>
            ) : (
              "N/A"
            )}
          </>
        );
      },
    },
    {
      id: "created_at",
      label: "Transaction Date",
    },
    {
      id: "statusDate",
      label: "Status",
      renderData: (row) => {
        let className = "pending-ticket";
        return (
          <Label
            variant="ghost"
            color={row.status === "succeeded" ? "success" : "error"}
          >
            {row.status === "succeeded" ? "Succeeded" : "Failed"}
          </Label>
        );
      },
    },
  ];

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("subscription_list_data");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search_text) {
        setSearchText(filter_data.search_text);
      }
    }
    setFilterState(filter_data);
    setFilterStateUpdated(filter_data);
    getMembersList(filter_data);
  }, [rowsPerPage, page]);
  const MENU_OPTIONS = [
    {
      label: "View Detail",
      icon: "iconoir:page-search",
      handleClick: handleOpenPopup,
    },
  ];

  if (isLoadingSection == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="">
        <div className=" mt-3">
          <div className="col-12">
            <CustomMUITable
              TABLE_HEAD={TABLE_HEAD}
              // MENU_OPTIONS={MENU_OPTIONS}
              data={subscriptionsData}
              rowArray={[20]}
              is_hide={state?.email ? true : false}
              className="card-with-background hide-pagination"
              custom_pagination={{
                total_count: totalCount,
                rows_per_page: rowsPerPage,

                page: page,
                handleChangePage: handleChangePage,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
              // custom_search={{
              //   searchText: searchText,
              //   setSearchText: setSearchText,
              //   handleSubmit: searchFunction,
              // }}
              pageCount={pageCount}
              totalPages={totalPages}
              handleChangePages={handleChangePages}
              // pagePagination={true}
            />
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <SubscriptionListFilter
            filterState={filterState}
            handleChange={handleChange}
            handleChangeOther={handleChangeOther}
            handleChangeDate={handleChangeDate}
            searchSubmitFilter={searchFunction}
            handleClearFilter={handleClearFilter}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
          />
        }
      />
      <CustomPopover
        isOpenPop={showPop}
        isClosePop={setshowPop}
        heigh="200px"
        title={value?.member_name ?? "detail"}
        componentToPassDown={<SubscriptionDetail value={value} />}
      />
    </>
  );
}
