import {
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { uploadImageOns3 } from "src/DAL/commonApi/commonApi";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { get_sales_team_navitems, navItemApi } from "src/DAL/Menu/Menu";
import { useSnackbar } from "notistack";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import { Icon } from "@iconify/react";
import RecordNotFound from "src/components/RecordNotFound";
import IsLinkField from "../../components/NavItems/IsLinkField";
import LinkAndPathField from "../../components/NavItems/LinkAndPathField";
import TitleField from "../../components/NavItems/TitleField";
import ValueField from "../../components/NavItems/ValueField";
import IsOpenNewTab from "../../components/NavItems/IsOpenNewTab";
import RemoveOrLocked from "../../components/NavItems/RemoveOrLocked";
import UploadImageBox from "../../components/NavItems/UploadImageBox";
import searchFill from "@iconify/icons-eva/search-fill";
import { styled } from "@mui/material/styles";
const uuidv4 = require("uuid").v4;

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 41,
  color: "#fff",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    boxShadow: theme.customShadows.z8,
    border: "2px solid #f6bd4b",
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const GeneralNavItems = ({ type, value, heading }) => {
  const [navItems, setNavItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [addNavItem, setAddNavItem] = useState("");
  const { userInfo } = usePGIMode();
  const classes = useStyles();

  const update_value = (name, value, parent_id, child_id) => {
    setNavItems((old) => {
      const nav_items = [...old];

      const index = nav_items.findIndex((item) => item._id === parent_id);
      if (index === -1) return nav_items; // parent item not found

      const selectedObject = { ...nav_items[index] };

      if (child_id) {
        const child_index = selectedObject.child_options.findIndex(
          (child) => child._id === child_id
        );
        if (child_index === -1) return nav_items; // child item not found
        const childObject = { ...selectedObject.child_options[child_index] };
        childObject[name] = value;
        selectedObject.child_options[child_index] = childObject;
      } else {
        selectedObject[name] = value;
      }
      nav_items[index] = selectedObject;
      return nav_items;
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleAddNavItem = (e) => {
    const { value } = e.target;
    scrollToBottom();
    setAddNavItem(value);
    let newParent = {};
    if (value === "parent") {
      newParent = {
        icon: "",
        is_link: false,
        is_open_new_tab: false,
        path: "",
        title: "",
        value: "",
        _id: uuidv4(),
        child_options: [],
      };
    } else {
      newParent = {
        icon: "",
        is_link: false,
        is_open_new_tab: false,
        title: "",
        is_expanded: true,
        value: "",
        _id: uuidv4(),
        child_options: [
          {
            icon: "",
            is_link: false,
            is_open_new_tab: false,
            path: "",
            title: "",
            value: "",
            _id: uuidv4(),
          },
        ],
      };
    }

    setNavItems((prevItems) => [...prevItems, newParent]);
  };

  const handleChangeInputs = (event, parent, child = null) => {
    const value = event.target.value;
    const name = event.target.name;
    const parent_id = parent._id;
    const child_id = child ? child._id : null;
    update_value(name, value, parent_id, child_id);
  };

  const fileChangedHandler = async (e, parent, child = null) => {
    const parent_id = parent._id;
    const child_id = child ? child._id : null;
    update_value("is_loading", true, parent_id, child_id);
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("width", "2200");
    try {
      const imageUpload = await uploadImageOns3(formData);
      if (imageUpload.code === 200) {
        update_value("icon", imageUpload.image_path, parent_id, child_id);
        setTimeout(() => {
          // Just waiting for set image in state for a while
          update_value("is_loading", false, parent_id, child_id);
        }, 0);
      } else {
        update_value("is_loading", false, parent_id, child_id);
        enqueueSnackbar(imageUpload.message, { variant: "error" });
      }
    } catch (error) {
      update_value("is_loading", false, parent_id, child_id);
      enqueueSnackbar("Image upload failed", { variant: "error" });
    }
  };

  const handleRemove = (parent, child = null) => {
    const parent_id = parent._id;
    const child_id = child ? child._id : null;
    update_value("icon", "", parent_id, child_id);
  };

  const handleToggle = (index) => {
    setNavItems((old) =>
      old.map((item, i) => {
        if (i == index) {
          item.is_expanded = !item.is_expanded;
        }
        return item;
      })
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(navItems);

    if (result.type === "PARENT") {
      const [reorderedItem] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, reorderedItem);
    } else if (result.type === "CHILD") {
      const parentIndex = result.source.droppableId;
      const parent = newItems[parentIndex];

      const [reorderedChild] = parent.child_options.splice(
        result.source.index,
        1
      );
      parent.child_options.splice(result.destination.index, 0, reorderedChild);
    }

    newItems.forEach((item, index) => {
      item.order = index + 1;
      if (item.child_options) {
        item.child_options.forEach((childItem, childIndex) => {
          childItem.order = childIndex + 1;
        });
      }
    });

    setNavItems(newItems);
  };

  const getNavItemsList = (array_, query) => {
    if (query) {
      const _nav_list = array_
        .map((data) => {
          const is_main =
            data.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
          const match_child = data.child_options?.filter(
            (item) =>
              item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
          );

          if (is_main) {
            return data;
          } else if (match_child?.length > 0) {
            return {
              ...data,
              child_options: match_child,
            };
          }
          return null;
        })
        .filter(Boolean);
      return _nav_list;
    }
    return array_;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingButton(true);

    let handle_values = (item) => {
      return {
        icon: item.icon,
        is_link: item.is_link,
        is_locked: item.is_locked,
        type: item.type,
        show_on: item.show_on,
        is_open_new_tab: item.is_open_new_tab,
        link: item.link,
        order: item.order,
        path: item.path,
        title: item.title,
        value: item.value,
        matches: item.matches,
        _id: item._id,
      };
    };

    let items_array = navItems.map((item) => {
      let mappedItem = { ...handle_values(item) };
      if (item.child_options && item.child_options.length > 0) {
        mappedItem.child_options = item.child_options.map((child) =>
          handle_values(child)
        );
      }
      return mappedItem;
    });

    let postData = {
      [value]: items_array,
      nav_item_type: type,
    };
    const result = await navItemApi(postData);
    if (result.code == 200) {
      setIsLoadingButton(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingButton(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleRemoveNavItem = (index) => {
    if (navItems.length === 1) return;
    const updatedItem = navItems.filter((_, i) => i !== index);
    setNavItems(updatedItem);
  };

  const GetNavList = async () => {
    setIsLoading(true);
    const result = await get_sales_team_navitems(type);
    if (result.code == 200) {
      let new_array = result.nav_items.map((item) => {
        if (!item.child_options) {
          item.child_options = [];
        }
        return item;
      });
      setNavItems(new_array);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    GetNavList();
  }, [type]);

  useEffect(() => {
    setNavItems((old) =>
      old.map((item) => {
        return { ...item, is_expanded: true };
      })
    );
  }, [searchInput]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin mb-4 mt-2">
        <div className="col-md-6">
          <h2>{heading} Nav Items</h2>
        </div>
        <div className="col-md-6 d-flex justify-content-end">
          <SearchStyle
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <Box
                  component={Icon}
                  icon={searchFill}
                  sx={{ color: "text.disabled" }}
                />
              </InputAdornment>
            }
          />
          {userInfo.role == "super_admin" && (
            <div className="ps-3">
              <FormControl sx={{ minWidth: 150 }} size="small">
                <InputLabel id="demo-select-small-label">
                  Add Nav Item
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={addNavItem}
                  label="Add Nav Item"
                  onChange={handleAddNavItem}
                >
                  <MenuItem value="parent">Add Parent</MenuItem>
                  <MenuItem value="child">Add Parent With Child</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="parents" type="PARENT">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {getNavItemsList(navItems, searchInput).length > 0 ? (
                  getNavItemsList(navItems, searchInput).map((item, index) => (
                    <Draggable
                      key={index}
                      draggableId={`parent-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <>
                            {item.child_options &&
                            item.child_options.length <= 0 ? (
                              <div className="card mt-2">
                                <div className="main row position-relative">
                                  <div className={`col-md-2 mt-3 mb-3 ms-2`}>
                                    <TitleField
                                      item={item}
                                      handleChange={(e) =>
                                        handleChangeInputs(e, item)
                                      }
                                    />
                                  </div>
                                  <div className={`col-md-2 mt-3 mb-3 ps-0`}>
                                    <IsLinkField
                                      item={item}
                                      handleChange={(e) =>
                                        handleChangeInputs(e, item)
                                      }
                                    />
                                  </div>

                                  {(userInfo.role == "super_admin" ||
                                    item.is_link) && (
                                    <div className={`col-md-2 mt-3 mb-3 ps-0`}>
                                      <LinkAndPathField
                                        item={item}
                                        handleChange={(e) =>
                                          handleChangeInputs(e, item)
                                        }
                                      />
                                    </div>
                                  )}

                                  {userInfo.role == "super_admin" && (
                                    <div className="col-md-2 mt-3 mb-3 ps-0">
                                      <ValueField
                                        item={item}
                                        handleChange={(e) =>
                                          handleChangeInputs(e, item)
                                        }
                                      />
                                    </div>
                                  )}

                                  <div
                                    className={`${
                                      userInfo.role == "super_admin" ||
                                      item.is_link
                                        ? "col-md-2"
                                        : "col-md-3"
                                    } mt-3 mb-3 ps-0`}
                                  >
                                    <IsOpenNewTab
                                      item={item}
                                      handleChange={(e) =>
                                        handleChangeInputs(e, item)
                                      }
                                    />
                                  </div>
                                  <div
                                    className={`${
                                      userInfo.role == "super_admin" ||
                                      item.is_link
                                        ? "col-md-2"
                                        : "col-md-3"
                                    } mt-3 mb-3 ms-2`}
                                  >
                                    <RemoveOrLocked
                                      item={item}
                                      heading={heading}
                                      handleChange={(e) =>
                                        handleChangeInputs(e, item)
                                      }
                                    />
                                  </div>
                                  <div
                                    className={`col-md-1 navitems ${
                                      item?.icon ? "mt-2" : "mt-3"
                                    }`}
                                  >
                                    <UploadImageBox
                                      item={item}
                                      handleRemove={() => handleRemove(item)}
                                      handleChange={(e) =>
                                        fileChangedHandler(e, item)
                                      }
                                    />
                                  </div>
                                  <div
                                    className="menu-box-option"
                                    {...provided.dragHandleProps}
                                  >
                                    <div className="menu_parent">
                                      <DragIndicatorIcon />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="mt-2">
                                <Accordion
                                  className="question-background"
                                  expanded={Boolean(item.is_expanded)}
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      <ExpandMoreIcon
                                        onClick={() => {
                                          handleToggle(index);
                                        }}
                                      />
                                    }
                                    aria-controls={`panel1a-content${index}`}
                                    id={`panel1a-header${index}`}
                                    className="svg-color text-white nav-item-box"
                                  >
                                    <div className="card-accordian mt-2">
                                      <div className="main menu-box-row row ps-0">
                                        <div className={`col-md-4 ms-2`}>
                                          <TitleField
                                            item={item}
                                            handleChange={(e) =>
                                              handleChangeInputs(e, item)
                                            }
                                          />
                                        </div>
                                        {userInfo.role == "super_admin" && (
                                          <div className="col-md-3 ps-0">
                                            <ValueField
                                              item={item}
                                              handleChange={(e) =>
                                                handleChangeInputs(e, item)
                                              }
                                            />
                                          </div>
                                        )}
                                        <div
                                          className={`${
                                            userInfo.role == "super_admin"
                                              ? "col-md-3"
                                              : "col-md-6"
                                          } mb-3 ps-0`}
                                        ></div>
                                        <div className="col-md-1 navitems">
                                          <UploadImageBox
                                            item={item}
                                            handleRemove={() =>
                                              handleRemove(item)
                                            }
                                            handleChange={(e) =>
                                              fileChangedHandler(e, item)
                                            }
                                          />
                                        </div>
                                        <div className="menu-box-option">
                                          <div
                                            className="menu"
                                            {...provided.dragHandleProps}
                                          >
                                            <DragIndicatorIcon />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </AccordionSummary>
                                  <div className="">
                                    <AccordionDetails>
                                      <Droppable
                                        droppableId={`${index}`}
                                        type="CHILD"
                                      >
                                        {(childProvided) => (
                                          <div
                                            ref={childProvided.innerRef}
                                            {...childProvided.droppableProps}
                                          >
                                            {item.child_options &&
                                              item.child_options.length > 0 &&
                                              item.child_options.map(
                                                (items, child_index) => (
                                                  <Draggable
                                                    key={items._id}
                                                    draggableId={`${items._id}`}
                                                    index={child_index}
                                                  >
                                                    {(childProvided) => (
                                                      <div
                                                        ref={
                                                          childProvided.innerRef
                                                        }
                                                        {...childProvided.draggableProps}
                                                      >
                                                        <div className="main row position-relative">
                                                          <div
                                                            className={`${
                                                              userInfo.role ==
                                                                "super_admin" ||
                                                              items?.is_link
                                                                ? "col-md-2"
                                                                : "col-md-3"
                                                            } mt-3 mb-3 ms-2`}
                                                          >
                                                            <TitleField
                                                              item={items}
                                                              handleChange={(
                                                                e
                                                              ) =>
                                                                handleChangeInputs(
                                                                  e,
                                                                  item,
                                                                  items
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <div
                                                            className={`col-md-2 mb-3 ps-0 mt-3`}
                                                          >
                                                            <IsLinkField
                                                              item={items}
                                                              handleChange={(
                                                                e
                                                              ) =>
                                                                handleChangeInputs(
                                                                  e,
                                                                  index,
                                                                  child_index
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          {(userInfo.role ==
                                                            "super_admin" ||
                                                            items?.is_link) && (
                                                            <div
                                                              className={` ${
                                                                userInfo.role ==
                                                                  "super_admin" ||
                                                                items?.is_link
                                                                  ? "col-md-2"
                                                                  : "col-md-3"
                                                              } mt-3 mb-3 ps-0`}
                                                            >
                                                              <LinkAndPathField
                                                                item={items}
                                                                handleChange={(
                                                                  e
                                                                ) =>
                                                                  handleChangeInputs(
                                                                    e,
                                                                    index,
                                                                    child_index
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                          )}

                                                          {userInfo.role ==
                                                            "super_admin" && (
                                                            <div className="col-2 mt-3 mb-3 ps-0">
                                                              <ValueField
                                                                item={items}
                                                                handleChange={(
                                                                  e
                                                                ) =>
                                                                  handleChangeInputs(
                                                                    e,
                                                                    index,
                                                                    child_index
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                          )}

                                                          <div
                                                            className={`col-12 col-md-2 mb-3 ps-0 mt-3`}
                                                          >
                                                            <IsOpenNewTab
                                                              item={items}
                                                              handleChange={(
                                                                e
                                                              ) =>
                                                                handleChangeInputs(
                                                                  e,
                                                                  item,
                                                                  items
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <div
                                                            className={`col-12 ${
                                                              userInfo.role ==
                                                                "super_admin" ||
                                                              items?.is_link
                                                                ? "col-md-2"
                                                                : "col-md-3"
                                                            }  mb-3 ms-2 mt-3`}
                                                          >
                                                            <RemoveOrLocked
                                                              item={items}
                                                              heading={heading}
                                                              handleChange={(
                                                                e
                                                              ) =>
                                                                handleChangeInputs(
                                                                  e,
                                                                  item,
                                                                  items
                                                                )
                                                              }
                                                            />
                                                          </div>

                                                          <div
                                                            className={`col-md-1 navitems ${
                                                              item?.icon
                                                                ? "mt-2"
                                                                : "mt-3"
                                                            }`}
                                                          >
                                                            <UploadImageBox
                                                              item={items}
                                                              handleRemove={() =>
                                                                handleRemove(
                                                                  item,
                                                                  items
                                                                )
                                                              }
                                                              handleChange={(
                                                                e
                                                              ) =>
                                                                fileChangedHandler(
                                                                  e,
                                                                  item,
                                                                  items
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <div className="menu-box-option">
                                                            <div
                                                              className="menu_parent"
                                                              {...childProvided.dragHandleProps}
                                                            >
                                                              <DragIndicatorIcon />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                )
                                              )}
                                            {childProvided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </AccordionDetails>
                                  </div>
                                </Accordion>
                              </div>
                            )}
                          </>
                        </div>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <RecordNotFound title="Data" />
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            {isLoadingButton ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default GeneralNavItems;
