import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { delegate_Url, s3baseUrl } from "src/config/config";
import ReactVideoPlayer from "../../components/ReactVideoPlayer/ReactVideoPlayer";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { deleteProgramme, programmeDetail } from "src/DAL/Programme/Programme";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import { useState } from "react";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function ProgrammesDetail() {
  const params = useParams();
  const classes = useStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [programmeDetailInfo, setProgrammeDetailInfo] = useState();
  const [openDelete, setOpenDelete] = useState(false);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const handleCopyReferralId = async (e) => {
    await navigator.clipboard.writeText(
      delegate_Url + "delegate-training/" + programmeDetailInfo.program_slug
    );
    enqueueSnackbar("Delegate Training Url Copied", { variant: "success" });
  };

  const handleNavigateEdit = () => {
    navigate(`/programmes/edit-programme/${params.programme_slug}`, {
      state: programmeDetailInfo,
    });
  };
  const handleNavigateReminder = () => {
    navigate(`/programmes/reminder-config/${params.programme_slug}`, {
      state: programmeDetailInfo,
    });
  };

  const handleNavigateGroup = () => {
    navigate(`/programmes/automated-groups/${params.programme_slug}`, {
      state: programmeDetailInfo,
    });
  };
  const handleNavigateAdd = () => {
    navigate(`/programmes/${"add"}/${programmeDetailInfo._id}`, {
      state: programmeDetailInfo,
    });
  };
  const handleNavigateRemove = () => {
    navigate(`/programmes/${"remove"}/${programmeDetailInfo._id}`, {
      state: programmeDetailInfo,
    });
  };

  const handleNavigateDocs = () => {
    navigate(`/programmes/programm-document/${params.programme_slug}`, {
      state: programmeDetailInfo,
    });
  };
  const handleNavigateConfiguration = () => {
    navigate(`/programmes/programm-configuration/${params.programme_slug}`, {
      state: programmeDetailInfo,
    });
  };

  const handleNavigateSection = () => {
    navigate(`/programmes/sections/${params.programme_slug}`, {
      state: programmeDetailInfo,
    });
  };

  const handleNavigateReviews = () => {
    navigate(`/programmes/reviews/${params.programme_slug}`, {
      state: programmeDetailInfo,
    });
  };

  const handleLockedProgramInfo = () => {
    navigate(`/programmes/locked-programme/${params.programme_slug}`, {
      state: programmeDetailInfo,
    });
  };

  const handleManageLessons = () => {
    navigate(`/lessons/${params.programme_slug}`, {
      state: programmeDetailInfo,
    });
  };
  const handleManageProgramModule = () => {
    navigate(`/programmes/modules/${params.programme_slug}`, {
      state: programmeDetailInfo,
    });
  };
  const handleManageMember = () => {
    navigate(`/programmes/members/${programmeDetailInfo._id}`, {
      state: programmeDetailInfo,
    });
  };
  const ManageNavItemsAccess = () => {
    navigate(`/programmes/navitems/${programmeDetailInfo._id}`, {
      state: programmeDetailInfo,
    });
  };
  const handleNavigateQuestions = () => {
    navigate(
      `/programmes/delegate-questions?created_for_id=${programmeDetailInfo._id}`,
      {
        state: programmeDetailInfo,
      }
    );
  };
  const handleNavigateAnswers = () => {
    navigate(
      `/programmes/delegate-questions/answers?created_for_id=${programmeDetailInfo._id}`,
      {
        state: programmeDetailInfo,
      }
    );
  };

  const handleDelete = async () => {
    handleCloseDelete();
    let result = await deleteProgramme(params.programme_slug);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate("/programmes");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDetail = async () => {
    setIsLoading(true);
    let result = await programmeDetail(params.programme_slug);
    if (result.code === 200) {
      console.log(result, "result of the program");
      setProgrammeDetailInfo(result.program);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },

    {
      title: programmeDetailInfo?.title,
      navigation: ``,
      active: true,
    },
  ];
  if (programmeDetailInfo?.title) {
    localStorage.setItem("program_title", programmeDetailInfo?.title);
    localStorage.setItem("program_slug", programmeDetailInfo?.program_slug);
  }
  useEffect(() => {
    if (state) {
      console.log(state, "statestatestatestatestatestatestatestatestate");
      setProgrammeDetailInfo(state);
    } else {
      handleDetail();
    }
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const delegate_questions_child_options = [
    {
      label: "Questions List",
      icon: "akar-icons:edit",
      handleClick: handleNavigateQuestions,
    },
    {
      label: "Question Answers",
      icon: "akar-icons:edit",
      handleClick: handleNavigateAnswers,
    },
  ];

  let MENU_OPTIONS = [
    {
      label: "Tab Configuration",
      icon: "akar-icons:edit",
      handleClick: handleNavigateConfiguration,
    },
    {
      label: "Manage Doc",
      icon: "akar-icons:edit",
      handleClick: handleNavigateDocs,
    },
    {
      label: "Manage Sections",
      icon: "akar-icons:edit",
      handleClick: handleNavigateSection,
    },
    {
      label: "Manage Lessons",
      icon: "akar-icons:edit",
      handleClick: handleManageLessons,
    },
    {
      label: "Manage Program Modules",
      icon: "akar-icons:edit",
      handleClick: handleManageProgramModule,
    },
    {
      label: "Manage Member",
      icon: "akar-icons:edit",
      handleClick: handleManageMember,
    },
    {
      label: "Manage Nav Items Access",
      icon: "fluent:video-recording-20-regular",
      handleClick: ManageNavItemsAccess,
    },
    {
      label: "Programme Reviews",
      icon: "akar-icons:edit",
      handleClick: handleNavigateReviews,
    },
    {
      label: "Automated Group",
      icon: "akar-icons:edit",
      handleClick: handleNavigateGroup,
    },
    {
      label: "Add Member Programme Access",
      icon: "akar-icons:edit",
      handleClick: handleNavigateAdd,
    },
    {
      label: "Remove Member Programme Access",
      icon: "akar-icons:edit",
      handleClick: handleNavigateRemove,
    },
    {
      label: "Locked Programme Info",
      icon: "akar-icons:edit",
      handleClick: handleLockedProgramInfo,
    },
    {
      label: "Delegate Questions",
      icon: "akar-icons:edit",
      child_options: delegate_questions_child_options,
    },
    {
      label: "Program Reminder Configuration",
      icon: "akar-icons:edit",
      handleClick: handleNavigateReminder,
    },
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleNavigateEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleOpenDelete,
    },
    {
      label: "Copy Delegate Training URL",
      icon: "akar-icons:edit",
      handleClick: handleCopyReferralId,
    },
  ];

  return (
    <div className="container-fluid">
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this programme?"}
        handleAgree={handleDelete}
      />
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <span className="">
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </span>
        </div>
      </div>
      <div className="row section-space">
        <div className="col-12 d-flex justify-content-between">
          <h2>{programmeDetailInfo?.title}</h2>
          <CustomPopoverSection
            menu={MENU_OPTIONS}
            data={programmeDetailInfo}
          />
        </div>
      </div>

      <div className="row media-margin">
        <div className="col-12">
          {programmeDetailInfo?.video_url == "" ? (
            <img
              src={
                programmeDetailInfo?.program_images
                  ? s3baseUrl + programmeDetailInfo.program_images.thumbnail_1
                  : ""
              }
            />
          ) : (
            <ReactVideoPlayer url={programmeDetailInfo?.video_url} />
          )}
        </div>
        <div className="col-12 section-space">
          {programmeDetailInfo?.audio_file == "" ? (
            ""
          ) : (
            <audio
              className="w-100"
              src={
                programmeDetailInfo?.audio_file
                  ? s3baseUrl + programmeDetailInfo?.audio_file
                  : ""
              }
              controls
            />
          )}
        </div>
        <div className="col-12 section-space img_width_100">
          <p>
            <div
              dangerouslySetInnerHTML={{
                __html: programmeDetailInfo?.detailed_description,
              }}
            ></div>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProgrammesDetail;
