import * as React from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
//import Editor from "../../components/Editor/Editor";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
// import { VaultListing } from "src/DAL/Vault/Vault";
import Autocomplete from "@mui/material/Autocomplete";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { IconButton, Divider, CircularProgress } from "@mui/material";
// import { AddProgram } from "src/DAL/Programmes/Programmes";
import { object } from "prop-types";
import { menuList } from "src/DAL/Menu/Menu";
import TinyEditor from "src/components/ckeditor/Ckeditor";
import {
  activeSupportTeamListApi,
  addDepartmentApi,
} from "src/DAL/Department/Department";
import { add_dynamite_event_start_expiry_api } from "src/DAL/DynamiteEvents/DynamiteEvents";
import DynamiteEventStartExpiryMembers from "../DynamiteEvents/EventMembers/DynamiteEventStartExpiryMembers";
import {
  add_programme_bulk_access_api,
  programmeListing,
  programmeMemberListing,
} from "src/DAL/Programme/Programme";
import ProgrammeBulkMembers from "./ProgrammeBulkMembers";
import { capitalCase } from "change-case";
import ActiveLastBreadcrumb from "src/components/GeneralComponents/BreadCrums";

// import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function ProgrammeBulkAccess() {
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [detailDescriptionCk, setDetailDescriptionCk] = React.useState("");
  const [date, setDate] = useState(new Date());
  const [selected, setSelected] = useState([]);
  const [reload, setReload] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [programValue, setProgramValue] = React.useState([]);
  const { state } = useLocation();
  const handleChangeDate = (newValue) => {
    setDate(newValue);
  };

  const handleChangeProgram = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedProgram(value);
  };

  const [inputs, setInputs] = React.useState({
    change_for: "all",
    detailed_description: "",
    program_access_type: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      type: inputs.change_for,
      program: params.id,
      program_access_type: params.type,
    };
    if (inputs.change_for == "specific") {
      postData.member = selected;
    }

    // console.log(postData, "post Data");
    setIsLoading(false);

    const result = await add_programme_bulk_access_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      //   navigate(-1);
      setReload(!reload);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const programmeListData = async () => {
    let result = await programmeListing();
    if (result.code == 200) {
      setProgramValue(result.program);
    }
  };
  let program_title = localStorage.getItem("program_title");
  let program_slug_local = localStorage.getItem("program_slug");
  let breadCrumbMenu = [
    {
      title: "Programmes",
      navigation: `/programmes`,
      active: false,
    },

    {
      title: state ? state?.title : program_title ? program_title : "",
      navigation: `/programmes/programmes-detail/${program_slug_local}`,
      active: false,
    },
    {
      title:
        params.type == "add"
          ? "Add Member Programme Access"
          : "Remove Member Programme Access",
      navigation: ``,
      active: true,
    },
  ];

  React.useEffect(() => {
    programmeListData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <div className="col-12 mb-3">
            <span className="">
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2>
            {params.type == "add"
              ? "Add Member Programme Access"
              : "Remove Member Programme Access"}
          </h2>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="mb-5">
        <div className="row">
          {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Choose Access Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="program_access_type"
                value={inputs.program_access_type}
                label="Change For All Users"
                onChange={handleChange}
              >
                <MenuItem value="add">Add Access</MenuItem>
                <MenuItem value="remove">Remove Access</MenuItem>
              </Select>
            </FormControl>
          </div> */}
          {
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Programme Access For
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="change_for"
                  value={inputs.change_for}
                  label="Programme Access For"
                  onChange={handleChange}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="specific">Specific</MenuItem>
                </Select>
              </FormControl>
            </div>
          }

          <div
            className="text-end mt-4"
            id={`${inputs.change_for == "specific" ? "fixedbutton" : ""}`}
          >
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
      {inputs.change_for == "specific" && (
        <ProgrammeBulkMembers
          selected={selected}
          setSelected={setSelected}
          reload={reload}
        />
      )}
    </div>
  );
}
