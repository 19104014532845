import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import {
  add_billing_ticket_api,
  getAddTransactionDataListApi,
} from "src/DAL/Transaction/transactionApi";
import { list_pages_for_billing_tickets_api } from "src/DAL/WebsitePages/WebsitePages";
import { useEffect } from "react";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import PhoneInput from "react-phone-input-2";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const get_user_name = (member) => {
  return member.first_name + " " + member.last_name + " (" + member.email + ")";
};

const get_affiliate_name = (member) => {
  return member?.affiliate_user_created_for == "memberuser"
    ? member?.first_name +
        " " +
        member?.last_name +
        " (" +
        member?.email +
        ")" +
        " | Member"
    : member?.first_name +
        " " +
        member?.last_name +
        " (" +
        member?.email +
        ")" +
        " | Consultant";
};

const RenderOptions = (seats_count) => {
  let new_seats_count = seats_count;
  if (seats_count > 10) {
    new_seats_count = 10;
  }
  const options = [...Array(new_seats_count)].map((_, index) => {
    return (
      <MenuItem value={index + 1} key={index + 1}>
        {index + 1}
      </MenuItem>
    );
  });
  return options;
};

export default function AddPaidTicket() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [pagesList, setPagesList] = useState([]);
  const [affiliateList, setAffiliateList] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [plansList, setPlansList] = useState([]);
  const [serchText, setSerchText] = useState("");
  const [serchTextAffiliate, setSerchTextAffiliate] = useState("");
  const [selectedMember, setSelectedMember] = useState(null);
  const [affiliate, setAffiliate] = useState(null);
  const [affiliate2, setAffiliate2] = useState(null);
  const [venuesList, setVenuesList] = useState([]);
  const [usersList, setUsersList] = useState([]);

  const [inputs, setInputs] = useState({
    title: "",
    page: null,
    status: true,
    member_type: "existing",
    transaction_Type: "sale_page",
    amount: "",
    first_name: "",
    last_name: "",
    contact_number: "",
    email: "",
    venue: "",
    password: "",
    ticket_type: "paid",
    plan_type: "onetime",
    plan: "",
    referral_commission: "0",
    dynamite_digital_commission: 0,
    first_affiliate_commission: 0,
    second_affiliate_commission: 0,
    selected_tickets: 1,
  });

  const getLists = async (type) => {
    let postData = {
      type: type ? type : "",
      user_id: selectedMember ? selectedMember._id : "",
      page_id: inputs.page,
      search_text: serchText,
    };
    const result = await getAddTransactionDataListApi(postData);
    if (result.code == 200) {
      let members = result.members.map((member) => {
        return {
          ...member,
          user_name: get_user_name(member),
        };
      });
      setMembersList(members);
      setAffiliateList(result?.affiliate);
      get_list_pages_for_billing_tickets();
    }
  };

  const getSearchGroupsAndMembers = async () => {
    let postData = {
      type: "",
      user_id: "",
      page_id: "",
      search_text: serchText,
    };
    const result = await getAddTransactionDataListApi(postData);
    if (result.code === 200) {
      let members = result.members.map((member) => {
        return {
          ...member,
          user_name: get_user_name(member),
        };
      });
      setMembersList(members);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const get_list_pages_for_billing_tickets = async () => {
    const result = await list_pages_for_billing_tickets_api();
    if (result.code === 200) {
      setPagesList(result.sale_page);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const getMemberAffiliate = async () => {
    if (selectedMember?._id && selectedMember.affliliate) {
      setAffiliate(selectedMember.affliliate);
    }
  };

  const getSearchAffiliate = async () => {
    let postData = {
      type: "affiliate",
      user_id: selectedMember ? selectedMember._id : "",
      page_id: inputs.page,
      search_text: serchTextAffiliate,
    };
    const result = await getAddTransactionDataListApi(postData);
    if (result.code === 200) {
      setAffiliateList(result?.affiliate);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const number_of_seats_variable = (plan) => {
    let variable_to_use = "available_number_of_seats";
    if (plan?.plan_ticket_type == "vip") {
      variable_to_use = "available_vip_number_of_seats";
    } else if (plan?.plan_ticket_type == "executive") {
      variable_to_use = "available_executive_number_of_seats";
    }
    return variable_to_use;
  };

  const get_available_seats = (venuesList, plan) => {
    let variable_to_use = number_of_seats_variable(plan);
    let sumOfSeats = 0;
    for (let i = 0; i < venuesList.length; i++) {
      sumOfSeats += venuesList[i][variable_to_use];
    }
    return sumOfSeats;
  };

  const is_disabled = (venue) => {
    let disabled = false;
    let variable_to_use = number_of_seats_variable(inputs.plan);
    if (venue[variable_to_use] < 1) {
      disabled = true;
    }

    return disabled;
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };

  const handleSearchAffiliate = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchTextAffiliate(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let phone_length = inputs.contact_number.toString().length;
    if (inputs.member_type == "new") {
      if (+phone_length < 10) {
        enqueueSnackbar("Please enter valid phone number", {
          variant: "error",
        });
        return;
      }
    }

    let first_user = {
      venue_id: inputs.venue,
      first_name: selectedMember?.first_name,
      last_name: selectedMember?.last_name,
      email: selectedMember?.email,
      contact_number: selectedMember?.contact_number,
    };

    if (inputs.member_type == "new") {
      first_user = {
        venue_id: inputs.venue,
        first_name: inputs.first_name,
        last_name: inputs.last_name,
        email: inputs.email,
        contact_number: inputs.contact_number,
        password: inputs.password,
      };
    }

    let tickets_user = [first_user, ...usersList];

    let postData = {
      amount: inputs.amount,
      member_type: inputs.member_type,
      transaction_type: "payment_plan",
      affliliate: affiliate?._id,
      sale_page: inputs.page?._id,
      plan: inputs.plan?._id,
      member: selectedMember?._id,
      tickets: tickets_user,
      ticket_type: inputs.ticket_type,
      ticket_count: inputs.selected_tickets,
    };

    if (inputs.member_type == "new") {
      postData.first_name = inputs.first_name;
      postData.last_name = inputs.last_name;
      postData.email = inputs.email;
      postData.contact_number = inputs.contact_number;
      postData.password = inputs.password;
    }

    if (inputs.ticket_type == "paid") {
      postData.referral_commission = inputs.first_affiliate_commission;
      postData.dynamite_commission = inputs.dynamite_digital_commission;
      postData.transaction_referral = affiliate2 ? affiliate2?._id : "";
      postData.transaction_referral_commission =
        inputs.second_affiliate_commission;
    }
    setIsLoading(true);

    const result = await add_billing_ticket_api(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate("/billing-tickets");
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangePlan = (value) => {
    let add_obj = {
      plan: value,
      amount: value.plan_price,
    };
    if (value.is_plan_free) {
      add_obj.ticket_type = "free";
    } else {
      add_obj.ticket_type = "paid";
    }
    setInputs((values) => ({ ...values, ...add_obj }));
  };

  const handleSelectPage = (value) => {
    setInputs((values) => ({ ...values, page: value, plan: null }));
    setPlansList(value.plans);
    setVenuesList(value.vanues);
  };

  const handleChangeTickets = (e) => {
    const { name, value } = e.target;
    let new_obj = {
      [name]: value,
      amount: inputs.plan?.plan_price * value,
    };
    setInputs((prevState) => ({
      ...prevState,
      ...new_obj,
    }));

    let new_value = value - 1;
    if (new_value > usersList.length) {
      let new_array = [];
      for (let i = 0; i < new_value - usersList.length; i++) {
        new_array.push({
          first_name: "",
          last_name: "",
          email: "",
          contact_number: "",
          venue_id: "",
        });
      }
      setUsersList((old) => [...old, ...new_array]);
    } else {
      setUsersList((prevArray) => prevArray.slice(0, new_value));
    }
  };

  const update_venus = (name, value, venue_selected) => {
    let variable_to_use = number_of_seats_variable(inputs.plan);
    if (name == "venue") {
      setVenuesList((prev) => {
        return prev.map((venue) => {
          if (venue._id == value) {
            return { ...venue, [variable_to_use]: venue[variable_to_use] - 1 };
          } else {
            return venue;
          }
        });
      });
    }
    if (venue_selected) {
      setVenuesList((prev) => {
        return prev.map((venue) => {
          if (venue._id == venue_selected) {
            return { ...venue, [variable_to_use]: venue[variable_to_use] + 1 };
          } else {
            return venue;
          }
        });
      });
    }
  };

  const handleChangeUsersList = (event, index) => {
    let venue_selected = "";
    const name = event.target.name;
    const value = event.target.value;

    let temp_state = [...usersList];
    let temp_element = { ...temp_state[index] };
    if (temp_element.venue) {
      venue_selected = temp_element.venue;
    }
    temp_element[name] = value;
    temp_state[index] = temp_element;
    setUsersList(temp_state);
    update_venus(name, value, venue_selected);
  };

  const handleChangeUsersListOther = (phone, index) => {
    let temp_state = [...usersList];
    let temp_element = { ...temp_state[index] };
    temp_element = { ...temp_element, contact_number: phone };
    temp_state[index] = temp_element;
    setUsersList(temp_state);
  };

  useEffect(() => {
    getLists();
  }, []);

  useEffect(() => {
    getMemberAffiliate();
  }, [selectedMember?._id]);

  useEffect(() => {
    getSearchGroupsAndMembers();
  }, [serchText]);

  useEffect(() => {
    getSearchAffiliate();
  }, [serchTextAffiliate]);

  useEffect(() => {
    setInputs((values) => ({ ...values, plan: "" }));
  }, [inputs.ticket_type]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(`/billing-tickets`)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>
          <h2 className="ms-1">Add Paid Ticket</h2>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Member Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="member_type"
                value={inputs.member_type}
                label="Member Type"
                onChange={handleChange}
                required
              >
                <MenuItem value="existing">Existing Member</MenuItem>
                <MenuItem value="new">New Member</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Ticket Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="ticket_type"
                value={inputs.ticket_type}
                label="Ticket Type"
                onChange={handleChange}
                required
              >
                <MenuItem value="paid">Paid</MenuItem>
                <MenuItem value="free">Free</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="mt-4 p-3 p-md-4 persons-list-billing">
          <div className="row">
            <div className="col-12">
              <h3>{`User Info`}</h3>
            </div>
            {inputs.member_type == "existing" ? (
              <div className="col-md-6 col-sm-12 mt-2">
                <Autocomplete
                  id="tags-outlined"
                  options={membersList}
                  getOptionLabel={(option) => option.user_name}
                  filterSelectedOptions
                  value={selectedMember}
                  onChange={(event, newValue) => {
                    setSelectedMember(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Members"
                      placeholder="Members"
                      onChange={handleSearch}
                      required
                    />
                  )}
                />
              </div>
            ) : (
              <>
                <div className="col-md-6 col-sm-12 mt-4 mt-md-2">
                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    required
                    name="first_name"
                    value={inputs.first_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mt-4 mt-md-2">
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    required
                    name="last_name"
                    value={inputs.last_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                    name="email"
                    value={inputs.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic1"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    required
                    type="password"
                    name="password"
                    value={inputs.password}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 col-sm-12 mt-4 table-phone-input ">
                  <PhoneInput
                    country={"gb"}
                    inputClass="react-phone-input"
                    dropdownClass="flag-input"
                    inputProps={{
                      name: "contact_number",
                      required: true,
                      autoComplete: "off",
                    }}
                    value="44"
                    enableSearch
                    disableSearchIcon
                    countryCodeEditable={false}
                    buttonStyle={{
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                    onChange={(value) => {
                      handleChangeOthers("contact_number", value);
                    }}
                  />
                </div>
              </>
            )}
            <div
              className={`${
                inputs.member_type == "existing"
                  ? "col-md-6 col-sm-12 mt-4 mt-md-2"
                  : "col-md-6 col-sm-12 mt-4"
              }`}
            >
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label1">
                  Select Venue
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label1"
                  id="demo-simple-select1"
                  name="venue"
                  value={inputs.venue}
                  label="Select Venue"
                  onChange={handleChange}
                  required
                >
                  {venuesList.map((venue) => {
                    return (
                      <MenuItem value={venue._id} disabled={is_disabled(venue)}>
                        {venue.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="mt-4 p-3 p-md-4 persons-list-billing">
          <div className="row">
            <div className="col-12">
              <h3>{`Sale Page Info`}</h3>
            </div>
            <div className="col-md-6 col-sm-12 mt-2 ">
              <MUIAutocomplete
                inputLabel="Sale Page"
                selectedOption={inputs.page}
                setSelectedOption={handleSelectPage}
                optionsList={pagesList}
                name="sale_page_title"
                required
              />
            </div>
            <div className="col-md-6 col-sm-12 mt-4 mt-md-2">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Payment Plan
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="plan"
                  value={!!inputs.plan ? inputs.plan?._id : ""}
                  label="Payment Plan"
                  required
                >
                  {plansList.map((plan) => {
                    let available_seats = get_available_seats(venuesList, plan);
                    return (
                      <MenuItem
                        value={plan?._id}
                        disabled={available_seats < 1 ? true : false}
                        onClick={() => handleChangePlan(plan)}
                      >
                        {plan?.plan_title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {inputs.plan?.event_type == "onsite" &&
              get_available_seats(venuesList, inputs.plan) > 0 && (
                <>
                  <div className="col-md-6 col-sm-12 mt-4">
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label1">
                        Select Tickets
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label1"
                        id="demo-simple-select1"
                        name="selected_tickets"
                        value={inputs.selected_tickets}
                        label="Select Tickets"
                        onChange={handleChangeTickets}
                        required
                      >
                        {RenderOptions(
                          get_available_seats(venuesList, inputs.plan)
                        )}
                      </Select>
                    </FormControl>
                  </div>
                </>
              )}
          </div>
        </div>
        {inputs.ticket_type == "paid" && inputs.plan && (
          <div className="mt-4 p-3 p-md-4 persons-list-billing">
            <div className="col-12">
              <h3>{`Transaction Info`}</h3>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12 mt-2">
                <TextField
                  id="outlined-basic"
                  label="Amount"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="amount"
                  disabled
                  value={inputs.amount}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-4 mt-md-2">
                <TextField
                  id="outlined-basic"
                  label="Dynamite Digital Commission"
                  variant="outlined"
                  required
                  type="number"
                  fullWidth
                  name="dynamite_digital_commission"
                  value={inputs.dynamite_digital_commission}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-4">
                <Autocomplete
                  id="tags-outlined"
                  options={affiliateList}
                  getOptionLabel={(option) =>
                    get_affiliate_name(option?.affiliate_user_info)
                  }
                  filterSelectedOptions
                  value={affiliate}
                  onChange={(event, newValue) => {
                    setAffiliate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Referral User"
                      placeholder="Referral User"
                      onChange={handleSearchAffiliate}
                    />
                  )}
                />
              </div>
              <div className="col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Referral User Commission"
                  variant="outlined"
                  required
                  fullWidth
                  type="number"
                  name="first_affiliate_commission"
                  value={inputs.first_affiliate_commission}
                  onChange={handleChange}
                />
              </div>
              {inputs.plan?.commission_pay_to == "both" && (
                <>
                  <div className="col-md-6 col-sm-12 mt-4">
                    <Autocomplete
                      id="tags-outlined"
                      options={affiliateList}
                      getOptionLabel={(option) =>
                        get_affiliate_name(option?.affiliate_user_info)
                      }
                      filterSelectedOptions
                      value={affiliate2}
                      onChange={(event, newValue) => {
                        setAffiliate2(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Transaction Referral"
                          placeholder="Transaction Referral"
                          onChange={handleSearchAffiliate}
                          required={
                            inputs.second_affiliate_commission > 0
                              ? true
                              : false
                          }
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Transaction Referral Commission"
                      variant="outlined"
                      fullWidth
                      type="number"
                      name="second_affiliate_commission"
                      value={inputs.second_affiliate_commission}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {inputs.selected_tickets > 0 &&
          usersList.map((user, index) => {
            return (
              <div className="mt-4 p-3 p-md-4 persons-list-billing">
                <div className="col-12">
                  <h3>{`Person ${index + 2}`}</h3>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12 mt-2">
                    <TextField
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      required
                      name="first_name"
                      value={user.first_name}
                      onChange={(e) => {
                        handleChangeUsersList(e, index);
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mt-4 mt-md-2">
                    <TextField
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      required
                      name="last_name"
                      value={user.last_name}
                      onChange={(e) => {
                        handleChangeUsersList(e, index);
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      required
                      name="email"
                      value={user.email}
                      onChange={(e) => {
                        handleChangeUsersList(e, index);
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mt-4 table-phone-input">
                    <PhoneInput
                      country={"gb"}
                      inputClass="react-phone-input"
                      dropdownClass="flag-input"
                      inputProps={{
                        name: "contact_number",
                        autoComplete: "off",
                      }}
                      value="44"
                      enableSearch
                      disableSearchIcon
                      countryCodeEditable={false}
                      buttonStyle={{
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onChange={(phone) => {
                        handleChangeUsersListOther(phone, index);
                      }}
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <FormControl fullWidth required>
                      <InputLabel id="demo-simple-select-label1">
                        Select Venue
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label1"
                        id="demo-simple-select1"
                        name="venue_id"
                        value={user.venue_id}
                        label="Select Venue"
                        onChange={(e) => {
                          handleChangeUsersList(e, index);
                        }}
                        required
                      >
                        {venuesList.map((venue) => {
                          return (
                            <MenuItem
                              value={venue._id}
                              disabled={is_disabled(venue)}
                            >
                              {venue.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            );
          })}
        <div className="text-end mt-4">
          <button className="small-contained-button">Submit</button>
        </div>
      </form>
    </div>
  );
}
